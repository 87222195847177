import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { Editor } from '@tinymce/tinymce-react';
import { useAppContext } from 'providers/AppProvider';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { logout } from 'store/actions/AuthActions';
import Error404 from 'pages/error/Error404';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

const NewTaskType = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useAppContext();
    let errorObj = {
        name: null,
        organization_id: null,
        description: null
    }
    const [organizationList, setOrganizationList] = useState([]);
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState('US');
    const [content, setContent] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        organization_id: '',
        description: ''
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Setting'), url: '#' },
        { label: translate(props.lang, 'Task Type'), url: '/task-type/overview' },
        { label: translate(props.lang, 'New Task Type'), active: true }
    ]
    useEffect(() => {
        if (props.lang === 'ZH') {
            setLanguage('zh_CN')
        } else {
            setLanguage('US')
        }
    }, [props.lang])

    useEffect(() => {
        getOrganization();
    }, [props.auth.auth.token])

    const getOrganization = () => {
        fetch(api.get_organizations, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data);
                } else if (responseJson.status === 401) {
                    dispatch(logout());
                    navigate('/')
                } else {
                    console.log('error company', responseJson.message)
                    // swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!content) {
            errorMsg.description = "This field is required.";
            check = false;
        }
        if (!formData.organization_id) {
            errorMsg.organization_id = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            newFormData.description = content;
            setLoading(true);
            fetch(api.add_project_category, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been created successfully."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        resetForm();
                        navigate('/task-type/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            organization_id: ''
        });
        setErrors(errorObj);
    }

    return (
        props?.auth?.permission?.createProjectCategory ? (
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <h2 className="mb-4">{translate(props.lang, "New Task Type")}</h2>
                <Row className='align-items-center justify-content-center'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task Type Name")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="name"
                                    placeholder={translate(props.lang, "Enter task type name")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organization_id ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select organization")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                    isSearchable={true}
                                    value={organizationList.filter(option => option.organization_id === formData.organization_id).length > 0 ? organizationList.filter(option => option.organization_id === formData.organization_id).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organization_id"
                                    onChange={(e) => handleOptionSelected(e, 'organization_id')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization_id)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Requirement")} <span className="text-danger">*</span></span>
                        <div className={`groupLabel-input w-100 px-3 ${errors.description ? 'errorBorder' : ''}`}>
                            <Editor
                                key={language}
                                apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                                init={{
                                    language: language,
                                    language_load: true,
                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                }}
                                initialValue={formData.description}
                                className="w-100"
                                onEditorChange={(content, editor) => {
                                    setContent(content);
                                }}
                            />
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/task-type/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewTaskType);
