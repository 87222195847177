import { faEdit, faFileExport, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const CustomerList = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [statistic, setStatistic] = useState(0);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });
    let breadcrumbData = [
        { label: translate(props.lang, 'Customer'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]
    const timerRef = useRef(null);
    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            if (props?.auth?.permission?.allCustomer) {
                getList();
                setFirstLoad(false)
            }
        }
    }, [props.auth.permission]);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, name, email, phone, companyName]);

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_customers + "?page=" + page + "&limit=" + productPerpage + '&details=1';
            let search = 0;
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (email) {
                search = 1;
                url = url + `&email=${email}`
            }
            if (phone) {
                search = 1;
                url = url + `&phone=${phone}`
            }
            if (companyName) {
                search = 1;
                url = url + `&customer_company_name=${companyName}`
            }
            // if (search) {
            //     url = url + `&isSearch=1`
            // }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            if (sortBy.subSort && sortBy.subOrder) {
                url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        setTotalResultCount(responseJson.data.subs.totalCustomers)
                        let totalPage = Math.ceil(responseJson.data.subs.totalCustomers / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.totalCustomers)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(entry => {
            let formattedData = {
                "title": entry.title ? entry.title : '-',
                "Name": entry.name ? entry.name : '-',
                "Email": entry.email ? entry.email : '-',
                "Phone": entry.phone ? entry.country_code + entry.phone : '-',
                "Company": entry.customer_company_name ? entry.customer_company_name : '-',
            };
            newData.push(formattedData);
        });
        return newData;
    }

    const exportExcel = () => {
        setLoadingExcel(true);
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_customers + `?page=${pageCount}&limit=${exportPageSize}&details=1`;
                let search = 0;
                if (name) {
                    search = 1;
                    url = url + `&name=${name}`
                }
                if (email) {
                    search = 1;
                    url = url + `&email=${email}`
                }
                if (phone) {
                    search = 1;
                    url = url + `&phone=${phone}`
                }
                if (companyName) {
                    search = 1;
                    url = url + `&customer_company_name=${companyName}`
                }
                // if (search) {
                //     url = url + `&isSearch=1`
                // }
                if (sortBy.sortField && sortBy.sortOrder) {
                    url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
                }
                if (sortBy.subSort && sortBy.subOrder) {
                    url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
                }
                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.totalCustomers;
                            processData = processData.concat(formatData(responseJson.data.data));
                            console.log(totaldata, processData);

                        } else {
                            if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
                                dispatch(logout());
                                navigate('/');
                            }
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, 'customer')
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        }
        else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
            setLoadingExcel(false);
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, "Customer")}</h2>
                <div className="mb-4">
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                            <div className="tableBgContainer py-4 px-4 total">
                                <span className="fs-8 fw-bold">{translate(props.lang, "Total Customer")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                <Form.Control
                                    type="text"
                                    placeholder={translate(props.lang, "Search by name")}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    className='input-transparent py-1 px-3'
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                <Form.Control
                                    type="text"
                                    placeholder={translate(props.lang, "Search by email")}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    className='input-transparent py-1 px-3'
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                <Form.Control
                                    type="text"
                                    placeholder={translate(props.lang, "Search by contact no.")}
                                    onChange={e => setPhone(e.target.value)}
                                    value={phone}
                                    className='input-transparent py-1 px-3'
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                <Form.Control
                                    type="text"
                                    placeholder={translate(props.lang, "Search by company name")}
                                    onChange={e => setCompanyName(e.target.value)}
                                    value={companyName}
                                    className='input-transparent py-1 px-3'
                                />
                            </div>
                        </Col>
                        {props?.auth?.permission?.allCustomer ? (
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                                    {loadingExcel ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                            {translate(props.lang, "Export")}
                                        </>
                                    )}
                                </Button>
                            </Col>
                        ) : null}
                        {props?.auth?.permission?.createCustomer ? (
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="primary" className='w-100 text-center' onClick={() => { navigate('/customer/new') }}>
                                    <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                    {translate(props.lang, "Add Customer")}
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                </div>

                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <div className='table-responsive'>
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    {/* <th className='py-2 text-center'>{translate(props.lang, "No.")}</th> */}
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('name', false)}>
                                            <span className="pe-2">{translate(props.lang, "Name")}</span>
                                            {sortBy.sortField === 'name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                            <span className="pe-2">{translate(props.lang, "Email Address")}</span>
                                            {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer">
                                            <span>{translate(props.lang, "Contact No.")}</span>
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('customer_company_name')}>
                                            <span className="pe-2">{translate(props.lang, "Company")}</span>
                                            {sortBy.sortField === 'customer_company_name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={5}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result.length > 0 ? (
                                        result.map((item, i) => (
                                            <tr role="row" key={i}>
                                                {/* <td className='fs-9 py-2 text-center'>
                                                    {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                                                    {i + 1 === productPerpage ? '0' : i + 1}.
                                                </td> */}
                                                <td className='td-text py-2 fs-9 text-capitalize'>{item.name}</td>
                                                <td className='td-text py-2 fs-9'>{item.email ? item.email : '-'}</td>
                                                <td className='td-text py-2 fs-9'>{item.country_code + item.phone}</td>
                                                <td className='td-text py-2 fs-9'>{item.customer_company_name ? item.customer_company_name : '-'}</td>
                                                <td className='py-2' style={{ minWidth: '100px' }}>
                                                    <Link to={`/customer/edit/${item.customer_id}`} className='me-3'>
                                                        <FontAwesomeIcon icon={faEdit} color="#839AB4" fontSize={20} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={5}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </Table>
                    </div>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(CustomerList);
