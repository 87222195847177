
const customSelectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
        ...base,
        background: "transparent",
        border: "none",
        boxShadow: state.isFocused ? null : null,
        color: "black",
        // padding: "0px 5px",
        width: '100%',
        minHeight: '18px',
        height: '100%'
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '0px',
        fontSize: '0.8rem',
        width: '100%',
        paddingTop: '0px',
        // paddingTop: '0.25rem',
        // paddingBottom: '0.25rem'
    }),
    menu: (base) => ({
        ...base,
        marginTop: 0,
        // color: "#000000",
        padding: "0px 0px",
        overflow: 'hidden',
        zIndex: "99",
        // height: '10em',
        borderRadius: "4px",
        background: "#FFFFFF",
        boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        backdropFilter: "blur(10px)",
        fontSize: '0.8rem',
    }),
    menuList: (base) => ({
        ...base,
        padding: "0px 0px",
        fontSize: '0.8rem',
    }),
    singleValue: (base) => ({
        ...base,
        color: "#000",
        padding: "0px 0px",
        alignItems: "center",
        fontWeight: '500',
        display: "flex",
        fontSize: '0.8rem',
        overflow: 'visible',
        top: window.innerWidth > 2000 ? '50%' :'46%',
        margin: "0px",
    }),
    input: (base) => ({
        ...base,
        paddingTop: '0px',
        paddingBottom: '0px',
        fontSize: '0.8rem',
    }),
    placeholder: (base) => ({
        ...base,
        color: 'rgba(138, 148, 173, 0.8)',
        fontWeight: '600',
        fontSize: '0.8rem',
        top: window.innerWidth > 2000 ? '50%' :'46%',
        marginLeft: '0px',
        marginRight: '0px'
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "rgba(219, 0, 7, 0.05)" : state.isFocused ? "rgba(219, 0, 7, 0.05)" : "white", // Background color when selected or focused
        color: '#101828',
        fontWeight: "500",
        fontSize: '0.8rem',
        ':hover': {
            background: "rgba(219, 0, 7, 0.05)", // Hover background color in red
            color: '#101828',
            fontWeight: "500"
        },
    }),
};

export default customSelectStyles;