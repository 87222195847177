import DateFnsUtils from "@date-io/date-fns";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import moment from 'moment';
import { useAppContext } from "providers/AppProvider";
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from "store/actions/AuthActions";
import Swal from "sweetalert2";
import { api } from 'utils/api';
import { dateButtonTextMap } from "utils/config";
import customSelectDarkStyles from "utils/customSelectDarkStyles";
import customSelectStyles from 'utils/customSelectStyles';
import { getSweelAlertBg, getSweelAlertTextColor } from "utils/function";
import { translate } from 'utils/translate';

const localeMap = {
    en: enLocale,
    zh: zhCNLocale,
};

const NewTimesheet = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useAppContext();
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = Array.from({ length: 60 }, (_, i) => i);
    let errorObj = {
        start_date: '',
        end_date: '',
        start_timer: '',
        end_timer: '',
        description: '',
        endHour: '',
        endMinute: '',
        startHour: '',
        startMinute: '',
        backdate_reason: '',
    }
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [startHour, setStartHour] = useState('');
    const [startMinute, setStartMinute] = useState('');
    const [endHour, setEndHour] = useState('');
    const [endMinute, setEndMinute] = useState('');
    const [overtimeChecked, setOvertimeChecked] = useState(false);
    const [formData, setFormData] = useState({
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD'),
        start_timer: '',
        end_timer: '',
        description: '',
        backdate_reason: '',
    })

    let breadcrumbData = [
        { label: translate(props.lang, 'Timesheet') },
        { label: translate(props.lang, 'New Timesheet'), active: true }
    ]

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
    }, [props.selectedTask])

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            if (type === 'start_timer' || type === 'end_timer') {
                newFormData[type] = e;
            } else {
                newFormData[type] = moment(e).format('YYYY-MM-DD');
            }

        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let newFormData = { ...formData };
        if (!formData.start_date) {
            errorMsg.start_date = "This field is required.";
            check = false
        }
        if (!formData.end_date) {
            errorMsg.end_date = "This field is required.";
            check = false
        }
        if (!startHour) {
            errorMsg.startHour = "This field is required.";
            check = false
        }
        if (!startMinute) {
            errorMsg.startMinute = "This field is required.";
            check = false
        }
        if (!endHour) {
            errorMsg.endHour = "This field is required.";
            check = false
        }
        if (!endMinute) {
            errorMsg.endMinute = "This field is required.";
            check = false
        }
        if (startHour && startMinute) {
            let newTime = moment(new Date()).format('DD-MM-YYYY');
            newTime = newTime + ' ' + startHour.toString() + ':' + startMinute.toString() + ":00";
            newFormData.start_timer = `${startHour.toString().length < 2 ? "0" + startHour.toString() : startHour.toString()}` + ':' + `${startMinute.toString().length < 2 ? "0" + startMinute.toString() : startMinute.toString()}` + ":00";
        }
        if (endHour && endMinute) {
            let newTime = moment(new Date()).format('DD-MM-YYYY');
            newTime = newTime + ' ' + endHour.toString() + ':' + endMinute.toString() + ":00";
            newFormData.end_timer = `${endHour.toString().length < 2 ? "0" + endHour.toString() : endHour.toString()}` + ':' + `${endMinute.toString().length < 2 ? "0" + endMinute.toString() : endMinute.toString()}` + ":00";
        }
        if (!formData.description) {
            errorMsg.description = "This field is required.";
            check = false
        }
        if (!formData.backdate_reason && !overtimeChecked) {
            errorMsg.backdate_reason = "This field is required.";
            check = false
        }
        if (formData.start_date && formData.end_date) {
            if (formData.end_date < formData.start_date) {
                errorMsg.start_date = "End date cannot smaller than start date";
                check = false
            } else {
                if (newFormData.start_timer && newFormData.end_timer) {
                    let newStartTime = formData.start_date + ' ' + startHour.toString() + ':' + startMinute.toString() + ":00";
                    let newEndTime = formData.end_date + ' ' + endHour.toString() + ':' + endMinute.toString() + ":00";
                    if (new Date(newEndTime) <= new Date(newStartTime)) {
                        errorMsg.endHour = "End time cannot smaller than start time";
                        check = false
                    } else {
                        if (new Date(newEndTime) > new Date()) {
                            errorMsg.endHour = "Cannot enter future date";
                            check = false
                        }
                    }
                }
            }
        }
        setErrors(errorMsg)
        if (check) {
            setLoading(true);
            fetch(api.add_project_timesheet + '/' + props.selectedTask.project.project_id + '/' + props.selectedTask.task.project_task_id, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        if (overtimeChecked && overtimeChecked) {
                            submitOTForm();
                        }
                        else {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Time Log added successful."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            props.closePanel();
                        }

                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const submitOTForm = () => {
        let check = true;
        let newFormData = { ...formData };

        if (startHour && startMinute) {
            let newTime = moment(new Date()).format('DD-MM-YYYY');
            newTime = newTime + ' ' + startHour.toString() + ':' + startMinute.toString() + ":00";
            newFormData.start_timer = `${startHour.toString().length < 2 ? "0" + startHour.toString() : startHour.toString()}` + ':' + `${startMinute.toString().length < 2 ? "0" + startMinute.toString() : startMinute.toString()}` + ":00";
        }
        if (endHour && endMinute) {
            let newTime = moment(new Date()).format('DD-MM-YYYY');
            newTime = newTime + ' ' + endHour.toString() + ':' + endMinute.toString() + ":00";
            newFormData.end_timer = `${endHour.toString().length < 2 ? "0" + endHour.toString() : endHour.toString()}` + ':' + `${endMinute.toString().length < 2 ? "0" + endMinute.toString() : endMinute.toString()}` + ":00";
        }

        let data = {
            "project_id": props.selectedTask.project.project_id,
            "project_task_id": props.selectedTask.task.project_task_id,
            "start_date": newFormData?.start_date,
            "start_timer": newFormData?.start_timer,
            "end_date": newFormData?.end_date,
            "end_timer": newFormData?.end_timer,
            "description": newFormData?.description,
        }

        if (check) {
            setLoading(true);
            fetch(api.add_overtime, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been submit successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        props.closePanel();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        if (type === 'startHour') {
            setStartHour(value)
        } else if (type === 'startMinute') {
            setStartMinute(value)
        } else if (type === 'endHour') {
            setEndHour(value)
        } else if (type === 'endMinute') {
            setEndMinute(value)
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "New Timesheet")}</h2>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type='text'
                                placeholder={translate(props.lang, "Project")}
                                className={`form-control input-transparent py-1 px-3`}
                                value={props.selectedTask && props.selectedTask.project ? props.selectedTask.project.name : ''}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 h-100`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type='text'
                                placeholder={translate(props.lang, "Task")}
                                className={`form-control input-transparent py-1 px-3`}
                                value={props.selectedTask && props.selectedTask.task.name ? props.selectedTask.task.name : ''}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.start_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Date")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    format="yyyy-MM-dd"
                                    className="form-control input-transparent py-1 px-3"
                                    placeholder={translate(props.lang, "Select start date")}
                                    value={formData.start_date ? formData.start_date : null}
                                    onChange={(date) => onChangeCalendar(date, 'start_date')}
                                    clearLabel={dateButtonTextMap[locale].clear}
                                    cancelLabel={dateButtonTextMap[locale].cancel}
                                    okLabel={dateButtonTextMap[locale].ok}
                                    views={['year', 'month', 'date']}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.start_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.startHour || errors.startMinute ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Time")} <span className="text-danger">*</span></span>
                        <Row className='px-0 mx-0'>
                            <Col lg={6} md={6} sm={6} xs={6} className='ps-0'>
                                <div className="input-group-select px-3 py-1">
                                    <Select
                                        options={
                                            hours.map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i }))
                                        }
                                        placeholder={translate(props.lang, "Select hour")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={
                                            startHour ? (
                                                (hours.filter(item => item.toString() == startHour).map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i })))
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'startHour')}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className='pe-0'>
                                <div className="input-group-select px-3 py-1">
                                    <Select
                                        options={
                                            minutes.map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i }))
                                        }
                                        placeholder={translate(props.lang, "Select minute")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={
                                            startMinute ? (
                                                (minutes.filter(item => item.toString() == startMinute).map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i })))
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'startMinute')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.startHour)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.end_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "End Date")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    format="yyyy-MM-dd"
                                    className="form-control input-transparent py-1 px-3"
                                    placeholder={translate(props.lang, "Select end date")}
                                    value={formData.end_date ? formData.end_date : null}
                                    onChange={(date) => onChangeCalendar(date, 'end_date')}
                                    clearLabel={dateButtonTextMap[locale].clear}
                                    cancelLabel={dateButtonTextMap[locale].cancel}
                                    okLabel={dateButtonTextMap[locale].ok}
                                    views={['year', 'month', 'date']}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.end_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.endHour || errors.endMinute ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "End Time")} <span className="text-danger">*</span></span>
                        <Row className='px-0 mx-0'>
                            <Col lg={6} md={6} sm={6} xs={6} className='ps-0'>
                                <div className="input-group-select px-3 py-1">
                                    <Select
                                        options={
                                            hours.map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i }))
                                        }
                                        placeholder={translate(props.lang, "Select hour")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={
                                            endHour ? (
                                                (hours.filter(item => item.toString() == endHour).map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i })))
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'endHour')}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className='pe-0'>
                                <div className="input-group-select px-3 py-1">
                                    <Select
                                        options={
                                            minutes.map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i }))
                                        }
                                        placeholder={translate(props.lang, "Select minute")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={
                                            endMinute ? (
                                                (minutes.filter(item => item.toString() == endMinute).map((item, i) => ({ value: item.toString(), label: item.toString().length === 1 ? '0' + item.toString() : item, key: i })))
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'endMinute')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.endHour)}</span>
                    </div>
                </Col>
                {
                    props && props?.auth && props?.auth?.profile?.ableClaimOT ?
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <Form.Check
                                type='checkbox'
                                id='defaultCheckbox'
                                label={translate(props.lang, "Overtime")}
                                onChange={(e) => setOvertimeChecked(e.target.checked)}
                            />
                        </Col> : null
                }

                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <Form.Control
                                as="textarea"
                                name="description"
                                className="form-control input-transparent py-1 px-3"
                                rows={3}
                                value={formData.description}
                                placeholder={translate(props.lang, "Enter description")}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                    </div>
                </Col>

                {
                    !overtimeChecked ?
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.backdate_reason ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Reason Why You Backdate")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <Form.Control
                                        as="textarea"
                                        name="backdate_reason"
                                        className="form-control input-transparent py-1 px-3"
                                        rows={3}
                                        value={formData.backdate_reason}
                                        placeholder={translate(props.lang, "Enter reason")}
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.backdate_reason)}</span>
                            </div>
                        </Col> : null
                }
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => props.closePanel()}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div >
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewTimesheet);
