import { faEye, faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from "antd";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import dayjs from "dayjs";
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { approvalStatus, exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
const { RangePicker } = DatePicker;

const OvertimeRequestList = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { config } = useAppContext();
  const [productPerpage, setProductPerpage] = useState(10)
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [result, setResult] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [workerName, setWorkerName] = useState('');
  const [statistic, setStatistic] = useState(0);
  const [status, setStatus] = useState('pending');
  const timerRef = useRef(null);
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortOrder: '',
    subSort: '',
    subOrder: '',
  });

  let breadcrumbData = [
    { label: translate(props.lang, 'Overtime'), url: '#' },
    { label: translate(props.lang, 'Overtime Request'), active: true }
  ]

  const changePage = (event) => {
    setPageNumber(event.selected + 1);
    getList(event.selected + 1)
  }

  useEffect(() => {
    if (firstLoad) {
      if (props?.auth?.permission?.overtimeRequest) {
        getList();
        setFirstLoad(false)
      }
    }
  }, [props.auth.auth.token, props.auth.permission]);

  useEffect(() => {
    if (!firstLoad) {
      setPageNumber(1)
      getList(1);
    }
  }, [sortBy.sortField, sortBy.sortOrder, props.lang, status, workerName, startDate, endDate, productPerpage]);

  const handleTimeChange = (e) => {
    const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
    const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
    setStartDate(formattedFromDate);
    setEndDate(formattedToDate);
  }

  const getList = (page = pageNumber) => {
    setLoading(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      let url = api.get_overtime_requests + "?page=" + page + "&limit=" + productPerpage + '';
      if (startDate && endDate) {
        url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
      }
      if (status) {
        url += `&approvalStatus=${status}`
      }
      if (workerName) {
        url += `&workerName=${workerName}`
      }
      if (sortBy.sortField && sortBy.sortOrder) {
        url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
      }
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          if (responseJson.status === "success") {
            setResult(responseJson.data.data);
            setTotalResultCount(responseJson.data.subs.total)
            let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
            setPageCount(totalPage);
            setStatistic(responseJson.data.subs.total)
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else if (responseJson.tokenExpired) {
              dispatch(logout());
              navigate('/')
            } else {
              console.log('error', responseJson.message)
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }, 1000);
  }

  const formatData = (data) => {
    let newData = [];
    data.forEach(entry => {
      let formattedData = {
        "User": entry.worker ? entry.worker?.name : '-',
        "Project": entry && entry?.project ? entry.project?.name : '-',
        "Task": entry && entry?.project_task ? entry.project_task?.name : '-',
        "Overtime Start": entry && entry?.start ? moment(entry.start).format("YYYY-MM-DD HH:mm:ss") : '-',
        "Overtime End": entry && entry?.end ? moment(entry.end).format("YYYY-MM-DD HH:mm:ss") : '-',
        "Description": entry ? entry.description : '-',
        "Status": entry ? entry.overtime_status : '-',
      };
      newData.push(formattedData)
    })
    return newData;
  }

  const exportExcel = () => {
    const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
    let processData = [];
    let totaldata = 0;
    if (totalPagesCount > 0) {
      setLoadingExcel(true);
      for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
        let url = api.get_overtime_requests + "?page=" + pageCount + "&limit=" + exportPageSize + '';
        if (startDate && endDate) {
          url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
        }
        if (status) {
          url += `&approvalStatus=${status}`
        }
        if (workerName) {
          url += `&workerName=${workerName}`
        }
        if (sortBy.sortField && sortBy.sortOrder) {
          url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
        }

        fetch(url, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accept-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
          }),
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.status === "success") {
              totaldata = responseJson.data.subs.total;
              processData = processData.concat(formatData(responseJson.data.data));
            } else {
              if (responseJson.message === 'token expired') {
                dispatch(logout());
                navigate('/')
              } else if (responseJson.tokenExpired) {
                dispatch(logout());
                navigate('/')
              } else {
                console.log('error', responseJson.message)
              }
            }
            if (processData.length === totaldata) {
              downloadExcel(processData, 'overtime_request')
              setLoadingExcel(false)
            }
          }).catch(error => {
            console.error("error", error);
          });

        setTimeout(() => { }, 500) // add some delay here to let server take rest.
      }
    } else {
      Swal.fire({
        icon: "error",
        title: translate(props.lang, 'Error'),
        text: translate(props.lang, "No data to export"),
        background: getSweelAlertBg(), // Dark background color
        color: getSweelAlertTextColor(), // Text color
      });
    }
  }

  return (
    <div>
      <PageBreadcrumb items={breadcrumbData} />
      <div className="mb-9">
        <h2 className="mb-5">{translate(props.lang, "Overtime Request")}</h2>
        <div className="mb-4">
          <Row className="g-3">
            <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
              <div className="tableBgContainer py-4 px-4 total">
                <span className="fs-8 fw-bold">{translate(props.lang, "Total Overtime Request")}</span>
                <div className="dataBg px-2 py-1 mt-2">
                  <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="g-3">
            <Col lg={4} md={4} xs={12} sm={12}>
              <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                <RangePicker
                  className="w-100 input-transparent px-2 h-100"
                  placeholder={[
                    translate(props.lang, "From"),
                    translate(props.lang, "To"),
                  ]}
                  onChange={(e) => handleTimeChange(e)}
                />
              </div>
            </Col>
            <Col lg={4} md={4} xs={12} sm={12}>
              <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                <Form.Control
                  type="text"
                  placeholder={translate(props.lang, "Search by User Name")}
                  onChange={e => setWorkerName(e.target.value)}
                  value={workerName}
                  className='input-transparent py-1 px-3'
                />
              </div>
            </Col>
            <Col lg={4} md={4} xs={12} sm={12}>
              <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                <Select
                  options={
                    approvalStatus.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) }))
                  }
                  placeholder={translate(props.lang, "Select status")}
                  className="input-transparent w-100 text-capitalize"
                  styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                  isClearable={true}
                  value={approvalStatus.filter(option => option.value === status).length > 0 ? approvalStatus.filter(option => option.value === status).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                  onChange={(e) => setStatus(e ? e.value : '')}
                />
              </div>
            </Col>
            {props?.auth?.permission?.overtimeRequest ? (
            <Col lg={4} md={4} xs={12} sm={12}>
              <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                {loadingExcel ? (
                  <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                    {translate(props.lang, "Export")}
                  </>
                )}
              </Button>
            </Col>
            ) : null}
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
          <div className='table-responsive'>
            <Table responsive="sm" hover>
              <thead>
                <tr>
                  {/* <th className='py-2 text-center'>{translate(props.lang, "No.")}</th> */}
                  <th className='py-2 text-start'>{translate(props.lang, "Worker")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Project")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Task")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Start")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "End")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Description")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Submitted Datetime")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Status")}</th>
                  <th className='py-2 text-start'></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={9}>
                      <DataLoading theme='light' />
                    </td>
                  </tr>
                ) : (
                  result.length > 0 ? (
                    result.map((item, i) => (
                      <tr role="row" key={i}>
                        {/* <td className='fs-9 text-center' style={{ minWidth: '50px' }}>
                          {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                          {i + 1 === productPerpage ? '0' : i + 1}.
                        </td> */}
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '200px' }}>{item?.worker?.name}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>{item.project.name}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>{item.project_task?.name}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>{moment(item.start).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>{moment(item.end).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>{item.description ? item.description : '-'}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td className='td-text text-capitalize fs-9' style={{ minWidth: '150px' }}>
                          <div className={`statusBox ${item.overtime_status} text-center px-2`}>{translate(props.lang, item.overtime_status)}</div>
                        </td>
                        <td className='' style={{ minWidth: '50px' }}>
                          <Link to={`/overtime-request/view/${item.overtime_id}`}>
                            <FontAwesomeIcon icon={faEye} color="#839AB4" fontSize={20} />
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr role="row">
                      <td colSpan={9}>
                        <NoResultFound />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
          <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
              <div className='d-flex w-100 align-items-center justify-content-end'>
                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                <div className="input-group-select-paginate ms-2 px-2">
                  <Select
                    options={
                      shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                    }
                    className="input-transparent w-100 text-capitalize px-0"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                    name="productPerpage"
                    isSearchable={false}
                    onChange={(e) => setProductPerpage(e ? e.value : '')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center my-3'>
            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
              {!loading && result.length > 0 ? (
                <div className="my-3">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    forcePage={pageNumber - 1}
                    onPageChange={changePage}
                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                    disabledClassName={"pagination__link--disabled text-muted"}
                    activeClassName={"pagination__link--active text-white"}
                    marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(OvertimeRequestList);
