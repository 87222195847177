import Avatar from 'components/base/Avatar';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import ThemeToggler from 'components/common/ThemeToggler';
import FeatherIcon from 'feather-icons-react';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Modal, Nav } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { languageList } from 'utils/config';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import { logout } from 'store/actions/AuthActions';

const NavItems = (props) => {
  const dispatch = useDispatch();
  const { config: { navbarPosition } } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [loadingCheckIn, setLoadingCheckIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    accuracy: null,
    error: null,
  });
  const timerRef = useRef(null)

  const updateTheme = () => {
    dispatch({
      type: "UPDATE_THEME",
      theme: props.theme === 'light' ? 'dark' : 'light',
    })
  }

  const changeLanguage = (language) => {
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: language
    });
    localStorage.setItem('pms-lang', language);
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLocation(); // Attempt to get location on component mount
      getClockInOutStatus();
    }
    return () => {
      isMounted = false;  // Clean up when component is unmounted
    };
  }, [])

  const getLocation = () => {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy,
                error: null,
              });
            },
            (error) => {
              setLocation({
                latitude: null,
                longitude: null,
                accuracy: null,
                error: error.message,
              });
            }
          );
        }
        else if (result.state === 'prompt') {
          // If permission is prompt, ask for location access
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy,
                error: null,
              });
            },
            (err) => {
              setLocation({
                latitude: null,
                longitude: null,
                accuracy: null,
                error: 'Geolocation is disabled by the user.',
              });
            }
          );
        } else {
          setLocation({
            latitude: null,
            longitude: null,
            accuracy: null,
            error: 'Geolocation is not supported by this browser.',
          });
        }
      })
    }
    else {
      setLocation({
        latitude: null,
        longitude: null,
        accuracy: null,
        error: 'Geolocation is not supported by this browser.',
      });
    }
  }

  const checkLocation = () => {
    if (location.error) {
      getLocation();
      if ('geolocation' in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
          if (result.state === 'denied') {
            setLocation({
              latitude: null,
              longitude: null,
              accuracy: null,
              error: 'Geolocation is not supported by this browser.',
            });

            Swal.fire({
              icon: "error",
              title: translate(props.lang, 'Error'),
              text: "Location is required to turn on for clock in/ out.",
              background: getSweelAlertBg(), // Dark background color
              color: getSweelAlertTextColor(), // Text color
            });
          }
        })
      }
    } else {
      clockIn();
    }
  }

  const getClockInOutStatus = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      fetch(api.get_my_clock + '?clockOnly=1', {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          setLoadingCheckIn(false)
          if (responseJson.status === 'success') {
            setCheckIn(responseJson.data.is_time_in)
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else {
              Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: responseJson.message,
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }, 500);
  }

  const clockIn = () => {
    let data = {
      "location": location
    }

    let clockInOutStatus = !checkIn ? 'in' : 'out'
    fetch(api.toggle_worker_attendance + '/' + clockInOutStatus, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.token
      }),
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        setLoading(false)
        if (responseJson.status === 'success') {
          setCheckIn(!checkIn)
          Swal.fire({
            icon: "success",
            title: translate(props.lang, 'Success'),
            text: !checkIn ? translate(props.lang, "Clock In") : translate(props.lang, "Clock Out"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
          });
        } else {
          if (responseJson.message === 'token expired') {
            dispatch(logout());
            navigate('/')
          } else {
            Swal.fire({
              icon: "error",
              title: translate(props.lang, 'Error'),
              text: responseJson.message,
              background: getSweelAlertBg(), // Dark background color
              color: getSweelAlertTextColor(), // Text color
            });
          }
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  return (
    <div className="navbar-nav navbar-nav-icons flex-row align-items-center">
      <Nav.Item>
        {props.auth.permission && props.auth.permission.toggleWorkerAttendance ? (
          !checkIn ? (
            <Button variant="subtle-primary" disabled={loadingCheckIn} className='me-2' onClick={() => checkLocation()}>{translate(props.lang, "Clock In")}</Button>
          ) : (
            <Button variant="subtle-danger" disabled={loadingCheckIn} onClick={() => checkLocation()}>{translate(props.lang, "Clock Out")}</Button>
          )
        ) : null}
      </Nav.Item>
      <Nav.Item>
        <ThemeToggler className="px-2" updateTheme={updateTheme} lang={props.lang} />
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <FeatherIcon icon="globe" size={20} />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="end"
            className={'py-0  shadow border'}
          >
            {languageList.map((item, i) => (
              <Dropdown.Item onClick={() => changeLanguage(item.code)} key={i}>{item.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={require('../../../assets/img/team/avatar-rounded.webp')} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, i18n, theme } = state;
  return {
    lang: i18n.lang,
    auth: auth,
    theme: theme
  }
}

export default connect(mapStateToProps)(NavItems);
