import { faEllipsis, faFileExport, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/base/Avatar';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Offcanvas, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from "react-select";
import { api } from 'utils/api';
import { exportPageSize, priorityList, shownItem, statusList } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
import EditProject from './EditProject';
import NewProject from './NewProject';
import Swal from 'sweetalert2';
import { logout } from 'store/actions/AuthActions';

const ProjectList = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();
    const [totalResultCount, setTotalResultCount] = useState(0)
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openPanel, setOpenPanel] = useState(false);
    const [openEditPanel, setOpenEditPanel] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [statistic, setStatistic] = useState(0);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [pic, setPIC] = useState('');
    const [label, setLabel] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [priority, setPriority] = useState('');
    const [status, setStatus] = useState(null);
    const { config } = useAppContext();
    const [summaryInfo, setSummaryInfo] = useState({
        total: 0,
        active: 0,
        completed: 0,
        cancelled: 0,
        overdue: 0
    })
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });
    const timerRef = useRef(null);
    const timerSummaryRef = useRef(null);
    let breadcrumbData = [
        { label: translate(props.lang, 'Project'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]

    useEffect(() => {
        if (status) {
            if (status.length > 0) {
                status.map((s, i) => {
                    let tempData = statusList.filter(item => item.value === s.value);
                    if (tempData.length > 0) {
                        s.label = translate(props.lang, tempData[0].label)
                    }
                })
            }
        }
    }, [props.lang])

    const changePage = (event) => {
        setLoading(true);
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let value = queryParams.get('status');
        let tempData = statusList.filter(item => item.value === value);
        if (tempData.length === 0) {
            setStatus([]);
        } else {
            setStatus([{ label: translate(props.lang, tempData[0].label), value: tempData[0].value }]);
        }
    }, [])

    useEffect(() => {
        if (firstLoad) {
            if (props?.auth?.permission?.allProject) {
                getList();
                getSummary();
                setFirstLoad(false)
            }
        }
    }, [props.auth.permission]);

    useEffect(() => {
        if (!firstLoad) {
            setLoading(true);
            getSummary();
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, name, status, priority, customerName, label, pic]);

    const getSummary = () => {
        clearTimeout(timerSummaryRef.current);
        timerSummaryRef.current = setTimeout(() => {
            fetch(api.get_project_summary, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        setSummaryInfo(responseJson.data)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const viewDetails = (item) => {
        navigate(`/project/details/${item.project_id}`)
    }

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getList = (page = pageNumber) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_project + "?page=" + page + "&limit=" + productPerpage + '&details=1&showMinimum=1';
            let search = 0;
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (status) {
                let statusString = '';
                status.map((item, i) => {
                    statusString += item.value
                    if (i !== status.length - 1) {
                        statusString += ','
                    }
                })
                url = url + `&projectStatus=${statusString}`
            }
            if (priority.toString()) {
                url = url + `&priority=${priority}`
            }
            if (customerName) {
                url = url + `&customerName=${customerName}`
            }
            if (pic) {
                url = url + `&pic=${pic}`
            }
            if (label) {
                url = url + `&label=${label}`
            }

            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalProjects / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.totalProjects)
                        setTotalResultCount(responseJson.data.subs.totalProjects)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_project + `?page=${pageCount}&details=1&limit=${exportPageSize}`;

                if (name) {
                    url = url + `&name=${name}`
                }
                if (status) {
                    url = url + `&projectStatus=${status}`
                }
                if (priority) {
                    url = url + `&priority=${priority}`
                }
                if (customerName) {
                    url = url + `&customerName=${customerName}`
                }
                if (pic) {
                    url = url + `&pic=${pic}`
                }
                if (label) {
                    url = url + `&label=${label}`
                }

                if (sortBy.sortField && sortBy.sortOrder) {
                    url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
                }
                if (sortBy.subSort && sortBy.subOrder) {
                    url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.totalProjects;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            }
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, 'project_list')
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(entry => {
            let allPIC = []
            entry.owner.filter(pic => pic.type === 'person in charge').map((person, j) => {
                allPIC.push(person.oWorker && person.oWorker.name ? person.oWorker.name : '');
            });
            let formattedData = {
                "Project Number": entry.project_number ? entry.project_number : '-',
                "Project Name": entry.name ? entry.name : '-',
                "Client": entry.customer ? entry.customer.name : '-',
                "Status": entry.progress_status ? translate(props.lang, getLabel(entry.progress_status, 'status')) : '-',
                "Person In Charge": allPIC.length > 0 ? allPIC.join(',') : '-',
                "Completion": entry.progress_percentage ? entry.progress_percentage + "%" : '-',
                "Priority": entry.priority_level ? translate(props.lang, getLabel(entry.priority_level, 'priority')) : '-',
                "Start Date": entry.start_date ? entry.start_date : '-',
                "Due Date": entry.due_date ? entry.due_date : '-',
                "Label": entry.label ? entry.label : '-',
                "Creator": entry.creator ? entry.creator.name : '-',
            };
            newData.push(formattedData);
        });
        return newData;
    }

    const getColor = (status) => {
        let color = '#fff';
        let filterList = statusList.filter(item => item.value === status);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#f2f2f2';
        }
        return color;
    }

    const getColorPriority = (priority) => {
        let color = '#000';
        let filterList = priorityList.filter(item => item.value.toString() === priority.toString());
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#f2f2f2';
        }
        return color;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    const editItem = (id) => {
        if (props?.auth?.permission?.editProject) {
            setSelectedId(id);
            setOpenEditPanel(true);
        }
    }

    const closeEditPanel = () => {
        setOpenEditPanel(false);
        setSelectedId(null)
    }

    const updatePriority = (project, level) => {
        let newFormData = {
            projectIds: [project.project_id],
            value: Number(level),
            target: 'priority_level'
        }
        fetch(api.edit_project_table, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(newFormData)
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getList();
                    getSummary()
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: translate(props.lang, 'Error'),
                            text: responseJson.message,
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const setStatusStage = (value) => {
        let tempData = statusList.filter(item => item.value === value);
        if (tempData.length === 0) {
            setStatus([]);
        } else {
            setStatus([{ label: translate(props.lang, tempData[0].label), value: tempData[0].value }]);
        }
    }

    const updateStatus = (project, status) => {
        let newFormData = {
            projectIds: [project.project_id],
            value: status,
            target: 'progress_status'
        }
        fetch(api.edit_project_table, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(newFormData)
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === 'success') {
                    swal(translate(props.lang, "Success"), translate(props.lang, "Project has been updated successful."), "success");
                    getList();
                    getSummary()
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout(props.history, 'login'));
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, "Error"), responseJson.message, "error");
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }


    return (
        <>
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{translate(props.lang, "Project")}</h2>
                    <div className="mb-4">
                        <Row className="g-3 mb-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 total" onClick={() => setStatusStage('')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Total Project")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.total ? summaryInfo.total : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 active" onClick={() => setStatusStage('ongoing')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Active Project")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.active ? summaryInfo.active : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 onqueue" onClick={() => setStatusStage('onqueue')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Onqueue Project")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.onqueue ? summaryInfo.onqueue : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 completed" onClick={() => setStatusStage('completed')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Completed Project")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.completed ? summaryInfo.completed : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 cancelled" onClick={() => setStatusStage('cancelled')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Cancelled Project")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.cancelled ? summaryInfo.cancelled : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 overdue" onClick={() => setStatus([{ label: translate(props.lang, 'Overdue'), value: 'overdue' }])}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Overdue Project")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.overdue ? summaryInfo.overdue : 0}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="name"
                                        placeholder={translate(props.lang, "Search by project name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={name}
                                        autoComplete="off"
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="customerName"
                                        placeholder={translate(props.lang, "Search by customer name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={customerName}
                                        autoComplete="off"
                                        onChange={e => setCustomerName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="pic"
                                        placeholder={translate(props.lang, "Search by person in charge")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={pic}
                                        autoComplete="off"
                                        onChange={e => setPIC(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="label"
                                        placeholder={translate(props.lang, "Search by label")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={label}
                                        autoComplete="off"
                                        onChange={e => setLabel(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={statusList.length > 0 ? statusList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select status")}
                                        className="input-transparent w-100 text-capitalize basic-multi-select"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={status}
                                        name="status"
                                        isMulti
                                        onChange={(e) => setStatus(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={priorityList.length > 0 ? priorityList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select priority")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={priorityList.filter(option => option.value === priority).length > 0 ? priorityList.filter(option => option.value === priority).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                                        name="priority"
                                        onChange={(e) => setPriority(e ? e.value : '')}
                                    />
                                </div>
                            </Col>
                            {props?.auth?.permission?.allProject ? (
                                <Col lg={4} md={4} xs={12} sm={12}>
                                    <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                                        {loadingExcel ? (
                                            <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                                {translate(props.lang, "Export")}
                                            </>
                                        )}

                                    </Button>
                                </Col>
                            ) : null}
                            {props?.auth?.permission?.createProject ? (
                                <Col lg={4} md={4} xs={12} sm={12}>
                                    <Button variant="primary" className='w-100 text-center' onClick={() => setOpenPanel(true)}>
                                        <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                        {translate(props.lang, "Add Project")}
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                    </div>

                    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                        <div className='table-responsive'>
                            <Table responsive="sm" className='table-custom'>
                                <thead>
                                    <tr>
                                        <th className={`th-text fs-8 fw-bold`}>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('project_number')}>
                                                <span className="pe-2">{translate(props.lang, "Project Name")}</span>
                                                {sortBy.sortField === 'project_number' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className={`th-text fs-8 fw-bold`} style={{ minWidth: '200px' }}>{translate(props.lang, "Client")}</th>
                                        <th className={`th-text fs-8 fw-bold`} style={{ maxWidth: 'fit-content' }}>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('progress_status')}>
                                                <span className="pe-2">{translate(props.lang, "Status")}</span>
                                                {sortBy.sortField === 'progress_status' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className={`th-text fs-8 fw-bold`} style={{ minWidth: '200px' }}>{translate(props.lang, "Person In Charge")}</th>
                                        <th className={`th-text fs-8 fw-bold`}>{translate(props.lang, "Completion")}(%)</th>
                                        <th className={`th-text fs-8 fw-bold`}>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('priority_level')}>
                                                <span className="pe-2">{translate(props.lang, "Priority")}</span>
                                                {sortBy.sortField === 'priority_level' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className={`th-text fs-8 fw-bold`}>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('start_date')}>
                                                <span className="pe-2">{translate(props.lang, "Start Date")}</span>
                                                {sortBy.sortField === 'start_date' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className={`th-text fs-8 fw-bold`}>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('due_date')}>
                                                <span className="pe-2">{translate(props.lang, "Due Date")}</span>
                                                {sortBy.sortField === 'due_date' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className={`th-text fs-8 fw-bold`}>{translate(props.lang, "Label")}</th>
                                        <th className={`th-text fs-8 fw-bold`}>{translate(props.lang, "Creator")}</th>
                                        <th className={`th-text fs-8 fw-bold`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan={11}>
                                                <DataLoading theme='light' />
                                            </td>
                                        </tr>
                                    ) : (
                                        result.length > 0 ? (
                                            <>
                                                {result.map((item, i) => (
                                                    <tr role="row" key={i}>
                                                        <td style={{ minWidth: '200px' }}>
                                                            <div className='cursor-pointer' onClick={() => editItem(item.project_id)}>
                                                                <span className='td-text fs-10 fw-normal d-block'>#{item.project_number}</span>
                                                                <span className='text-primary fs-9 fw-bold'>{item.name ? item.name : ''}</span>
                                                            </div>
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal'>
                                                            <div className='d-flex align-items-center w-100 h-100'>
                                                                <span className='td-text fs-9 fw-normal d-block'>{item.customer.name ? item.customer.name : ''}</span>
                                                            </div>
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal'>
                                                            {props.auth.permission && props.auth.permission.editProject ? (
                                                                <Dropdown className="btn-group w-100">
                                                                    <Dropdown.Toggle
                                                                        className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                        style={{ background: getColor(item.progress_status), color: '#fff' }}
                                                                        data-toggle="dropdown"
                                                                        size='sm'
                                                                        data-boundary="window"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {translate(props.lang, getLabel(item.progress_status, 'status'))}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='py-0'>
                                                                        {statusList.map((status, x) => (
                                                                            <Dropdown.Item key={x} as={status.value} onClick={() => updateStatus(item, status.value)}><span className='cursor-pointer fw-bold' style={{ color: status.color }}>{translate(props.lang, status.label)}</span></Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                            ) : (
                                                                <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.progress_status), color: '#fff' }}>
                                                                    <span>{translate(props.lang, getLabel(item.progress_status, 'status'))}</span>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal'>
                                                            <div className="avatar-group avatar-group-dense text-center">
                                                                {item.owner.filter(pic => pic.type === 'person in charge').length > 0 ? (
                                                                    <div className="d-flex flex-column gap-4 me-2">
                                                                        <Avatar.Group>
                                                                            {item.owner.filter(pic => pic.type === 'person in charge').slice(0, 3).map((person, j) => (
                                                                                <div key={j} className="position-relative assignee text-center hoverMe d-flex align-items-center">
                                                                                    <Avatar size="m" variant="name">
                                                                                        <span>{person.oWorker && person.oWorker.name ? person.oWorker.name.substring(0, 1) : ''}</span>
                                                                                    </Avatar>
                                                                                    <div className="tooltip-name">{person.oWorker && person.oWorker.name ? person.oWorker.name : ''}</div>
                                                                                </div>
                                                                            ))}
                                                                        </Avatar.Group>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal align-middle white-space-nowrap projectprogress'>
                                                            <div className="d-flex w-100 align-items-center h-100">
                                                                <div>
                                                                    <span className="fs-10 fw-bold">{item.total_task_completed}</span>
                                                                </div>
                                                                <div className='task-progress-bar mx-1 p-0' style={{ height: '3px' }}>
                                                                    <div className='task-progress-track text-center d-flex align-items-center' style={{ width: item.total_task > 0 ? (Number(item.total_task_completed) / Number(item.total_task) * 100).toString() + '%' : '0%', height: '3px' }}>
                                                                    </div>
                                                                    <span className="fs-10 progress-percent">{item.total_task_completed > 0 ? (Number(item.total_task_completed) / Number(item.total_task) * 100).toFixed(2) : 0}%</span>
                                                                </div>
                                                                <div>
                                                                    <span className="fs-10 fw-bold">{item.total_task - item.total_task_completed}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal'>
                                                            {props.auth.permission && props.auth.permission.editProject ? (
                                                                <Dropdown className="btn-group">
                                                                    <Dropdown.Toggle
                                                                        className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                        style={{ background: getColorPriority(item.priority_level), color: '#fff' }}
                                                                        data-toggle="dropdown"
                                                                        size='sm'

                                                                    >
                                                                        {translate(props.lang, getLabel(item.priority_level, 'priority'))}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {priorityList.map((p, x) => (
                                                                            <Dropdown.Item key={x} onClick={() => updatePriority(item, p.value.toString())}><span className='cursor-pointer' style={{ color: p.color }}>{translate(props.lang, p.label)}</span></Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            ) : (
                                                                <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColorPriority(item.priority_level), color: '#fff' }}>
                                                                    <span>{translate(props.lang, getLabel(item.priority_level, 'priority'))}</span>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal'>{item && item.start_date ? item.start_date : "-"}</td>
                                                        <td className='td-text fs-9 fw-normal'>{item && item.due_date ? item.due_date : "-"}</td>
                                                        <td className='td-text fs-9 fw-normal'>{item && item.label ? item.label : "-"}</td>
                                                        <td className='td-text fs-9 fw-normal'>
                                                            {item.creator ? (
                                                                <div className="position-relative text-center hoverMe d-flex align-items-center">
                                                                    {item.creator.name ? (
                                                                        <>
                                                                            <div className='assignee'>
                                                                                <Avatar size="m" variant="name">
                                                                                    <span>{item.creator.name.substring(0, 1)}</span>
                                                                                </Avatar>
                                                                            </div>
                                                                            <div className="tooltip-name">{item.creator.name}</div>
                                                                        </>
                                                                    ) : null}

                                                                </div>) : '-'}
                                                        </td>
                                                        <td className='td-text fs-9 fw-normal'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="phoenix-secondary"
                                                                    className="dropdown-caret-none btn-icon"
                                                                >
                                                                    <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="py-0">
                                                                    <Dropdown.Item onClick={() => viewDetails(item)}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <tr role="row">
                                                <td colSpan={11}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )
                                    )}

                                </tbody>
                            </Table>
                        </div>
                        <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                                <div className='d-flex w-100 align-items-center justify-content-end'>
                                    <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                    <div className="input-group-select-paginate ms-2 px-2">
                                        <Select
                                            options={
                                                shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                            }
                                            className="input-transparent w-100 text-capitalize px-0"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                            name="productPerpage"
                                            isSearchable={false}
                                            onChange={(e) => setProductPerpage(e ? e.value : '')}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-center my-3'>
                            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                {!loading && result.length > 0 ? (
                                    <div className="my-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            forcePage={pageNumber - 1}
                                            onPageChange={changePage}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-white"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Offcanvas className={`w-50`} show={openPanel} onHide={() => setOpenPanel(false)} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <NewProject closePanel={() => setOpenPanel(false)} getList={getList} />
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas className={`w-50`} show={openEditPanel} onHide={() => closeEditPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <EditProject closePanel={() => closeEditPanel()} getList={getList} id={selectedId} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ProjectList);
