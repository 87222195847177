import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilChartPie,
  UilClipboard,
  UilMegaphone,
  UilMoneyBillStack,
  UilSetting,
  UilUserCircle
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  permission?: string[];
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  permission?: string[];
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Dashboard',
        icon: 'home',
        path: '/dashboard',
        pathName: 'dashboard',
        active: true,
        permission: []
      },
    ]
  },
  {
    label: 'announcement',
    horizontalNavLabel: 'announcement',
    active: true,
    icon: UilMegaphone,
    labelDisabled: true,
    pages: [
      {
        name: 'Announcement',
        icon: 'speaker',
        path: '/user-announcement',
        pathName: 'announcement',
        active: true,
        permission: []
      },
    ]
  },
  {
    label: 'forum',
    horizontalNavLabel: 'forum',
    active: true,
    icon: UilClipboard,
    labelDisabled: true,
    pages: [
      {
        name: 'Forum',
        icon: 'message-square',
        path: '/forum/overview',
        pathName: 'forum',
        active: true,
        permission: ['allForum'],
      },
    ]
  },
  {
    label: 'project',
    horizontalNavLabel: 'project',
    active: true,
    icon: UilClipboard,
    labelDisabled: true,
    pages: [
      {
        name: 'Project',
        icon: 'clipboard',
        path: '/project/overview',
        pathName: 'project-overview',
        active: true,
        permission: ['allProject'],
      },
    ]
  },
  {
    label: 'task',
    horizontalNavLabel: 'task',
    active: true,
    icon: UilClipboard,
    labelDisabled: true,
    pages: [
      {
        name: 'Task',
        icon: 'clipboard',
        path: '/task/overview',
        pathName: 'task-overview',
        active: true,
        permission: ['allProjectTask'],
      },
    ]
  },
  {
    label: 'timesheet',
    horizontalNavLabel: 'timesheet',
    active: true,
    icon: UilClipboard,
    labelDisabled: true,
    pages: [
      {
        name: 'Timesheet',
        icon: 'clock',
        active: true,
        permission: ['allTimesheet', 'allOvertime'],
        pages: [
          {
            name: 'Daily Task',
            path: '/timesheet/daily-task',
            pathName: 'daily-task',
            active: true,
            permission: ['allTimesheet'],
          },
          {
            name: 'Overtime',
            path: '/timesheet/overtime',
            pathName: 'overtime',
            active: true,
            permission: ['allOvertime'],
          },
        ]
      }
    ]
  },
  {
    label: 'claim',
    horizontalNavLabel: 'claim',
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,
    pages: [
      {
        name: 'Claim',
        icon: 'file-text',
        path: '/claim/overview',
        pathName: 'claim-overview',
        active: true,
        permission: ['allClaim'],
      },
    ]
  },
  {
    label: 'customer',
    horizontalNavLabel: 'customer',
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,
    pages: [
      {
        name: 'Customer',
        icon: 'users',
        path: '/customer/overview',
        pathName: 'customer-overview',
        active: true,
        permission: ['allCustomer'],
      },
    ]
  },
  {
    label: 'time attendance',
    horizontalNavLabel: 'time attendance',
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,
    pages: [
      {
        name: 'Time Attendance',
        icon: 'clock',
        path: '/time-attendance',
        pathName: 'time-attendance',
        active: true,
        permission: ['toggleWorkerAttendance'],
      },
    ]
  },
  // {
  //   label: 'activity log',
  //   horizontalNavLabel: 'activity log',
  //   active: true,
  //   icon: UilUserCircle,
  //   labelDisabled: true,
  //   pages: [
  //     {
  //       name: 'Activity Log',
  //       icon: 'activity',
  //       path: '/activity-log/overview',
  //       pathName: 'activity-log',
  //       active: true
  //     },
  //   ]
  // },
  {
    label: 'payment',
    horizontalNavLabel: 'payment',
    active: true,
    icon: UilMoneyBillStack,
    labelDisabled: true,
    pages: [
      {
        name: 'Finance',
        icon: 'dollar-sign',
        active: true,
        permission: ['allProjectFinancial', 'claimRequest'],
        pages: [
          {
            name: 'Quotation',
            path: '/quotation/overview',
            pathName: 'quotation',
            active: true,
            permission: ['allProjectFinancial'],
          },
          {
            name: 'Invoice',
            path: '/invoice/overview',
            pathName: 'invoice',
            active: true,
            permission: ['allProjectFinancial'],
          },
          {
            name: 'Payment',
            path: '/payment/overview',
            pathName: 'payment',
            active: true,
            permission: ['allProjectFinancial'],
          },
          {
            name: 'Refund',
            path: '/refund/overview',
            pathName: 'refund',
            active: true,
            permission: ['allProjectFinancial'],
          },
          {
            name: 'Claim Request',
            path: '/claim-request/overview',
            pathName: 'claimRequest',
            active: true,
            permission: ['claimRequest'],
          },
          {
            name: 'Overtime Request',
            path: '/overtime-request/overview',
            pathName: 'overtime',
            active: true,
            permission: ['overtimeRequest'],
          },
        ]
      }
    ]
  },
  {
    label: 'report',
    horizontalNavLabel: 'report',
    active: true,
    icon: UilClipboard,
    labelDisabled: true,
    pages: [
      {
        name: 'Report',
        icon: 'file-text',
        path: '/report/overview',
        pathName: 'report',
        active: true,
        permission: ['allReport', 'viewWorkerAllAttendance', 'viewAllWorkerAttendance'],
        pages: [
          {
            name: 'Timesheet',
            path: '/report/timesheet',
            pathName: 'timesheet',
            active: true,
            permission: ['allReport'],
          },
          {
            name: 'Time Attendance',
            path: '/report/time-attendance',
            pathName: 'attendance',
            active: true,
            permission: ['viewWorkerAllAttendance', 'viewAllWorkerAttendance'],
          },
        ]
      },
    ]
  },
  {
    label: 'setting',
    horizontalNavLabel: 'setting',
    active: true,
    icon: UilSetting,
    labelDisabled: true,
    pages: [
      {
        name: 'Setting',
        icon: 'settings',
        active: true,
        permission: ['allSetting'],
        pages: [
          {
            name: 'Organization',
            path: '/organization/overview',
            pathName: 'organization',
            active: true,
            permission: ['allOrganization'],
          },
          {
            name: 'Department',
            path: '/department/overview',
            pathName: 'department',
            active: true,
            permission: ['allDepartment'],
          },
          {
            name: 'Job Position',
            path: '/job-position/overview',
            pathName: 'job-position',
            active: true,
            permission: ['allWorkerPosition'],
          },
          {
            name: 'Ranking',
            path: '/ranking/overview',
            pathName: 'ranking',
            active: true,
            permission: ['allWorkerRanking'],
          },
          {
            name: 'User',
            path: '/user/overview',
            pathName: 'user',
            active: true,
            permission: ['allWorker'],
          },
          {
            name: 'Task Type',
            path: '/task-type/overview',
            pathName: 'task-type',
            active: true,
            permission: ['allProjectCategory'],
          },
          {
            name: 'User Group',
            path: '/user-group/overview',
            pathName: 'user-group',
            active: true,
            permission: ['allAnnouncementGroup'],
          },
          {
            name: 'Announcement',
            path: '/announcement/overview',
            pathName: 'announcement',
            active: true,
            permission: ['allAnnouncement'],
          },
          {
            name: 'Financial Setting',
            path: '/financial/setting',
            pathName: 'payfinancialment',
            active: true,
            permission: ['allProjectFinancial'],
          },
          {
            name: 'Claim Approval Setting',
            path: '/claim-setting/overview',
            pathName: 'claim-setting',
            active: true,
            permission: ['allClaimSetting'],
          },
          {
            name: 'Overtime Approval Setting',
            path: '/overtime-setting/overview',
            pathName: 'overtime',
            active: true,
            permission: ['allOvertimeSetting'],
          },
          {
            name: 'Feedback',
            path: '/feedback/overview',
            pathName: 'feedback',
            active: true,
            permission: ['viewFeedbackList'],
          }
        ]
      },
    ]
  },
  {
    label: 'Feedback',
    horizontalNavLabel: 'feedback',
    active: true,
    icon: UilUserCircle,
    labelDisabled: true,
    pages: [
      {
        name: 'Submit Feedback Form',
        icon: 'headphones',
        path: '/feedback',
        pathName: 'feedback',
        active: true,
        permission: [],
      },
    ]
  },
];
