import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api, attachmentUrl } from 'utils/api';
import { translate } from 'utils/translate';
import ActivityTimeline from './ActivityTimeline';
import TaskDetailsSummary from './TaskDetailsSummary';
import TaskTimesheet from './TaskTimesheet';
import TeamMembers from './TeamMembers';
import { logout } from 'store/actions/AuthActions';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Error404 from 'pages/error/Error404';

const TaskDetails = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [taskInfo, setTaskInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const timerRef = useRef(null);
    const location = useLocation();

    let breadcrumbData = [
        { label: translate(props.lang, 'Task'), url: '/task/overview' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    useEffect(() => {
        if(props?.auth?.permission?.allProjectTask){
            let isMounted = true;
            if (id && isMounted) {
                getInfo();
            } else {
                setTaskInfo(null)
            }
            return () => {
                isMounted = false;  // Clean up when component is unmounted
            };
        }
    }, [id, props.auth.permission])

    const getInfo = () => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_project_tasks_details + `/${location.state}/${id}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setTaskInfo(responseJson.data)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 2000);
    }

    return (
        props?.auth?.permission?.allProjectTask ?
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, taskInfo?.task[0]?.name)}</h2>
                {loading ? (
                    <div>
                        <DataLoading />
                    </div>
                ) : (
                    <div className="mb-4">
                        <Row className="g-0">
                            <Col xs={12} xxl={8} className="px-0 bg-body">
                                <div className="pb-9">
                                    {/* <div className="mb-5">
                                        <div className="d-flex justify-content-end">
                                            <RevealDropdownTrigger>
                                                <RevealDropdown>
                                                    <Dropdown.Item>Edit</Dropdown.Item>
                                                    <Dropdown.Item className="text-danger">Delete</Dropdown.Item>
                                                    <Dropdown.Item>Downlaod</Dropdown.Item>
                                                    <Dropdown.Item>Report abuse</Dropdown.Item>
                                                </RevealDropdown>
                                            </RevealDropdownTrigger>
                                        </div>
                                    </div> */}
                                    <Row className="gx-0 gx-sm-5 gy-8 mb-5">
                                        <Col xs={12} xl={7} xxl={7} className="pe-xl-0">
                                            <TaskDetailsSummary taskInfo={taskInfo} lang={props.lang} auth={props.auth} />
                                        </Col>
                                        <Col xs={12} sm={5} lg={5} xl={5}>
                                            <TeamMembers taskInfo={taskInfo} lang={props.lang} auth={props.auth} />
                                        </Col>
                                    </Row>
                                    <div className=''>
                                        <h3 className="text-body-emphasis mb-4">{translate(props.lang, "Description")}</h3>
                                        {taskInfo && taskInfo?.task[0]?.description ? (
                                            <p className="text-body-secondary mb-4" dangerouslySetInnerHTML={{ __html: taskInfo && taskInfo?.task[0]?.description }}></p>
                                        ) : null}

                                    </div>
                                    <div>
                                        <h2 className="mb-4">{translate(props.lang, "Attachment")}</h2>
                                        {taskInfo && taskInfo?.task[0].attachment ? (
                                            taskInfo?.task[0].attachment.length > 0 ? (
                                                taskInfo?.task[0].attachment.map((item, i) => (
                                                    <div key={i} className={'border-bottom py-4'}>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-start">
                                                                <a href={attachmentUrl + item.path} target="_blank">
                                                                    <div className="d-flex align-items-center mb-1">
                                                                        <FontAwesomeIcon
                                                                            icon={faFileLines}
                                                                            className="me-2 fs-9 text-body-tertiary"
                                                                        />
                                                                        <p className="text-body-highlight mb-0 lh-1">{item.originalname ? item.originalname : item.name}</p>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (<p className="text-body-highlight mb-0 lh-1">{translate(props.lang, "No attachment")}</p>)
                                        ) : (
                                            <p className="text-body-highlight mb-0 lh-1">{translate(props.lang, "No attachment")}</p>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} xxl={4} className="px-0 border-start-xxl">
                                <div className="py-3 px-4 px-lg-6 bg-light dark__bg-gray-1100 position-relative top-1">
                                    <h3 className="text-body-highlight mb-4 fw-bold">
                                        {translate(props.lang, "Workflow")}
                                    </h3>
                                    <ActivityTimeline taskInfo={taskInfo} lang={props.lang} auth={props.auth} />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <TaskTimesheet taskInfo={taskInfo} lang={props.lang} auth={props.auth} />
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </div>
        : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TaskDetails);
