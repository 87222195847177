import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { api } from 'utils/api';
import { translate } from 'utils/translate';
import MyTask from './MyTask';
import Notification from './Notification';
import ProjectStatusReport from './ProjectStatusReport';
import WorkflowOverview from './WorkflowOverview';
import { logout } from 'store/actions/AuthActions';
import { useDispatch } from 'react-redux';

const DashboardOverview = (props) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [dashboardInfo, setDashboardInfo] = useState({
        ongoingTaskList: [],
        totalCompletedProject: 0,
        totalCompletedTask: 0,
        totalOngoingProject: 0,
        totalOngoingTask: 0
    })
    const timerRef = useRef(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Dashboard'), url: '#' },
        { label: translate(props.lang, 'Overview'), active: true }
    ]

    useEffect(() => {
        getDashboard();
    }, [props.auth.auth.token])

    const getDashboard = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_my_dashboard + `?page=1&limit=10`;

            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        setDashboardInfo(responseJson.data);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else { 
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <div className="mb-4">
                    <Row className="g-3 mb-3">
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 ongoing" onClick={() => navigate(`/project/overview?status=ongoing`)}>
                                <span className="fs-8 fw-bold">{translate(props.lang, "On Going Projects")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{dashboardInfo.totalOngoingProject ? dashboardInfo.totalOngoingProject : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 completed" onClick={() => navigate(`/project/overview?status=completed`)}>
                                <span className="fs-8 fw-bold">{translate(props.lang, "Completed Projects")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{dashboardInfo.totalCompletedProject ? dashboardInfo.totalCompletedProject : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 ongoingTask" onClick={() => navigate(`/task/overview?status=ongoing`)}>
                                <span className="fs-8 fw-bold">{translate(props.lang, "On Going Tasks")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{dashboardInfo.totalOngoingTask ? dashboardInfo.totalOngoingTask : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 completedTask" onClick={() => navigate(`/task/overview?status=completed`)}>
                                <span className="fs-8 fw-bold">{translate(props.lang, "Completed Tasks")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{dashboardInfo.totalCompletedTask ? dashboardInfo.totalCompletedTask : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mb-4">
                    <Row className="g-3 align-items-stretch">
                        <Col lg={6} md={6} xs={12} sm={12} className='mb-3'>
                            <ProjectStatusReport dashboardInfo={dashboardInfo} />
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12} className='mb-3'>
                            <Notification />
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className='mb-3'>
                            <WorkflowOverview/>
                        </Col>
                    </Row>
                </div>
                <MyTask />
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(DashboardOverview);
