import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}


export function saveTokenInLocalStorage(tokenDetails, rememberMe) {
    tokenDetails.rememberMe = rememberMe;
    if(rememberMe){
        tokenDetails.expiresIn = 86400 * 30;
        var date = new Date();
        date.setDate(date.getDate() + 30);

        tokenDetails.expireDate = new Date(date)
    }else{
        tokenDetails.expiresIn = 7200 ;
        tokenDetails.expireDate = new Date(new Date().getTime() +  (120 * 60 * 1000) )
    }
    localStorage.setItem('pms-crm', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history, path) {
    setTimeout(() => {
        dispatch(logout());
    }, timer);
}

export function checkAutoLogin(dispatch, history, path) {
    const tokenDetailsString = localStorage.getItem('pms-crm');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout());
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout());
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));
}
