import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faPlus, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import { useAppContext } from 'providers/AppProvider';
import { AsyncPaginate } from "react-select-async-paginate";
import moment from 'moment';
import { deepCopyArray, getFinanceColor, getFinanceLabel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import Swal from 'sweetalert2';
import { dateButtonTextMap, discountTypeOption } from "utils/config";
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import NoResultFound from "page/Components/NoResultFound";
import { logout } from "store/actions/AuthActions";
import Timeline from "components/base/Timeline";
import Error404 from "pages/error/Error404";
import { Editor } from '@tinymce/tinymce-react';

const localeMap = {
    en: enLocale,
    zh: zhCNLocale,
};

const EditQuotation = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    let errorObj = {
        organization_address_id: '',
        organization_id: '',
        payment_method: '',
        customer_id: '',
        customer_address_id: '',
        projectId: '',
        type: '',
        subject: '',
        customer_note: '',
        tnc: '',
        creation_date: '',
        due_date: '',
        delivery_date: '',
        discount: '',
        discount_type: '',
        payment_term: '',
        project_task_id: '',
        taskName: '',
        taskCode: '',
        description: '',
        amount: '',
        tax: '',
        task_discount: '',
        task_discount_type: '',
        tasks: '',
        quantity: ''
    }
    const { config } = useAppContext();
    const [language, setLanguage] = useState('US');
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [projectInfo, setProjectInfo] = useState(null);
    const [organizationList, setOrganizationList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedCustomTask, setSelectedCustomTask] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]); //store array index
    const [paymentTermOption, setPaymentTermOption] = useState([])
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [addressOption, setAddressOption] = useState([]);
    const [financialInfo, setFinancialInfo] = useState(null)
    const [taxOption, setTaxOption] = useState([]);
    const [customerAddressOption, setCustomerAddressOption] = useState([]);
    const [editRow, setEditRow] = useState('');
    const [currency, setCurrency] = useState('')
    const [taskOption, setTaskOption] = useState([]);
    const [content, setContent] = useState('');
    const timerRef = useRef(null);
    const infoRef = useRef(null);
    const [editable, setEditable] = useState(false);
    const [tableFormData, setTableFormData] = useState({
        project_task_id: '',
        taskName: '',
        taskCode: '',
        description: '',
        quantity: 1,
        amount: '',
        tax: 0,
        discount: '',
        discount_type: 'percent',
        finalAmount: 0,
        isRemoved: 0
    })
    const [formData, setFormData] = useState({
        type: 'quotation',
        projectId: '',
        companyDetails: {
            organization_id: '',
            organization_address_id: '',
            payment_method: []
        },
        billingDetails: {
            customer_id: '',
            customer_address_id: '',
        },
        tasks: [],
        creation_date: moment(new Date()).format('YYYY-MM-DD'),
        due_date: moment(new Date()).format('YYYY-MM-DD'),
        delivery_date: moment(new Date()).format('YYYY-MM-DD'),
        discount: 0,
        discount_type: 'percent',
        tnc: '',
        payment_term: '',
        customer_note: '',
        subject: '',
    })
    const [finalPrice, setFinalPrice] = useState({
        subTotal: 0.00,
        totalPrice: 0.00,
        totalDiscount: 0.00,
        totalTax: 0.00,
    })

    let breadcrumbData = [
        { label: translate(props.lang, 'Finance'), url: '#' },
        { label: translate(props.lang, 'Quotation'), url: '/quotation/overview' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    useEffect(() => {
        if (props?.auth?.permission?.allProjectFinancial) {
            let isMounted = true;
            if (id && isMounted) {
                getInfo();
            }
            return () => {
                isMounted = false;  // Clean up when component is unmounted
            }
        }
    }, [id, props.auth.permission])

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
        if (props.lang === 'ZH') {
            setLanguage('zh_CN')
        } else {
            setLanguage('US')
        }
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
        getSettings();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true);
        clearTimeout(infoRef.current);
        infoRef.current = setTimeout(() => {
            fetch(api.get_project_financial_details + `/${id}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setFinancialInfo(responseJson.data)
                        if (responseJson.data) {
                            let arr = [];
                            responseJson.data.project_financial_task.map((item) => {
                                arr.push({
                                    project_financial_task_id: item.project_financial_task_id,
                                    project_task_id: item.tasks?.project_task_id,
                                    taskName: item.name ? item.name : item.tasks?.name,
                                    taskCode: item.code,
                                    description: item.description,
                                    amount: item.amount,
                                    tax: item.tax,
                                    discount: item.discount,
                                    discount_type: item.discount_type,
                                    quantity: item.quantity,
                                    isRemoved: 0,
                                })
                            })
                            calculateFinalAmount(arr)
                            let newFormData = {
                                type: responseJson.data.type,
                                projectId: responseJson.data?.project?.project_id,
                                companyDetails: {
                                    organization_id: responseJson.data.project_organization?.organization?.organization_id,
                                    organization_address_id: responseJson.data.organization_address?.organization_address_id,
                                },
                                billingDetails: {
                                    customer_id: responseJson.data.customer?.customer_id,
                                    customer_address_id: responseJson.data.customer_address_id,
                                },
                                tasks: arr,
                                creation_date: moment(new Date(responseJson.data.creation_date)).format('YYYY-MM-DD'),
                                due_date: moment(new Date(responseJson.data.due_date)).format('YYYY-MM-DD'),
                                delivery_date: moment(new Date(responseJson.data.delivery_date)).format('YYYY-MM-DD'),
                                discount: responseJson.data.discount_amount,
                                discount_type: responseJson.data.discount_type,
                                tnc: responseJson.data.tnc,
                                payment_term: responseJson.data.payment_term_obj,
                                customer_note: responseJson.data.subject,
                                subject: responseJson.data.subject,
                            };
                            if (responseJson.data.customer?.customer_id) {
                                getCustomerInfo(responseJson.data.customer.customer_id);
                            }
                            setFormData(newFormData);
                            getProjectTaskByProject(responseJson.data?.project?.project_id, responseJson.data.project_organization?.organization?.organization_id)
                            getOrganization(responseJson.data?.project_organization?.organization?.organization_id)
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const getSettings = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_settings, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        let filter = responseJson.data.filter(item => item.category === 'setting');
                        if (filter.length > 0) {
                            setPaymentTermOption(filter[0]?.value?.payment_term)
                        }
                        let taxFiltered = responseJson.data.filter(item => item.category === 'financial');
                        if (taxFiltered.length > 0) {
                            let arr = [];
                            taxFiltered[0].value.forEach(element => {
                                arr.push({ label: element.tax + "" + (element.tax_type === 'percent' ? '%' : ''), value: element.tax })
                            })
                            setTaxOption(arr);
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const getProjectDetails = (id) => {
        fetch(api.get_project_details + '/' + id + '?group=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setProjectInfo(responseJson.data);
                    if (responseJson.data.customer) {
                        setFormData(prevState => ({ ...prevState, billingDetails: { customer_id: responseJson.data.customer.customer_id } }))
                    }
                    setTaskOption(responseJson.data.task)
                    setOrganizationList(responseJson.data.project_organization)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getCustomerInfo = (id) => {
        fetch(api.get_customer_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setCustomerAddressOption(responseJson.data.address)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getProjectTaskByProject = (id, organization_id) => {
        fetch(api.get_project_tasks_details + `/${id}?isOptions=1&group=1`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    let filtered = responseJson.data.data.filter(item => item?.project_category_assigned?.project_category?.organization?.organization_id === organization_id);
                    setTaskOption(filtered);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    useEffect(() => {
        if (paymentMethod.length > 0 && financialInfo && financialInfo.payment_method) {
            paymentMethod.map((item, i) => {
                if (item.description === financialInfo.payment_method[0].description) {
                    setSelectedPaymentMethod([i])
                }
            })
        }
    }, [financialInfo, paymentMethod])

    const getOrganization = (id) => {
        fetch(api.get_organization_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setAddressOption(responseJson.data.address);
                    setPaymentMethod(responseJson.data.payment_method ? responseJson.data.payment_method : []);
                    setCurrency(responseJson.data.currency)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleTableFormChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const newFormData = { ...tableFormData };
        newFormData[fieldName] = fieldValue;
        let finalAmount = newFormData.amount;
        if (fieldName === 'amount' || fieldName === 'tax' || fieldName === 'discount') {
            newFormData.finalAmount = calculateItemPrice(newFormData);
        } else if (fieldName === 'taskName') {
            if (fieldValue) {
                if (!editRow && selectedCustomTask) {
                    let filter = formData.tasks.filter(item => item.project_task_id === "");
                    newFormData.taskCode = "ITEM00" + (Number(filter.length) + 1);
                }
            }
        }
        setTableFormData(newFormData);
    }

    // const handleTableFormChange = (event) => {
    //     const fieldName = event.target.name;
    //     const fieldValue = event.target.value;
    //     const newFormData = { ...tableFormData };
    //     newFormData[fieldName] = fieldValue;
    //     if(fieldName !== 'taskName'){
    //         newFormData.finalAmount = calculateFinalAmount(newFormData);
    //     }
    //     setTableFormData(newFormData);
    // }

    const handleTableOptionSelected = (e, type) => {
        const fieldValue = e ? e.value : '';
        const newFormData = { ...tableFormData };
        newFormData[type] = fieldValue;
        if (type === 'project_task_id') {
            if (fieldValue) {
                let filter = taskOption.filter(item => item.project_task_id === fieldValue);
                if (filter.length > 0) {
                    newFormData.quantity = filter[0].quantity ? filter[0].quantity : 1;
                    newFormData.description = filter[0].description;
                    newFormData.taskName = filter[0].name;
                }
            }
        }
        newFormData.finalAmount = calculateItemPrice(newFormData);
        // if (type === 'project_task_id') {
        //     if (fieldValue) {
        //         let filter = taskOption.filter(item => item.project_task_id === fieldValue);
        //         if (filter.length > 0) {
        //             newFormData.quantity = filter[0].quantity ? filter[0].quantity : 1;
        //             newFormData.description = filter[0].description;
        //             newFormData.taskName = filter[0].name;
        //         }
        //     }
        // }
        // let finalAmount = newFormData.amount;
        // if (type === 'tax') {
        //     if (newFormData.amount) {
        //         if (newFormData.discount) {
        //             if (newFormData.discount_type === 'percent') {
        //                 finalAmount = Number(finalAmount) - (Number(finalAmount) * Number(newFormData.discount) / 100);
        //             } else if (newFormData.discount_type === 'amount') {
        //                 finalAmount = Number(finalAmount) - Number(newFormData.discount);
        //             }
        //             finalAmount = finalAmount + (finalAmount * fieldValue / 100);
        //         } else {
        //             finalAmount = Number(finalAmount) + (finalAmount * fieldValue / 100);
        //         }
        //     }
        // } else if (type === 'discount') {
        //     if (newFormData.amount > 0) {
        //         if (newFormData.discount_type === 'percent') {
        //             finalAmount = Number(finalAmount) - (Number(finalAmount) * Number(fieldValue) / 100);
        //         } else if (newFormData.discount_type === 'amount') {
        //             finalAmount = Number(finalAmount) - Number(fieldValue);
        //         }

        //         if (newFormData.tax) {
        //             finalAmount = Number(finalAmount) + (Number(finalAmount) * Number(newFormData.tax) / 100);
        //         }
        //     }
        // }
        // newFormData.finalAmount = Number(finalAmount).toFixed(2);
        setTableFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const newFormData = { ...formData };
        if (type === 'organization_id') {
            if (e) {
                getOrganization(e?.value)
            }
            newFormData.companyDetails.organization_id = e?.value;
        } else if (type === 'method' || type === 'organization_address_id' || type === 'organization_bank_id') {
            newFormData.companyDetails[type] = e?.value;
        } else if (type === 'projectId') {
            newFormData[type] = e?.value;
            getProjectDetails(e?.value)
        } else if (type === 'customer_address_id') {
            newFormData.billingDetails[type] = e?.value;
        } else {
            newFormData[type] = e?.value;
        }
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.projectId) {
            errorMsg.projectId = "This field is required.";
            check = false;
        }
        if (!formData.subject) {
            errorMsg.subject = "This field is required.";
            check = false;
        }
        if (!formData.creation_date) {
            errorMsg.creation_date = "This field is required.";
            check = false;
        }
        if (!formData.due_date) {
            errorMsg.due_date = "This field is required.";
            check = false;
        }
        if (!formData.delivery_date) {
            errorMsg.delivery_date = "This field is required.";
            check = false;
        }
        // if (!formData.payment_term) {
        //     errorMsg.payment_term = "This field is required.";
        //     check = false;
        // }
        if (formData.tasks.length === 0) {
            errorMsg.tasks = "This field is required.";
            check = false;
        }
        if (!formData.billingDetails.customer_id) {
            errorMsg.customer_id = "This field is required.";
            check = false;
        }
        if (!formData.companyDetails.organization_id) {
            errorMsg.organization_id = "This field is required.";
            check = false;
        }
        if (!formData.companyDetails.organization_address_id) {
            errorMsg.organization_address_id = "This field is required.";
            check = false;
        }
        if (selectedPaymentMethod.length === 0) {
            errorMsg.payment_method = "This field is required.";
            check = false;
        } else {
            let arr = [];
            arr.push(paymentMethod[selectedPaymentMethod]);
            newFormData.companyDetails.payment_method = arr;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_project_financial + `/${id}`, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        navigate('/quotation/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const handleEditorChange = (newContent) => {
        setContent(newContent);
        // setTableFormData(prevState => ({ ...prevState, description: newContent }));
    };

    const editData = (i) => {
        setEditRow(i);
        if (formData.tasks[i].project_task_id) {
            setSelectedCustomTask(false)
        } else {
            setSelectedCustomTask(true)
        }
        setContent(formData.tasks[i].description);
        console.log("form", formData.tasks[i])
        let newFormData = formData.tasks[i];
        newFormData.finalAmount = calculateItemPrice(formData.tasks[i])
        setTableFormData(newFormData);
        setModalOpen(true);
    }

    const removeData = (index) => {
        let newFormData = { ...formData };
        let newList = deepCopyArray(formData.tasks);
        newList[index].isRemoved = 1;
        // newList.splice(index, 1);
        newFormData.tasks = newList;
        setFormData(newFormData);
        calculateFinalAmount(newList)
    }

    const addNewRow = () => {
        setModalOpen(true);
        setEditRow(null);
        setSelectedCustomTask(false);
    }

    const closeModal = () => {
        setModalOpen(false)
        setTableFormData({
            project_task_id: '',
            taskName: '',
            taskCode: '',
            description: '',
            quantity: 1,
            amount: '',
            tax: '',
            discount: 0,
            discount_type: 'percent',
            finalAmount: 0,
        })
        setEditRow(null)
        setSelectedCustomTask(false)
        setErrors(errorObj);
    }

    const addItem = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let data = { ...tableFormData };

        Object.keys(tableFormData).map((item) => {
            if (item !== 'isRemoved') {
                if (item === "project_task_id") {
                    if (!selectedCustomTask) {
                        if (!tableFormData.project_task_id) {
                            check = false
                            errorMsg.project_task_id = "This field is required.";
                        }
                    }
                } else if (item === "taskName") {
                    if (selectedCustomTask) {
                        if (!tableFormData.taskName) {
                            check = false
                            errorMsg.taskName = "This field is required.";
                        }
                    }
                } else if (item === "taskCode") {
                    if (selectedCustomTask) {
                        if (!tableFormData.taskCode) {
                            check = false
                            errorMsg.taskCode = "This field is required.";
                        } else {
                            let filtered = formData.tasks.filter(item => item.taskCode === tableFormData.taskCode);
                            if (filtered.length > 0) {
                                if (editRow) {
                                    let currentData = formData.tasks[editRow];
                                    if (currentData.taskCode !== tableFormData.taskCode) {
                                        check = false
                                        errorMsg.taskCode = "Task code already exist.";
                                    }
                                } else {
                                    check = false
                                    errorMsg.taskCode = "Task code already exist.";
                                }
                            }
                        }
                    }
                } else if (item === "description") {
                    if (!content) {
                        check = false
                        errorMsg.description = "This field is required.";
                    } else {
                        data.description = content;
                    }
                } else {
                    if (item !== 'tax' && item !== 'discount') {
                        if (!tableFormData[item]) {
                            check = false
                            errorMsg[item] = "This field is required.";
                        }
                    }
                    if (item === 'amount' || item === 'quantity' || item === 'discount' || item === 'tax') {
                        if (tableFormData[item].toString()) {
                            data[item] = Number(tableFormData[item])
                        }
                    }
                    if (!tableFormData.tax) {
                        tableFormData.tax = 0;
                    }
                    if (!tableFormData.discount) {
                        tableFormData.discount = 0;
                    }
                }
            }
        })
        setErrors(errorMsg)
        if (check) {
            let newFormData = { ...formData };
            if (editRow) {
                newFormData.tasks[Number(editRow)] = data;
            } else {
                newFormData.tasks.push(data);
            }
            setContent('');
            setFormData(newFormData);
            closeModal();
            setEditRow(null)
            calculateFinalAmount(newFormData.tasks);
        }
    }

    const calculateFinalAmount = (arr) => {
        let newFinalPrice = { ...finalPrice };
        let totalPrice = 0;
        let subTotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        arr.map((item) => {
            if (item.amount && !item.isRemoved) {
                subTotal = Number(subTotal) + Number(item.amount);
                let newAmount = Number(item.amount);
                let newTax = 0;
                if (item.discount) {
                    let newDiscount = 0;
                    if (item.discount_type === 'percent') {
                        newDiscount = Number(item.amount) * Number(item.discount) / 100;
                        newAmount = Number(item.amount) - (Number(item.amount) * Number(item.discount) / 100);
                    } else {
                        newDiscount = Number(item.discount);
                        newAmount = Number(item.amount) - Number(item.discount);
                    }
                    totalDiscount = Number(totalDiscount) + Number(newDiscount);
                }
                if (item.tax) {
                    newTax = (Number(newAmount) * Number(item.tax) / 100);
                    newAmount = Number(newAmount) + (Number(newAmount) * Number(item.tax) / 100);
                    totalTax = Number(totalTax) + newTax;
                }
                totalPrice = Number(totalPrice) + Number(newAmount);
            }
        })
        newFinalPrice.totalTax = Number(totalTax).toFixed(2);
        newFinalPrice.subTotal = Number(subTotal).toFixed(2);
        newFinalPrice.totalDiscount = Number(totalDiscount).toFixed(2);
        newFinalPrice.totalPrice = Number(totalPrice).toFixed(2);
        setFinalPrice(newFinalPrice)
    }

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            newFormData[type] = moment(e).format('YYYY-MM-DD');
        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const selectPayment = (index) => {
        if (editable) {
            let newForm = [...selectedPaymentMethod];
            var arrIdx = newForm.indexOf(index);
            if (arrIdx > -1) {
                newForm.splice(arrIdx, 1);
            } else {
                newForm.push(index);
            }
            setSelectedPaymentMethod(newForm)
        }
    }

    const onChangeSelectedCustomTask = () => {
        setSelectedCustomTask(!selectedCustomTask);
        setTableFormData({
            project_task_id: '',
            taskName: '',
            taskCode: '',
            description: '',
            quantity: 1,
            amount: '',
            tax: '',
            discount: 0,
            discount_type: 'percent',
            finalAmount: 0,
        });
        setErrors(errorObj)
    }

    const updateStatus = (val) => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Confirm this action? Action cannot be revert."),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let newFormData = {
                    status: val,
                    remark: 'quotation',
                }
                fetch(api.edit_project_financial_status + `/${id}`, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(newFormData)
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === 'success') {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been update successful."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        });
    }

    const calculateItemPrice = (item) => {
        let finalAmount = item.amount;
        if (item.discount) {
            if (item.discount_type === 'percent') {
                finalAmount = Number(finalAmount) - (Number(finalAmount) * item.discount / 100);

            } else if (item.discount_type === 'amount') {
                finalAmount = Number(finalAmount) - Number(item.discount);
            }
        }
        if (item.tax) {
            finalAmount = Number(finalAmount) + (Number(finalAmount) * Number(item.tax) / 100);
        }
        return finalAmount ? finalAmount : 0
    }

    const convertToInvoice = () => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Confirm convert this quotation to invoice?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.convert_quotation + `/${id}`
                fetch(url, {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Quotation has been convert to invoice successfully."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    return (
        props?.auth?.permission?.allProjectFinancial ? (
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <h2 className="mb-4">{translate(props.lang, "Quotation")} {financialInfo?.financial_number}</h2>
                <div>
                    <Row className='align-items-stretch justify-content-end'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                            {props?.auth?.permission?.editProjectFinancial ? (
                                <>
                                    {financialInfo?.financial_status === 'draft' && !editable ? (
                                        <Button variant="outline-primary" className='ms-md-2' onClick={() => setEditable(true)}>
                                            <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                                        </Button>
                                    ) : null}
                                    {financialInfo?.financial_status === 'draft' ? (
                                        <Button variant="outline-success" className='ms-md-2' onClick={() => updateStatus('sendclient')}>
                                            <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Sent to Client")}</span>
                                        </Button>
                                    ) : null}
                                    {financialInfo?.financial_status === 'draft' || financialInfo?.financial_status === 'sendclient' ? (
                                        <Button variant="outline-warning" className='ms-md-2' onClick={() => convertToInvoice()}>
                                            <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Convert to Invoice")}</span>
                                        </Button>
                                    ) : null}
                                    {financialInfo?.financial_status === 'draft' || financialInfo?.financial_status === 'sendclient' ? (
                                        <Button variant="outline-danger" className='ms-md-2' onClick={() => updateStatus('voided')}>
                                            <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Void")}</span>
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}
                            {financialInfo ? (
                                <Button variant="secondary" className='ms-md-2' onClick={() => navigate(`/quotation/pdf/${id}`)}>
                                    <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "PDF View")}</span>
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </div>
                <div className='border-bottom py-3'>
                    <Row className='align-items-stretch justify-content-start'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Quotation")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.financial_number ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quotation No.")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="financialNumber"
                                        placeholder={translate(props.lang, "Quotation No.")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.financial_number}
                                        autoComplete="off"
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.financial_number)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 h-100 ${errors.financial_status ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Status")}</span>
                                <div className={`input-group px-3`}>
                                    {financialInfo?.financial_status ? (
                                        <div className="btn light px-2 text-capitalize text-center status-dropdown d-flex align-items-center justify-content-center" style={{ background: getFinanceColor(financialInfo?.financial_status, 'status'), color: '#fff' }}>
                                            <span className='fs-9 fw-normal'>{translate(props.lang, getFinanceLabel(financialInfo?.financial_status, 'status'))}</span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.financial_status)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.projectId ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="project"
                                        placeholder={translate(props.lang, "Project")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.project?.name}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.projectId)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.subject ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Subject")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="subject"
                                        placeholder={translate(props.lang, "Enter subject")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={formData.subject}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                        readOnly={!editable}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.subject)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.creation_date ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quotation Date")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="creation_date"
                                        placeholder={translate(props.lang, "Quotation Date")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.creation_date}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.creation_date)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.due_date ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quotation Due Date")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="due_date"
                                        placeholder={translate(props.lang, "Quotation Due Date")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.due_date}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.due_date)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.delivery_date ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quotation Delivery Date")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="delivery_date"
                                        placeholder={translate(props.lang, "Quotation Delivery Date")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.delivery_date}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.delivery_date)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input h-100 py-1 ${errors.payment_term ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Term")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="payment_term"
                                        placeholder={translate(props.lang, "Payment Term")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.payment_term_obj?.name}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.payment_term)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-bottom py-3'>
                    <Row className='align-items-stretch justify-content-start'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Company Details")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.organization_id ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Company")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="project"
                                        placeholder={translate(props.lang, "Company")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.project_organization?.organization?.name}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization_id)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.organization_address_id ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")} <span className="text-danger">*</span></span>
                                <div className="input-group-select px-3">
                                    <Select
                                        options={
                                            addressOption.length > 0 ? addressOption.map((item, i) => ({ key: i, value: item.organization_address_id, label: item.name + ' (' + item.address1 + ' ' + item.address2 + ')' })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                        }
                                        placeholder={translate(props.lang, "Select address")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={
                                            formData.companyDetails.organization_address_id ? (
                                                addressOption.filter(item => item.organization_address_id == formData.companyDetails.organization_address_id).length > 0 ?
                                                    (addressOption.filter(item => item.organization_address_id == formData.companyDetails.organization_address_id).map((item, i) => ({ value: item.organization_address_id, label: item.name + ' (' + item.address1 + ' ' + item.address2 + ')', key: i })))
                                                    : null
                                            ) : null
                                        }
                                        name="organization_address_id"
                                        onChange={(e) => handleOptionSelected(e, 'organization_address_id')}
                                        isDisabled={!editable}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization_address_id)}</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 mb-2 ${errors.payment_method ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Method")} <span className="text-danger">*</span></span>
                                <div className="mb-2">
                                    {paymentMethod.length > 0 ? (
                                        paymentMethod.map((item, i) => (
                                            <div className={`groupLabel-input mx-3 mt-1 ${selectedPaymentMethod.includes(i) ? 'selected' : ''}`} onClick={() => selectPayment(i)} key={i}>
                                                <Row className="px-0 mx-0 align-items-center">
                                                    <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                        <span className={`fw-bold px-3 fs-9 text-grey-1 d-block mt-2`}>{item.name}</span>
                                                        <div className={`input-group px-3 mb-2`}>
                                                            <span className="lh-base break-word fs-9 break-white-space">{item.description}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="text-end px-3">
                                                        {selectedPaymentMethod.includes(i) ? (
                                                            <FontAwesomeIcon icon={faCheckCircle} color="#01D2AB" size="lg" />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={`groupLabel-input mx-3 mt-1 pt-2`}>
                                            <div className={`input-group px-3 mb-2`}>
                                                <span className="lh-base break-word fs-9 break-white-space">{translate(props.lang, "No option available")}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.payment_method)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-bottom py-3'>
                    <Row className='align-items-stretch justify-content-start'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className={`fw-semibold fs-7 text-grey-1 d-block mt-2`}>{translate(props.lang, "Billing Details")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="name"
                                        placeholder={translate(props.lang, "Customer")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.customer?.name}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer's Company")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type={'text'}
                                        name="customer_company_name"
                                        placeholder={translate(props.lang, "Customer")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={financialInfo?.customer?.customer_company_name}
                                        autoComplete="off"
                                        readOnly
                                    // onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.customer_address_id ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")}</span>
                                <div className="input-group-select px-3">
                                    <Select
                                        options={
                                            customerAddressOption.length > 0 ? customerAddressOption.map((item, i) => ({ key: i, value: item.customer_address_id, label: item.name + ` ( ${item.address1},${item.address2}, ${item.town}, ${item.state} )` })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                        }
                                        placeholder={translate(props.lang, "Select address")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={customerAddressOption.filter(option => option.customer_address_id === formData.billingDetails.customer_address_id).length > 0 ? customerAddressOption.filter(option => option.customer_address_id === formData.billingDetails.customer_address_id).map((item, i) => ({ key: i, value: item.customer_address_id, label: item.name + ` ( ${item.address1},${item.address2}, ${item.town}, ${item.state} )` })) : null}
                                        name="customer_address_id"
                                        onChange={(e) => handleOptionSelected(e, 'customer_address_id')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.customer_address_id)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-bottom py-3 bg-body-highlight px-3'>
                    <Row className='align-items-stretch justify-content-start'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className={`fw-semibold fs-7 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quotation Items")} <span className="text-danger">*</span></span>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className='table-responsive'>
                                <Table responsive="sm" hover>
                                    <thead>
                                        <tr>
                                            <th className={`fw-bold fs-8 px-1`}>{translate(props.lang, "Item")}</th>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Quantity")}</th>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Price")}</th>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Tax")}</th>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Discount")}</th>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Total Amount")}</th>
                                            <th className={`fw-bold fs-8`}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.tasks.map((item, i) => (
                                            !item.isRemoved ? (
                                                <tr key={i} role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                    <td className={`px-1 fw-normal fs-9 lh-base text-capitalize`} style={{ minWidth: '300px' }}>
                                                        <div>
                                                            <span className="fw-bold">{item.taskName} {item.taskCode ? `(${item.taskCode})` : ''}</span>
                                                        </div>
                                                        <div className="mt-1" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                    </td>
                                                    <td className={`fw-normal fs-9 lh-base`}>{item.quantity}</td>
                                                    <td className={`fw-normal fs-9 lh-base`}>{item.amount}</td>
                                                    <td className={`fw-normal fs-9 lh-base`}>{item.tax ? taxOption.filter(tx => tx.value.toString() === item.tax.toString()).length > 0 ? taxOption.filter(tx => tx.value.toString() === item.tax.toString())[0].label : '-' : '-'}</td>
                                                    <td className={`fw-normal fs-9 lh-base`}>{item.discount}{item.discount_type === 'percent' ? '%' : ''}</td>
                                                    <td className={`fw-normal fs-9 lh-base`}>{calculateItemPrice(item).toFixed(2)}</td>
                                                    <td className={`fw-normal fs-9 lh-base`}>
                                                        {financialInfo?.financial_status === 'draft' && editable ? (
                                                            <div className="d-flex">
                                                                <div className="px-2" onClick={() => editData(i.toString())}>
                                                                    <FontAwesomeIcon icon={faEdit} color="#194BFB" size={22} />
                                                                </div>
                                                                <div className="px-2" onClick={() => removeData(i)}>
                                                                    <FontAwesomeIcon icon={faTrashAlt} color="red" size={22} />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            ) : null
                                        ))}
                                        {formData.tasks.length === 0 ? (
                                            <tr role="row">
                                                <td colSpan={7}>
                                                    <div className={`${errors.tasks ? 'errorBorder' : ''}`}>
                                                        <NoResultFound />
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.tasks)}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={2} className={`fw-bold fs-9 lh-base text-end`}>{translate(props.lang, "Subtotal")}</td>
                                                <td className={`fw-semibold fs-9 lh-base`}>{finalPrice.subTotal}</td>
                                                <td className={`fw-semibold fs-9 lh-base`}>{finalPrice.totalTax}</td>
                                                <td className={`fw-semibold fs-9 lh-base`}>{finalPrice.totalDiscount}</td>
                                                <td className={`fw-semibold fs-9 lh-base`}>{finalPrice.totalPrice}</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        {financialInfo?.financial_status === 'draft' && editable ? (
                            <Col lg={12} sm={12} xs={12} md={12} className="mb-3">
                                <Button variant="secondary" className='w-100 text-center h-100' onClick={() => addNewRow()}>
                                    <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                    {translate(props.lang, "Add New Data")}
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                </div>
                <div className='py-3'>
                    <Row className='align-items-stretch justify-content-start'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.customer_note ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer Note")}</span>
                                <div className={`input-group`}>
                                    <Form.Control
                                        as="textarea"
                                        name="customer_note"
                                        className="form-control input-transparent py-1 px-3"
                                        rows={3}
                                        value={formData.customer_note}
                                        placeholder={translate(props.lang, "Enter customer note")}
                                        onChange={e => handleAddFormChange(e)}
                                        readOnly={!editable}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.customer_note)}</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.tnc ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Terms and Condition")}</span>
                                <div className={`input-group`}>
                                    <Form.Control
                                        as="textarea"
                                        name="tnc"
                                        className="form-control input-transparent py-1 px-3"
                                        rows={3}
                                        value={formData.tnc}
                                        placeholder={translate(props.lang, "Enter payment terms and condition")}
                                        onChange={e => handleAddFormChange(e)}
                                        readOnly={!editable}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.tnc)}</span>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="d-flex justify-content-start gap-3">
                                {financialInfo?.financial_status === 'draft' && editable ? (
                                    <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                        <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                                    </Button>
                                ) : null}
                                <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/quotation/overview')}>
                                    {translate(props.lang, "Cancel")}
                                </Button>
                            </div>
                        </Col>

                    </Row>
                </div>
                <Modal className="fade" show={modalOpen} centered size="lg">
                    <Modal.Header className="border-0 pt-4 pb-2 px-3">
                        <div className="d-flex w-100 align-items-center">
                            <div className="w-75"></div>
                            <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                                <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                    <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                </svg>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="border-0 px-3">
                        <div className='px-2'>
                            <Row className="justify-content-start px-0 mx-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="px-0 mb-4">
                                    <div className="text-center">
                                        <span className="fw-bold fs-7">{editRow ? translate(props.lang, "Edit Quotation Item") : translate(props.lang, "Add Quotation Item")}</span>
                                    </div>
                                </Col>
                                {!selectedCustomTask ? (
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                        <div className={`groupLabel-input py-1 ${errors.project_task_id ? 'errorBorder' : ''}`}>
                                            <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "Task")} {editRow ? null : (<span className="fs-9 text-primary ms-2 text-decoration-underline cursor-pointer" onClick={() => onChangeSelectedCustomTask()}>{!selectedCustomTask ? translate(props.lang, "Create custom task") : translate(props.lang, "Choose existing task")}</span>)} </span>
                                            <div className="input-group-select px-3">
                                                <Select
                                                    options={
                                                        taskOption.map((item, i) => ({ value: item.project_task_id, label: item.name, key: i }))
                                                    }
                                                    placeholder={translate(props.lang, "Select task")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                    isSearchable={true}
                                                    value={taskOption.filter(option => option.project_task_id === tableFormData.project_task_id).length > 0 ? taskOption.filter(option => option.project_task_id === tableFormData.project_task_id).map((item, i) => ({ key: i, value: item.project_task_id, label: item.name })) : null}
                                                    name="project_task_id"
                                                    onChange={(e) => handleTableOptionSelected(e, 'project_task_id')}
                                                    isDisabled={editRow ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.project_task_id)}</span>
                                        </div>
                                    </Col>
                                ) : (
                                    <>
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                            <div className={`groupLabel-input py-1 ${errors.taskName ? 'errorBorder' : ''}`}>
                                                <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "Task Name")} {editRow ? null : (<span className="fs-9 text-primary ms-2 text-decoration-underline cursor-pointer" onClick={() => onChangeSelectedCustomTask()}>{!selectedCustomTask ? translate(props.lang, "Create custom task") : translate(props.lang, "Choose existing task")}</span>)} </span>
                                                <div className={`input-group`}>
                                                    <input
                                                        type={'text'}
                                                        name="taskName"
                                                        placeholder={translate(props.lang, "Enter task name")}
                                                        className="form-control input-transparent py-1 px-3"
                                                        value={tableFormData.taskName}
                                                        autoComplete="off"
                                                        onChange={e => handleTableFormChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.taskName)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                            <div className={`groupLabel-input py-1 ${errors.taskCode ? 'errorBorder' : ''}`}>
                                                <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "Task Code")}</span>
                                                <div className={`input-group`}>
                                                    <input
                                                        type={'text'}
                                                        name="taskCode"
                                                        placeholder={translate(props.lang, "Enter task code")}
                                                        className="form-control input-transparent py-1 px-3"
                                                        value={tableFormData.taskCode}
                                                        autoComplete="off"
                                                        readOnly
                                                    // onChange={e => handleTableFormChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.taskCode)}</span>
                                            </div>
                                        </Col>
                                    </>
                                )}
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                                    <div className={`groupLabel-input w-100 px-3 ${errors.description ? 'errorBorder' : ''}`}>
                                        <Editor
                                            key={language}
                                            apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                                            init={{
                                                language: language,
                                                language_load: true,
                                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                            }}
                                            initialValue={tableFormData.description}
                                            className="w-100"
                                            onEditorChange={(content, editor) => {
                                                handleEditorChange(content)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                                    </div>
                                </Col>
                                {/* <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                                        <div className={`input-group`}>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                className="form-control input-transparent py-1 px-3"
                                                rows={3}
                                                value={tableFormData.description}
                                                placeholder={translate(props.lang, "Enter description")}
                                                onChange={e => handleTableFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                                    </div>
                                </Col> */}
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.quantity ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quantity")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'number'}
                                                name="quantity"
                                                placeholder={translate(props.lang, "Enter quantity")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={tableFormData.quantity}
                                                autoComplete="off"
                                                onChange={e => handleTableFormChange(e)}
                                                onWheel={event => event.currentTarget.blur()}
                                                readOnly={tableFormData.project_task_id}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.quantity)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Price")} <span className="text-danger">*</span></span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'number'}
                                                name="amount"
                                                placeholder={translate(props.lang, "Enter price")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={tableFormData.amount}
                                                autoComplete="off"
                                                onChange={e => handleTableFormChange(e)}
                                                onWheel={event => event.currentTarget.blur()}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.amount)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Tax")}</span>
                                        <div className="input-group-select px-3">
                                            <Select
                                                options={
                                                    taxOption.length > 0 ? taxOption.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                }
                                                placeholder={translate(props.lang, "Select tax")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={taxOption.filter(option => option.value.toString() === tableFormData.tax.toString()).length > 0 ? taxOption.filter(option => option.value.toString() === tableFormData.tax.toString()).map((item, i) => ({ key: i, value: item.value, label: item.label })) : null}
                                                name="tax"
                                                onChange={(e) => handleTableOptionSelected(e, 'tax')}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.discount ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Discount")}</span>
                                        <div className="d-flex w-100 align-items-center">
                                            <div className={`input-group w-50`}>
                                                <input
                                                    type={'number'}
                                                    name="discount"
                                                    placeholder={translate(props.lang, "Enter discount")}
                                                    className="form-control input-transparent py-1 px-3"
                                                    value={tableFormData.discount}
                                                    autoComplete="off"
                                                    onChange={e => handleTableFormChange(e)}
                                                    onWheel={event => event.currentTarget.blur()}
                                                />
                                            </div>
                                            <div className="input-group-select w-50 px-3">
                                                <Select
                                                    options={
                                                        discountTypeOption.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i }))
                                                    }
                                                    placeholder={translate(props.lang, "Select discount type")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                    value={discountTypeOption.filter(option => option.value === tableFormData.discount_type).length > 0 ? discountTypeOption.filter(option => option.value === tableFormData.discount_type).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                                                    name="discount_type"
                                                    onChange={(e) => handleOptionSelected(e, 'discount_type')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.discount)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Total Amount")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'text'}
                                                name="finalAmount"
                                                placeholder={translate(props.lang, "Amount")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={Number(tableFormData.finalAmount).toFixed(2)}
                                                autoComplete="off"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                    <Row className="px-0 mx-0">
                                        <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                            <Button variant="primary" className="px-3 px-sm-10 w-100" onClick={() => addItem()}>
                                                {translate(props.lang, "Confirm")}
                                            </Button>


                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                            <Button variant="phoenix-primary" className="px-3 px-sm-10 w-100" onClick={() => closeModal()}>
                                                {translate(props.lang, "Cancel")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditQuotation);
