import { faEdit, faPlus, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useState } from 'react';
import { Col, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { approvalSettingType } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { deepCopyArray, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const EditClaimSetting = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    let errorObj = {
        type: null,
        organization_id: null,
        department_id: null,
        approver: null,
        worker_id: ''
    }
    const { config } = useAppContext();
    const [modalOpen, setModalOpen] = useState(false)
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [loadingApprover, setLoadingApprover] = useState(false);
    const [editRow, setEditRow] = useState('');
    const [organizationList, setOrganizationList] = useState([]);
    const [filteredDepartmentList, setFilteredDepartmentList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [formData, setFormData] = useState({
        type: '',
        organization_id: '',
        department_id: '',
        approver: [],
    })
    const [approverForm, setApproverForm] = useState({
        worker: null,
        layer: '',
    });
    let breadcrumbData = [
        { label: translate(props.lang, 'Claim Approval Setting'), url: '/claim-setting/overview' },
        { label: translate(props.lang, 'Edit Claim Approval Setting'), active: true }
    ]

    useEffect(() => {
        getOrganization();
        getDepartment();
    }, [])

    useEffect(() => {
        if (props?.auth?.permission?.allClaimSetting) {
            getInfo();
        }
    }, [id, departmentList, props.auth.permission])

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_claim_settings_by_id + '/' + id + "?details=1", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    let newFormData = { ...formData };
                    newFormData.type = responseJson.data.type;
                    newFormData.organization_id = responseJson.data.organization.organization_id;
                    newFormData.department_id = responseJson.data.department.department_id;

                    if (responseJson.data.type === 'department') {
                        let filterList = departmentList.filter(item => item.organization_id === responseJson.data.organization_id);
                        setFilteredDepartmentList(filterList);
                    }

                    let result = Object.values((responseJson.data.approver).reduce((r, { layer, worker }) => {
                        if (!r[layer]) r[layer] = { layer, worker: [] };
                        let data = {
                            value: worker.worker_id, label: worker.name + "(" + worker.employee_number + ")"
                        }
                        r[layer].worker.push(data);
                        return r;
                    }, {}));

                    setApproverList(result);
                    setFormData(newFormData);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getOrganization = () => {
        fetch(api.get_organizations, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data);
                } else if (responseJson.status === 401) {
                    dispatch(logout());
                    navigate('/')
                } else {
                    console.log('error company', responseJson.message)
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getDepartment = () => {
        fetch(api.get_departments + '?details=1&isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setDepartmentList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        if (type === 'organization_id') {
            if (!value) {
                newFormData.department_id = ''
            } else {
                let filterList = departmentList.filter(item => item.organization.organization_id === value);
                setFilteredDepartmentList(filterList);
            }
        }
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData }
        if (!formData.type) {
            errorMsg.type = "This field is required.";
            check = false;
        } else {
            if (formData.type !== 'company') {
                if (!formData.organization_id) {
                    errorMsg.organization_id = "This field is required.";
                    check = false;
                }
                if (!formData.department_id) {
                    errorMsg.department_id = "This field is required.";
                    check = false;
                }
            }
        }
        if (approverList.length === 0) {
            errorMsg.approver = "This field is required.";
            check = false;
        } else {
            let arr = [];

            approverList.map((item, i) => {
                let wArr = []
                item.worker.map((w, index) => {
                    wArr.push(w.value);
                })
                arr.push({ worker_id: wArr, layer: item.layer })
            })
            newFormData.approver = arr;
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_claim_settings + "/" + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        navigate('/claim-setting/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const editData = (i) => {
        setEditRow(i);
        let newFormData = { ...approverForm };
        newFormData.worker = approverList[i].worker;
        newFormData.layer = approverList[i].layer;
        setApproverForm(newFormData);
        setModalOpen(true);
    }

    const removeData = (index) => {
        let newList = deepCopyArray(approverList);
        newList.splice(index, 1);
        newList.map((item, i) => {
            item.layer = i + 1;
        })
        setApproverList(newList);
    }

    const addNewRow = () => {
        let newFormData = { ...approverForm };
        newFormData.layer = approverList.length + 1;
        setApproverForm(newFormData);
        setModalOpen(true)
    }

    const closeModal = () => {
        setApproverForm({ worker: null });
        setModalOpen(false)
    }

    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingApprover) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);
                const hasMore = page < totalPage;
                setLoadingApprover(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                setLoadingSalesOwner(false)
                console.error('Error fetching data:', error);
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    const handleWorkerOptionSelected = (e) => {
        let newFormData = { ...approverForm };
        newFormData.worker = e;
        setApproverForm(newFormData);
    }

    const addItem = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let data = { ...approverForm }
        if (!data.worker) {
            check = false
            errorMsg.worker_id = "This field is required.";
        }
        setErrors(errorMsg)
        if (check) {
            let newFormData = deepCopyArray(approverList);
            if (editRow && editRow.toString()) {
                newFormData[Number(editRow)] = data;
            } else {
                newFormData.push(data);
            }
            setApproverList(newFormData);
            closeModal();
            setEditRow(null)
        }
    }

    return (
        props?.auth?.permission?.editClaimSetting ? (
            <>
                <div>
                    <PageBreadcrumb items={breadcrumbData} />
                    <h2 className="mb-4">{translate(props.lang, "Edit Claim Approval Setting")}</h2>
                    <Row className='align-items-start justify-content-center'>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.type ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Type")} <span className="text-danger">*</span></span>
                                <div className="input-group-select px-3">
                                    <Select
                                        options={
                                            approvalSettingType.map((item, i) => ({ key: i, value: item.value, label: item.label }))
                                        }
                                        placeholder={translate(props.lang, "Select type")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        value={approvalSettingType.filter(option => option.value === formData.type).length > 0 ? approvalSettingType.filter(option => option.value === formData.type).map((item, i) => ({ key: i, value: item.value, label: item.label })) : null}
                                        name="type"
                                        onChange={(e) => handleOptionSelected(e, 'type')}
                                        isDisabled
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.type)}</span>
                            </div>
                        </Col>
                        {formData.type && formData.type !== 'company' ? (
                            <>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.organization_id ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                                        <div className="input-group-select px-3">
                                            <Select
                                                options={
                                                    organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                                }
                                                placeholder={translate(props.lang, "Select organization")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                isSearchable={true}
                                                value={organizationList.filter(option => option.organization_id === formData.organization_id).length > 0 ? organizationList.filter(option => option.organization_id === formData.organization_id).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                                name="organization_id"
                                                onChange={(e) => handleOptionSelected(e, 'organization_id')}
                                                isDisabled
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization_id)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.department_id ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Department")} <span className="text-danger">*</span></span>
                                        <div className={`input-group-select px-3`}>
                                            <Select
                                                options={
                                                    filteredDepartmentList.map((item, i) => ({ key: i, value: item.department_id, label: item.name }))
                                                }
                                                placeholder={translate(props.lang, "Select department")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                isSearchable={true}
                                                name="department_id"
                                                value={
                                                    formData.department_id ? (
                                                        departmentList.filter(item => item.department_id == formData.department_id).length > 0 ?
                                                            (departmentList.filter(item => item.department_id == formData.department_id).map((item, i) => ({ value: item.department_id, label: item.name, key: i })))
                                                            : null
                                                    ) : null
                                                }
                                                onChange={(e) => handleOptionSelected(e, 'department_id')}
                                                isDisabled
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.department_id)}</span>
                                    </div>
                                </Col>
                            </>
                        ) : null}
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className='table-responsive'>
                                <Table responsive className="w-100 table-list position-relative">
                                    <thead>
                                        <tr>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Layer")}</th>
                                            <th className={`fw-bold fs-8`}>{translate(props.lang, "Approver")}</th>
                                            <th className={`fw-bold fs-8`}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {approverList.length > 0 ? approverList.map((item, i) => (
                                            <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                <td className={`fw-normal fs-9 lh-base text-capitalize`}>{item.layer}</td>
                                                <td className={`fw-normal fs-9 lh-base break-word break-white-space`}>{item && item.worker.length > 0 ? item.worker.map(x => x.label).join('or ') : ''}</td>
                                                <td className={`fw-normal fs-9 lh-base`}>
                                                    <div className="d-flex">
                                                        <div className="px-2" onClick={() => editData(i.toString())}>
                                                            <FontAwesomeIcon icon={faEdit} color="#194BFB" size={'lg'} />
                                                        </div>
                                                        <div className="px-2" onClick={() => removeData(i)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} color="red" size={'lg'} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr role="row">
                                                <td colSpan={3}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col lg={12} sm={12} xs={12} md={12} className="mb-3">
                            <Button variant="primary" className='w-100 text-center h-100' onClick={() => addNewRow()}>
                                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                {translate(props.lang, "Add New Data")}
                            </Button>
                        </Col>


                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="d-flex justify-content-start gap-3">
                                <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                    <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                                </Button>
                                <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/claim-setting/overview')}>
                                    {translate(props.lang, "Cancel")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal className="fade" show={modalOpen} centered>
                    <Modal.Header className="border-0 pt-4 pb-2 px-3">
                        <div className="d-flex w-100 align-items-center px-2">
                            <div className="w-75 text-start">
                                <span className="fw-bold fs-7">{editRow ? translate(props.lang, "Edit Approver") : translate(props.lang, "Add Approver")}</span>
                            </div>
                            <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                                <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                    <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                </svg>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="border-0 px-3">
                        <div className='px-2'>
                            <Row className="justify-content-start px-0 mx-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                    <div className={`groupLabel-input py-1 ${errors.layer ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Layer")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'text'}
                                                name="layer"
                                                placeholder={translate(props.lang, "Enter Layer")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={approverForm.layer}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.layer)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                    <div className={`groupLabel-input py-1 ${errors.worker_id ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Approver")} <span className="text-danger">*</span></span>
                                        <div className={`input-group-select px-3`}>
                                            <AsyncPaginate
                                                placeholder={translate(props.lang, "Select approver")}
                                                loadingMessage={() => translate(props.lang, 'Loading Data')}
                                                debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                                loadOptions={loadOptions} // Function to load options asynchronously
                                                additional={{
                                                    page: 1, // Initial page
                                                }}
                                                isMulti
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                value={approverForm.worker}
                                                closeMenuOnSelect={false}
                                                onChange={(e) => handleWorkerOptionSelected(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.worker_id)}</span>
                                    </div>
                                    <div className=''>
                                        <span className="text-danger font-12 lh-base">*{translate(props.lang, "Either one of these approver in this layer can approve/reject request.")}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                                    <Row className="px-0 mx-0">
                                        <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                            <Button variant="primary" className="px-3 px-sm-10 w-100" onClick={() => addItem()}>
                                                {translate(props.lang, "Confirm")}
                                            </Button>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                            <Button variant="phoenix-primary" className="px-3 px-sm-10 w-100" onClick={() => closeModal()}>
                                                {translate(props.lang, "Close")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        ) : null
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditClaimSetting);
