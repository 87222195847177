import { useAppContext } from 'providers/AppProvider';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

function NotResultFound(props) {
    const { config } = useAppContext();
    return ( 
        <>
            <Row className="align-items-center justify-content-center px-0 mx-0 py-4">
                <Col lg={2} md={2} sm={6} xs={6} className='text-center'>
                    <img src={require('../../assets/img/nodata.svg').default} className="img-fluid nodata" alt="no-data" />
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className='mt-2 text-center'>
                    <span className={`fs-9 ${config.theme === 'dark' ? 'text-white' : 'text-black'}`}>
                        {translate(props.lang, "Oops! No result found...")}
                    </span>
                </Col>
            </Row>

        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NotResultFound);