import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { useNavigate } from 'react-router-dom';
import DataLoading from 'page/Components/DataLoading';
import { translate } from 'utils/translate';

echarts.use([TooltipComponent, PieChart]);

const ProjectStatusReport = (props) => {
    const navigate = useNavigate();
    const { getThemeColor, config } = useAppContext();
    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(true)
    const [color, setColor] = useState([])

    useEffect(() => {
        if (props.dashboardInfo) {
            let data = [
                { value: props.dashboardInfo.totalDraftProject, name: translate(props.lang, 'Draft'), key: 'draft', link: '/project/overview?status=draft' },
                { value: props.dashboardInfo.totalOngoingProject, name: translate(props.lang, 'On Going'), key: 'ongoing', link: '/project/overview?status=ongoing' },
                { value: props.dashboardInfo.totalCompletedProject, name: translate(props.lang, 'Completed'), key: 'completed', link: '/project/overview?status=completed' },
                { value: props.dashboardInfo.totalOnQueueProject, name: translate(props.lang, 'On Queue'), key: 'onqueue', link: '/project/overview?status=onqueue' },
                { value: props.dashboardInfo.totalMaintenanceProject, name: translate(props.lang, 'Maintenance'), key: 'maintenance', link: '/project/overview?status=maintenance' },
                { value: props.dashboardInfo.totalQCProject, name: translate(props.lang, 'QC'), key: 'qc', link: '/project/overview?status=qc' },
                { value: props.dashboardInfo.totalCancelledProject, name: translate(props.lang, 'Cancelled'), key: 'cancelled', link: '/project/overview?status=cancelled' },
                { value: props.dashboardInfo.totalPendingFeedbackProject, name: translate(props.lang, 'Pending Feedback'), key: 'pendingfeedback', link: '/project/overview?status=pendingfeedback' },
            ]
            setChartData(data)
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [props.dashboardInfo, props.lang])

    useEffect(() => {
        if (config.theme === 'dark') {
            setColor([
                '#8d8d8d',
                '#02CFFC',
                '#6FD41F',
                '#FFB800',
                '#108C76',
                '#B972FF',
                '#FF2D20',
                '#0C3F99'
            ])
        } else {
            setColor([
                '#8d8d8d',
                '#02CFFC',
                '#6FD41F',
                '#FFB800',
                '#108C76',
                '#B972FF',
                '#FF2D20',
                '#0C3F99'
            ])
        }
    }, [config.theme])

    const getDefaultOptions = (getThemeColor, isDark, chartData) => ({
        color: color,
        tooltip: {
            trigger: 'item'
        },
        responsive: true,
        maintainAspectRatio: false,

        series: [
            {
                name: translate(props.lang, 'Project'),
                type: 'pie',
                radius: ['48%', '90%'],
                startAngle: 30,
                avoidLabelOverlap: false,

                label: {
                    show: false,
                    position: 'center',
                    formatter: '{x|{d}%} \n {y|{b}}',
                    rich: {
                        x: {
                            fontSize: 31.25,
                            fontWeight: 800,
                            color: getThemeColor('tertiary-color'),
                            padding: [0, 0, 5, 15]
                        },
                        y: {
                            fontSize: 12.8,
                            color: getThemeColor('tertiary-color'),
                            fontWeight: 600
                        }
                    }
                },
                emphasis: {
                    label: {
                        show: true
                    }
                },
                labelLine: {
                    show: false
                },
                data: chartData
            }
        ],
        grid: {
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            containLabel: false
        }
    });

    const onChartClick = (params) => {
        if (params.data.link) {
            navigate(params.data.link);
        }
    };

    return (
        <Card className='h-100'>
            <Card.Header className="border-bottom-0 py-3">
                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                        <h3 className="text-body-emphasis">{translate(props.lang, "Projects Status Report")}</h3>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="to-do-list-body py-1">
                {loading ? (
                    <DataLoading theme='light' />
                ) : (
                    <Row className="align-items-center justify-content-center">
                        <Col xs={12} md={4}>
                            {chartData.map((item, i) => (
                                <div className="d-flex align-items-center mb-1" key={i} onClick={() => navigate(`/project/overview?status=${item.key}`)}>
                                    <span className={`d-inline-block bullet-item me-2`} style={{ background: color[i] }} />
                                    <p className="mb-0 fw-bold text-body lh-sm flex-1 fs-9 text-uppercase">
                                        {item.name} ({item.value})
                                    </p>
                                </div>
                            ))}
                        </Col>
                        <Col xs={12} md={8}>
                            <div className="position-relative mb-sm-4 mb-xl-0">
                                <ReactEChartsCore
                                    echarts={echarts}
                                    lazyUpdate={true}
                                    option={getDefaultOptions(getThemeColor, config.isDark, chartData)}
                                    style={{ width: '100%' }}
                                    onEvents={{
                                        click: onChartClick,
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ProjectStatusReport);

