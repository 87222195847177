import { faEllipsis, faFileExport, faPlus, faSpinner, faStopCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useEffect, useRef, useState } from 'react';
import { Badge, Col, Dropdown, Form, Offcanvas, Row, Spinner, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { api } from 'utils/api';
import { exportPageSize, priorityList, shownItem, stageList, statusList } from 'utils/config';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
import EditTask from './EditTask';
import NewTask from './NewTask';
import NewTimesheet from 'page/Timesheet/NewTimesheet';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import { useAppContext } from 'providers/AppProvider';
import Avatar from 'components/base/Avatar';
import Swal from 'sweetalert2';
import { logout } from 'store/actions/AuthActions';
import Error404 from 'pages/error/Error404';

const TaskList = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const { config } = useAppContext();
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [productPerpage, setProductPerpage] = useState(10)
    const [openPanel, setOpenPanel] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [openEditPanel, setOpenEditPanel] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [assignedToMe, setAssignedToMe] = useState(false);
    const [workerName, setWorkerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [status, setStatus] = useState('');
    const [taskType, setTaskType] = useState('');
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [stage, setStage] = useState('');
    const [priority, setPriority] = useState('');
    const [expandedRowId, setExpandedRowId] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [timesheetPanel, setTimesheetPanel] = useState(false);
    const [timerPanel, setTimerPanel] = useState(false);
    const [selectedTimer, setSelectedTimer] = useState(null);
    const [remark, setRemark] = useState('')
    const [currentRunningTask, setCurrentRunningTask] = useState(null);
    const [loadingAction, setLoadingAction] = useState('')
    const [currentRunningTaskTimer, setCurrentRunningTaskTimer] = useState({
        hours: '00',
        minutes: '00',
        seconds: '00'
    });
    const [summaryInfo, setSummaryInfo] = useState({
        total: 0,
        ongoing: 0,
        completed: 0,
        cancelled: 0,
        pendingFeedback: 0
    })
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });
    const timerRef = useRef(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Task'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]

    const changePage = (event) => {
        setLoading(true);
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (status) {
            if (status.length > 0) {
                status.map((s, i) => {
                    let tempData = statusList.filter(item => item.value === s.value);
                    if (tempData.length > 0) {
                        s.label = translate(props.lang, tempData[0].label)
                    }
                })
            }
        }
        if (stage) {
            if (stage.length > 0) {
                stage.map((s, i) => {
                    let tempData = stageList.filter(item => item.value === s.value);
                    if (tempData.length > 0) {
                        s.label = translate(props.lang, tempData[0].label)
                    }
                })
            }
        }
    }, [props.lang])

    useEffect(() => {
        getTaskType()
    }, [])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let value = queryParams.get('status');
        let tempData = statusList.filter(item => item.value === value);
        if (tempData.length === 0) {
            setStatus([]);
        } else {
            setStatus([{ label: translate(props.lang, tempData[0].label), value: tempData[0].value }]);
        }
    }, [])

    useEffect(() => {
        if (firstLoad) {
            if (props?.auth?.permission?.allProjectTask) {
                getList();
                setFirstLoad(false)
                getSummary();
            }
        }
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setLoading(true);
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, workerName, projectName, taskType, status, priority, name, stage, assignedToMe]);

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getSummary = () => {
        fetch(api.get_project_task_summary, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setSummaryInfo(responseJson.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getTaskType = () => {
        fetch(api.get_project_categories + '?details=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setTaskTypeList(responseJson.data.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getList = (page = pageNumber) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_project_tasks + "?page=" + page + "&limit=" + productPerpage;
            if (projectName) {
                url = url + `&projectName=${projectName}`
            }
            if (name) {
                url = url + `&name=${name}`
            }
            if (workerName) {
                url = url + `&workerName=${workerName}`
            }
            if (status && status.length > 0) {
                let statusString = '';
                status.map((item, i) => {
                    statusString += item.value
                    if (i !== status.length - 1) {
                        statusString += ','
                    }
                })
                url = url + `&taskStatus=${statusString}`
            }
            if (priority.toString()) {
                url = url + `&priority=${priority}`
            }
            if (stage) {
                let stageString = '';
                stage.map((item, i) => {
                    stageString += item.value
                    if (i !== stage.length - 1) {
                        stageString += ','
                    }
                })
                url = url + `&stage=${stageString}`
            }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            if (sortBy.subSort && sortBy.subOrder) {
                url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
            }
            if (assignedToMe) {
                url = url + `&me=1`
            }
            if (taskType) {
                url = url + `&categoryId=${taskType}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalTasks / productPerpage);
                        setPageCount(totalPage);
                        setTotalResultCount(responseJson.data.subs.totalTasks);
                        responseJson.data.data.map((item, i) => {
                            if (item.task_timesheet.length > 0) {
                                setCurrentRunningTask({ project_task_id: item.project_task_id, start_time: item.task_timesheet[0].start })
                            }
                            item.subs.map((subtask) => {
                                if (subtask.task_timesheet.length > 0) {
                                    setCurrentRunningTask({ project_task_id: subtask.project_task_id, start_time: subtask.task_timesheet[0].start })
                                }
                            })
                        })
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const secondsToDhms = (seconds) => {
        seconds = Number(seconds);

        var h = Math.floor(seconds / 60);
        let m = Math.round(seconds % 60);

        if (h === 0 && m === 0) {
            return "-";
        } else {
            let currentText = "";
            if (h > 0) {
                if (h.toString().length === 1) {
                    h = "0" + h;
                }
                currentText = currentText + h + ` hours `
            } else {
                currentText = currentText + '0 hour '
            }
            if (m > 0) {
                if (m.toString().length === 1) {
                    m = "0" + m;
                }
                currentText = currentText + m + ` minutes `
            } else {
                currentText = currentText + '0 minutes '
            }
            return currentText;
        }
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(item => {
            let assignee = "";
            item.project_worker.map((worker, j) => {
                assignee += worker.worker.name + `(${worker.worker.employee_number})`;
                if (j !== item.project_worker.length - 1) {
                    assignee += ","
                }
            })
            let formattedData = {
                "Project": item.project.name ? item.project.name + ` (${item?.project?.project_number})` : '',
                "Task": item.name ? item.name : '',
                "Main Task": '',
                "Task Type": item.project_category_assigned.project_category.name ? item.project_category_assigned.project_category.name : '',
                "Assignee": assignee,
                "Status": getLabel(item.progress_status, "status"),
                "Stage": getLabel(item.stage, "stage"),
                "Priority": getLabel(item.priority_level, "priority"),
                "Start Date": item.start_date,
                "Due Date": item.due_date,
                "Label": item.label,
                "Progress Duration": secondsToDhms(item.progress_duration),
                "Outcome Link": item.outcome_link,
            };
            newData.push(formattedData);
            if (item.subs.length > 0) {
                item.subs.map((subtask) => {
                    let assignee2 = "";
                    subtask.project_worker.map((worker, j) => {
                        assignee2 += worker.worker.name + `(${worker.worker.employee_number})`;
                        if (j !== subtask.project_worker.length - 1) {
                            assignee2 += ","
                        }
                    })
                    let formattedData2 = {
                        "Project": item.project.name ? item.project.name : '',
                        "Task": subtask.name ? subtask.name : '',
                        "Main Task": item.name ? item.name : '',
                        "Task Type": subtask.project_category_assigned.project_category.name ? subtask.project_category_assigned.project_category.name : '',
                        "Assignee": assignee2,
                        "Status": getLabel(subtask.progress_status, "status"),
                        "Stage": getLabel(subtask.stage, "stage"),
                        "Priority": getLabel(subtask.priority_level, "priority"),
                        "Start Date": subtask.start_date,
                        "Due Date": subtask.due_date,
                        "Label": subtask.label,
                        "Progress Duration": secondsToDhms(subtask.progress_duration),
                        "Outcome Link": subtask.outcome_link,
                    };
                    newData.push(formattedData2);
                })

            }
        });
        console.log(newData)
        return newData;
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_project_tasks + "?page=" + pageCount + "&limit=" + exportPageSize + '&details=1';
                if (projectName) {
                    url = url + `&projectName=${projectName}`
                }
                if (name) {
                    url = url + `&name=${name}`
                }
                if (workerName) {
                    url = url + `&workerName=${workerName}`
                }
                if (status && status.length > 0) {
                    let statusString = '';
                    status.map((item, i) => {
                        statusString += item.value
                        if (i !== status.length - 1) {
                            statusString += ','
                        }
                    })
                    url = url + `&taskStatus=${statusString}`
                }
                if (priority.toString()) {
                    url = url + `&priority=${priority}`
                }
                if (stage) {
                    let stageString = '';
                    stage.map((item, i) => {
                        stageString += item.value
                        if (i !== stage.length - 1) {
                            stageString += ','
                        }
                    })
                    url = url + `&stage=${stageString}`
                }
                if (sortBy.sortField && sortBy.sortOrder) {
                    url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
                }
                if (sortBy.subSort && sortBy.subOrder) {
                    url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
                }
                if (assignedToMe) {
                    url = url + `&me=1`
                }
                if (taskType) {
                    url = url + `&categoryId=${taskType}`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.totalTasks;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }
                        setTimeout(() => {
                            downloadExcel(processData, "task_overview")
                            setLoadingExcel(false)
                        }, 2000);
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    const getColor = (val, type) => {
        let color = '#fff';
        if (type === 'status') {
            let filterList = statusList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        } else if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        }

        return color;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    const toggleRow = (id) => {
        let newArr = [...expandedRowId];
        let index = newArr.indexOf(id);
        if (index > -1) {
            newArr.splice(index, 1);
        } else {
            newArr.push(id)
        }
        setExpandedRowId(newArr);
    };

    const addSubTask = (item) => {
        setSelectedTask(item);
        setOpenPanel(true);
    }

    const editItem = (id, projectId) => {
        if (props.auth.permission && props.auth.permission.editProjectTask) {
            setSelectedId(id);
            setSelectedProjectId(projectId);
            setOpenEditPanel(true);
        }
    }

    const closeEditPanel = () => {
        setOpenEditPanel(false);
        setSelectedProjectId(null);
        setSelectedId(null)
    }

    const closeTimesheetPanel = () => {
        setSelectedTask(null);
        setTimesheetPanel(false)
    }

    const addTimeLog = (item, project) => {
        let data = { task: item, project }
        setSelectedTask(data);
        setTimesheetPanel(true)
    }

    const toggleTimer = (item) => {
        let url = "";
        let message = "";
        let formData = {}
        if (item.task_timesheet.length > 0) {
            setSelectedTimer(item)
            setTimerPanel(true)
        } else {
            url = api.project_task_timer + '/' + item.project.project_id + '/' + item.project_task_id + '/start';
            message = translate(props.lang, "Are you sure you want to start this timer?")
            Swal.fire({
                title: translate(props.lang, "Confirmation"),
                text: message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate(props.lang, "Confirm"),
                cancelButtonText: translate(props.lang, "No"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(url, {
                        method: "PUT",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            "Accept-Language": props.lang.toLowerCase(),
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(formData)
                    })
                        .then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.status === 'success') {
                                getList();
                            } else {
                                if (responseJson.message === 'token expired') {
                                    dispatch(logout());
                                    navigate('/')
                                } else if (responseJson.tokenExpired) {
                                    dispatch(logout());
                                    navigate('/')
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }).catch(error => {
                            console.error("error", error);
                        });
                }
            })
        }
    }

    const toggleSubtaskTimer = (main, item) => {
        let url = "";
        let message = "";
        let formData = {}
        if (item.task_timesheet.length > 0) {
            let obj = { ...item, project: { project_id: main.project.project_id } }
            setSelectedTimer(obj)
            setTimerPanel(true)

        } else {
            url = api.project_task_timer + '/' + main.project.project_id + '/' + item.project_task_id + '/start';
            message = translate(props.lang, "Are you sure you want to start this timer?")
            Swal.fire({
                title: translate(props.lang, "Confirmation"),
                text: message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate(props.lang, "Confirm"),
                cancelButtonText: translate(props.lang, "No"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(url, {
                        method: "PUT",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            "Accept-Language": props.lang.toLowerCase(),
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(formData)
                    })
                        .then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.status === 'success') {
                                getList();
                            } else {
                                if (responseJson.message === 'token expired') {
                                    dispatch(logout());
                                    navigate('/')
                                } else if (responseJson.tokenExpired) {
                                    dispatch(logout());
                                    navigate('/')
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }).catch(error => {
                            console.error("error", error);
                        });
                }
            })
        }
    }

    useEffect(() => {
        if (currentRunningTask) {
            setTimeout(() => {
                setCurrentRunningTaskTimer(calculateTimer())
            }, 1000);
        }
    });

    const calculateTimer = () => {
        let date1 = new Date();
        let date2 = new Date(currentRunningTask.start_time);
        date1 = new Date(date1);
        date2 = new Date(date2);

        let seconds = Math.floor((date1 - (date2)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
        const difference = +date1 - +date2;
        let timeLeft = {};

        if (difference > 0) {
            if (hours.toString().length === 1) {
                hours = "0" + hours;
            }
            if (minutes.toString().length === 1) {
                minutes = "0" + minutes;
            }
            if (seconds.toString().length === 1) {
                seconds = "0" + seconds;
            }
            timeLeft = {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        return timeLeft;
    }

    const closeTimerPanel = () => {
        setTimerPanel(false);
        setRemark('');
        setSelectedTimer(null);
    }


    const submitForm = () => {
        if (!remark) {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "Please fill in remark"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        } else {
            setLoadingAction(true);
            let formData = {}
            formData.project_task_timer_id = selectedTimer.task_timesheet[0].project_task_timer_id;
            formData.remark = remark;
            fetch(api.project_task_timer + '/' + selectedTimer.project.project_id + '/' + selectedTimer.project_task_id + '/stop', {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoadingAction(false);
                    if (responseJson.status === 'success') {
                        getList();
                        setSelectedTimer(null);
                        setTimerPanel(false);
                        setRemark('');
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const updateStatus = (task, subtask, status) => {
        let newFormData = {
            projectTaskIds: subtask ? [subtask.project_task_id] : [task.project_task_id],
            value: status,
            target: 'progress_status'
        }
        fetch(api.edit_project_task_table + "/" + task.project.project_id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(newFormData)
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getList();
                    getSummary();
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: translate(props.lang, 'Error'),
                            text: responseJson.message,
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const updatePriority = (main, task, level) => {
        let newFormData = {
            projectTaskIds: [task.project_task_id],
            value: Number(level),
            target: 'priority_level'
        }
        fetch(api.edit_project_task_table + "/" + main.project.project_id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(newFormData)
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getList();
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: translate(props.lang, 'Error'),
                            text: responseJson.message,
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const updateStage = (task, subtask, stage) => {
        let newFormData = {
            projectTaskIds: subtask ? [subtask.project_task_id] : [task.project_task_id],
            value: stage,
            target: 'stage'
        }
        fetch(api.edit_project_task_table + "/" + task.project.project_id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(newFormData)
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getList();
                    getSummary();
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: translate(props.lang, 'Error'),
                            text: responseJson.message,
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const viewTaskDetails = (taskId, projectId) => {
        navigate(`/task/details/${taskId}`, { state: projectId })
    }

    const setStatusStage = (value) => {
        let tempData = statusList.filter(item => item.value === value);
        if (tempData.length === 0) {
            setStatus([]);
        } else {
            setStatus([{ label: translate(props.lang, tempData[0].label), value: tempData[0].value }]);
        }
    }

    return (
        props?.auth?.permission?.allProjectTask ?
        <>
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{translate(props.lang, "Task")}</h2>
                    <div className="mb-4">
                        <Row className="g-3 mb-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 total" onClick={() => setStatusStage('')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Total Task")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.total ? summaryInfo.total : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 ongoing" onClick={() => setStatusStage('ongoing')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "On Going Task")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.ongoing ? summaryInfo.ongoing : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 onqueue" onClick={() => setStatusStage('onqueue')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Onqueue Task")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.onqueue ? summaryInfo.onqueue : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 completed" onClick={() => setStatusStage('completed')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Completed Task")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.completed ? summaryInfo.completed : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 cancelled" onClick={() => setStatusStage('cancelled')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Cancelled Task")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.cancelled ? summaryInfo.cancelled : 0}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className="tableBgContainer py-4 px-4 pending" onClick={() => setStatusStage('pendingfeedback')}>
                                    <span className="fs-8 fw-bold">{translate(props.lang, "Pending Feedback Task")}</span>
                                    <div className="dataBg px-2 py-1 mt-2">
                                        <span className="fw-bold fs-7 d-block mt-1">{summaryInfo.pendingFeedback ? summaryInfo.pendingFeedback : 0}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="name"
                                        placeholder={translate(props.lang, "Search by task name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={name}
                                        autoComplete="off"
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </Col>

                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="projectName"
                                        placeholder={translate(props.lang, "Search by project name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={projectName}
                                        autoComplete="off"
                                        onChange={e => setProjectName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="workerName"
                                        placeholder={translate(props.lang, "Search by assignee name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={workerName}
                                        autoComplete="off"
                                        onChange={e => setWorkerName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={statusList.length > 0 ? statusList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select status")}
                                        className="input-transparent w-100 text-capitalize basic-multi-select"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={status}
                                        name="status"
                                        isMulti
                                        onChange={(e) => setStatus(e ? e : '')}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={stageList.length > 0 ? stageList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select stage")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={stage}
                                        name="stage"
                                        isMulti
                                        onChange={(e) => setStage(e ? e : '')}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={priorityList.length > 0 ? priorityList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select priority")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={priorityList.filter(option => option.value === priority).length > 0 ? priorityList.filter(option => option.value === priority).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                                        name="priority"
                                        onChange={(e) => setPriority(e ? e.value : '')}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={taskTypeList.length > 0 ? taskTypeList.map((item, i) => ({ value: item.project_category_id, label: item.name, key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select task type")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={
                                            taskTypeList.filter(item => item.project_category_id === taskType).length > 0 ?
                                                (taskTypeList.filter(item => item.project_category_id === taskType).map((item, i) => ({ value: item.project_category_id, label: item.name, key: i })))
                                                : null
                                        }
                                        name="status"
                                        onChange={(e) => setTaskType(e ? e.value : '')}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant={`${assignedToMe ? 'success' : 'secondary'}`} className="text-center w-100 h-100" onClick={() => setAssignedToMe(!assignedToMe)}>
                                    {translate(props.lang, "Assigned to Me")}
                                </Button>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="secondary" className="text-center w-100 h-100" onClick={() => exportExcel()}>
                                    {loadingExcel ? (
                                        <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                            {translate(props.lang, "Export")}
                                        </>
                                    )}
                                </Button>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="primary" className='w-100 text-center h-100' onClick={() => setOpenPanel(true)} >
                                    <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                    {translate(props.lang, "Add Task")}
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                        <div className='table-responsive'>
                            <Table responsive="sm" className='table-custom'>
                                <thead>
                                    <tr>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('name', false)}>
                                                <span className="pe-2">{translate(props.lang, "Task")}</span>
                                                {sortBy.sortField === 'name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                                <span className="pe-2">{translate(props.lang, "Time Log")}</span>
                                                {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                                <span className="pe-2">{translate(props.lang, "Project")}</span>
                                                {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                                <span className="pe-2">{translate(props.lang, "Assignee")}</span>
                                                {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                                <span className="pe-2">{translate(props.lang, "Status")}</span>
                                                {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                                <span className="pe-2">{translate(props.lang, "Stage")}</span>
                                                {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th className='py-2'>
                                            <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                                <span className="pe-2">{translate(props.lang, "Priority")}</span>
                                                {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                            </div>
                                        </th>
                                        <th>{translate(props.lang, "Start Date")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan={9}>
                                                <DataLoading theme='light' />
                                            </td>
                                        </tr>
                                    ) : (
                                        result.length > 0 ? (
                                            <>
                                                {result.map((item, i) => (
                                                    <>
                                                        <tr role="row" key={i}>
                                                            <td className='th-text fs-9 fw-normal px-2' style={{ minWidth: '200px' }}>
                                                                <div className="d-flex align-items-center">
                                                                    <div>
                                                                        <span className='text-primary fs-9 fw-bold cursor-pointer' onClick={() => editItem(item.project_task_id, item.project.project_id)}>{item.name}</span>
                                                                    </div>
                                                                    {item.subs.length > 0 ? (
                                                                        <div className="ms-2 cursor-pointer">
                                                                            <Badge bg="info" variant="phoenix" onClick={() => toggleRow(i)}>
                                                                                {item.subs.length}
                                                                            </Badge>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </td>
                                                            <td className='th-text fs-9 fw-normal'>
                                                                {item.project_worker.length > 0 ? (
                                                                    item.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                        <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => toggleTimer(item)}>
                                                                            {item.task_timesheet.length > 0 ? (
                                                                                <>
                                                                                    <FontAwesomeIcon icon={faStopCircle} color="red" className="fs-9 timer-icon me-1" />
                                                                                    <span className='fs-9 fw-normal'>{currentRunningTaskTimer.hours}:</span>
                                                                                    <span className='fs-9 fw-normal'>{currentRunningTaskTimer.minutes}:</span>
                                                                                    <span className='fs-9 fw-normal'>{currentRunningTaskTimer.seconds} </span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={require('../../assets/img/timer.svg').default} className='timer-icon me-2' alt="timer" />
                                                                                    <span className='fs-9 fw-normal'>{translate(props.lang, "Start")}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : null
                                                                ) : null}
                                                            </td>
                                                            <td className='th-text fs-9 fw-normal text-capitalize'>{item.project.name}</td>
                                                            <td className='th-text fs-9 fw-normal'>
                                                                <div className="avatar-group avatar-group-dense text-center">
                                                                    {item.project_worker.length > 0 ? (
                                                                        <div className="d-flex flex-column gap-4 me-2">
                                                                            <Avatar.Group>
                                                                                {item.project_worker.slice(0, 3).map((person, j) => (
                                                                                    <div className="position-relative assignee text-center hoverMe d-flex align-items-center">
                                                                                        <Avatar size="m" variant="name">
                                                                                            <span>{person.worker.name.substring(0, 1)}</span>
                                                                                        </Avatar>
                                                                                        <div className="tooltip-name">{person.worker.name}</div>
                                                                                    </div>
                                                                                ))}
                                                                                {item.project_worker.length > 3 ? (
                                                                                    <div className="position-relative assignee extra hoverMe d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                                                        <Avatar size="m" variant="name">
                                                                                            <span>+{item.project_worker.length - 3}</span>
                                                                                        </Avatar>
                                                                                        <div className="tooltip-name">
                                                                                            {item.project_worker.slice(3).map((person, j) => (
                                                                                                <span className="d-block mb-1" key={j}>{person.worker.name}</span>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                                <div className="position-relative assignee empty text-centerd-flex align-items-center" onClick={() => editItem(item.project_task_id, item.project.project_id)}>
                                                                                    <Avatar size="m" variant="name">
                                                                                        <FontAwesomeIcon icon={faUserPlus} size='sm' />
                                                                                    </Avatar>
                                                                                </div>
                                                                            </Avatar.Group>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="position-relative assignee empty text-centerd-flex align-items-center" onClick={() => editItem(item.project_task_id, item.project.project_id)}>
                                                                            <Avatar size="m" variant="name">
                                                                                <FontAwesomeIcon icon={faUserPlus} size='sm' />
                                                                            </Avatar>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="th-text fs-9 fw-normal">
                                                                {props?.auth?.permission?.editProjectTask ? (
                                                                    <Dropdown className="btn-group w-100">
                                                                        <Dropdown.Toggle
                                                                            className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                            style={{ background: getColor(item.progress_status, 'status'), color: '#fff' }}
                                                                            data-toggle="dropdown"
                                                                        >
                                                                            {translate(props.lang, getLabel(item.progress_status, "status"))}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            {statusList.map((status, x) => (
                                                                                <Dropdown.Item key={x} as={status.value} onClick={() => updateStatus(item, null, status.value)}><span className='cursor-pointer fw-bold' style={{ color: status.color }}>{translate(props.lang, status.label)}</span></Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                ) : (
                                                                    <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.progress_status, 'status'), color: '#fff' }}>
                                                                        <span className='fs-9 fw-normal'>{translate(props.lang, getLabel(item.progress_status, 'status'))}</span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="th-text fs-9 fw-normal">
                                                                {props?.auth?.permission?.editProjectTask ? (
                                                                    <Dropdown className="btn-group w-100">
                                                                        <Dropdown.Toggle
                                                                            className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                            style={{ background: getColor(item.stage, 'stage'), color: '#fff' }}
                                                                            data-toggle="dropdown"
                                                                        >
                                                                            {translate(props.lang, getLabel(item.stage, "stage"))}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            {stageList.map((stage, x) => (
                                                                                <Dropdown.Item key={x} as={stage.value} onClick={() => updateStage(item, null, stage.value)}><span className='cursor-pointer' style={{ color: stage.color }}>{translate(props.lang, stage.label)}</span></Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                ) : (
                                                                    <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.stage, 'stage'), color: '#fff' }}>
                                                                        <span className='fs-9 fw-normal'>{translate(props.lang, getLabel(item.stage, 'stage'))}</span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className='th-text fs-9 fw-normal'>
                                                                {props?.auth?.permission?.editProjectTask ? (
                                                                    <Dropdown className="btn-group">
                                                                        <Dropdown.Toggle
                                                                            className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                            style={{ background: getColor(item.priority_level, 'priority'), color: '#fff' }}
                                                                            data-toggle="dropdown"
                                                                        >
                                                                            {translate(props.lang, getLabel(item.priority_level, "priority"))}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            {priorityList.map((p, x) => (
                                                                                <Dropdown.Item key={x} onClick={() => updatePriority(item, item, p.value.toString())}><span className='cursor-pointer' style={{ color: p.color }}>{translate(props.lang, p.label)}</span></Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                ) : (
                                                                    <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.priority_level, 'priority'), color: '#fff' }}>
                                                                        <span>{translate(props.lang, getLabel(item.priority_level, 'priority'))}</span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className='th-text fs-9 fw-normal text-capitalize'>{item.start_date}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        variant="phoenix-secondary"
                                                                        className="dropdown-caret-none btn-icon"
                                                                    >
                                                                        <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu className="py-0">
                                                                        {props?.auth?.permission?.createProjectTask ? (
                                                                            <Dropdown.Item onClick={() => addSubTask(item)}>{translate(props.lang, "New Sub Task")}</Dropdown.Item>
                                                                        ) : null}
                                                                        {item.project_worker.length > 0 ? (
                                                                            item.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                                <Dropdown.Item onClick={() => addTimeLog(item, item.project)}>{translate(props.lang, "Add Time Log")}</Dropdown.Item>

                                                                            ) : null
                                                                        ) : null}
                                                                        <Dropdown.Item onClick={() => viewTaskDetails(item.project_task_id, item.project.project_id)}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                            </td>
                                                        </tr>
                                                        {item.subs.length > 0 ? (
                                                            <>
                                                                <tr className={`px-0`}>
                                                                    <td colSpan={9} className={`p-3 subTask-section ${expandedRowId.includes(i) ? '' : 'd-none'}`}>
                                                                        <Table responsive="sm" className='table-custom mb-0'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className={`th-text px-0`}>{translate(props.lang, "Sub Task")}</th>
                                                                                    <th className={`th-text`}>{translate(props.lang, "Time Log")}</th>
                                                                                    <th className={`th-text`} style={{ minWidth: '100px' }}>{translate(props.lang, "Assignee")}</th>
                                                                                    <th className={`th-text`}>{translate(props.lang, "Status")}</th>
                                                                                    <th className={`th-text`}>{translate(props.lang, "Stage")}</th>
                                                                                    <th className={`th-text`}>{translate(props.lang, "Priority")}</th>
                                                                                    <th className={`th-text`}>{translate(props.lang, "Start Date")}</th>
                                                                                    <th className={`th-text`}></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {item.subs.map((subtask, j) => (
                                                                                    <tr key={j}>
                                                                                        <td className='th-text fs-9 fw-normal'><span className='text-primary cursor-pointer' onClick={() => editItem(subtask.project_task_id, item.project.project_id)}>{subtask.name}</span></td>
                                                                                        <td className='th-text fs-9 fw-normal'>
                                                                                            {subtask.project_worker.length > 0 ? (
                                                                                                subtask.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                                                    <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => toggleSubtaskTimer(item, subtask)}>
                                                                                                        {subtask.task_timesheet.length > 0 ? (
                                                                                                            <>
                                                                                                                <FontAwesomeIcon icon={faStopCircle} color="red" className="fs-9 timer-icon me-1" />
                                                                                                                <span className='fs-9 fw-normal'>{currentRunningTaskTimer.hours}:</span>
                                                                                                                <span className='fs-9 fw-normal'>{currentRunningTaskTimer.minutes}:</span>
                                                                                                                <span className='fs-9 fw-normal'>{currentRunningTaskTimer.seconds} </span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <img src={require('../../assets/img/timer.svg').default} className='timer-icon me-2' alt="timer" />
                                                                                                                <span className='fs-9 fw-normal'>{translate(props.lang, "Start")}</span>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                ) : null
                                                                                            ) : null}
                                                                                        </td>
                                                                                        <td className='th-text fs-9 fw-normal'>
                                                                                            <div className="avatar-group avatar-group-dense text-center">
                                                                                                {item.project_worker.length > 0 ? (
                                                                                                    <div className="d-flex flex-column gap-4 me-2">
                                                                                                        <Avatar.Group>
                                                                                                            {subtask.project_worker.slice(0, 3).map((person, j) => (
                                                                                                                <div className="position-relative assignee text-center hoverMe d-flex align-items-center">
                                                                                                                    <Avatar size="m" variant="name">
                                                                                                                        <span>{person.worker.name.substring(0, 1)}</span>
                                                                                                                    </Avatar>
                                                                                                                    <div className="tooltip-name">{person.worker.name}</div>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                            {subtask.project_worker.length > 3 ? (
                                                                                                                <div className="position-relative assignee extra hoverMe d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                                                                                    <Avatar size="m" variant="name">
                                                                                                                        <span>+{subtask.project_worker.length - 3}</span>
                                                                                                                    </Avatar>
                                                                                                                    <div className="tooltip-name">
                                                                                                                        {subtask.project_worker.slice(3).map((person, j) => (
                                                                                                                            <span className="d-block mb-1" key={j}>{person.worker.name}</span>
                                                                                                                        ))}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            <div className="position-relative assignee empty text-centerd-flex align-items-center" onClick={() => editItem(subtask.project_task_id, item.project.project_id)}>
                                                                                                                <Avatar size="m" variant="name">
                                                                                                                    <FontAwesomeIcon icon={faUserPlus} size='sm' />
                                                                                                                </Avatar>
                                                                                                            </div>
                                                                                                        </Avatar.Group>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="position-relative assignee empty text-centerd-flex align-items-center" onClick={() => editItem(subtask.project_task_id, item.project.project_id)}>
                                                                                                        <Avatar size="m" variant="name">
                                                                                                            <FontAwesomeIcon icon={faUserPlus} size='sm' />
                                                                                                        </Avatar>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="th-text fs-9 fw-normal">
                                                                                            {props?.auth?.permission?.editProjectTask ? (
                                                                                                <Dropdown className="btn-group w-100">
                                                                                                    <Dropdown.Toggle
                                                                                                        className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                                                        style={{ background: getColor(subtask.progress_status, 'status'), color: '#fff' }}
                                                                                                        data-toggle="dropdown"
                                                                                                    >
                                                                                                        {translate(props.lang, getLabel(subtask.progress_status, "status"))}
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu>
                                                                                                        {statusList.map((status, x) => (
                                                                                                            <Dropdown.Item key={x} onClick={() => updateStatus(item, subtask, status.value)}><span className='cursor-pointer fw-bold' style={{ color: status.color }}>{translate(props.lang, status.label)}</span></Dropdown.Item>
                                                                                                        ))}
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            ) : (
                                                                                                <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.progress_status, 'status'), color: '#fff' }}>
                                                                                                    <span>{translate(props.lang, getLabel(subtask.progress_status, 'status'))}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="th-text fs-9 fw-normal">
                                                                                            {props?.auth?.permission?.editProjectTask ? (
                                                                                                <Dropdown className="btn-group w-100">
                                                                                                    <Dropdown.Toggle
                                                                                                        className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                                                        style={{ background: getColor(subtask.stage, 'stage'), color: '#fff' }}
                                                                                                        data-toggle="dropdown"
                                                                                                    >
                                                                                                        {translate(props.lang, getLabel(subtask.stage, "stage"))}
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu>
                                                                                                        {stageList.map((stage, x) => (
                                                                                                            <Dropdown.Item key={x} onClick={() => updateStage(item, subtask, stage.value)}><span className='cursor-pointer' style={{ color: stage.color }}>{translate(props.lang, stage.label)}</span></Dropdown.Item>
                                                                                                        ))}
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            ) : (
                                                                                                <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.stage, 'stage'), color: '#fff' }}>
                                                                                                    <span>{translate(props.lang, getLabel(subtask.stage, 'stage'))}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td className='th-text fs-9 fw-normal'>
                                                                                            {props?.auth?.permission?.editProjectTask ? (
                                                                                                <Dropdown className="btn-group">
                                                                                                    <Dropdown.Toggle
                                                                                                        className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                                                                                        style={{ background: getColor(subtask.priority_level, 'priority'), color: '#fff' }}
                                                                                                        data-toggle="dropdown"
                                                                                                    >
                                                                                                        {translate(props.lang, getLabel(subtask.priority_level, 'priority'))}
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu>
                                                                                                        {priorityList.map((p, x) => (
                                                                                                            <Dropdown.Item key={x} onClick={() => updatePriority(item, subtask, p.value.toString())}><span className='cursor-pointer' style={{ color: p.color }}>{translate(props.lang, p.label)}</span></Dropdown.Item>
                                                                                                        ))}
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            ) : (
                                                                                                <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(subtask.priority_level, 'priority'), color: '#fff' }}>
                                                                                                    <span>{translate(props.lang, getLabel(subtask.priority_level, 'priority'))}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="th-text fs-9 fw-normal">{subtask.start_date}</td>
                                                                                        <td>
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle
                                                                                                    variant="phoenix-secondary"
                                                                                                    className="dropdown-caret-none btn-icon"
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                                                </Dropdown.Toggle>

                                                                                                <Dropdown.Menu className="py-0">
                                                                                                    {subtask.project_worker.length > 0 ? (
                                                                                                        subtask.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                                                            <Dropdown.Item onClick={() => addTimeLog(subtask, item.project)}>{translate(props.lang, "Add Time Log")}</Dropdown.Item>

                                                                                                        ) : null
                                                                                                    ) : null}
                                                                                                    <Dropdown.Item onClick={() => viewTaskDetails(subtask.project_task_id, item.project.project_id)}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </td>

                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </Table>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ) : null}
                                                    </>
                                                ))}
                                            </>
                                        ) : (
                                            <tr role="row">
                                                <td colSpan={8}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )
                                    )}

                                </tbody>
                            </Table>
                        </div>
                        <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                                <div className='d-flex w-100 align-items-center justify-content-end'>
                                    <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                    <div className="input-group-select-paginate ms-2 px-2">
                                        <Select
                                            options={
                                                shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                            }
                                            className="input-transparent w-100 text-capitalize px-0"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                            name="productPerpage"
                                            isSearchable={false}
                                            onChange={(e) => setProductPerpage(e ? e.value : '')}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-center my-3'>
                            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                {!loading && result.length > 0 ? (
                                    <div className="my-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            forcePage={pageNumber - 1}
                                            onPageChange={changePage}
                                            containerClassName={"pagination fw-normal fs-9 text-uppercase text-white"}
                                            previousLinkClassName={"pagination__previouslink text-white"}
                                            nextLinkClassName={"pagination__nextlink text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-white"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Offcanvas className={`w-50`} show={openPanel} onHide={() => setOpenPanel(false)} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <NewTask closePanel={() => { setOpenPanel(false); }} getList={getList} selectedTask={selectedTask} resetSelectedTask={() => setSelectedTask(null)} />
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas className={`w-50`} show={openEditPanel} onHide={() => closeEditPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <EditTask closePanel={() => closeEditPanel()} getList={getList} id={selectedId} projectId={selectedProjectId} />
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas className={`w-50`} show={timesheetPanel} onHide={() => closeTimesheetPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <NewTimesheet closePanel={() => closeTimesheetPanel()} selectedTask={selectedTask} />
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas className={`w-50`} show={timerPanel} onHide={() => closeTimerPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h2 className="mb-4">{translate(props.lang, "Stop Timer")}</h2>
                    <Row className="justify-content-start">
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                            <div className={`groupLabel-input py-1`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <Form.Control
                                        as="textarea"
                                        name="remark"
                                        className="form-control input-transparent py-1 px-3"
                                        rows={3}
                                        value={remark}
                                        placeholder={translate(props.lang, "Enter description")}
                                        onChange={e => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loadingAction}>
                                <span className="fw-bold fs-9 text-white">{loadingAction ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
        : <Error404 />
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TaskList);
