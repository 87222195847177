import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api, attachmentUrl } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import { dateButtonTextMap, regexDecimalPoint } from 'utils/config';
import { deepCopyArray, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import NoResultFound from 'page/Components/NoResultFound';
import Swal from 'sweetalert2';
import { logout } from 'store/actions/AuthActions';
import Error404 from 'pages/error/Error404';

const localeMap = {
    en: enLocale,
    zh: zhCNLocale,
};

const data = [
    { name: 'task 1', quantity: 1, price: 10, tax: 1, discount: 0, amount: 100 },
]

const EditClaim = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    let errorObj = {
        claim_start_period: '',
        claim_end_period: '',
        bank_name: '',
        bank_account: '',
        items: '',
        attachments: '',
        claim_status: '',
        reason: '',

        claim_purchase_date: '',
        description: '',
        quantity: '',
        category: '',
        amount: '',
    }
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [total, setTotal] = useState('')
    const [editRow, setEditRow] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [itemForm, setItemForm] = useState({
        claim_purchase_date: '',
        description: '',
        quantity: '',
        category: '',
        amount: '',
    })
    const [formData, setFormData] = useState({
        claim_start_period: '',
        claim_end_period: '',
        bank_name: '',
        bank_account: '',
        claim_status: '',
        items: [],
        attachments: [],
    })
    const currentDate = new Date();
    const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const { getRootProps: getAttachmentRootProps, getInputProps: getAttachmentInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(uploadedFiles.concat(acceptedFiles));
            // Call your backend API endpoint to upload files
        },
        multiple: true
    })

    let breadcrumbData = [
        { label: translate(props.lang, 'Claim'), url: '/claim/overview' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
        if(props?.auth?.permission?.allClaim) {
            getInfo();
        }
    }, [id, props.auth.permission])

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_claim_by_id + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    let newFormData = { ...formData };
                    newFormData.bank_name = responseJson.data.bank_name;
                    newFormData.bank_account = responseJson.data.bank_account;
                    newFormData.claim_start_period = responseJson.data.claim_start_period;
                    newFormData.claim_end_period = responseJson.data.claim_end_period;
                    newFormData.items = responseJson.data.item;
                    newFormData.claim_status = responseJson.data.claim_status;
                    newFormData.reason = responseJson.data.approval_reject_message;
                    newFormData.attachments = responseJson.data.attachment;
                    setUploadedFiles(responseJson.data.attachment);
                    setFormData(newFormData);

                    if (responseJson.data.item && responseJson.data.item.length > 0) {
                        let newFormData2 = { ...itemForm };
                        newFormData2.claim_purchase_date = responseJson.data?.item?.claim_purchase_date;
                        newFormData2.description = responseJson.data?.item?.description;
                        newFormData2.quantity = responseJson.data?.item?.quantity;
                        newFormData2.category = responseJson.data?.item?.category;
                        newFormData2.amount = responseJson.data?.item?.amount;
                        setItemForm(newFormData2);

                        let total = 0;
                        (responseJson.data?.item).forEach(item => {
                            total += Number(item.amount);
                        })
                        setTotal(total);
                    }
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }


    const removePreviewFile = (index) => {
        let newUploadedAttachment = [...uploadedFiles];
        newUploadedAttachment.splice(index, 1);
        setUploadedFiles(newUploadedAttachment);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleItemAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...itemForm };
        if (fieldName === 'amount') {
            if (regexDecimalPoint.test(fieldValue)) {
                newFormData[fieldName] = fieldValue;
            }
        } else {
            newFormData[fieldName] = fieldValue;
        }

        setItemForm(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.bank_name) {
            errorMsg.bank_name = "This field is required.";
            check = false;
        }
        if (!formData.bank_account) {
            errorMsg.bank_account = "This field is required.";
            check = false;
        }
        if (!formData.claim_start_period) {
            errorMsg.claim_start_period = "This field is required.";
            check = false;
        }
        if (!formData.claim_end_period) {
            errorMsg.claim_end_period = "This field is required.";
            check = false;
        }
        if (formData.claim_start_period && formData.claim_end_period) {
            if (formData.claim_end_period < formData.claim_start_period) {
                errorMsg.claim_end_period = "Period end date cannot smaller than period start date";
                check = false;
            }
        }
        if (uploadedFiles.length === 0) {
            errorMsg.attachments = "This field is required";
            check = false;
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true)
            let uploadPromises = [];
            let popUpMsg = [];
            let fileArr = formData.attachments;

            if (uploadedFiles.length > 0) {
                uploadedFiles.map((item) => {
                    let uploadPromise = new Promise((resolve, reject) => {

                        let form = new FormData();
                        form.append("image", item, encodeURIComponent(item.name));
                        fetch(api.upload_image + '/claim', {
                            method: 'POST',
                            headers: {
                                "Accept-Language": props.lang.toLowerCase(),
                                "Authorization": "Bearer " + props.auth.auth.token
                            },
                            body: form,
                        }).then(response => response.json())
                            .then(responseJson => {
                                if (responseJson.status === 'success') {
                                    resolve('done')
                                    fileArr.push(responseJson.data[0])
                                } else {
                                    reject('reject');
                                    popUpMsg.push(item + ' upload fail.');
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    })
                    uploadPromises.push(uploadPromise);
                })
            }

            Promise.all(uploadPromises).then(() => {
                newFormData.attachments = fileArr;
                proceedSubmitServer(newFormData)
            }, (error) => {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    title: translate(props.lang, 'Error'),
                    text: popUpMsg.join('\n'),
                    background: getSweelAlertBg(), // Dark background color
                    color: getSweelAlertTextColor(), // Text color
                });
            })
        }
    }

    const proceedSubmitServer = (data) => {
        setLoading(true);
        fetch(api.add_claim, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Claim created successful"),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    resetForm();
                    navigate('/claim/overview')
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const editData = (i) => {
        setEditRow(i);
        let newFormData = { ...formData.items[i] }
        setItemForm(newFormData);
        setModalOpen(true);
    }

    const removeData = (index) => {
        let newFormData = { ...formData }
        let newList = deepCopyArray(newFormData.items);
        newList.splice(index, 1);
        newList.map((item, i) => {
            item.layer = i + 1;
        })
        newFormData.items = newList;
        setFormData(newFormData);
    }

    const addNewRow = () => {
        setModalOpen(true)
    }

    const resetForm = () => {
        setFormData({
            claim_start_period: '',
            claim_end_period: '',
            bank_name: '',
            bank_account: '',
            items: [],
            attachments: [],
        })

        setItemForm({
            claim_purchase_date: '',
            description: '',
            quantity: '',
            category: '',
            amount: '',
        })
    }

    const closeModal = () => {
        setModalOpen(false)
        setItemForm({
            claim_purchase_date: '',
            description: '',
            quantity: '',
            category: '',
            amount: '',
        })
    }

    const addItem = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let data = { ...itemForm }
        Object.keys(itemForm).forEach(item => {
            if (!itemForm[item]) {
                check = false;
                errorMsg[item] = "This field is required.";
            }
        });
        setErrors(errorMsg)
        if (check) {
            let newFormData = { ...formData };
            let newList = deepCopyArray(formData.items);
            if (editRow && editRow.toString()) {
                newList[Number(editRow)] = data;
            } else {
                newList.push(data);
            }
            newFormData.items = newList;
            setFormData(newFormData);

            let total = 0;
            newList.forEach(item => {
                total += Number(item.amount);
            })
            setTotal(total)

            closeModal();
            setEditRow(null)
        }
    }

    const onChangeCalendar = (e, type) => {
        if (type === 'claim_start_period' || type === 'claim_end_period') {
            let newFormData = { ...formData };
            newFormData[type] = e ? moment(e).format('YYYY-MM-DD') : '';
            setFormData(newFormData);

        } else {
            let newFormData = { ...itemForm };
            newFormData[type] = e ? moment(e).format('YYYY-MM-DD') : '';
            setItemForm(newFormData);
        }
    }

    return (
        props?.auth?.permission?.allClaim ? (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Claim Details")}</h2>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 text-white`} style={{ background: formData.claim_status === "pending" ? "orange" : formData.claim_status === "rejected" ? "red" : "green" }}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Status")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="claim_status"
                                    placeholder={translate(props.lang, "Status")}
                                    className="form-control input-transparent py-1 px-3 text-capitalize text-white"
                                    value={translate(props.lang, formData.claim_status)}
                                    autoComplete="off"
                                    readOnly
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.bank_name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="bank_name"
                                    placeholder={translate(props.lang, "Enter Bank Name")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.bank_name}
                                    autoComplete="off"
                                    readOnly
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.bank_name)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.bank_account ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account No.")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="bank_account"
                                    placeholder={translate(props.lang, "Enter Bank Account No.")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.bank_account}
                                    autoComplete="off"
                                    readOnly
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.bank_account)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.claim_start_period ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Period Start From")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="claim_start_period"
                                    placeholder={translate(props.lang, "Select Start Date")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.claim_start_period ? formData.claim_start_period : null}
                                    autoComplete="off"
                                    readOnly
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.claim_start_period)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.claim_end_period ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Period End From")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="claim_end_period"
                                    placeholder={translate(props.lang, "Select Start Date")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.claim_end_period ? formData.claim_end_period : null}
                                    autoComplete="off"
                                    readOnly
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.claim_end_period)}</span>
                        </div>
                    </Col>
                    {
                        formData.claim_status === "rejected" ?
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`py-1`}>
                                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Reason")} <span className="text-danger">*</span></span>
                                    <div className={`groupLabel-textarea mt-2 mb-1`}>
                                        <textarea
                                            rows="7"
                                            placeholder={translate(props.lang, "Enter remark")}
                                            className="form-control input-transparent py-1 px-3"
                                            name="reason"
                                            value={formData.reason}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </Col> : null
                    }
                </Row>
            </div>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className='table-responsive'>
                            <Table responsive className="w-100 table-list position-relative">
                                <thead>
                                    <tr>
                                        {/* <th className={`fw-bold fs-8`}>{translate(props.lang, "No.")}</th> */}
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Date")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Description")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Quantity")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Category")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Cost")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData.items.length > 0 ? (
                                            <>
                                                {formData.items.map((item, i) => (
                                                    <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                        {/* <td className={`fw-normal fs-9 lh-base text-capitalize`}>{i + 1}.</td> */}
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.claim_purchase_date}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.description}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.quantity}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.category}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.amount}</td>
                                                    </tr>
                                                ))}
                                                <tr role="row">
                                                    <td colSpan={4} className='text-end'>
                                                        <span className='fs-9 fw-bold'>{translate(props.lang, "Total")}</span>
                                                    </td>
                                                    <td colSpan={2} className={`text-start`}>
                                                        <span className='fw-normal fs-9'>{total}</span>
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr role="row">
                                                <td colSpan={6}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.attachments ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attachment")} <span className="text-danger">*</span></span>
                            <div className="px-3">
                                {uploadedFiles && uploadedFiles?.length > 0 ?
                                    uploadedFiles.map((file, index) => (
                                        <div key={index} className={`${index !== uploadedFiles.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                                            <a href={attachmentUrl + file.path} target='_blank'>
                                                <span className="fs-9 font-weight-400">{index + 1}. {attachmentUrl + file.path}</span>
                                            </a>
                                        </div>
                                    )) :
                                    <NoResultFound />
                                }
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.attachments)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditClaim);
