

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { tooltipFormatterDefault } from 'helpers/echart-utils';
import { CallbackDataParams } from 'echarts/types/dist/shared';

import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import React, { useEffect, useMemo, useState } from 'react';
import { statusList } from 'utils/config';
echarts.use([TooltipComponent, PieChart]);

interface ChildComponentProps {
    data: Array<{ value: number; name: string }>;
    title: string;
}

const getDefaultOptions = (getThemeColor: (name: string) => string, data: Array<{ value: number; name: string }>, title: string) => ({
    color: [
        '#8d8d8d',
        '#02CFFC',
        '#6FD41F',
        '#FFB800',
        '#108C76',
        '#B972FF',
        '#FF2D20',
        '#0C3F99',
    ],
    tooltip: {
        trigger: 'item'
    },
    responsive: true,
    maintainAspectRatio: false,

    series: [
        {
            name: title,
            type: 'pie',
            radius: ['50%', '90%'],
            startAngle: 30,
            avoidLabelOverlap: false,

            label: {
                show: false,
                position: 'center',
                formatter: '{x|{d}%}\n{y|{b}}',
                rich: {
                    x: {
                        fontSize: 31.25,
                        fontWeight: 800,
                        color: getThemeColor('tertiary-color'),
                        padding: [0, 0, 5, 15]
                    },
                    y: {
                        fontSize: 12.8,
                        color: getThemeColor('tertiary-color'),
                        fontWeight: 600
                    }
                }
            },
            emphasis: {
                label: {
                    show: true
                }
            },
            labelLine: {
                show: false
            },
            data: data,
        }
    ],
    grid: {
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        containLabel: false
    }
});

const TasksPieChart: React.FC<ChildComponentProps> = ({ data, title }) => {
    const { getThemeColor } = useAppContext();

    const chartOptions = useMemo(() => getDefaultOptions(getThemeColor, data, title), [getThemeColor, data]);

    // useEffect(() => {
    //     getDefaultOptions(getThemeColor, data);
    // }, [data, getThemeColor]);

    return (
        <ReactEChartsCore
            echarts={echarts}
            option={chartOptions}
            style={{ minHeight: '320px', width: '100%' }}
        />
    );
};

export default TasksPieChart;

