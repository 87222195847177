import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import Error404 from 'pages/error/Error404';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import { api } from 'utils/api';
import { translate } from 'utils/translate';

const ViewFeedback = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    })
    const { id } = useParams();

    let breadcrumbData = [
        { label: translate(props.lang, 'Feedback'), url: '/feedback/overview' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_feedback_by_id + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    if(responseJson.data){
                        setFormData(responseJson.data);
                    }
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/');
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/');
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    useEffect(() => {
        if(props?.auth?.permission?.viewFeedbackList){
            if (id) {
                getInfo();
            } else {
                setFormData({
                    title: '',
                    description: ''
                })
            }
        }
    }, [id, props.auth.permission]);

    return (
        props?.auth?.permission?.viewFeedbackList ? (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Feedback Details")}</h2>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Title")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="title"
                                placeholder={translate(props.lang, "Title")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.title}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 `}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                        <div className={`input-group`}>
                            <Form.Control
                                as="textarea"
                                name="description"
                                className="form-control input-transparent py-1 px-3"
                                rows={3}
                                value={formData.description}
                                placeholder={translate(props.lang, "Description")}
                                readOnly
                            />
                        </div>
                    </div>
                </Col>

                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={()=>navigate('/feedback/overview')}>
                        {translate(props.lang, "Back")}
                    </Button>
                </Col>
            </Row>
        </div>
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ViewFeedback);
