import { faEllipsis, faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from "antd";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import dayjs from "dayjs";
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Offcanvas, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
import EditTimesheet from './EditTimesheet';
const { RangePicker } = DatePicker;

const TimesheetList = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const endDateRef = useRef(null);
    const { config } = useAppContext();
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [openEditPanel, setOpenEditPanel] = useState(false);
    const [openViewPanel, setOpenViewPanel] = useState(false);
    const [selectedTimesheet, setSelectedTimesheet] = useState(null);
    const [productPerpage, setProductPerpage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [assigneeList, setAssigneeList] = useState(null);
    const [loadingAssignee, setLoadingAssignee] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const timerRef = useRef(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Timesheet'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1);
    }

    const handleTimeChange = (e) => {
        const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
        const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
        setStartDate(formattedFromDate);
        setEndDate(formattedToDate);
    }

    // useEffect(() => {
    //     const today = new Date();
    //     const firstDayOfWeek = new Date(today);
    //     const lastDayOfWeek = new Date(today);

    //     // Adjusting to the nearest Monday (beginning of the current week)
    //     // Note: In JavaScript, getDay() returns 0 for Sunday, 1 for Monday, etc.
    //     // To adjust to Monday, we subtract today's day number by 1,
    //     // or use 6 (to go back to the previous Monday) if today is Sunday.
    //     firstDayOfWeek.setDate(today.getDate() - (today.getDay() ? today.getDay() - 1 : 6));

    //     // Adjusting to the nearest Sunday (end of the current week)
    //     // We add the difference needed to reach the next Sunday.
    //     // If today is Sunday, we don't need to add any days.
    //     lastDayOfWeek.setDate(today.getDate() + (today.getDay() ? 7 - today.getDay() : 0));

    //     // Formatting the dates as YYYY-MM-DD
    //     const mondayDate = firstDayOfWeek.toISOString().split('T')[0];
    //     const sundayDate = lastDayOfWeek.toISOString().split('T')[0];

    //     setStartDate(mondayDate);
    //     setEndDate(sundayDate);
    // }, [])

    useEffect(() => {
        if (firstLoad) {
            if (props?.auth?.permission?.allTimesheet) {
                getList();
                setFirstLoad(false)
            }
        }
    }, [props.auth.permission])

    useEffect(() => {
        if (!firstLoad && !openEditPanel) {
            setPageNumber(1)
            getList(1);
        }
    }, [props.auth.auth.token, productPerpage, props.lang, startDate, endDate, assigneeList, openEditPanel]);

    const closeEditPanel = () => {
        setOpenEditPanel(false);
        setSelectedTimesheet(null)
    }

    const closeViewPanel = () => {
        setOpenViewPanel(false);
        setSelectedTimesheet(null)
    }

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_my_timesheet + "?page=" + page + "&limit=" + productPerpage + '&details=1';
            if (startDate && endDate) {
                url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
            }
            if (assigneeList) {
                url = url + `&worker_id=${assigneeList.value}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalTimers / productPerpage);
                        setPageCount(totalPage);
                        setTotalResultCount(responseJson.data.subs.totalTimers)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const secondsToDhms = (seconds) => {
        seconds = Number(seconds);
        var h = Math.floor(seconds / 60);
        let m = Math.round(seconds % 60);

        if (h === 0 && m === 0) {
            return "-";
        } else {
            let currentText = "";
            if (h > 0) {
                if (h.toString().length === 1) {
                    h = "0" + h;
                }
                currentText = currentText + h + ` hours `
            } else {
                currentText = currentText + '0 hour '
            }
            if (m > 0) {
                if (m.toString().length === 1) {
                    m = "0" + m;
                }
                currentText = currentText + m + ` minutes `
            } else {
                currentText = currentText + '0 minutes '
            }
            return currentText;
        }
    }

    const editTimesheet = (item) => {
        setSelectedTimesheet(item);
        setOpenEditPanel(true);
    }

    const viewTimesheet = (item) => {
        setSelectedTimesheet(item);
        setOpenViewPanel(true);
    }

    const deleteTimesheet = (id) => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Are you sure you want to remove this data?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.remove_project_timesheet + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been deleted successfully."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getList();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(item => {
            item.logs.map((log, i) => {
                let formattedData = {
                    "Date": item.date ? item.date : '-',
                    "Employee": log.project_worker ? log.project_worker.worker.name ? log.project_worker.worker.name : '-' : '-',
                    "Project": log.project.name ? log.project.name : '-',
                    "Task": log.project_task.name ? log.project_task.name : '-',
                    "Daily Log": log.duration ? secondsToDhms(log.duration) : '0',
                    "Time Period": log.start && log.end ? (moment(log.start).format('YYYY-MM-DD HH:mm:ss') + " - " + moment(log.end).format('YYYY-MM-DD HH:mm:ss')) : log.start ? moment(log.start).format('YYYY-MM-DD HH:mm:ss') + " - now" : '',
                    "Description": log.description ? removeHtmlTags(decodeHtmlEntity(log.description)) : '-',
                    "Submitted at": log.created_at ? moment(log.created_at).format('YYYY-MM-DD HH:mm:ss') : '-',
                };
                newData.push(formattedData);
            })
        });
        return newData;
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_my_timesheet + "?page=" + pageCount + "&details=1&limit=" + exportPageSize;
                if (startDate && endDate) {
                    url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
                }
                if (assigneeList) {
                    url = url + `&worker_id=${assigneeList.value}`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.totalTimers;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, "timesheet")
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingAssignee) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);
                const hasMore = page < totalPage;
                setLoadingAssignee(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                setLoadingAssignee(false)
                console.error('Error fetching data:', error);
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    // Function to decode HTML entities
    const decodeHtmlEntity = (str) => {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = str;
        return textarea.value;
    };

    // Function to remove HTML tags
    const removeHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };


    return (
        <>
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{translate(props.lang, "Timesheet")}</h2>
                    <div className="mb-4">
                        <Row className="g-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                    <RangePicker
                                        className="w-100 input-transparent px-2 py-2 h-100"
                                        placeholder={[
                                            translate(props.lang, "From"),
                                            translate(props.lang, "To"),
                                        ]}
                                        onChange={(e) => handleTimeChange(e)}
                                    />
                                </div>
                            </Col>
                            {props?.auth?.auth?.is_view_all ? (
                                <Col lg={4} md={4} xs={12} sm={12}>
                                    <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                        <div className="input-group-select px-3 w-100">
                                            <AsyncPaginate
                                                placeholder={translate(props.lang, "Select assignee")}
                                                loadingMessage={() => translate(props.lang, 'Loading Data')}
                                                debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                                loadOptions={loadOptions} // Function to load options asynchronously
                                                additional={{
                                                    page: 1, // Initial page
                                                }}
                                                isClearable={true}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                value={assigneeList}
                                                closeMenuOnSelect={true}
                                                onChange={(e) => setAssigneeList(e)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            ) : null}
                            {props?.auth?.permission?.allTimesheet ? (
                                <Col lg={4} md={4} xs={12} sm={12}>
                                    <Button variant="secondary" className="text-center w-100 h-100" onClick={() => exportExcel()}>
                                        {loadingExcel ? (
                                            <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                                {translate(props.lang, "Export")}
                                            </>
                                        )}
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                    </div>

                    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                        <div className='table-responsive'>
                            <Table responsive="sm" className='table-custom'>
                                <thead>
                                    <tr>
                                        <th className='py-2'>{translate(props.lang, "Project")}</th>
                                        <th className='py-2'>{translate(props.lang, "Task")}</th>
                                        <th className='py-2'>{translate(props.lang, "Worker")}</th>
                                        <th className='py-2'>{translate(props.lang, "Daily Log")}</th>
                                        <th className='py-2'>{translate(props.lang, "Time Period")}</th>
                                        <th className='py-2'>{translate(props.lang, "Description")}</th>
                                        <th className='py-2'>{translate(props.lang, "Submitted Datetime")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan={8}>
                                                <DataLoading theme='light' />
                                            </td>
                                        </tr>
                                    ) : (
                                        result.length > 0 ? (
                                            result.map((item, i) => (
                                                <>
                                                    <tr role="row" key={i}>
                                                        <td colSpan={8} className="timesheet-date">{item.date}</td>
                                                    </tr>
                                                    {item.logs.length > 0 ? (
                                                        item.logs.map((record, x) => (
                                                            <tr key={x}>
                                                                <td className='td-text text-capitalize fs-9' style={{ minWidth: '200px' }}>{record.project.name}</td>
                                                                <td className='td-text text-capitalize fs-9' style={{ minWidth: '200px' }}>{record.project_task.name}</td>
                                                                <td className='td-text text-capitalize fs-9' style={{ minWidth: '200px' }}>{record.project_worker ? record.project_worker.worker.name : ''}</td>
                                                                <td className='td-text fs-9' style={{ minWidth: '200px' }}>{secondsToDhms(record.duration)}</td>
                                                                <td className='td-text fs-9' style={{ minWidth: '300px' }}>
                                                                    {record.start && record.end ? (
                                                                        <span>{moment(record.start).format('YYYY-MM-DD HH:mm:ss')} - {moment(record.end).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                                    ) : item.start ? (
                                                                        <span>{moment(record.start).format('YYYY-MM-DD HH:mm:ss')} - {translate(props.lang, "now")}</span>
                                                                    ) : null}

                                                                </td>
                                                                <td className='td-text fs-9' style={{ minWidth: '400px' }} dangerouslySetInnerHTML={{ __html: record.description }}></td>
                                                                <td className='td-text fs-9' style={{ minWidth: '200px' }}>{record.created_at ? moment(record.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                                <td style={{ minWidth: '50px' }}>
                                                                    {record.project_worker.worker.worker_id === props.auth.auth.worker_id ? (
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                variant="phoenix-secondary"
                                                                                className="dropdown-caret-none btn-icon"
                                                                            >
                                                                                <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu className="py-0">
                                                                                {props?.auth?.permission?.allTimesheet ? (
                                                                                    <Dropdown.Item onClick={() => viewTimesheet(record)}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                                ) : null}
                                                                                {props?.auth?.permission?.editTimesheet ? (
                                                                                    <Dropdown.Item onClick={() => editTimesheet(record)}>{translate(props.lang, "Edit")}</Dropdown.Item>
                                                                                ) : null}
                                                                                {props?.auth?.permission?.removeTimesheet ? (
                                                                                    <Dropdown.Item onClick={() => deleteTimesheet(record.project_task_timer_id)}>{translate(props.lang, "Delete")}</Dropdown.Item>
                                                                                ) : null}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    ) : (
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                variant="phoenix-secondary"
                                                                                className="dropdown-caret-none btn-icon"
                                                                            >
                                                                                <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu className="py-0">
                                                                                {props?.auth?.permission?.allTimesheet ? (
                                                                                    <Dropdown.Item onClick={() => viewTimesheet(record)}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                                ) : null}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))

                                                    ) : null}

                                                </>
                                            ))
                                        ) : (
                                            <tr role="row">
                                                <td colSpan={8}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )
                                    )}

                                </tbody>
                            </Table>
                        </div>
                        <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                                <div className='d-flex w-100 align-items-center justify-content-end'>
                                    <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                    <div className="input-group-select-paginate ms-2 px-2">
                                        <Select
                                            options={
                                                shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                            }
                                            className="input-transparent w-100 text-capitalize px-0"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                            name="productPerpage"
                                            isSearchable={false}
                                            onChange={(e) => setProductPerpage(e ? e.value : '')}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-center my-3'>
                            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                {!loading && result.length > 0 ? (
                                    <div className="my-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            forcePage={pageNumber - 1}
                                            onPageChange={changePage}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-white"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Offcanvas className={`w-50`} show={openEditPanel} onHide={() => closeEditPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <EditTimesheet closePanel={() => closeEditPanel()} editable={true} getList={getList} timesheet={selectedTimesheet} />
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas className={`w-50`} show={openViewPanel} onHide={() => closeViewPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <EditTimesheet closePanel={() => closeViewPanel()} editable={false} getList={getList} timesheet={selectedTimesheet} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TimesheetList);
