import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor, replaceHtmlTagFromString } from 'utils/function';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'store/actions/AuthActions';

const EditJobPosition = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    let errorObj = {
        name: null,
        scope: null,
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [jobpositionInfo, setJobpositionInfo] = useState(null);
    const [editable, setEditable] = useState(false);
    const [language, setLanguage] = useState('US');
    const [formData, setFormData] = useState({
        name: '',
        scope: '',
    })
    const infoRef = useRef(null);
    let breadcrumbData = [
        { label: translate(props.lang, 'Setting'), url: '#' },
        { label: translate(props.lang, 'Job Position'), url: '/job-position/overview' },
        { label: translate(props.lang, 'Edit Job Position'), active: true }
    ]

    useEffect(() => {
        if (props.lang === 'ZH') {
            setLanguage('zh_CN')
        } else {
            setLanguage('US')
        }
    }, [props.lang])

    useEffect(() => {
        if (props?.auth?.permission?.allWorkerPosition) {
            getInfo();
        }
    }, [props.auth.permission])

    const getInfo = () => {
        clearTimeout(infoRef.current);
        infoRef.current = setTimeout(() => {
            if (id) {
                fetch(api.get_worker_position_details + '/' + id, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            setJobpositionInfo(responseJson.data)
                            let newFormData = { ...formData };
                            newFormData.name = responseJson.data.name;
                            newFormData.scope = replaceHtmlTagFromString(responseJson.data.scope);
                            setFormData(newFormData);
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }

                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        }, 1000);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.scope) {
            errorMsg.scope = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_worker_position + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        getInfo();
                        setEditable(false)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        getInfo();
        setErrors(errorObj);
        setEditable(false)
    }

    const toggleStatus = (status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.edit_worker_position_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been update successful."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Job Position")}</h2>
            {props?.auth?.permission?.editWorkerPosition ? (
                <Row className='align-items-center justify-content-end'>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                        {!editable ? (
                            <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                            </Button>
                        ) : null}
                        {jobpositionInfo ? (
                            jobpositionInfo.status === 'active' ? (
                                <Button variant="outline-danger" onClick={() => toggleStatus('active')}>
                                    <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Disable")}</span>
                                </Button>
                            ) : (
                                <Button variant="outline-success" onClick={() => toggleStatus('inactive')}>
                                    <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Enable")}</span>
                                </Button>
                            )
                        ) : null}

                    </Col>
                </Row>
            ) : null}
            <Row className='align-items-center justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Position Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter job position name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                readOnly={!editable}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className={`groupLabel-input py-1 ${errors.scope ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Scope")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <Form.Control
                                as="textarea"
                                name="scope"
                                className="form-control input-transparent py-1 px-3"
                                rows={3}
                                value={formData.scope}
                                placeholder={translate(props.lang, "Enter job scope")}
                                onChange={e => handleAddFormChange(e)}
                                readOnly={!editable}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.scope)}</span>
                    </div>
                </Col>

                {editable ? (
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => resetForm()}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditJobPosition);
