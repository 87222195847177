import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useEffect, useState } from "react";
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { attachmentUrl } from 'utils/api';
import { translate } from 'utils/translate';

const PaymentList = (props) => {
    let navigate = useNavigate();
    let [result, setResult] = useState([])

    useEffect(() => {
        if (props.data) {
            setResult(props.data);
        }
    }, [props.data])

    return (
        <>
            <div>
                <div className="py-3 px-4 px-lg-6 bg-light dark__bg-gray-1100 position-relative top-1">
                    <span className="d-block my-3 fs-7 fw-bold">{translate(props.lang, "Payment")}</span>
                    <div className='table-responsive'>
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    <th className='py-2 px-1'>{translate(props.lang, "Payment Date")}</th>
                                    <th className='py-2'>{translate(props.lang, "Payment No.")}</th>
                                    <th className='py-2'>{translate(props.lang, "Amount")}</th>
                                    <th className='py-2'>{translate(props.lang, "Attachment")}</th>
                                    <th className='py-2'>{translate(props.lang, "Type")}</th>
                                    <th className='py-2'>{translate(props.lang, "Payment Remark")}</th>
                                    <th className='py-2'>{translate(props.lang, "Invoice No.")}</th>
                                    <th className='py-2'>{translate(props.lang, "Status")}</th>
                                    <th className='py-2'>{translate(props.lang, "Cancel Remark")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.loading ? (
                                    <tr>
                                        <td colSpan={10}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result.length > 0 ? (
                                        result.map((item, i) => (
                                            <tr role="row" key={i}>
                                                <td className='td-text py-2 fs-9 px-1' style={{ minWidth: '100px' }}>{item.payment_date}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item.project_financial_payment_number}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item?.amount}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '50px' }}>
                                                    {
                                                        item?.attachment.length > 0 ? (
                                                            <a href={attachmentUrl + item.attachment[0].path} className='cursor-pointer' target='_blank'>{translate(props.lang, "View")}</a>) : ''
                                                    }
                                                </td>
                                                <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '100px' }}>{translate(props.lang, item.type)}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item.remark ? item.remark : '-'}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}><span className='text-primary cursor-pointer' onClick={() => navigate(`/invoice/details/${item?.project_financial?.project_financial_id}`)}>{item?.project_financial?.financial_number}</span></td>
                                                <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '100px' }}>
                                                    <div className={`statusBox ${item.payment_status} d-inline-block text-center px-2`}>{translate(props.lang, item.payment_status)}</div>
                                                </td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item.cancel_remark ? item.cancel_remark : '-'}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={10}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(PaymentList);
