import { faEllipsis, faFileExport, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import Error404 from 'pages/error/Error404';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { api } from 'utils/api';
import { exportPageSize, quotationStatus, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getFinanceColor, getFinanceLabel } from 'utils/function';
import { translate } from 'utils/translate';

const InvoiceList = (props) => {
  let navigate = useNavigate();
  const { config } = useAppContext()
  const [productPerpage, setProductPerpage] = useState(10)
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [result, setResult] = useState([]);
  const [financialNumber, setFinancialNumber] = useState('');
  const [customer, setCustomer] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [organizationList, setOrganizationList] = useState([]);
  const [statistic, setStatistic] = useState(0);
  const [summary, setSummary] = useState({
    total_amount: 0,
    total_paid_amount: 0,
    total_partial_paid_invoice: 0,
    total_unpaid_amount: 0,
    total_unpaid_invoice: 0,
    total_paid_invoice: 0,
  });
  const [status, setStatus] = useState('');
  const timerRef = useRef(null);

  let breadcrumbData = [
    { label: translate(props.lang, 'Payment'), url: '#' },
    { label: translate(props.lang, 'Invoice'), active: true }
  ]

  const changePage = (event) => {
    setPageNumber(event.selected + 1);
    getList(event.selected + 1)
  }

  useEffect(() => {
    if (firstLoad) {
      if (props?.auth?.permission?.allProjectFinancial) {
        getList();
        setFirstLoad(false)
      }
    }
  }, [props.auth.auth.token, props.auth.permission]);

  useEffect(() => {
    if (!firstLoad) {
      setPageNumber(1)
      getList(1);
    }
  }, [props.lang, status, organizationId, customer, financialNumber, productPerpage]);

  useEffect(() => {
    getOrganization();
  }, [props.auth.auth.token])

  const getOrganization = () => {
    fetch(api.get_organizations, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.token
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "success") {
          setOrganizationList(responseJson.data.data);
        } else if (responseJson.status === 401) {
          dispatch(logout());
          navigate('/')
        } else {
          console.log('error', responseJson.message)
        }

      }).catch(error => {
        console.error("error", error);
      });
  }

  const getList = (page = pageNumber) => {
    setLoading(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      let url = api.get_project_financials + "?page=" + page + "&limit=" + productPerpage + '&details=1&type=invoice';
      if (financialNumber) {
        url = url + `&financial_number=${financialNumber}`
      }
      if (customer) {
        url = url + `&customer_name=${customer}`
      }
      if (status) {
        url = url + `&financial_status=${status}`
      }
      if (organizationId) {
        url = url + `&organization_id=${organizationId}`
      }
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          if (responseJson.status === "success") {
            setResult(responseJson.data.data);
            setTotalResultCount(responseJson.data.subs.total)
            let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
            setPageCount(totalPage);
            setStatistic(responseJson.data.subs.total)
            setSummary(responseJson.data.subs.summary.invoice)
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else if (responseJson.tokenExpired) {
              dispatch(logout());
              navigate('/')
            } else {
              console.log('error', responseJson.message)
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }, 1000);
  }

  const formatData = (data) => {
    let newData = [];
    data.forEach(entry => {
      let formattedData = {
        "Invoice No.": entry.financial_number ? entry.financial_number : '-',
        "Organization": entry?.project_organization?.organization?.name,
        "Customer": entry.customer ? entry.customer.name : '-',
        "Amount": entry.total_amount ? entry.total_amount : '-',
        "Status": translate(props.lang, getFinanceLabel(entry.financial_status, 'status')),
        "Created Date": entry.created_at ? moment(entry.created_at).format('YYYY-MM-DD HH:mm:ss') : '-',
        "Payment Status": translate(props.lang, entry.payment_status),
      };
      newData.push(formattedData)
    })
    return newData;
  }

  const exportExcel = () => {
    setLoadingExcel(true);
    const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
    let processData = [];
    let totaldata = 0;
    for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
      let url = api.get_project_financials + "?page=" + pageCount + "&limit=" + exportPageSize + '&details=1&type=invoice';
      if (financialNumber) {
        url = url + `&financial_number=${financialNumber}`
      }
      if (customer) {
        url = url + `&customer_name=${customer}`
      }
      if (status) {
        url = url + `&financial_status=${status}`
      }
      if (organizationId) {
        url = url + `&organization_id=${organizationId}`
      }

      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang,
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.status === "success") {
            totaldata = responseJson.data.subs.total;
            processData = processData.concat(formatData(responseJson.data.data));
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout(props.history, 'login'));
            } else if (responseJson.tokenExpired) {
              dispatch(logout(props.history, 'login'));
            } else {
              console.log('error', responseJson.message)
            }
          }
          if (processData.length === totaldata) {
            downloadExcel(processData, 'invoice')
            setLoadingExcel(false)
          }
        }).catch(error => {
          console.error("error", error);
        });

      setTimeout(() => { }, 500) // add some delay here to let server take rest.
    }
  }

  return (
    props?.auth?.permission?.allProjectFinancial ? (
      <div>
        <PageBreadcrumb items={breadcrumbData} />
        <div className="mb-9">
          <h2 className="mb-5">{translate(props.lang, "Invoice")}</h2>
          <div className="mb-4">
            <Row className="g-3">
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 total">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Invoice")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 total">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Invoice Amount")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{summary ? summary.total_amount : 0}</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 completed">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Paid Invoice")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{summary ? summary.total_paid_invoice : 0}</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 ongoingTask">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Partially Paid Invoice")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{summary ? summary.total_partial_paid_invoice : 0}</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 cancelled">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Unpaid Amount")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{summary ? summary.total_unpaid_amount : 0}</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 total">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Unpaid Invoice")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{summary ? summary.total_unpaid_invoice : 0}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-3">
              <Col lg={4} md={4} xs={12} sm={12}>
                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                  <Form.Control
                    type="text"
                    placeholder={translate(props.lang, "Search by invoice no.")}
                    onChange={e => setFinancialNumber(e.target.value)}
                    value={financialNumber}
                    className='input-transparent py-1 px-3'
                  />
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12}>
                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                  <Form.Control
                    type="text"
                    placeholder={translate(props.lang, "Search by customer name")}
                    onChange={e => setCustomer(e.target.value)}
                    value={customer}
                    className='input-transparent py-1 px-3'
                  />
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12}>
                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                  <Select
                    options={
                      organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                    }
                    placeholder={translate(props.lang, "Select organization")}
                    className="input-transparent w-100 text-capitalize"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    isSearchable={true}
                    isClearable={true}
                    value={organizationList.filter(option => option.organization_id === organizationId).length > 0 ? organizationList.filter(option => option.organization_id === organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                    onChange={(e) => setOrganizationId(e ? e.value : '')}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12}>
                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                  <Select
                    options={
                      quotationStatus.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) }))
                    }
                    placeholder={translate(props.lang, "Select status")}
                    className="input-transparent w-100 text-capitalize"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    isSearchable={true}
                    isClearable={true}
                    value={quotationStatus.filter(option => option.value === status).length > 0 ? quotationStatus.filter(option => option.value === status).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                    onChange={(e) => setStatus(e ? e.value : '')}
                  />
                </div>
              </Col>
              {props?.auth?.permission?.allProjectFinancial ? (
                <Col lg={4} md={4} xs={12} sm={12}>
                  <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                    {loadingExcel ? (
                      <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                        {translate(props.lang, "Export")}
                      </>
                    )}
                  </Button>
                </Col>
              ) : null}
              {props?.auth?.permission?.createProjectFinancial ? (
                <Col lg={4} md={4} xs={12} sm={12}>
                  <Button variant="primary" className='w-100 text-center' onClick={() => { navigate('/invoice/new') }}>
                    <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                    {translate(props.lang, "Add Invoice")}
                  </Button>
                </Col>
              ) : null}
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <div className='table-responsive'>
              <Table responsive="sm" hover>
                <thead>
                  <tr>
                    <th className='py-2 px-0'>{translate(props.lang, "Invoice No.")}</th>
                    <th className='py-2'>{translate(props.lang, "Organization")}</th>
                    <th className='py-2'>{translate(props.lang, "Customer")}</th>
                    <th className='py-2'>{translate(props.lang, "Amount")}</th>
                    <th className='py-2'>{translate(props.lang, "Status")}</th>
                    <th className='py-2'>{translate(props.lang, "Payment Status")}</th>
                    <th className='py-2'>{translate(props.lang, "Created Date")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={8}>
                        <DataLoading theme='light' />
                      </td>
                    </tr>
                  ) : (
                    result.length > 0 ? (
                      result.map((item, i) => (
                        <tr role="row" key={i}>
                          <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '150px' }}>{item.financial_number}</td>
                          <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '200px' }}>{item?.project_organization?.organization?.name}</td>
                          <td className='td-text py-2 fs-9 break-word' style={{ minWidth: '200px' }}>{item.customer.name}</td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{Number(item.total_amount).toLocaleString('en-US')}</td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>
                            <div className={`statusBox text-center text-white py-1`} style={{ background: getFinanceColor(item.financial_status, "status") }}>
                              {translate(props.lang, getFinanceLabel(item.financial_status, 'status'))}
                            </div>
                          </td>
                          <td className='td-text py-2 fs-9 break-word' style={{ minWidth: '200px' }}>
                            <div className={`statusBox py-1 ${item.payment_status}`}>
                              {translate(props.lang, item.payment_status)}
                            </div>
                          </td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td className='py-2' style={{ minWidth: '50px' }}>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="phoenix-secondary"
                                className="dropdown-caret-none btn-icon"
                              >
                                <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="py-0">
                                <Dropdown.Item onClick={() => navigate(`/invoice/details/${item.project_financial_id}`)}>{translate(props.lang, "View")}</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr role="row">
                        <td colSpan={8}>
                          <NoResultFound />
                        </td>
                      </tr>
                    )
                  )}

                </tbody>
              </Table>
            </div>
            <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
              <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                <div className='d-flex w-100 align-items-center justify-content-end'>
                  <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                  <div className="input-group-select-paginate ms-2 px-2">
                    <Select
                      options={
                        shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                      }
                      className="input-transparent w-100 text-capitalize px-0"
                      styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                      value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                      name="productPerpage"
                      isSearchable={false}
                      onChange={(e) => setProductPerpage(e ? e.value : '')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='justify-content-center align-items-center my-3'>
              <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                {!loading && result.length > 0 ? (
                  <div className="my-3">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={pageCount}
                      forcePage={pageNumber - 1}
                      onPageChange={changePage}
                      containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                      previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                      nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                      disabledClassName={"pagination__link--disabled text-muted"}
                      activeClassName={"pagination__link--active text-white"}
                      marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    ) : <Error404 />
  );
};
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(InvoiceList);
