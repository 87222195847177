import { connect } from 'react-redux';
import "../../assets/scss/loading.scss";
import { translate } from 'utils/translate';
import { useAppContext } from 'providers/AppProvider';


const DataLoading = (props) => {
    const { config } = useAppContext();
    return (
        <div className={`${props.theme === 'dark' ? 'darkLoading' : 'lightLoading'}`}>
            <div className="preloader-container py-4">
                <div>
                    <div className="progress-1"></div>
                    <div className="mt-2 text-center">
                        <span className={`fs-9 ${config.theme === 'dark' ? 'text-white' : 'text-black'}`}>{translate(props.lang, "Loading Data")}...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(DataLoading);