import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { version } from 'utils/api';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  return (
    <footer className={classNames(className, 'footer')}>
      <Row className="justify-content-between align-items-center h-100">
        <Col md={6} lg={6} sm={6} xs={6} className="text-start">
          <p className="mb-0 text-body fw-bold fs-9">
            PMS{' '}{new Date().getFullYear()} &copy;{' '}
            <a href="https://dothouz.com/" className='fw-bold' target="_blank" rel="noreferrer">
              Dothouz
            </a>
          </p>
        </Col>
        <Col md={6} lg={6} sm={6} xs={6} className="text-end">
          <p className="mb-0 text-body-tertiary fw-bold fs-9">
            {version}
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
