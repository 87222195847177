import { faMessage, } from "@fortawesome/free-regular-svg-icons";
import { faEllipsis, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import moment from "moment";
import DataLoading from "page/Components/DataLoading";
import { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Dropdown, Modal, Button } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { logout } from "store/actions/AuthActions";
import { api } from "utils/api";
import Swal from 'sweetalert2'
import { translate } from "utils/translate";
import { getSweelAlertBg, getSweelAlertTextColor } from "utils/function";
import NoResultFound from "page/Components/NoResultFound";

const ForumList = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [productPerpage, setProductPerpage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedId, setSelectedId] = useState('');
    const [editModal, setEditModal] = useState(false);
    const [result, setResult] = useState([]);
    const [formData, setFormData] = useState({
        description: '',
        reaction: {}
    })
    const [editFormData, setEditFormData] = useState({
        description: '',
        reaction: {}
    })
    const timerRef = useRef(null);
    let breadcrumbData = [
        { label: translate(props.lang, 'Forum'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            if(props.auth.permission && props.auth.permission.allForum){
                getList();
                setFirstLoad(false)
            }
        }
    }, []);

    const getList = (page = pageNumber) => {
        // setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_forums + "?page=" + page + "&limit=" + productPerpage;

            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setFirstLoad(false);
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
                        setPageCount(totalPage);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const viewDetails = (item) => {
        // navigate('/forum/' + item.forum_id);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleEditFormChange = (e) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        if (!formData.description) {
            check = false;
        }
        if (check) {
            fetch(api.add_forum, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        resetForm();
                        getList();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const updateEditForm = () => {
        let check = true;
        if (!editFormData.description) {
            check = false;
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "Please write down your opinion"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
        if (check) {
            fetch(api.edit_forum + `/${selectedId}`, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(editFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        // Swal.fire({
                        //     icon: "success",
                        //     title: translate(props.lang, 'Success'),
                        //     text: "",
                        //     background: getSweelAlertBg(), // Dark background color
                        //     color: getSweelAlertTextColor(), // Text color
                        // });
                        getList();
                        closeModal();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const editData = (item) => {
        setSelectedId(item.forum_id);
        setEditFormData({
            description: item.description,
            reaction: {}
        })
        setEditModal(true);
    }

    const closeModal = () => {
        setSelectedId('');
        setEditModal(false);
        setEditFormData({
            description: '',
            reaction: {}
        })
    }

    const removeData = (id) => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Confirm delete data? Action cannot be revert."),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.remove_forum + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            getList();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    const likePost = (id) => {
        let url = api.react_forum + `/${id}`;
        let data = {
            "reaction": "like"
        };
        fetch(url, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    getList();
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: translate(props.lang, 'Error'),
                            text: responseJson.message,
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const resetForm = () => {
        setFormData({
            description: '',
            reaction: {}
        })
    }

    useEffect(() => {
        if (result?.length > 0) {
            let arrayTemp = (result.map((item, i) => item.reaction?.filter(r => r.react === 'like')[0]?.user?.filter(u => u.worker_id === props.auth.auth.worker_id).length > 0 ? true : false));
            setLikesArray(arrayTemp)
        }
    }, [props.auth.auth, result]);

    const [likesArray, setLikesArray] = useState([]);
    const debouncedOnChange = (id, index) => {
        let tempArray = [...likesArray];
        tempArray[index] = !tempArray[index];
        setLikesArray(tempArray);
        debounce(likePost(id), 1000);
    }

    console.log(likesArray);

    const debounce = (callback, wait) => {
        let timer;
        const debouncedFunc = () => {
            if (shouldCallCallback(Date.now())) {
                callback();
            } else {
                timer = startTimer(callback);
            }
        }
        return debouncedFunc;
    }

    return (
        <>
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{translate(props.lang, "Forum")}</h2>
                    {props.auth.permission && props.auth.permission.createForum ? (
                        <div className="card p-2 mb-3">
                            <Row className="align-items-center justify-content-start px-0 mx-0">
                                <Col lg={11} md={11} sm={10} xs={10} className="px-0">
                                    <div className={`groupLabel-input py-1`}>
                                        <div className={`input-group`}>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                className="form-control input-transparent py-1 px-3"
                                                rows={3}
                                                value={formData.description}
                                                placeholder={translate(props.lang, "Write down what you think about your company/job/colleagues")}
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={1} md={1} sm={2} xs={2} className={`${formData.description ? '' : 'cursor-not-allowed'} px-0 d-flex align-items-center justify-content-center`}>
                                    <div className="sendBtn" onClick={() => submitForm()}>
                                        <img src={require('../../assets/img/send.svg').default} alt="send" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    <Row className="align-items-center px-0 mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                            {
                                loading ? (
                                    <DataLoading theme='light' />
                                ) : (
                                    result.length > 0 ?
                                        (result.map((item, i) => (
                                            <div key={i} className="news-container px-3 py-3 mb-3 cursor-pointer">
                                                <Row className="px-0 mx-0 align-items-center">
                                                    <Col lg={8} md={8} sm={12} xs={12} className="mb-2 order-2 order-md-1" onClick={() => viewDetails(item)}>
                                                        <div className="news_content_summary fs-8 fw-bold" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                    </Col>
                                                    <Col lg={4} md={4} sm={12} xs={12} className="text-end order-1 order-md-2 mb-2">
                                                        <div className="d-flex w-100 align-items-center justify-content-end">
                                                            <div>
                                                                <span className="fs-9 fw-semibold">{translate(props.lang, "Posted at")} {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                            </div>
                                                            {item.worker.worker_id === props.auth.auth.worker_id ? (
                                                                <div className="ms-3">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle
                                                                            variant="phoenix-secondary"
                                                                            className="dropdown-caret-none btn-icon"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu className="py-0">
                                                                            {props.auth.permission && props.auth.permission.editForum ? (
                                                                                <Dropdown.Item onClick={() => editData(item)}>{translate(props.lang, "Edit")}</Dropdown.Item>
                                                                            ) : null}
                                                                            {props.auth.permission && props.auth.permission.removeForum ? (
                                                                                <Dropdown.Item onClick={() => removeData(item.forum_id)}>{translate(props.lang, "Delete")}</Dropdown.Item>
                                                                            ) : null}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="px-0 mx-0 align-items-center">
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <hr className="py-0 my-2" />
                                                    </Col>
                                                    <Col lg={8} md={8} sm={6} xs={6} className="text-start">
                                                        <div className="d-flex w-100 align-items-center justify-content-start">
                                                            {props.auth.permission && props.auth.permission.reactForum ? (
                                                                <div className="d-flex align-items-center me-4" onClick={() => debouncedOnChange(item?.forum_id, i)}>
                                                                    {likesArray[i] && likesArray[i] ?
                                                                        <FontAwesomeIcon icon={faHeart} color="red" fontSize={20} /> :
                                                                        <FontAwesomeIcon icon={faHeart} color="#c2c2c2" fontSize={20} />
                                                                    }
                                                                    <div className="px-2">
                                                                        <span className="fs-9 fw-normal">{item.reaction.filter(r => r.react === 'like').length > 0 ? item.reaction.filter(r => r.react === 'like')[0].counter : 0} {translate(props.lang, "Like")}</span>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            <div className="d-flex align-items-center">
                                                                <FontAwesomeIcon icon={faMessage} color="#3774FF" fontSize={20} />
                                                                <div className="px-2">
                                                                    <span className="fs-9 fw-normal">{translate(props.lang, "Comments")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={4} sm={12} xs={12} className="text-end">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <span className="fs-9 font-weight-400">{translate(props.lang, "Post by")} {props.auth.permission && props.auth.permission.viewForumUser ? item?.worker && item?.worker?.name ? item?.worker?.name : translate(props.lang, 'Anonymous') : translate(props.lang, 'Anonymous')}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))) : (
                                            <div className="news-container px-3 py-3 mb-3 cursor-pointer">
                                                <NoResultFound />
                                            </div>
                                        )
                                )
                            }
                            {result.length > 0 ? (
                                <div className="my-4">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink text-white"}
                                        nextLinkClassName={"pagination__nextlink text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal className="fade" show={editModal} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-3">
                    <div className="d-flex w-100 align-items-center">
                        <div className="w-75"></div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3">
                    <div className='px-2'>
                        <Row className="justify-content-start px-0 mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="px-0 mb-4">
                                <div className="text-center">
                                    <span className="font-weight-600 font-30 text-dark-blue">{translate(props.lang, "Edit Forum")}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                                    <div className={`input-group`}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            className="form-control input-transparent py-1 px-3"
                                            rows={3}
                                            value={editFormData.description}
                                            placeholder={translate(props.lang, "Enter description")}
                                            onChange={e => handleEditFormChange(e)}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                <Row className="px-0 mx-0">
                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => updateEditForm()}>
                                            {translate(props.lang, "Submit")}
                                        </Button>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => closeModal()}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ForumList);