import Avatar from 'components/base/Avatar';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import { api } from 'utils/api';
import { translate } from 'utils/translate';

const Notification = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('overdue_task')
    const [loading, setLoading] = useState(true)
    const [result, setResult] = useState({
        overdue_task: [],
        pending_task: [],
    })
    const timerRef = useRef(null);

    useEffect(()=>{
        getList();
    },[])

    const getList = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setLoading(true)
            fetch(api.get_project_task_dashboard + `/${activeTab}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        let data = { ...result };
                        data.overdue_task = responseJson.data.overdue;
                        data.pending_task = responseJson.data.pending;
                        setResult(data);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    function getDaysBetweenDates(dateString) {
        const today = new Date(); // Get today's date
        const otherDate = new Date(dateString); // Parse the other date
    
        // Calculate the difference in time (milliseconds)
        const differenceInTime = today - otherDate;
    
        // Convert the difference from milliseconds to days
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
        
        if(activeTab === 'overdue_task'){
            return translate(props.lang, "Overdue") + " " + differenceInDays + ' ' + translate(props.lang, "Days")
        } else if(activeTab === 'pending_task'){
            return ""
            // return translate(props.lang, "Due in") + " " + differenceInDays + ' ' + translate(props.lang, "Day")
        } else {
            return ""
            // return translate(props.lang, "Upcoming in") + " " + differenceInDays + ' ' + translate(props.lang, "Days")
        }

    }

    return (
        <Card className="h-100 todo-list">
            <Card.Header className="border-bottom-0 py-3">
                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                        <h3 className="text-body-emphasis">{translate(props.lang, "Notifications")}</h3>
                    </Col>
                </Row>
                <Row className="justify-content-start align-items-center mt-2">
                    <Col lg={4} md={4} xs={6} sm={6} className='mb-2 md-md-0'>
                        <div className={`notificationTab px-2 text-center overdue ${activeTab === 'overdue_task' ? 'active' : ''}`} onClick={() => setActiveTab('overdue_task')}>
                            <span className='fw-bold fs-9 lh-base'>{translate(props.lang, "Overdue Task")} ({result?.overdue_task?.length})</span>
                        </div>
                    </Col>
                    <Col lg={4} md={4} xs={6} sm={6} className='mb-2 md-md-0'>
                        <div className={`notificationTab px-2 text-center pending ${activeTab === 'pending_task' ? 'active' : ''}`} onClick={() => setActiveTab('pending_task')}>
                            <span className='fw-bold fs-9'>{translate(props.lang, "Due In A Day")} ({result?.pending_task?.length})</span>
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="py-0 scrollbar to-do-list-body" style={{ overflowX: 'hidden' }}>
                <Row className="justify-content-center align-items-center position-relative">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        {loading ? (
                            <DataLoading theme='light' />
                        ) : (
                            result[activeTab].length > 0 ? (
                            result[activeTab].map((item, i) => (
                                <div key={i} className={`d-flex align-items-center py-2 ${i !== result[activeTab].length - 1 ? 'border-bottom' : ''} todolist-item w-100`} onClick={() => navigate(`/task/details/${item.project_task_id}`, { state: item.project.project_id })}>
                                    <Row className='align-items-center px-0 mx-0 w-100'>
                                        <Col lg={6} md={6} sm={6} xs={6} className='mb-2 mb-md-0 ps-0'>
                                            <span className='fw-bold fs-8 d-block'>{item.name ? item.name : ''}</span>
                                            <span className='fw-normal fs-9'>{item?.project?.name}</span>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={6} className='text-end pe-0'>
                                            <div className='d-flex w-100 justify-content-end mb-2'>
                                                {item.project_worker.length > 0 ? (
                                                    <div className="d-flex flex-column gap-4">
                                                        <Avatar.Group>
                                                            {item.project_worker.slice(0, 3).map((person, j) => (
                                                                <OverlayTrigger
                                                                    key={j}
                                                                    placement={'left'}
                                                                    overlay={
                                                                        <Tooltip id={'tooltip-' + j}>
                                                                            {person?.worker?.name}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className='assignee cursor-pointer'>
                                                                        <Avatar size="s" variant="name">
                                                                            {person?.worker?.name.substring(0, 1)}
                                                                        </Avatar>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            ))}
                                                            {item.project_worker.length > 3 ? (
                                                                <OverlayTrigger
                                                                    placement={'left'}
                                                                    overlay={
                                                                        <Tooltip id={'tooltip-1'}>
                                                                            {item.project_worker.slice(3).map((person, j) => (
                                                                                <span className="d-block mb-1" key={j}>{person?.worker?.name}</span>
                                                                            ))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className='assignee cursor-pointer'>
                                                                        <Avatar size="s" variant="name">
                                                                            +{item.project_worker.length - 3}
                                                                        </Avatar>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            ) : null}
                                                        </Avatar.Group>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {item.due_date && getDaysBetweenDates(item.due_date) ? (
                                            <div className={`notificationDesc d-inline-block text-center me-auto px-2 ${activeTab}`}>
                                                <span className='fw-bold fs-10 text-uppercase lh-sm'>{activeTab !== 'upcoming_task' ? item.due_date ? getDaysBetweenDates(item.due_date) : '' : item.start_date ? getDaysBetweenDates(item.start_date) : ''}</span>
                                            </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        ) : (
                            <div>
                                <NoResultFound />
                            </div>
                        )
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Notification);

