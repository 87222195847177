import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useEffect, useState } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getColor, getLabel, minutesToHour } from "utils/function";
import { translate } from 'utils/translate';

const ProjectTask = (props) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        if (props.projectInfo) {
            setResult(props.projectInfo.task);
        } else {
            setResult([])
        }
    }, [props.projectInfo])

    return (
        <>
            <div id={"taskSection"}>
                <div className="mx-n4 px-4 py-3 mx-lg-n6 px-lg-6 bg-light dark__bg-gray-1100 position-relative top-1">
                    <span className="d-block my-3 fs-7 fw-bold">{translate(props.lang, "Project Task")}</span>
                    <div className='table-responsive'>
                        <Table responsive="sm" className='table-custom'>
                            <thead>
                                <tr>
                                    <th className='py-2 px-0 fw-bold fs-8'>{translate(props.lang, "Task")}</th>
                                    <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Task Type")}</th>
                                    <th>{translate(props.lang, "Duration")}</th>
                                    <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Status")}</th>
                                    <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Stage")}</th>
                                    <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Priority")}</th>
                                    <th className='py-2 fw-bold fs-8'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={6}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result && result.length > 0 ? (
                                        result.map((item, i) => (
                                            <>
                                                <tr key={i}>
                                                    <td className='td-text text-capitalize fs-9 fw-semibold'>{item.name}</td>
                                                    <td className='td-text text-capitalize fs-9 fw-semibold'>{item.project_category_assigned ? item?.project_category_assigned?.category?.name : ''}</td>
                                                   <td className='td-text text-capitalize fs-9 fw-semibold'>{minutesToHour(item.progress_duration, props.lang)}</td>
                                                   <td className='td-text fs-9'>
                                                        <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.progress_status, 'status'), color: '#fff' }}>
                                                            <span className='fs-9 fw-bold'>{translate(props.lang, getLabel(item.progress_status, 'status'))}</span>
                                                        </div>
                                                    </td>
                                                    <td className='td-text fs-9'>
                                                        <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.stage, 'stage'), color: '#fff' }}>
                                                            <span className='fs-9 fw-bold'>{translate(props.lang, getLabel(item.stage, 'stage'))}</span>
                                                        </div>
                                                    </td>
                                                    <td className='td-text fs-9'>
                                                        <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.priority_level, 'priority'), color: '#fff' }}>
                                                            <span className='fs-9 fw-bold'>{translate(props.lang, getLabel(item.priority_level, 'priority'))}</span>
                                                        </div>
                                                    </td>
                                                    <td className='td-text fs-9 fw-normal'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="phoenix-secondary"
                                                                    className="dropdown-caret-none btn-icon"
                                                                >
                                                                    <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="py-0">
                                                                    <Dropdown.Item onClick={() => navigate(`/task/details/${item.project_task_id}`, { state: props.projectInfo.project_id })}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={6}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {/* <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>Results per page</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ProjectTask);
