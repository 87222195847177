import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import NoResultFound from 'page/Components/NoResultFound';
import OTApprovalLog from 'page/Timesheet/OTApprovalLog';
import Error404 from 'pages/error/Error404';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { getSweelAlertBg, getSweelAlertTextColor, minutesToHour } from 'utils/function';
import { translate } from 'utils/translate';

const OvertimeRequestDetails = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    let errorObj = {
        remark: '',
    }
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [loadingSubmission, setLoadingSubmission] = useState(false);
    const [remark, setRemark] = useState('')
    const [overtimeLogs, setOvertimeLogs] = useState([]);
    const [formData, setFormData] = useState({
        start: '',
        end: '',
        user: '',
        approval_status: '',
        overtime_status: '',
        project: '',
        task: '',
        duration: ''
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Overtime Request'), url: '/timesheet/overtime-request' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
        getInfo();
    }, [id, loadingSubmission])

    const getInfo = () => {
        setLoading(true);
        fetch(api.get_overtime + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    let newFormData = { ...formData };
                    newFormData.start = moment(responseJson.data.start).format('YYYY-MM-DD HH:mm:ss');
                    newFormData.end = moment(responseJson.data.end).format('YYYY-MM-DD HH:mm:ss');
                    newFormData.user = responseJson.data?.worker?.name;
                    newFormData.approval_status = responseJson.data.approval_status;
                    newFormData.overtime_status = responseJson.data.overtime_status;
                    newFormData.project = responseJson.data.project.name;
                    newFormData.task = responseJson.data.project_task.name;
                    newFormData.duration = responseJson.data.duration;
                    setFormData(newFormData);
                    setOvertimeLogs(responseJson.data.logs);
                    setRemark(responseJson.data.approval_reject_message);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const submitForm = (action) => {
        let check = true;
        let errorMsg = { ...errorObj }

        if (action === 'rejected' && !remark) {
            errorMsg.remark = "This field is required.";
            check = false
        }
        setErrors(errorMsg);
        if (check) {
            let data = {
                status: action,
                remark: remark
            }
            fetch(api.approval_overtime + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoadingSubmission(!loadingSubmission)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    return (
        props?.auth?.permission?.overtimeRequest ? (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Overtime Request Details")}</h2>
            <Row className='align-items-stretch justify-content-start mb-3'>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "User")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="user"
                                placeholder={translate(props.lang, "User")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.user}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                {/* <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`} style={{ background: formData.overtime_status === "pending" ? "rgba(255,209,67,1)" : formData.overtime_status === "rejected" ? "rgba(255,45,32, 0.8)" : "rgba(111,212,31,0.9)" }}>
                        <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "Status")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="overtime_status"
                                placeholder={translate(props.lang, "Status")}
                                className="form-control input-transparent py-1 px-3 text-capitalize"
                                value={translate(props.lang, formData.overtime_status)}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col> */}
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="project"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.project ? formData.project : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="task"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.task ? formData.task : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Datetime")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="start"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.start ? formData.start : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "End Datetime")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="end"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.end ? formData.end : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Duration")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="duration"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.duration ? (minutesToHour(formData.duration, props.lang)) : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "Status")}</span>
                        <div className={`input-group px-3`}>
                            <div className={`statusBox fs-9 fw-semibold py-1 text-capitalize ${formData.overtime_status} text-center px-2`}>{translate(props.lang, formData.overtime_status)}</div>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className="font-16 fw-bold text-grey-2 d-block mb-4">
                            {translate(props.lang, "Overtime Approval Log")}
                        </span>
                        {
                            overtimeLogs && overtimeLogs?.length > 0 ?
                                <OTApprovalLog overtimeLogs={overtimeLogs} lang={props.lang} />
                                : <NoResultFound />
                        }
                    </Col>
                    {formData.overtime_status === "pending" ?
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className="font-16 fw-bold text-grey-2">
                                {translate(props.lang, "Remark")} <span className="font-12 fw-500 text-red">({translate(props.lang, "Required if rejected")})</span>
                            </span>
                            <div className={`groupLabel-textarea mt-2 mb-1 ${errors.remark ? 'errorBorder' : ''}`}>
                                <textarea
                                    rows="7"
                                    placeholder={translate(props.lang, "Enter remark")}
                                    className="form-control input-transparent py-1 px-3"
                                    name="remark"
                                    value={remark}
                                    onChange={e => setRemark(e.target.value)}
                                />
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.remark)}</span>
                            </div>
                        </Col> : null
                    }

                    {/* {formData.overtime_status === "rejected" ?
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className="font-16 fw-bold text-grey-2">
                                {translate(props.lang, "Remark")} <span className="font-12 fw-500 text-red">({translate(props.lang, "Required if rejected")})</span>
                            </span>
                            <div className={`groupLabel-textarea mt-2 mb-1`}>
                                <textarea
                                    rows="7"
                                    placeholder={translate(props.lang, "Enter remark")}
                                    className="form-control input-transparent py-1 px-3"
                                    name="remark"
                                    value={remark}
                                    readOnly
                                />
                            </div>
                        </Col> : null
                    } */}
                </Row>
                {formData.overtime_status === "pending" ?
                    <Row className='justify-content-center align-items-center mt-5'>
                        <Col lg={6} md={6} sm={6} xs={6} className='mb-3'>
                            <Button variant="danger" className="w-100" onClick={() => submitForm('rejected')} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Reject")}</span>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='mb-3'>
                            <Button variant="primary" className="w-100" onClick={() => submitForm('approved')} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Approve")}</span>
                            </Button>
                        </Col>
                    </Row>
                    : (
                        <Row className='justify-content-start align-items-center mt-5'>
                            <Col lg={6} md={6} sm={6} xs={6} className='mb-3'>
                                <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/timesheet/overtime-request')}>
                                    {translate(props.lang, "Back")}
                                </Button>
                            </Col>
                        </Row>
                    )
                }
            </div>
        </div >
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(OvertimeRequestDetails);
