import { faBriefcase, faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, getLabel, minutesToHour } from 'utils/function';
import { translate } from 'utils/translate';

const TaskDetailsSummary = (props) => {
    return (
        <>
            <div className="mb-4 mb-xl-7">
                <table className="lh-sm mb-4 mb-sm-0 mb-xl-4">
                    <tbody>
                        <tr>
                            <td className="py-1" colSpan={2}>
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faEarthAmericas} className="me-2 text-body-tertiary fs-9" />
                                    <h5 className="text-body mb-0 text-nowrap">{props.taskInfo ? props.taskInfo?.task[0]?.project_task_id : ''}</h5>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center mb-0">
                                    <FontAwesomeIcon icon={faBriefcase} className="me-2 text-body-tertiary fs-9" />
                                    <h5 className="text-body mb-0 text-nowrap">{translate(props.lang, "Project")}</h5>
                                </div>
                            </td>
                            <td className="py-1">
                                <div className='d-flex align-items-center'>
                                    <span className="fw-bold text-body-highlight px-1 d-block">{" "}:{" "}</span>
                                    <span className="fw-bold text-body-highlight d-block">{props.taskInfo ? props.taskInfo.name ? props.taskInfo.name : '-' : '-'}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="lh-sm">
                    <tbody>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Category")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                    {props.taskInfo ? props.taskInfo.task[0] ? props.taskInfo.task[0]?.project_category_assigned?.project_category?.name : '-' : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Started")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                {props.taskInfo && props.taskInfo?.task[0]?.start_date ? props.taskInfo?.task[0]?.start_date : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Deadline")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                {props.taskInfo && props.taskInfo?.task[0]?.due_date ? props.taskInfo?.task[0]?.due_date : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Estimate Delivery Date")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                {props.taskInfo && props.taskInfo?.task[0]?.estimated_delivery_date ? props.taskInfo?.task[0]?.estimated_delivery_date : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Progress")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                {props.taskInfo ? props.taskInfo?.task[0]?.progress_percentage : 0}%
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Status")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight">
                                {props.taskInfo && props.taskInfo.task[0].progress_status ?
                                    <div className='btn light px-2 text-capitalize text-center status-dropdown fs-8 fw-semibold' style={{ background: getColor(props.taskInfo.task[0].progress_status, 'status'), color: '#fff' }}>
                                        {translate(props.lang, getLabel(props.taskInfo.task[0].progress_status, 'status'))}
                                    </div> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Stage")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight">
                                {props.taskInfo && props.taskInfo.task[0].stage ?
                                    <div className='btn light px-2 text-capitalize text-center status-dropdown fs-8 fw-semibold' style={{ background: getColor(props.taskInfo.task[0].stage, 'stage'), color: '#fff' }}>
                                        {translate(props.lang, getLabel(props.taskInfo.task[0].stage, 'stage'))}
                                    </div> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Priority")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight">
                                {props.taskInfo && props.taskInfo.task[0].priority_level.toString() ?
                                    <div className='btn light px-2 text-capitalize text-center status-dropdown fs-8 fw-semibold' style={{ background: getColor(props.taskInfo.task[0].priority_level, 'priority'), color: '#fff' }}>
                                        {translate(props.lang, getLabel(props.taskInfo.task[0].priority_level, 'priority'))}
                                    </div> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold py-1">
                                {translate(props.lang, "Duration")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize text-start px-1 fs-8'>
                                    {props.taskInfo ? minutesToHour(props.taskInfo?.task[0]?.progress_duration, props.lang) : 0}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold py-1">
                                {translate(props.lang, "Outcome Link")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize text-start px-1 fs-8'>
                                    {props.taskInfo && props.taskInfo.task[0]?.outcome_link ? props.taskInfo.task[0]?.outcome_link : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold py-1">
                                {translate(props.lang, "Task Cost")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize text-start px-1 fs-8'>
                                    {props.taskInfo ? props.taskInfo?.task[0]?.task_cost : 0}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold py-1">
                                {translate(props.lang, "Financial Status")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize text-start px-1 fs-8'>
                                    {props.taskInfo ? props.taskInfo.task[0]?.project_financial_task[0]?.project_financial?.financial_status : '-'}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TaskDetailsSummary;
