import Swal from 'sweetalert2'
import {
    formatError,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp
} from '../../services/AuthService';
import { api } from '../../utils/api';
import { translate } from '../../utils/translate';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PERMISSION_ACCESS = "UPDATE_PERMISSION_ACCESS";
export const UPDATE_UNREAD_NEWS_COUNT = "UPDATE_UNREAD_NEWS_COUNT";

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout() {
    // if (path) {
    //     if (path.includes('/login') || path.includes('/register') || path.includes('/forgot-password') || path.includes('/success') || path.includes('/test')) {
    //     } else {
    //         // window.location.href = window.location.origin;
    //     }
    // } else {
    //     // history.push('/login');
    // }
    
    localStorage.removeItem('pms-crm');
    localStorage.removeItem('pms-timer');
    return {
        type: LOGOUT_ACTION,
    };
}


// export function loginAction(email, password, history, rememberMe, lang) {
//     return (dispatch) => {
//         login(email, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data, rememberMe);
//                 if(!rememberMe){
//                     runLogoutTimer(
//                         dispatch,
//                         response.data.expiresIn * 1000,
//                         history,
//                     );
//                 }          

//                 dispatch(loginConfirmedAction(response.data));
// 				history.push('/dashboard');                
//             })
//             .catch((error) => {
// 				//console.log(error);
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }

export function loginAction(inputData, history, lang) {
    return (dispatch) => {
        let darkTheme = JSON.parse(localStorage.getItem('isDark'));
        let data = {
            "username": inputData.username,
            "password": inputData.password,
            "remember": inputData.rememberMe
        };
        fetch(api.login, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": lang.toLowerCase()
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                dispatch(loadingToggleAction(false))
                if (responseJson.status === 'success') {
                    saveTokenInLocalStorage(responseJson.data, inputData.rememberMe);
                    dispatch(loginConfirmedAction(responseJson.data));
                } else {
                    Swal.fire({
                        icon: "error",
                        title: translate(lang, 'Error'),
                        text: responseJson.message,
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    const errorMessage = translate(lang, 'Invalid email or password');
                    dispatch(loginFailedAction(errorMessage));
                }
            }).catch(error => {
                dispatch(loadingToggleAction(false))
                Swal.fire({
                    icon: "error",
                    title: translate(lang, 'Error'),
                    text: "Server error",
                    background: getSweelAlertBg(), // Dark background color
                    color: getSweelAlertTextColor(), // Text color
                });
                console.error("error", error);
            });
    };
}


export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
