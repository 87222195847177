import { useEffect, useRef, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import { api, attachmentUrl } from 'utils/api';
import { Button, Col, Row, Table } from 'react-bootstrap';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useReactToPrint } from 'react-to-print';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { translate } from 'utils/translate';
import "../../assets/scss/pdf.scss";
import DataLoading from 'page/Components/DataLoading';
import Swal from 'sweetalert2';
import Error404 from 'pages/error/Error404';

function InvoicePdf(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const infoRef = useRef(null);
    const [financialInfo, setFinancialInfo] = useState(null)
    const [loading, setLoading] = useState(false);
    const [organizationInfo, setOrganizationInfo] = useState(false);
    const reportTemplateRef = useRef(null);
    const [finalPrice, setFinalPrice] = useState({
        subTotal: 0.00,
        totalPrice: 0.00,
        totalDiscount: 0.00,
        totalTax: 0.00,
    })

    let breadcrumbData = [
        { label: translate(props.lang, 'Finance'), url: '#' },
        { label: translate(props.lang, 'Invoice'), url: '/invoice/overview' },
        { label: translate(props.lang, 'PDF'), active: true }
    ]

    useEffect(() => {
        let isMounted = true;
        if (id && isMounted) {
            getInfo();
        }
        return () => {
            isMounted = false;  // Clean up when component is unmounted
        };
    }, [id])

    const getInfo = () => {
        setLoading(true);
        clearTimeout(infoRef.current);
        infoRef.current = setTimeout(() => {
            fetch(api.get_project_financial_details + `/${id}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setFinancialInfo(responseJson.data)
                        if (responseJson.data.project_financial_task.length > 0) {
                            let arr = [];
                            responseJson.data.project_financial_task.map((item) => {
                                arr.push({
                                    project_financial_task_id: item.project_financial_task_id,
                                    project_task_id: item.project_task_id,
                                    taskName: item.name,
                                    taskCode: item.code,
                                    description: item.description,
                                    amount: item.amount,
                                    tax: item.tax,
                                    discount: item.discount,
                                    discount_type: item.discount_type,
                                })
                            })
                            getOrganization(responseJson.data.project_organization?.organization?.organization_id)
                            calculateFinalPrice(arr)
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const getOrganization = (id) => {
        fetch(api.get_organization_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationInfo(responseJson.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const calculateFinalAmount = (item) => {
        let finalAmount = item.amount;
        if (item.discount) {
            if (item.discount_type === 'percent') {
                finalAmount = Number(finalAmount) - (Number(finalAmount) * item.discount / 100);

            } else if (item.discount_type === 'amount') {
                finalAmount = Number(finalAmount) - Number(item.discount);
            }
        }
        if (item.tax) {
            finalAmount = Number(finalAmount) + (Number(finalAmount) * Number(item.tax) / 100);
        }

        return Number(finalAmount).toLocaleString('en-US', { minimumFractionDigits: 2 })
    }

    const calculateFinalPrice = (arr) => {
        let newFinalPrice = { ...finalPrice };
        let totalPrice = 0;
        let subTotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        arr.map((item) => {
            if (item.amount) {
                subTotal = Number(subTotal) + Number(item.amount);
                let newAmount = Number(item.amount);
                let newTax = 0;
                if (item.discount) {
                    let newDiscount = 0;
                    if (item.discount_type === 'percent') {
                        newDiscount = Number(item.amount) * Number(item.discount) / 100;
                        newAmount = Number(item.amount) - (Number(item.amount) * Number(item.discount) / 100);
                    } else {
                        newDiscount = Number(item.discount);
                        newAmount = Number(item.amount) - Number(item.discount);
                    }
                    totalDiscount = Number(totalDiscount) + Number(newDiscount);
                }
                if (item.tax) {
                    newTax = (Number(newAmount) * Number(item.tax) / 100);
                    newAmount = Number(newAmount) + (Number(newAmount) * Number(item.tax) / 100);
                    totalTax = Number(totalTax) + newTax;
                }
                totalPrice = Number(totalPrice) + Number(newAmount);
            }
        })
        newFinalPrice.totalTax = Number(totalTax).toFixed(2);
        newFinalPrice.subTotal = Number(subTotal).toFixed(2);
        newFinalPrice.totalDiscount = Number(totalDiscount).toFixed(2);
        newFinalPrice.totalPrice = Number(totalPrice).toFixed(2);
        setFinalPrice(newFinalPrice)
    }

    const handlePrint = useReactToPrint({
        content: () => reportTemplateRef.current,
    });

    const generatePdf = () => {
        if (!loading) {
            const divToPrint = reportTemplateRef.current;
            html2canvas(divToPrint, {
                windowWidth: reportTemplateRef.current.scrollWidth,
                windowHeight: reportTemplateRef.current.scrollHeight,
                useCORS: true
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${financialInfo?.financial_number}.pdf`);
            });
        } else {
            Swal.fire({
                icon: "info",
                title: "",
                text: translate(props.lang, "Please wait for data finish loading."),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor, // Text color
            });
        }
    }

    return (
        props?.auth?.permission?.allProjectFinancial ? (
        <>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Invoice")} PDF</h2>
            <div>
                <Row className='align-items-stretch justify-content-end'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                        <Button variant="outline-primary" className='ms-md-2' onClick={() => generatePdf()}>
                            <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Download PDF")}</span>
                        </Button>
                        <Button variant="secondary" className='ms-md-2' onClick={() => handlePrint()}>
                            <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Print")}</span>
                        </Button>
                    </Col>
                </Row>
            </div>
            {loading ? (
                <div>
                    <DataLoading />
                </div>
            ) : (
                <>
                    <div id="capture" className="capture"></div>
                    <div id="divToDownload" ref={reportTemplateRef} className='mb-5'>
                        {financialInfo ? (
                            <div className="bgWhite p-4 mt-5">
                                <Row className="px-0 mx-0 align-items-center">
                                    <Col lg={2} md={2} sm={2} xs={2}>
                                        {organizationInfo ? (
                                            <img src={attachmentUrl + organizationInfo?.logo.path} className="img-fluid" style={{ maxWidth: '150px' }} />
                                        ) : null}
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10} className="text-end">
                                        <span className="d-block fs-10 text-black fw-bold">{organizationInfo?.name}</span>
                                        <span className="d-block fs-10 text-black fw-medium">SST No.: {organizationInfo?.tax_id}</span>
                                        <span className="d-block fs-10 text-black fw-medium">{financialInfo?.organization_address?.address1}</span>
                                        <span className="d-block fs-10 text-black fw-medium">{financialInfo?.organization_address?.address2}</span>
                                        <span className="d-block fs-10 text-black fw-medium">{financialInfo?.organization_address?.postcode} {financialInfo?.organization_address?.town}, {financialInfo?.organization_address?.state}</span>
                                        <span className="d-block fs-10 text-black fw-medium text-capitalize">{financialInfo?.organization_address?.country.toLowerCase()} </span>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                                        <h2 className="documentType"><span>Invoice</span></h2>
                                    </Col>
                                </Row>
                                <Row className="px-0 mx-0 align-items-start">
                                    <Col lg={6} md={6} sm={6} xs={6} className="mt-5">
                                        <span className="d-block fs-10 text-black fw-normal">Bill To: </span>
                                        {financialInfo?.customer_address ? (
                                            <>
                                                <span className="d-block fs-10 text-black fw-bold">{financialInfo?.customer_address?.attention ? financialInfo?.customer_address?.attention : financialInfo?.customer?.name}</span>
                                                <span className="d-block fs-10 text-black fw-medium mt-1">{financialInfo?.customer_address?.address1}</span>
                                                <span className="d-block fs-10 text-black fw-medium mt-1">{financialInfo?.customer_address?.address2}</span>
                                                <span className="d-block fs-10 text-black fw-medium mt-1">{financialInfo?.organization_address?.postcode} {financialInfo?.organization_address?.town}, {financialInfo?.organization_address?.state}</span>
                                                <span className="d-block fs-10 text-black fw-medium mt-1">{financialInfo?.organization_address?.country.toLowerCase()} </span>
                                            </>
                                        ) : financialInfo?.customer?.customer_company_name ? (
                                            <span className="d-block fs-10 text-black fw-bold">{financialInfo?.customer?.customer_company_name}</span>
                                        ) : <span className="d-block fs-10 text-black fw-bold">{financialInfo?.customer?.name}</span>}
                                        {financialInfo?.customer_address ? financialInfo?.customer_address?.attention ? (
                                            <div className="mt-3">
                                                <span className="d-block fs-9 text-black fw-medium mt-1">Attention To.: {financialInfo?.customer_address?.attention}</span>
                                            </div>
                                        ) : null : null}

                                        <div className="mt-3">
                                            <span className="d-block fs-10 text-black fw-medium mt-1">Subject : </span>
                                            <span className="d-block fs-10 text-black fw-medium">{financialInfo?.subject}</span>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="mt-5 text-end d-flex justify-content-end">
                                        <div className="w-50">
                                            <div className="d-flex w-100 justify-content-between">
                                                <div>
                                                    <span className="d-block fs-10 text-black fw-bold">Estimate#</span>
                                                </div>
                                                <div className="text-end">
                                                    <span className="d-block fs-10 text-black fw-semibold">{financialInfo?.financial_number}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex w-100 justify-content-between mt-1">
                                                <div>
                                                    <span className="d-block fs-10 text-black fw-bold">Estimate Date</span>
                                                </div>
                                                <div className="text-end">
                                                    <span className="d-block fs-10 text-black fw-semibold">{financialInfo?.delivery_date}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex w-100 justify-content-between mt-1">
                                                <div>
                                                    <span className="d-block fs-10 text-black fw-bold">Expiry Date</span>
                                                </div>
                                                <div className="text-end">
                                                    <span className="d-block fs-10 text-black fw-semibold">{financialInfo?.due_date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                                        <Table responsive bordered>
                                            <thead>
                                                <tr role="row">
                                                    <th className={`pdf-th-text fs-10 fw-bolder text-center`} style={{ width: "2.5%" }}>#</th>
                                                    <th className={`pdf-th-text fs-10 fw-bolder`} style={{ width: "30%" }}>ITEM</th>
                                                    <th className={`pdf-th-text fs-10 fw-bolder text-end`} style={{ width: "2.5%" }}>QTY.</th>
                                                    <th className={`pdf-th-text fs-10 fw-bolder text-end`} style={{ width: "10%" }}>PRICE</th>
                                                    <th className={`pdf-th-text fs-10 fw-bolder text-end`} style={{ width: "5%" }}>DISCOUNT</th>
                                                    <th className={`pdf-th-text fs-10 fw-bolder text-end`} style={{ width: "5%" }}>TAX</th>
                                                    <th className={`pdf-th-text fs-10 fw-bolder text-end`} style={{ width: "15%" }}>TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {financialInfo ? financialInfo?.project_financial_task ?
                                                    financialInfo?.project_financial_task.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className={`fw-semibold fs-10 py-1 align-top text-center`}>{i + 1}.</td>
                                                            <td className={`fw-semibold fs-10 py-1 align-top`}>
                                                                <span className="d-block fw-semibold">{item?.name}</span>
                                                                {item.description ? (
                                                                    <div className="mt-1" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                                ) : null}
                                                            </td>
                                                            <td className={`fw-semibold fs-10 py-1 align-top text-end`}>{item?.tasks?.quantity > 0 ? item?.tasks.quantity : 1}</td>
                                                            <td className={`fw-semibold fs-10 py-1 align-top text-end`}>{item.amount ? Number(item.amount).toLocaleString('en-US', { minimumFractionDigits: 2 }) : 0}</td>
                                                            <td className={`fw-semibold fs-10 py-1 align-top text-end`}>
                                                                {item?.discount > 0 ? (
                                                                    item?.discount_type === 'percent' ? (
                                                                        <>
                                                                            <span className="d-block">{Number(Number(item.amount) * Number(item.discount) / 100).toFixed(2)}</span>
                                                                            <span className="d-block mt-1">{Number(item.discount)}{item.discount_type === 'percent' ? '%' : ''}</span>
                                                                        </>
                                                                    ) : (
                                                                        <span className="d-block">{Number(item.discount).toFixed(2)}</span>
                                                                    )
                                                                ) : '-'}
                                                            </td>
                                                            <td className={`fw-semibold fs-10 py-1 align-top text-end`}>
                                                                {item?.tax > 0 ? (
                                                                    <>
                                                                        {item.discount > 0 ? (
                                                                            <span className="d-block ">{Number(item.amount) * Number(item.discount) / 100}</span>
                                                                        ) : (
                                                                            <span className="d-block">{Number(item.amount) * Number(item.tax) / 100}</span>
                                                                        )}
                                                                        <span className="d-block mt-1">{Number(item.tax)}%</span>
                                                                    </>
                                                                ) : '-'}
                                                            </td>
                                                            <td className={`text-end fw-normal fs-10 py-1 align-top px-1`}>
                                                                <span className="d-block">{calculateFinalAmount(item)}</span>
                                                            </td>
                                                        </tr>
                                                    )) : null : null}
                                                <tr>
                                                    <td colSpan={3} className={`text-end fw-bold fs-10 py-1 align-middle`}>Subtotal</td>
                                                    <td className={`text-end fw-semibold fs-10 py-1 align-middle`}>{finalPrice.subTotal ? Number(finalPrice.subTotal).toLocaleString('en-US', { minimumFractionDigits: 2 }) : ''}</td>
                                                    <td className={`text-end fw-semibold fs-10 py-1 align-middle`}>{finalPrice.totalDiscount}</td>
                                                    <td className={`text-end fw-semibold fs-10 py-1 align-middle`}>{finalPrice.totalTax}</td>
                                                    <td className={`text-end fw-semibold fs-10 py-1 align-middle`}>{finalPrice.totalPrice ? Number(finalPrice.totalPrice).toLocaleString('en-US', { minimumFractionDigits: 2 }) : 0}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                                        <span className="d-block fs-10 text-black fw-normal break-white-space">{financialInfo?.customer_note}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="mt-5">
                                        <span className="d-block fs-10 text-black fw-bold">OUR BANK DETAILS: </span>
                                        {financialInfo?.payment_method && financialInfo.payment_method.map((item, i) => (
                                            <>
                                                <span className="d-block fs-10 text-black fw-normal mt-1 text-uppercase break-white-space">{item.description}</span>
                                            </>
                                        ))}
                                        {/* <span className="d-block fs-9 text-black fw-normal mt-1">BANK ACCOUNT HOLDER NAME: <span className="fw-bold">{financialInfo?.organization_bank?.account_name}</span></span>
                                        <span className="d-block fs-9 text-black fw-medium mt-1">BANK NAME: <span className="fw-bold">{financialInfo?.organization_bank?.bank_name}</span></span>
                                        <span className="d-block fs-9 text-black fw-medium mt-1">BANK ACCOUNT NO.: <span className="fw-bold">{financialInfo?.organization_bank?.account_number}</span></span> */}
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                                        <span className="d-block fs-10 text-black fw-bold">Terms & Conditions: </span>
                                        <div>
                                            <span className="d-block fs-10 text-black fw-normal break-white-space">{financialInfo?.tnc}</span>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        ) : null}
                    </div>
                    <Button variant="phoenix-primary" className="px-3 px-sm-10 mb-4" onClick={() => navigate(`/invoice/details/${id}`)}>
                        {translate(props.lang, "Back")}
                    </Button>
                </>
            )}
        </>
        ) : <Error404 />
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(InvoicePdf);