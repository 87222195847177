import Avatar from 'components/base/Avatar';
import { useEffect, useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/72x72/57.webp';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import { connect, useDispatch } from 'react-redux';
import { logout } from 'store/actions/AuthActions';
import { translate } from 'utils/translate';

const ProfileDropdownMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [navItems] = useState([
    {
      label: 'Profile',
      icon: 'user',
      link: '/profile',
    },
    {
      label: 'Change Password',
      icon: 'lock',
      link: '/change-password'
    },
  ]);

  const onLogout = () => {
    dispatch(logout());
    navigate('/');
  }
  
  return (
    <Dropdown.Menu
      align="end"
      className={'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={require('../../../assets/img/team/avatar-rounded.webp')} size="xl" />
            <h6 className="text-body-emphasis">{props.auth.profile.name}</h6>
          </div>
          <div style={{ height: '5rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href={item.link} className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{translate(props.lang, item.label)}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          <div className="px-3 my-2">
            <div
              onClick={() => onLogout()}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              {translate(props.lang, "Sign out")}
            </div>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(ProfileDropdownMenu);
