import { faEye, faFileExport, faPlus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from "antd";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import dayjs from "dayjs";
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
const { RangePicker } = DatePicker;

const approveList = [
    { label: 'pending', value: 'pending' },
    { label: 'approved', value: 'approved' },
    { label: 'rejected', value: 'rejected' },
]

const ClaimList = (props) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [statistic, setStatistic] = useState(0);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [organization, setOrganization] = useState('');
    const [approveStatus, setApproveStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [formData, setFormData] = useState({
        filterType: 'name',
        filterValue: '',
    })
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });
    let breadcrumbData = [
        { label: translate(props.lang, 'Claim'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]
    const timerRef = useRef(null);
    const searchDataRef = useRef(formData);
    useEffect(() => {
        searchDataRef.current = formData;
    }, [formData]);

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            if(props?.auth?.permission?.allClaim){
                getList();
                setFirstLoad(false)
            }
        }
    }, [props.auth.permission]);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, approveStatus, startDate, endDate]);

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_claims + `?page=${page}&details=1`;
            let search = 0;
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            if (sortBy.subSort && sortBy.subOrder) {
                url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
            }
            if (approveStatus) {
                url = url + `&approvalStatus=${approveStatus}`
            }
            if (startDate && endDate) {
                url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.total)
                        setTotalResultCount(responseJson.data.subs.total)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(entry => {
            let formattedData = {
                "User": entry?.worker ? entry?.worker?.name : '-',
                "Claim Start": entry?.claim_start_period ? entry?.claim_start_period : '-',
                "Claim End": entry?.claim_end_period ? entry?.claim_end_period : '-',
                "Amount": entry?.amount ? entry?.amount : '-',
                "Bank Name": entry?.bank_name ? entry?.bank_name : '-',
                "Bank Account": entry?.bank_account ? entry?.bank_account : ''
            };
            newData.push(formattedData);
        });
        return newData;
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_claims + `?page=${pageCount}&limit=${exportPageSize}&details=1`;
                if (sortBy.sortField && sortBy.sortOrder) {
                    url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
                }
                if (sortBy.subSort && sortBy.subOrder) {
                    url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
                }
                if (approveStatus !== "All") {
                    url = url + `&approvalStatus=${approveStatus}`
                }
                if (startDate && endDate) {
                    url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.total;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.status === 'error' && responseJson.message.toLowerCase() === 'token expired') {
                                dispatch(logout());
                                navigate('/');
                            }
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, 'claim')
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    const handleTimeChange = (e) => {
        const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
        const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
        setStartDate(formattedFromDate);
        setEndDate(formattedToDate);
    }

    const removeClaimConfirmation = (id) => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Are you sure you want to remove this data?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0080C7",
            confirmButtonText:translate(props.lang,  "Confirm"),
            cancelButtonText: translate(props.lang, "Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                removeItem(id)
            }
        });
    }

    const removeItem = (id) => {
        setLoading(true);
        let data = {};
        fetch(api.remove_claim + "/" + id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": 'en',
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been deleted successfully."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getList();
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, "Claim")}</h2>
                <div className="mb-4">
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                            <div className="tableBgContainer py-4 px-4 total">
                                <span className="fs-8 fw-bold">{translate(props.lang, "Total Claim")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                <RangePicker
                                    className="w-100 input-transparent px-2 py-2 h-100"
                                    placeholder={[
                                        translate(props.lang, "From"),
                                        translate(props.lang, "To"),
                                    ]}
                                    onChange={(e) => handleTimeChange(e)}
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                <Select
                                    options={
                                        approveList.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) }))
                                    }
                                    placeholder={translate(props.lang, "Select status")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={approveList.filter(option => option.value === approveStatus).length > 0 ? approveList.filter(option => option.value === approveStatus).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                                    onChange={(e) => setApproveStatus(e ? e.value : '')}
                                />
                            </div>
                        </Col>
                        {props?.auth?.permission?.allClaim ? (
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                                {loadingExcel ? (
                                    <>
                                        <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                        {translate(props.lang, "Export")}
                                    </>
                                )}
                            </Button>
                        </Col>
                        ) : null}
                        {props?.auth?.permission?.createClaim ? (
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Button variant="primary" className='w-100 text-center' onClick={() => { navigate('/claim/new') }}>
                                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                {translate(props.lang, "Add Claim")}
                            </Button>
                        </Col>
                        ) : null}
                    </Row>
                </div>

                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <div className='table-responsive'>
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    {/* <th className='py-2 text-center px-0' style={{ minWidth: '50px' }}>{translate(props.lang, "No.")}</th> */}
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('name', false)}>
                                            <span className="pe-2">{translate(props.lang, "User")}</span>
                                            {sortBy.sortField === 'name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('code', false)}>
                                            <span className="pe-2">{translate(props.lang, "Claim Period")}</span>
                                            {sortBy.sortField === 'code' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('organization.name', true)}>
                                            <span className="pe-2">{translate(props.lang, "Amount")}</span>
                                            {sortBy.subSort === 'organization.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <span className="pe-2">{translate(props.lang, "Bank Name")}</span>
                                    </th>
                                    <th className='py-2'>
                                        <span className="pe-2">{translate(props.lang, "Bank Account")}</span>
                                    </th>
                                    <th className='py-2'>
                                        <span className="pe-2">{translate(props.lang, "Status")}</span>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={6}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result.length > 0 ? (
                                        result.map((item, i) => (
                                            <tr role="row" key={i}>
                                                {/* <td className='fs-9 py-2 text-center'>
                                                    {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                                                    {i + 1 === productPerpage ? '0' : i + 1}.
                                                </td> */}
                                                <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '300px' }}>{item?.worker?.name}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{item?.claim_start_period} - {item?.claim_end_period} </td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{Number(item.amount).toLocaleString('en-US')}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{item.bank_name}</td>
                                                <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{item.bank_account}</td>
                                                <td className={`td-text py-2 fs-9 fw-bold text-capitalize`} style={{ minWidth: '100px' }}>
                                                    <div className={`statusBox ${item.approval_status} text-center px-2`}>{translate(props.lang, item.approval_status)}</div>
                                                </td>
                                                <td className='py-2' style={{ minWidth: '100px' }}>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <Link to={`/claim/view/${item.claim_id}`} className=''>
                                                            <FontAwesomeIcon icon={faEye} color="#839AB4" fontSize={20} />
                                                        </Link>
                                                        {/* <div className='cursor-pointer' onClick={() => removeClaimConfirmation(item.claim_id)}>
                                                        <FontAwesomeIcon icon={faTrash} color="red" fontSize={18} />
                                                    </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={6}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </Table>
                    </div>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ClaimList);
