import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'utils/api';
import { translate } from 'utils/translate';
import OTApprovalLog from './OTApprovalLog';
import { minutesToHour } from 'utils/function';
import { logout } from 'store/actions/AuthActions';
import NoResultFound from 'page/Components/NoResultFound';

const OTDetailsView = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [overtimeLogs, setOvertimeLogs] = useState([]);
    const [formData, setFormData] = useState({
        start: '',
        end: '',
        user: '',
        approval_status: '',
        overtime_status: '',
        project: '',
        task: '',
        duration: '',
        description: '',
        approval_reject_message: '',
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Overtime'), url: '/timesheet/overtime' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    useEffect(() => {
        getInfo();
    }, [id])

    const getInfo = () => {
        setLoading(true);
        fetch(api.get_overtime + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    let newFormData = { ...formData };
                    newFormData.start = moment(responseJson.data.start).format('YYYY-MM-DD HH:mm:ss');
                    newFormData.end = moment(responseJson.data.end).format('YYYY-MM-DD HH:mm:ss');
                    newFormData.user = responseJson.data?.worker?.name;
                    newFormData.approval_status = responseJson.data.approval_status;
                    newFormData.overtime_status = responseJson.data.overtime_status;
                    newFormData.project = responseJson.data.project.name;
                    newFormData.task = responseJson.data.project_task.name;
                    newFormData.duration = responseJson.data.duration;
                    newFormData.description = responseJson.data.description;
                    newFormData.approval_reject_message = responseJson.data.approval_reject_message
                    setFormData(newFormData);
                    setOvertimeLogs(responseJson.data.logs);
                    setRemark(responseJson.data.approval_reject_message);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Overtime Details")}</h2>
            <Row className='align-items-stretch justify-content-start mb-3'>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "User")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="user"
                                placeholder={translate(props.lang, "User")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.user}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`} style={{ background: formData.overtime_status === "pending" ? "rgba(255,209,67,1)" : formData.overtime_status === "rejected" ? "rgba(255,45,32, 0.8)" : "rgba(111,212,31,0.9)" }}>
                        <span className={`fw-semibold px-3 fs-9 d-block mt-2`}>{translate(props.lang, "Status")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="overtime_status"
                                placeholder={translate(props.lang, "Status")}
                                className="form-control input-transparent py-1 px-3 text-capitalize"
                                value={translate(props.lang, formData.overtime_status) + (formData.overtime_status === 'rejected' ? formData.approval_reject_message : '')}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="project"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.project ? formData.project : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="task"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.task ? formData.task : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Datetime")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="start"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.start ? formData.start : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "End Datetime")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="end"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.end ? formData.end : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Duration")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="duration"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.duration ? (minutesToHour(formData.duration, props.lang)) : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="description"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.description ? formData.description : ''}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className="font-16 fw-bold text-grey-2 d-block mb-4">
                            {translate(props.lang, "Overtime Approval Log")}
                        </span>
                        {
                            overtimeLogs && overtimeLogs?.length > 0 ?
                                <OTApprovalLog overtimeLogs={overtimeLogs} lang={props.lang} />
                                : <NoResultFound />
                        }
                    </Col>

                    {formData.overtime_status === "rejected" ?
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className="font-16 fw-bold text-grey-2">
                                {translate(props.lang, "Remark")} <span className="font-12 fw-500 text-red">({translate(props.lang, "Required if rejected")})</span>
                            </span>
                            <div className={`groupLabel-textarea mt-2 mb-1`}>
                                <textarea
                                    rows="7"
                                    placeholder={translate(props.lang, "Enter remark")}
                                    className="form-control input-transparent py-1 px-3"
                                    name="remark"
                                    value={remark}
                                    readOnly
                                />
                            </div>
                        </Col> : null
                    }
                </Row>
            </div>
        </div >
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(OTDetailsView);
