import DateFnsUtils from "@date-io/date-fns";
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from '@tinymce/tinymce-react';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import moment from 'moment';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { logout } from "store/actions/AuthActions";
import Swal from 'sweetalert2';
import { api, attachmentUrl } from 'utils/api';
import { dateButtonTextMap, priorityList, stageList, statusList } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getSweelAlertBg, getSweelAlertTextColor, isValidUrl } from 'utils/function';
import { translate } from 'utils/translate';

const localeMap = {
    en: enLocale,
    zh: zhCNLocale,
};

const EditTask = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useAppContext();
    let errorObj = {
        name: '',
        start_date: '',
        due_date: '',
        estimated_delivery_date: '',
        assigned: '',
        taskRefer: '',
        projectCategory: '',
        description: '',
        progress_status: '',
        outcome_link: '',
        projectId: '',
        remark: '',
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [language, setLanguage] = useState('US');
    const [locale, setLocale] = useState('en');
    const [projectList, setProjectList] = useState([]);
    const [search_name, setSearchName] = useState('');
    const [parentTask, setParentTask] = useState(null);
    const [workerList, setWorkerList] = useState([]);
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loadingAssignee, setLoadingAssignee] = useState(false);
    const [attachmentPreview, setAttachmentPreview] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        start_date: null,
        due_date: null,
        assigned: [],
        taskRefer: '',
        projectCategory: '',
        outcome_link: '',
        progress_status: 'draft',
        description: '',
        projectId: '',
        quantity: 1,
        estimated_delivery_date: null,
        remark: '',
    })
    const { getRootProps: getAttachmentRootProps, getInputProps: getAttachmentInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(uploadedFiles.concat(acceptedFiles));
            // Call your backend API endpoint to upload files
        },
        multiple: true
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Task') },
        { label: translate(props.lang, 'Edit Task'), active: true }
    ]

    useEffect(() => {
        if (props.lang === 'ZH') {
            setLanguage('zh_CN')
        } else {
            setLanguage('US')
        }
        setLocale(props.lang.toLowerCase())
    }, [props.lang])


    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_project_tasks_details + '/' + props.projectId + '/' + props.id + '?details=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    if (responseJson.data.task.length > 0) {
                        // setTaskInfo(responseJson.data.task[0])
                        setContent(responseJson.data.task[0].description);
                        let newFormData = { ...formData };
                        newFormData.projectId = responseJson.data.project_id;
                        newFormData.name = responseJson.data.task[0].name;
                        newFormData.projectCategory = responseJson.data.task[0].project_category_assigned.project_category.project_category_id;
                        newFormData.description = responseJson.data.task[0].description ? responseJson.data.task[0].description : '';
                        newFormData.start_date = responseJson.data.task[0].start_date ? responseJson.data.task[0].start_date : '';
                        newFormData.due_date = responseJson.data.task[0].due_date ? responseJson.data.task[0].due_date : '';
                        newFormData.estimated_delivery_date = responseJson.data.task[0].estimated_delivery_date ? responseJson.data.task[0].estimated_delivery_date : '';
                        newFormData.progress_status = responseJson.data.task[0].progress_status;
                        newFormData.outcome_link = responseJson.data.task[0].outcome_link;
                        newFormData.taskRefer = responseJson.data.task[0].task_refer_id;
                        newFormData.remark = responseJson.data.task[0].remark;
                        newFormData.stage = responseJson.data.task[0].stage;
                        if (responseJson.data.task[0].attachment) {
                            setAttachmentPreview(responseJson.data.task[0].attachment)
                        }

                        let picArr = [];
                        responseJson.data.task[0].project_worker.map((item) => {
                            // picArr.push({ worker_id: item.worker.worker_id, name: item.worker.name, employee_number: item.worker.employee_number })
                            picArr.push({ value: item.worker.worker_id, label: item.worker.name + ' (' + item.worker.employee_number + ')' });
                        })
                        setAssigneeList(picArr);

                        setFormData(newFormData);
                    }

                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        // setIsModalOpen(true)
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData }
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.projectId) {
            errorMsg.projectId = "This field is required.";
            check = false;
        }
        if (!formData.projectCategory) {
            errorMsg.projectCategory = "This field is required.";
            check = false;
        }
        if (!formData.quantity) {
            errorMsg.quantity = "This field is required.";
            check = false;
        }
        if (!formData.progress_status) {
            errorMsg.progress_status = "This field is required.";
            check = false;
        }
        if (!formData.stage) {
            errorMsg.stage = "This field is required.";
            check = false;
        }

        if (formData.outcome_link) {
            if (!isValidUrl(formData.outcome_link)) {
                errorMsg.outcome_link = "Invalid URL";
                check = false;
            }
        } else {
            newFormData.outcome_link = ""
        }

        if (!content) {
            errorMsg.description = "This field is required.";
            check = false;
        } else {
            newFormData.description = content;
        }

        for (const key in newFormData) {
            // if (newFormData[key] === '' || newFormData[key] === null) {
            //     delete newFormData[key];
            // }
            if (key === 'project_name') {
                delete newFormData[key];
            }
        }

        let arr = [];
        if (assigneeList.length === 0) {
            // errorMsg.assigneeList = "This field is required.";
            // check = false;
            newFormData.assigned = JSON.stringify(arr);
        } else {
            assigneeList.map((item, i) => {
                arr.push(item.value)
            })
            newFormData.assigned = JSON.stringify(arr);
        }
        setErrors(errorMsg);
        if (check) {
            const form = new FormData();
            for (const key in newFormData) {
                form.append(key, newFormData[key]);
            }
            if (uploadedFiles.length > 0) {
                for (const key in uploadedFiles) {
                    form.append('attachment', uploadedFiles[key], encodeURIComponent(uploadedFiles[key].name));
                }
            }
            setLoading(true);
            fetch(api.edit_project_task + '/' + props.projectId + '/' + props.id, {
                method: "PUT",
                headers: new Headers({
                    "Authorization": "Bearer " + props.auth.auth.token,
                }),
                body: form
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        props.getList();
                        props.closePanel();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const handleChangeWorker = (e, type) => {
        if (type === 'assignee') {
            setAssigneeList(e);
        }
    }

    const searchNameRef = useRef(search_name);

    useEffect(() => {
        searchNameRef.current = search_name;
        if (!searchNameRef.current) {
            setWorkerList([])
        }
    }, [search_name]);

    useEffect(() => {
        setParentTask(props.selectedTask);
        if (props.selectedTask) {
            if (taskTypeList.length > 0) {
                let filterList = taskTypeList.filter(item => item.project_category_id === props.selectedTask.project_category_assigned.project_category.project_category_id);
                if (filterList.length > 0) {
                    setFormData({ ...formData, projectId: props.selectedTask.project.project_id, description: filterList[0].description, projectCategory: props.selectedTask.project_category_assigned.project_category.project_category_id });
                }
            } else {
                setFormData({ ...formData, projectId: props.selectedTask.project.project_id });
            }
        }

    }, [props.selectedTask, taskTypeList])

    useEffect(() => {
        getProject();
        getTaskType();
    }, [props.auth.auth.token])

    const getWorkers = (e) => {

        if (!e) {
            setWorkerList([])
            return;
        }
        setLoadingSearch(true);
        fetch(api.get_workers + `?isSearch=true&name=${e}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingSearch(false);
                if (responseJson.status === "success") {
                    setWorkerList(responseJson.data.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getProject = () => {
        fetch(api.get_project + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setProjectList(responseJson.data.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getTaskType = () => {
        fetch(api.get_project_categories + '?details=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setTaskTypeList(responseJson.data.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const removePreviewFile = (index) => {
        let newUploadedFiles = [...uploadedFiles];
        newUploadedFiles.splice(index, 1);
        setUploadedFiles(newUploadedFiles);
    }

    const removeExistingAttachment = (index) => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Are you sure you want to remove this data?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(api.remove_project_task_attachment + '/' + props.projectId + '/' + props.id, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(attachmentPreview[index])
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setLoading(false)
                        if (responseJson.status === 'success') {
                            let newAttachmentPreview = [...attachmentPreview];
                            newAttachmentPreview.splice(index, 1);
                            setAttachmentPreview(newAttachmentPreview);
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been deleted successfully."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        });
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        if (type === 'projectCategory') {
            let filterList = taskTypeList.filter(item => item.project_category_id === value);
            if (filterList.length > 0) {
                newFormData.description = filterList[0].description
            }
            newFormData[type] = value;
        } else {
            newFormData[type] = value;
        }

        setFormData(newFormData);
    }
    const selectStatus = (val) => {
        let newFormData = { ...formData };
        newFormData.progress_status = val;
        setFormData(newFormData);
    }

    const selectStage = (val) => {
        let newFormData = { ...formData };
        newFormData.stage = val;
        setFormData(newFormData);
    }

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            newFormData[type] = moment(e).format('YYYY-MM-DD');
        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const getColor = (status) => {
        let color = '#fff';
        let filterList = statusList.filter(item => item.value === status);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#f2f2f2';
        }
        return color;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    console.log(formData);

    const getColorStage = (status) => {
        let color = '#fff';
        let filterList = stageList.filter(item => item.value === status);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#000';
        }
        return color;
    }

    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingAssignee) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);
                const hasMore = page < totalPage;
                setLoadingAssignee(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                setLoadingAssignee(false)
                console.error('Error fetching data:', error);
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Task")}</h2>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.projectId ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project")} <span className="text-danger">*</span></span>
                        <div className="input-group-select px-3">
                            <Select
                                options={
                                    projectList.map((item, i) => ({ value: item.project_id, label: item.name, key: i }))
                                }
                                placeholder={translate(props.lang, "Select project")}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                isSearchable={true}
                                name="projectId"
                                isDisabled
                                value={
                                    formData.projectId ? (
                                        projectList.filter(item => item.project_id == formData.projectId).length > 0 ?
                                            (projectList.filter(item => item.project_id == formData.projectId).map((item, i) => ({ value: item.project_id, label: item.name, key: i })))
                                            : null
                                    ) : null
                                }
                                onChange={(e) => handleOptionSelected(e, 'projectId')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.projectId)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter task name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.projectCategory ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task Type")} <span className="text-danger">*</span></span>
                        <div className="input-group-select px-3">
                            <Select
                                options={
                                    taskTypeList.map((item, i) => ({ value: item.project_category_id, label: item.name + " (" + item.organization.name + ") ", key: i }))
                                }
                                placeholder={translate(props.lang, "Select task type")}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                isSearchable={true}
                                isDisabled
                                name="projectCategory"
                                value={
                                    formData.projectCategory ? (
                                        taskTypeList.filter(item => item.project_category_id == formData.projectCategory).length > 0 ?
                                            (taskTypeList.filter(item => item.project_category_id == formData.projectCategory).map((item, i) => ({ value: item.project_category_id, label: item.name + " (" + item.organization.name + ") ", key: i })))
                                            : null
                                    ) : null
                                }
                                onChange={(e) => handleOptionSelected(e, 'projectCategory')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.projectCategory)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.quantity ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quantity")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="quantity"
                                placeholder={translate(props.lang, "Enter quantity")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.quantity}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                                readOnly
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.quantity)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.start_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Date")}</span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    readOnly={false}
                                    format="dd/MM/yyyy"
                                    views={['year', 'month', 'date']}
                                    placeholder={translate(props.lang, "Select start date")}
                                    className={`form-control date input-transparent py-1 px-3`}
                                    value={formData.start_date ? new Date(formData.start_date) : null}
                                    onChange={(e) => onChangeCalendar(e, 'start_date')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.start_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.due_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Actual Delivery Date")}</span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    readOnly={false}
                                    format="dd/MM/yyyy"
                                    views={['year', 'month', 'date']}
                                    placeholder={translate(props.lang, "Select actual delivery date")}
                                    className={`form-control date input-transparent py-1 px-3`}
                                    value={formData.due_date ? new Date(formData.due_date) : null}
                                    onChange={(e) => onChangeCalendar(e, 'due_date')}
                                    clearLabel={dateButtonTextMap[locale].clear}
                                    cancelLabel={dateButtonTextMap[locale].cancel}
                                    okLabel={dateButtonTextMap[locale].ok}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.due_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.estimated_delivery_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Estimate Delivery Date")}</span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    readOnly={false}
                                    format="dd/MM/yyyy"
                                    views={['year', 'month', 'date']}
                                    placeholder={translate(props.lang, "Select estimate delivery date")}
                                    className={`form-control date input-transparent py-1 px-3`}
                                    value={formData.estimated_delivery_date ? new Date(formData.estimated_delivery_date) : null}
                                    onChange={(e) => onChangeCalendar(e, 'estimated_delivery_date')}
                                    clearLabel={dateButtonTextMap[locale].clear}
                                    cancelLabel={dateButtonTextMap[locale].cancel}
                                    okLabel={dateButtonTextMap[locale].ok}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.estimated_delivery_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Status")} <span className="text-danger">*</span></span>
                        <div className={`input-group px-3`}>
                            <Dropdown className="btn-group w-100 mb-1 ">
                                <Dropdown.Toggle
                                    className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                    style={{ background: getColor(formData.progress_status), color: '#fff' }}
                                    data-toggle="dropdown"
                                >
                                    {translate(props.lang, getLabel(formData.progress_status, "status"))}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='w-100'>
                                    {statusList.map((status, x) => (
                                        <Dropdown.Item key={x} as={status.value} onClick={() => selectStatus(status.value)}><span className='cursor-pointer' style={{ color: status.color }}>{translate(props.lang, status.label)}</span></Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Stage")} <span className="text-danger">*</span></span>
                        <div className={`input-group px-3`}>
                            <Dropdown className="btn-group w-100 mb-1">
                                <Dropdown.Toggle
                                    className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between fs-9"
                                    style={{ background: getColorStage(formData.stage), color: '#fff' }}
                                    data-toggle="dropdown"
                                >
                                    {formData.stage ? stageList.filter(item => item.value === formData.stage).length > 0 ? stageList.filter(item => item.value === formData.stage)[0].label : '' : translate(props.lang, "Select stage")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='w-100'>
                                    {stageList.map((status, x) => (
                                        <Dropdown.Item key={x} as={status.value} onClick={() => selectStage(status.value)}><span className='cursor-pointer' style={{ color: status.color }}>{translate(props.lang, status.label)}</span></Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.outcome_link ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Outcome Link")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="outcome_link"
                                placeholder={translate(props.lang, "Enter outcome link")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.outcome_link}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.outcome_link)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.assigned ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Assignee")}</span>
                        <div className={`input-group px-3`}>
                            <AsyncPaginate
                                placeholder={translate(props.lang, "Select option")}
                                loadingMessage={() => translate(props.lang, 'Loading Data')}
                                debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                loadOptions={loadOptions} // Function to load options asynchronously
                                additional={{
                                    page: 1, // Initial page
                                }}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                value={assigneeList}
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={(e) => handleChangeWorker(e, 'assignee')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.assigned)}</span>
                    </div>
                </Col>

                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.remark ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Remark")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="remark"
                                placeholder={translate(props.lang, "Enter remark")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.remark}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.remark)}</span>
                    </div>
                </Col>

                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                    <div className={`groupLabel-input w-100 px-3 ${errors.description ? 'errorBorder' : ''}`}>
                        <Editor
                            key={language}
                            apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                            init={{
                                language: language,
                                language_load: true,
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            }}
                            initialValue={formData.description}
                            className="w-100"
                            onEditorChange={(content, editor) => {
                                setContent(content);
                            }}
                        />
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.attachment ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attachment")}</span>
                        <div className="px-3">
                            <div {...getAttachmentRootProps()} className={`dropzone ${uploadedFiles.length === 0 ? 'mb-3' : ''}`}>
                                <input {...getAttachmentInputProps()} />
                                <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                                    {translate(props.lang, "Drag your files here")}{' '}
                                    <span className="text-body-secondary">{translate(props.lang, "or")} </span>
                                    <Button variant="link" className="p-0">
                                        {translate(props.lang, "Browse from device")}
                                    </Button>
                                    <br />
                                </div>
                            </div>

                            <div>
                                {attachmentPreview.length > 0 ? (
                                    <div className={`d-block align-items-center w-100 px-0 mx-0 my-3`}>
                                        {attachmentPreview.map((item, number) => (
                                            <div className="py-2 px-1 d-flex align-items-center fileHover">
                                                {item.webp ? (
                                                    <div className="d-flex align-items-center justify-content-start w-100 h-100">
                                                        <a href={`${attachmentUrl + item.webp}`} target="_blank">
                                                            <span>{number + 1}. {item.filename}</span>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-start w-100 h-100">
                                                        <a href={`${attachmentUrl + item.path}`} target="_blank">
                                                            <span>{number + 1}. {item.filename}</span>
                                                        </a>
                                                    </div>
                                                )}
                                                <button className="btn p-0" onClick={() => removeExistingAttachment(number)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>

                            {uploadedFiles.map((file, index) => (
                                <div key={index} className={`${index !== uploadedFiles.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                                    <span className="fs-9 font-weight-400">{index + 1}. {file.name}</span>
                                    <button className="btn p-0" onClick={() => removePreviewFile(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                    </button>
                                </div>
                            ))}
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.attachment)}</span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => props.closePanel()}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditTask);
