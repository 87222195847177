import App from 'App';
import MainLayout from 'layouts/MainLayout';
import AnnouncementList from 'page/Announcement/AnnouncementList';
import EditAnnouncement from 'page/Announcement/EditAnnouncement';
import NewAnnouncement from 'page/Announcement/NewAnnouncement';
import ClaimList from 'page/Claim/ClaimList';
import NewClaim from 'page/Claim/NewClaim';
import ClaimRequestList from 'page/ClaimRequest/ClaimRequestList';
import ClaimSettingList from 'page/ClaimSetting/ClaimSettingList';
import CustomerList from 'page/Customer/CustomerList';
import EditCustomer from 'page/Customer/EditCustomer';
import NewCustomer from 'page/Customer/NewCustomer';
import DashboardOverview from 'page/Dashboard/DashboardOverview';
import DepartmentList from 'page/Department/DepartmentList';
import EditDepartment from 'page/Department/EditDepartment';
import NewDepartment from 'page/Department/NewDepartment';
import ForumList from 'page/Forum/ForumList';
import EditInvoice from 'page/Invoice/EditInvoice';
import InvoiceList from 'page/Invoice/InvoiceList';
import NewInvoice from 'page/Invoice/NewInvoice';
import EditJobPosition from 'page/JobPosition/EditJobPosition';
import JobPositionList from 'page/JobPosition/JobPositionList';
import NewJobPosition from 'page/JobPosition/NewJobPosition';
import EditOrganization from 'page/Organization/EditOrganization';
import NewOrganization from 'page/Organization/NewOrganization';
import OrganizationList from 'page/Organization/OrganizationList';
import PaymentList from 'page/Payment/PaymentList';
import PaymentSettingList from 'page/PaymentSetting/PaymentSettingList';
import NewProject from 'page/Project/NewProject';
import ProjectList from 'page/Project/ProjectList';
import EditQuotation from 'page/Quotation/EditQuotation';
import NewQuotation from 'page/Quotation/NewQuotation';
import QuotationList from 'page/Quotation/QuotationList';
import EditRanking from 'page/Ranking/EditRanking';
import NewRanking from 'page/Ranking/NewRanking';
import RankingList from 'page/Ranking/RankingList';
import Report from 'page/Report/Report';
import NewTask from 'page/Task/NewTask';
import TaskList from 'page/Task/TaskList';
import EditTaskType from 'page/TaskType/EditTaskType';
import NewTaskType from 'page/TaskType/NewTaskType';
import TaskTypeList from 'page/TaskType/TaskTypeList';
import OTList from 'page/Timesheet/OTList';
import TimesheetList from 'page/Timesheet/TimesheetList';
import EditUser from 'page/User/EditUser';
import NewUser from 'page/User/NewUser';
import UserList from 'page/User/UserList';
import UserAnnoucementDetails from 'page/UserAnnouncement/UserAnnoucementDetails';
import UserAnnouncementList from 'page/UserAnnouncement/UserAnnouncementList';
import EditUserGroup from 'page/UserGroup/EditUserGroup';
import NewUserGroup from 'page/UserGroup/NewUserGroup';
import UserGroupList from 'page/UserGroup/UserGroupList';
import EditClaim from 'page/Claim/EditClaim';
import ViewClaimRequest from 'page/ClaimRequest/ViewClaimRequest';
import EditClaimSetting from 'page/ClaimSetting/EditClaimSetting';
import NewClaimSetting from 'page/ClaimSetting/NewClaimSetting';
import PageLoader from 'page/Components/PageLoader';
import Feedback from 'page/Feedback/Feedback';
import FeedbackList from 'page/Feedback/FeedbackList';
import ViewFeedback from 'page/Feedback/ViewFeedback';
import OvertimeRequestDetails from 'page/OvertimeRequest/OvertimeRequestDetails';
import OvertimeRequestList from 'page/OvertimeRequest/OvertimeRequestList';
import EditOvertimeSetting from 'page/OvertimeSetting/EditOvertimeSetting';
import NewOvertimeSetting from 'page/OvertimeSetting/NewOvertimeSetting';
import OvertimeSettingList from 'page/OvertimeSetting/OvertimeSettingList';
import ChangePassword from 'page/Profile/ChangePassword';
import Profile from 'page/Profile/Profile';
import ProjectDetails from 'page/Project/ProjectDetails';
import TimeAttendanceReport from 'page/Report/TimeAttendanceReport';
import TaskDetails from 'page/Task/TaskDetails';
import TimeAttendance from 'page/TimeAttendance/TimeAttendance';
import OTDetailsView from 'page/Timesheet/OTDetailsView';
import Error404 from 'pages/error/Error404';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import QuotationPdf from 'page/Quotation/QuotationPdf';
import InvoicePdf from 'page/Invoice/InvoicePdf';
import RefundList from 'page/Refund/RefundList';

const routes = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        
        children: [
          {
            index: true,
            // element: <DashboardOverview />
            element: (
              <Suspense fallback={
                  <PageLoader />
              }>
                  <DashboardOverview />
              </Suspense>
          )
          },
          {
            path: '/dashboard',
            module: 'dashboard',
            permission: [],
            children: [
              {
                index: true,
                element: <DashboardOverview />,
                permission: [],
              },
            ]
          },
          {
            path: '/change-password',
            module: 'Change Password',
            permission: [],
            children: [
              {
                index: true,
                element: <ChangePassword />,
                permission: [],
              },
            ]
          },
          {
            path: '/profile',
            module: 'Profile',
            children: [
              {
                index: true,
                element: <Profile />,
                permission: [],
              },
            ]
          },
          {
            path: '/user-announcement',
            module: 'announcement',
            permission: [],
            children: [
              {
                index: true,
                element: <UserAnnouncementList />,
                permission: [],
              },
              {
                path: ':id',
                element: <UserAnnoucementDetails />,
                permission: [],
              },
            ]
          },
          {
            path: '/project',
            module: "project",
            permission: ['allProject'],
            children: [
              {
                path: 'overview',
                element: <ProjectList />,
                permission: ['allProject'],
              },
              {
                path: 'new',
                element: <NewProject />,
                permission: ['createProject'],
              },
              {
                path: 'details/:id',
                element: <ProjectDetails />,
                permission: ['allProject'],
              },
            ]
          },
          {
            path: '/forum',
            module: "forum",
            permission: ['allForum'],
            children: [
              {
                path: 'overview',
                element: <ForumList />,
                permission: ['allForum'],
              },
            ]
          },
          {
            path: '/claim',
            module: "claim",
            permission: ['allClaim'],
            children: [
              {
                path: 'overview',
                element: <ClaimList />,
                permission: ['allClaim'],
              },
              {
                path: 'new',
                element: <NewClaim />,
                permission: ['createClaim'],
              },
              {
                path: 'view/:id',
                element: <EditClaim />,
                permission: ['allClaim', 'editClaim'],
              },
            ]
          },
          {
            path: '/claim-setting',
            module: 'Claim Setting',
            permission: ['allClaimSetting'],
            children: [
              {
                path: 'overview',
                element: <ClaimSettingList />,
                permission: ['allClaimSetting'],
              },
              {
                path: 'new',
                element: <NewClaimSetting />,
                permission: ['createClaimSetting'],
              },
              {
                path: 'edit/:id',
                element: <EditClaimSetting />,
                permission: ['editClaimSetting'],
              },
            ]
          },
          {
            path: '/overtime-setting',
            module: "overtime setting",
            permission: ['allOvertimeSetting'],
            children: [
              {
                path: 'overview',
                element: <OvertimeSettingList />,
                permission: ['allOvertimeSetting'],
              },
              {
                path: 'new',
                element: <NewOvertimeSetting />,
                permission: ['createOvertimeSetting'],
              },
              {
                path: 'edit/:id',
                element: <EditOvertimeSetting />,
                permission: ['editOvertimeSetting'],
              },
            ]
          },
          {
            path: '/customer',
            module: 'customer',
            permission: ['allCustomer'],
            children: [
              {
                path: 'overview',
                element: <CustomerList />,
                permission: ['allCustomer'],
              },
              {
                path: 'new',
                element: <NewCustomer />,
                permission: ['createCustomer'],
              },
              {
                path: 'edit/:id',
                element: <EditCustomer />,
                permission: ['editCustomer'],
              },
            ]
          },
          {
            path: '/organization',
            module: 'organization',
            permission: ['allOrganization'],
            children: [
              {
                path: 'overview',
                element: <OrganizationList />,
                permission: ['allOrganization'],
              },
              {
                path: 'new',
                element: <NewOrganization />,
                permission: ['createOrganization'],
              },
              {
                path: 'edit/:id',
                element: <EditOrganization />,
                permission: ['editOrganization', 'removeOrganization'],
              },
            ]
          },
          {
            path: '/time-attendance',
            module: 'worker attendance',
            permission: ['toggleWorkerAttendance'],
            children: [
              {
                index: true,
                element: <TimeAttendance />,
                permission: ['toggleWorkerAttendance']
              },
            ]
          },
          // {
          //   path: '/activity-log',
          //   children: [
          //     {
          //       path: 'overview',
          //       element: <ActivityLogList />
          //     },
          //   ]
          // },
          
          {
            path: '/financial',
            module: 'financial',
            permission: ['allProjectFinancial'],
            children: [
              {
                path: 'setting',
                element: <PaymentSettingList />,
                permission: ['allProjectFinancial']
              },
            ]
          },
          {
            path: '/department',
            module: 'department',
            permission: ['allDepartment'],
            children: [
              {
                path: 'overview',
                element: <DepartmentList />,
                permission: ['allDepartment'],
              },
              {
                path: 'new',
                element: <NewDepartment />,
                permission: ['createDepartment'],
              },
              {
                path: 'edit/:id',
                element: <EditDepartment />,
                permission: ['editDepartment', 'removeDepartment'],
              },
            ]
          },
          {
            path: '/job-position',
            module: 'worker position',
            permission: ['allWorkerPosition'],
            children: [
              {
                path: 'overview',
                element: <JobPositionList />,
                permission: ['allWorkerPosition'],
              },
              {
                path: 'new',
                element: <NewJobPosition />,
                permission: ['createWorkerPosition'],
              },
              {
                path: 'edit/:id',
                element: <EditJobPosition />,
                permission: ['editWorkerPosition', 'removeWorkerPosition'],
              },
            ]
          },
          {
            path: '/ranking',
            module: 'ranking',
            permission: ['allWorkerRanking'],
            children: [
              {
                path: 'overview',
                element: <RankingList />,
                permission: ['allWorkerRanking'],
              },
              {
                path: 'new',
                element: <NewRanking />,
                permission: ['createWorkerRanking'],
              },
              {
                path: 'edit/:id',
                element: <EditRanking />,
                permission: ['editWorkerRanking', 'removeWorkerRanking'],
              },
            ]
          },
          {
            path: '/task-type',
            module: "project category",
            permission: ['allProjectCategory'],
            children: [
              {
                path: 'overview',
                element: <TaskTypeList />,
                permission: ['allProjectCategory'],
              },
              {
                path: 'new',
                element: <NewTaskType />,
                permission: ['createProjectCategory'],
              },
              {
                path: 'edit/:id',
                element: <EditTaskType />,
                permission: ['editProjectCategory', 'removeProjectCategory'],
              },
            ]
          },
          {
            path: '/user',
            module: 'worker',
            permission: ['allWorker'],
            children: [
              {
                path: 'overview',
                element: <UserList />,
                permission: ['allWorker'],
              },
              {
                path: 'new',
                element: <NewUser />,
                permission: ['createWorker'],
              },
              {
                path: 'edit/:id',
                element: <EditUser />,
                permission: ['editWorker', 'removeWorker'],
              },
            ]
          },
          {
            path: '/task',
            module: 'project task',
            permission: ['allProjectTask'],
            children: [
              {
                path: 'overview',
                element: <TaskList />,
                permission: ['allProjectTask'],
              },
              {
                path: 'new',
                element: <NewTask />,
                permission: ['createProjectTask'],
              },
              {
                path: 'details/:id',
                element: <TaskDetails />,
                permission: ['editProjectTask', 'removeProjectTask'],
              },
            ]
          },
          {
            path: '/quotation',
            module: 'project financial',
            permission: ['allProjectFinancial'],
            children: [
              {
                path: 'overview',
                element: <QuotationList />,
                permission: ['allProjectFinancial'],
              },
              {
                path: 'new',
                element: <NewQuotation />,
                permission: ['createProjectFinancial'],
              },
              {
                path: 'details/:id',
                element: <EditQuotation />,
                permission: ['editProjectFinancial', 'removeProjectFinancial'],
              },
              {
                path: 'pdf/:id',
                element: <QuotationPdf />,
                permission: ['allProjectFinancial'],
              },
            ]
          },
          {
            path: '/invoice',
            module: 'project financial',
            permission: ['allProjectFinancial'],
            children: [
              {
                path: 'overview',
                element: <InvoiceList />,
                permission: ['allProjectFinancial'],
              },
              {
                path: 'new',
                element: <NewInvoice />,
                permission: ['createProjectFinancial'],
              },
              {
                path: 'details/:id',
                element: <EditInvoice />,
                permission: ['editProjectFinancial', 'removeProjectFinancial'],
              },
              {
                path: 'pdf/:id',
                element: <InvoicePdf />,
                permission: ['allProjectFinancial'],
              },
            ]
          },
          {
            path: '/payment',
            module: 'project financial',
            permission: ['allProjectFinancial'],
            children: [
              {
                path: 'overview',
                element: <PaymentList />,
                permission: ['allProjectFinancial'],
              },
            ]
          },
          {
            path: '/refund',
            module: 'project financial',
            permission: ['allProjectFinancial'],
            children: [
              {
                path: 'overview',
                element: <RefundList />,
                permission: ['allProjectFinancial'],
              },
            ]
          },
          {
            path: '/claim-request',
            module: 'claim request',
            permission: ['claimRequest'],
            children: [
              {
                path: 'overview',
                element: <ClaimRequestList />,
                permission: ['claimRequest'],
              },
              {
                path: 'view/:id',
                element: <ViewClaimRequest />,
                permission: ['claimRequest'],
              },
            ]
          },
          {
            path: '/user-group',
            module: 'announcement group',
            permission: ['allAnnouncementGroup'],
            children: [
              {
                path: 'overview',
                element: <UserGroupList />,
                permission: ['allAnnouncementGroup'],
              },
              {
                path: 'new',
                element: <NewUserGroup />,
                permission: ['createAnnouncementGroup'],
              },
              {
                path: 'edit/:id',
                element: <EditUserGroup />,
                permission: ['editAnnouncementGroup', 'removeAnnouncementGroup'],
              },
            ]
          },
          {
            path: '/announcement',
            module: 'announcement',
            permission: ['allAnnouncement'],
            children: [
              {
                path: 'overview',
                element: <AnnouncementList />,
                permission: ['allAnnouncement'],
              },
              {
                path: 'new',
                element: <NewAnnouncement />,
                permission: ['createAnnouncement'],
              },
              {
                path: 'edit/:id',
                element: <EditAnnouncement />,
                permission: ['editAnnouncement', 'removeAnnouncement'],
              },
            ]
          },
          {
            path: '/feedback',
            module: 'feedback',
            permission: [''],
            children: [
              {
                index: true,
                element: <Feedback />,
                permission: [''],
              },
              {
                path: 'overview',
                element: <FeedbackList />,
                permission: ['viewFeedbackList'],
              },
              {
                path: 'view/:id',
                element: <ViewFeedback />,
                permission: ['viewFeedbackList'],
              },
            ]
          },
          {
            path: '/timesheet',
            module: 'timesheet',
            permission: ['allTimesheet', 'allOvertime'],
            children: [
              {
                path: 'daily-task',
                element: <TimesheetList />,
                permission: ['allTimesheet'],
              },
              {
                path: 'overtime',
                element: <OTList />,
                permission: ['allOvertime'],
              },
              {
                path: 'overtime/view/:id',
                element: <OTDetailsView />,
                permission: ['allOvertime'],
              },
            ]
          },
          {
            path: '/overtime-request',
            module: 'timesheet',
            permission: ['allTimesheet'],
            children: [
              {
                path: 'overview',
                element: <OvertimeRequestList />,
                permission: ['overtimeRequest'],
              },
              {
                path: 'view/:id',
                element: <OvertimeRequestDetails />,
                permission: ['overtimeRequest'],
              },
            ]
          },
          {
            path: '/report',
            module: 'report',
            permission: ['allReport', 'viewWorkerAllAttendance', 'viewAllWorkerAttendance'],
            children: [
              {
                path: 'timesheet',
                element: <Report />,
                permission: ['allReport'],
              },
              {
                path: 'time-attendance',
                element: <TimeAttendanceReport />,
                permission: ['viewWorkerAllAttendance', 'viewAllWorkerAttendance'],
              },
            ]
          },
          {
            path: '*',
            element: <Error404 />
          }
        ]
      },

      
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
