import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import NoResultFound from 'page/Components/NoResultFound';
import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api, attachmentUrl } from 'utils/api';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
import ClaimRequestLog from './ClaimRequestLog';
import Error404 from 'pages/error/Error404';

const ViewClaimRequest = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    let errorObj = {
        remark: '',
    }
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('')
    const [remark, setRemark] = useState('')
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [claimLogs, setClaimLogs] = useState([]);
    const [itemForm, setItemForm] = useState({
        claim_purchase_date: '',
        description: '',
        quantity: '',
        category: '',
        amount: '',
    })
    const [formData, setFormData] = useState({
        claim_start_period: '',
        claim_end_period: '',
        bank_name: '',
        bank_account: '',
        items: [],
        attachments: [],
        user: '',
        approval_status: '',
        claim_status: '',
        reason: '',
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Claim Request'), url: '/claim-request/overview' },
        { label: translate(props.lang, 'Claim Request Details'), active: true }
    ]

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
        if(props?.auth?.permission?.claimRequest){
            getInfo();
        }
    }, [id,props.auth.permission])

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_claim_request + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    let newFormData = { ...formData };
                    newFormData.bank_name = responseJson.data[0].bank_name;
                    newFormData.bank_account = responseJson.data[0].bank_account;
                    newFormData.claim_start_period = responseJson.data[0].claim_start_period;
                    newFormData.claim_end_period = responseJson.data[0].claim_end_period;
                    newFormData.items = responseJson.data[0].item;
                    newFormData.attachments = responseJson.data[0].attachment;
                    newFormData.user = responseJson.data[0]?.worker?.name;
                    newFormData.approval_status = responseJson.data[0].approval_status;
                    newFormData.claim_status = responseJson.data[0].claim_status;
                    newFormData.reason = responseJson.data[0]?.approval_reject_message;
                    setUploadedFiles(responseJson.data[0].attachment);
                    setFormData(newFormData);
                    setClaimLogs(responseJson.data[0].log);

                    if (responseJson.data[0].item && responseJson.data[0].item.length > 0) {
                        let newFormData2 = { ...itemForm };
                        newFormData2.claim_purchase_date = responseJson.data[0]?.item?.claim_purchase_date;
                        newFormData2.description = responseJson.data[0]?.item?.description;
                        newFormData2.quantity = responseJson.data[0]?.item?.quantity;
                        newFormData2.category = responseJson.data[0]?.item?.category;
                        newFormData2.amount = responseJson.data[0]?.item?.amount;
                        setItemForm(newFormData2);

                        let total = 0;
                        (responseJson.data[0]?.item).forEach(item => {
                            total += Number(item.amount);
                        })
                        setTotal(total);
                    }
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = (action) => {
        let check = true;
        let errorMsg = { ...errorObj }

        if (action === 'rejected' && !remark) {
            errorMsg.remark = "This field is required.";
            check = false
        }
        setErrors(errorMsg);
        if (check) {
            let data = {
                status: action,
                remark: remark
            }
            setLoading(true)
            fetch(api.approval_claim_request + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        resetForm();
                        navigate('/claim-request/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }


    const resetForm = () => {
        setFormData({
            claim_start_period: '',
            claim_end_period: '',
            bank_name: '',
            bank_account: '',
            items: [],
            attachments: [],
        })

        setItemForm({
            claim_purchase_date: '',
            description: '',
            quantity: '',
            category: '',
            amount: '',
        })
    }

    return (
        props?.auth?.permission?.claimRequest ? (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Claim Request Details")}</h2>
            <Row className='align-items-stretch justify-content-start mb-3'>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "User")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="user"
                                placeholder={translate(props.lang, "User")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.user}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 text-white`} style={{ background: formData.claim_status === "pending" ? "orange" : formData.claim_status === "rejected" ? "red" : "green" }}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Status")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="approval_status"
                                placeholder={translate(props.lang, "Status")}
                                className="form-control input-transparent py-1 px-3 text-capitalize text-white"
                                value={translate(props.lang, formData.approval_status)}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="bank_name"
                                placeholder={translate(props.lang, "Bank Name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.bank_name}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account No.")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="bank_account"
                                placeholder={translate(props.lang, "Bank Account No.")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.bank_account}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Period Start From")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="claim_start_period"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.claim_start_period ? formData.claim_start_period : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Period End From")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="claim_end_period"
                                className="form-control input-transparent py-1 px-3"
                                value={formData.claim_end_period ? formData.claim_end_period : null}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className='groupLabel-input p-2'>
                        <div className='table-responsive'>
                            <Table responsive className="w-100 table-list position-relative">
                                <thead>
                                    <tr>
                                        {/* <th className='py-2 fw-bold fs-8 px-0'>{translate(props.lang, "No.")}</th> */}
                                        <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Date")}</th>
                                        <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Description")}</th>
                                        <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Quantity")}</th>
                                        <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Category")}</th>
                                        <th className='py-2 fw-bold fs-8'>{translate(props.lang, "Cost")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData.items.length > 0 ? (
                                            <>
                                                {formData.items.map((item, i) => (
                                                    <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                        {/* <td className={`fw-normal fs-9 lh-base py-2 text-capitalize`}>{i + 1}.</td> */}
                                                        <td className={`fw-normal fs-9 lh-base py-2`}>{item.claim_purchase_date}</td>
                                                        <td className={`fw-normal fs-9 lh-base py-2`}>{item.description}</td>
                                                        <td className={`fw-normal fs-9 lh-base py-2`}>{item.quantity}</td>
                                                        <td className={`fw-normal fs-9 lh-base py-2`}>{item.category}</td>
                                                        <td className={`fw-normal fs-9 lh-base py-2`}>{item.amount}</td>
                                                    </tr>
                                                ))}
                                                <tr role="row" className='bg-body-secondary'>
                                                    <td colSpan={4} className='text-start fw-bold fs-8 lh-base py-2 px-2'>
                                                        <span>{translate(props.lang, "Total")}</span>
                                                    </td>
                                                    <td colSpan={1} className={`text-start fw-bold fs-8 lh-base py-2`}>
                                                        <span>{total}</span>
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr role="row">
                                                <td colSpan={6}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className={`groupLabel-input py-1`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attachment")}</span>
                            <div className="px-3">
                                {uploadedFiles && uploadedFiles?.length > 0 ?
                                    uploadedFiles.map((file, index) => (
                                        <div key={index} className={`${index !== uploadedFiles.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                                            <a href={attachmentUrl + file.path} target='_blank'>
                                                <span className="fs-9 font-weight-400">{index + 1}. {attachmentUrl + file.path}</span>
                                            </a>
                                        </div>
                                    )) :
                                    <NoResultFound />
                                }
                            </div>
                        </div>
                    </Col>

                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className="font-16 fw-bold text-grey-2 d-block mb-4">
                            {translate(props.lang, "Claim Request Log")}
                        </span>
                        <ClaimRequestLog claimLogs={claimLogs} lang={props.lang} />
                    </Col>
                    {formData.claim_status === "pending" ?
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <span className="font-16 fw-bold text-grey-2">
                                {translate(props.lang, "Remark")} <span className="font-12 fw-500 text-red">({translate(props.lang, "Required if rejected")})</span>
                            </span>
                            <div className={`groupLabel-textarea mt-2 mb-1 ${errors.remark ? 'errorBorder' : ''}`}>
                                <textarea
                                    rows="7"
                                    placeholder={translate(props.lang, "Enter remark")}
                                    className="form-control input-transparent py-1 px-3"
                                    name="remark"
                                    value={remark}
                                    onChange={e => setRemark(e.target.value)}
                                />
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.remark)}</span>
                            </div>
                        </Col> : null
                    }

                    {
                        formData.claim_status === "rejected" ?
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`py-1`}>
                                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Reason")} <span className="text-danger">*</span></span>
                                    <div className={`groupLabel-textarea mt-2 mb-1`}>
                                        <textarea
                                            rows="4"
                                            placeholder={translate(props.lang, "Enter remark")}
                                            className="form-control input-transparent py-1 px-3"
                                            name="reason"
                                            value={formData.reason}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </Col> : null
                    }
                </Row>
                {formData.claim_status === "pending" ?
                    <Row className='justify-content-center align-items-center mt-5'>
                        <Col lg={6} md={6} sm={6} xs={6} className='mb-3'>
                            <Button variant="danger" className="w-100" onClick={() => submitForm('rejected')} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Reject")}</span>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='mb-3'>
                            <Button variant="primary" className="w-100" onClick={() => submitForm('approved')} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Approve")}</span>
                            </Button>
                        </Col>
                    </Row>
                    : (
                        <Row className='justify-content-start align-items-center mt-5'>
                            <Col lg={6} md={6} sm={6} xs={6} className='mb-3'>
                                <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/claim-request/overview')}>
                                    {translate(props.lang, "Back")}
                                </Button>
                            </Col>
                        </Row>
                    )
                }
            </div>
        </div>
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ViewClaimRequest);
