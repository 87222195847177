import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faEye, faFileExport, faPlus, faSpinner, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const typeList = [
    { label: 'Organization', value: 'organization' },
    { label: 'Department', value: 'department' },
    { label: 'User', value: 'user' },
]

const UserGroupList = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [productPerpage, setProductPerpage] = useState(10)
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [statistic, setStatistic] = useState(0);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const timerRef = useRef(null);
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });

    let breadcrumbData = [
        { label: translate(props.lang, 'Setting'), url: '#' },
        { label: translate(props.lang, 'User Group'), active: true }
    ]

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            if (props?.auth?.permission?.allAnnouncementGroup) {
                getList();
                setFirstLoad(false)
            }
        }
    }, [props.auth.auth.token, props.auth.permission]);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, name, type, productPerpage]);

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_announcement_groups + "?page=" + page + "&limit=" + productPerpage + '&details=1';
            let search = 0;
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (type) {
                search = 1;
                url = url + `&type=${type}`
            }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        setTotalResultCount(responseJson.data.subs.totalAudienceGroup)
                        let totalPage = Math.ceil(responseJson.data.subs.totalAudienceGroup / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.totalAudienceGroup)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(entry => {
            let formattedData = {
                "Name": entry.name ? entry.name : '-',
                "Type": entry.type ? entry.type : '-',
                "Description": entry.description ? entry.description : '-',
                "Status": entry.status ? entry.status : '-',
            };
            newData.push(formattedData)
        })
        return newData;
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_announcement_groups + "?page=" + pageCount + "&limit=" + exportPageSize + '&details=1';
                let search = 0;
                if (name) {
                    search = 1;
                    url = url + `&name=${name}`
                }
                if (type) {
                    search = 1;
                    url = url + `&type=${type}`
                }
                if (sortBy.sortField && sortBy.sortOrder) {
                    url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.totalAudienceGroup;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, 'user_group')
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, 'User Group')}</h2>
                <div className="mb-4">
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                            <div className="tableBgContainer py-4 px-4 total">
                                <span className="fs-8 fw-bold">{translate(props.lang, "Total User Group")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                <Form.Control
                                    type="text"
                                    placeholder={translate(props.lang, "Search by name")}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    className="input-transparent py-1 px-3"
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                <Select
                                    options={
                                        typeList.map((item, i) => ({ key: i, value: item.value, label: item.label }))
                                    }
                                    placeholder={translate(props.lang, "Select Type")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={typeList.filter(option => option.value === type).length > 0 ? typeList.filter(option => option.value === type).map((item, i) => ({ key: i, value: item.value, label: item.label })) : null}
                                    name="type"
                                    onChange={(e) => setType(e ? e.value : '')}
                                />
                            </div>
                        </Col>
                        {props?.auth?.permission?.allAnnouncementGroup ? (
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                                    {loadingExcel ? (
                                        <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                            {translate(props.lang, "Export")}
                                        </>
                                    )}
                                </Button>
                            </Col>
                        ) : null}
                        {props?.auth?.permission?.createAnnouncementGroup ? (
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="primary" className='w-100 text-center' onClick={() => { navigate('/user-group/new') }}>
                                    <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                    {translate(props.lang, "Add User Group")}
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                </div>
                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <div className='table-responsive'>
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    {/* <th className='py-2 text-center'>{translate(props.lang, "No.")}</th> */}
                                    <th className='py-2 text-center'>{translate(props.lang, "Name")}</th>
                                    <th className='py-2 text-center'>{translate(props.lang, "Type")}</th>
                                    <th className='py-2 text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={3}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result.length > 0 ? (
                                        result.map((item, i) => (
                                            <tr role="row" key={i}>
                                                <td className='fs-9 py-2 text-center'>
                                                    {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                                                    {i + 1 === productPerpage ? '0' : i + 1}.
                                                </td>
                                                <td className='td-text text-center py-2 fs-9'>{item.name}</td>
                                                <td className='td-text text-center py-2 fs-9 text-capitalize'>{item.type}</td>
                                                <td className='py-2 text-center' style={{ minWidth: '100px' }}>
                                                    <Link to={`/user-group/edit/${item.announcement_group_id}`}>
                                                        <FontAwesomeIcon icon={faEdit} color="#839AB4" fontSize={20} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={3}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </Table>
                    </div>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(UserGroupList);