const ZH_CN = {
    "Sign In": "登录",
    "Get access to your account": "访问您的账户",
    "Username": "用户名",
    "Password": "密码",
    "Remember me": "记住我",
    "Forgot Password?": "忘记密码？",
    "This field is required.": "此字段为必填项。",
    "Invalid email or password": "电子邮件或密码无效",
    "Dashboard": "仪表板",
    "Announcement": "公告",
    "Forum": "论坛",
    "Project": "项目",
    "Task": "任务",
    "Timesheet": "时间表",
    "Daily Task": "每日任务",
    "Overtime": "加班",
    "Claim": "报销",
    "Customer": "客户",
    "Time Attendance": "考勤",
    "Activity Log": "活动日志",
    "Finance": "财务",
    "Quotation": "报价",
    "Invoice": "发票",
    "Payment": "付款",
    "Claim Request": "报销请求",
    "Report": "报告",
    "Setting": "设置",
    "Organization": "组织",
    "Department": "部门",
    "Job Position": "职位",
    "Ranking": "排名",
    "User": "用户",
    "Task Type": "任务类型",
    "User Group": "用户组",
    "Financial Setting": "财务设置",
    "Profile": "个人资料",
    "Change Password": "更改密码",
    "Clock In": "打卡上班", //奴隶要好好工作
    "Clock Out": "打卡下班", //终于自由啦
    "Total Organization": "总组织",
    "Error": "错误",
    "No data to export": "无可导出数据",
    "Success": "成功",
    "Are you sure you want to deactivate this data?": "您确定要停用此数据吗？",
    "Are you sure you want to activate this data?": "您确定要激活此数据吗？",
    "Confirmation": "确认",
    "Confirm": "确认",
    "No": "否",
    "No.": "编号",
    "Data has been update successful.": "数据已成功更新。",
    "Search by name": "按名称搜索",
    "Search by code": "按代码搜索",
    "Export": "导出",
    "Organization Name": "组织名称",
    "Organization Code": "组织代码",
    "Results per page": "每页结果数",
    "Add Organization": "添加组织",
    "New Organization": "新组织",
    "Loading Data": "加载数据",
    "Invalid URL, URL must start with https://": "无效的URL，URL必须以https://开头",
    "Invalid email address": "无效的电子邮件地址",
    "Data has been created successfully.": "数据已成功创建。",
    "This organization code has been used.": "此组织代码已被使用。",
    "Enter organization name": "输入组织名称",
    "Organization Code": "组织代码",
    "Enter organization code": "输入组织代码",
    "SSM Number": "SSM编号",
    "Enter SSM number": "输入SSM编号",
    "Tax ID": "税务ID",
    "Enter tax ID": "输入税务ID",
    "Industry": "行业",
    "Enter industry": "输入行业",
    "Business Type": "业务类型",
    "Enter business type": "输入业务类型",
    "Logo": "标志",
    "Drag your files here": "将您的文件拖到此处",
    "Browse from device": "从设备浏览",
    "Billing Address Information": "账单地址信息",
    "Label": "标签",
    "Label address as (Eg: HQ / Penang Branch)": "将地址标记为（例如：总部/槟城分部）",
    "Street Address Line 1": "街道地址第1行",
    "Enter Street Address Line 1": "输入街道地址第1行",
    "Street Address Line 2": "街道地址第2行",
    "Enter Street Address Line 2": "输入街道地址第2行",
    "Postcode": "邮政编码",
    "Enter postcode": "输入邮政编码",
    "City": "城市",
    "Enter city": "输入城市",
    "State": "州/省",
    "Enter state": "输入州/省",
    "Country": "国家",
    "Select country": "选择国家",
    "Attention To": "收件人",
    "Enter attention to": "输入收件人",
    "Email Address": "电子邮件地址",
    "Enter email address": "输入电子邮件地址",
    "Phone Number": "电话号码",
    "Enter phone number": "输入电话号码",
    "Fax Number": "传真号码",
    "Enter fax number": "输入传真号码",
    "Add new address": "添加新地址",
    "Website URL": "网站URL",
    "Enter website URL": "输入网站URL",
    "Currency": "货币",
    "Select currency": "选择货币",
    "Fiscal Year": "财政年度",
    "Select month": "选择月份",
    "Select start date": "选择开始日期",
    "No option available": "无可用选项",
    "Attachment": "附件",
    "Submit": "提交",
    "Cancel": "取消",
    "Sign out": "登出",
    "Oops! No result found...": '没有找到结果...',
    "Edit Organization": "编辑组织",
    "Are you sure you want to remove this data?": "您确定要删除此数据吗？",
    "Data has been deleted successfully.": "数据已成功删除。",
    "Edit": "编辑",
    "Disable": "禁用",
    "Enable": "启用",
    "General": "常规",
    "Payment Information": "付款信息",
    "Description": "描述",
    "Name": "名称",
    "Add New Data": "添加新数据",
    "Edit Payment Method": "编辑付款方式",
    "Add Payment Method": "添加付款方式",
    "Payment Type": "付款类型",
    "Select payment type": "选择付款类型",
    "Enter description": "输入描述",
    "Close": "关闭",
    "Collapsed View": "折叠视图",
    "Total Department": "总部门",
    "Search by Organization Name": "按组织名称搜索",
    "Add Department": "添加部门",
    "Department Name": "部门名称",
    "Department Code": "部门代码",
    "New Department": "新部门",
    "Select organization": "选择组织",
    "Enter department name": "输入部门名称",
    "Enter department code": "输入部门代码",
    "Edit Department": "编辑部门",
    'Job Position': "职位",
    "Total Job Position": "总职位",
    "Add Job Position": "添加职位",
    "New Job Position": "新职位",
    "Job Position Name": "职位名称",
    "Enter job position name": "输入职位名称",
    "Job Scope": '工作范围',
    "Enter job scope": "输入工作范围",
    "Edit Job Position": "编辑职位",
    "Total Ranking": "总排名",
    "Add Ranking": "添加排名",
    "Code": "代码",
    "Level": "级别",
    "New Ranking": "新排名",
    "Ranking Name": "排名名称",
    "Enter ranking name": "输入排名名称",
    "Ranking Code": "排名代码",
    "Enter ranking code": "输入排名代码",
    "Ranking Level": "排名级别",
    "Enter ranking level": "输入排名级别",
    "Permission": "权限",
    "Select All": "全选",
    "Edit Ranking": "编辑排名",
    "Total Task Type": "总任务类型",
    "Add Task Type": "添加任务类型",
    "New Task Type": "新任务类型",
    "Task Type Name": "任务类型名称",
    "Enter task type name": "输入任务类型名称",
    "Requirement": "要求",
    "Edit Task Type": "编辑任务类型",
    "List": "列表",
    "Total Time Attendance": "总考勤",
    "Clock In Date": "签到日期",
    "Clock In Time": "签到时间",
    "Clock Out Date": "签退日期",
    "Clock Out Time": "签退时间",
    "Total Hours Worked": "总工作时间",
    "Total User Group": "总用户组",
    "Select Type": "选择类型",
    "Add User Group": "添加用户组",
    "Type": "类型",
    "New User Group": "新用户组",
    "User Group Name": "用户组名称",
    "Enter user group name": "输入用户组名称",
    "Target Audience Type": "目标受众类型",
    "Select type": "选择类型",
    "Select department": "选择部门",
    "Select user": "选择用户",
    "User": "用户",
    "Edit User Group": "编辑用户组",
    "Target Audience": "目标受众",
    "Total Announcement": "总公告",
    "Search by Title": "按标题搜索",
    "Add Announcement": "添加公告",
    "Title": "标题",
    "Release Date": "发布日期",
    "Target Audience Group": "目标受众组",
    "New Announcement": "新公告",
    "All": "全部",
    "Enter title": "输入标题",
    "Select audience type": "选择受众类型",
    "Audience Group": "受众组",
    "Publish Date": "发布日期",
    "Enter publish date": "输入发布日期",
    "Image": "图片",
    "File": "文件",
    "Edit Announcement": "编辑公告",
    "Customer Note": "客户备注",
    "Enter customer note": "输入客户备注",
    "Payment Terms and Condition": "付款条款和条件",
    "Enter payment terms and condition": "输入付款条款和条件",
    "Payment Term": "付款条款",
    "Payment Method": "付款方式",
    "Payment Name": "付款名称",
    "Enter payment name": "输入付款名称",
    "Edit Payment Term": "编辑付款条款",
    "Add Payment Term": "添加付款条款",
    "Payment Term Name": "付款条款名称",
    "Enter payment term name": "输入付款条款名称",
    "Add Claim Approval Setting": "添加报销审批设置",
    "Total Claim Approval Setting": "总报销审批设置",
    "Claim Approval Setting": "报销审批设置",
    "New Claim Approval Setting": "新报销审批设置",
    "Layer": "层级",
    "Approver": "审批人",
    "Edit Approver": "编辑审批人",
    "Add Approver": "添加审批人",
    "Enter layer": "输入层级",
    "Either one of these approver in this layer can approve/reject request.": "该层级中的任何一个审批人都可以批准/拒绝请求。",
    "Select approver": "选择审批人",
    "Edit Claim Approval Setting": "编辑报销审批设置",
    "users view this announcement": "位用户查看此公告",
    "Post by": "发帖者",
    "Details": "详情",
    "Posted at": "发表于",
    "Please write down your opinion": "请写下您的意见",
    "Confirm delete data? Action cannot be revert.": "确认删除数据？此操作无法撤销。",
    "Write down what you think about your company/job/colleagues": "写下您对公司/工作/同事的看法",
    "Delete": "删除",
    "Comments": "评论",
    "Like": "点赞",
    "Anonymous": "匿名",
    "Edit Forum": "编辑论坛",
    "Total Project": "总项目",
    "Active Project": "进行中的项目",
    "Onqueue Project": "排队中的项目",
    "Completed Project": "已完成的项目",
    "Cancelled Project": "已取消的项目",
    "Overdue Project": "逾期的项目",
    "Search by project name": "按项目名称搜索",
    "Search by customer name": "按客户名称搜索",
    "Search by person in charge": "按负责人搜索",
    "Search by label": "按标签搜索",
    "Select status": "选择状态",
    "Select priority": "选择优先级",
    "Add Project": "添加项目",
    "Project Name": "项目名称",
    "Status": "状态",
    "Completion": "完成情况",
    "Start Date": "开始日期",
    "Due Date": "到期日期",
    "Creator": "创建者",
    "View": "查看",
    "Client": "客户",
    "Person In Charge": "负责人",
    "Priority": "优先级",
    "Submit Feedback Form": "提交反馈表",
    "Form": "表格",
    "Data has been submit successful.": "数据已提交成功。",
    'Draft': "草稿",
    "On Going": "进行中",
    "Completed": "已完成",
    "On Queue": "在队列中",
    "Maintenance": '维护',
    "QC": "质量控制",
    "Execution": "执行",
    "Cancelled": '取消',
    "Pending Feedback": "待反馈",
    "Feedback": "反馈",
    "Select assignee": '选择受托人',
    "End Date": "结束日期",
    "Worker": "工人",
    "Daily Log": "每日日志",
    "Time Period": "时间段",
    "Submitted Datetime": "提交日期时间",
    "From": "从",
    "To": "至",
    "Total Overtime": "总加班",
    "Date": "日期",
    "Remark": '备注',
    "pending": "待处理",
    "approved": "已批准",
    "rejected": "已拒绝",
    "Total Claim": '总报销',
    "Add Claim": "添加报销",
    "Claim Period": "索赔期",
    "Amount": "金额",
    "Bank Name": '银行名称',
    "Bank Account": '银行账户',
    "None": "无",
    'Low': "低",
    "Medium": '中',
    "High": "高",
    "New Project": '新项目',
    "Start date cannot bigger than due date": "开始日期不能大于截止日期",
    "Enter project name": "输入项目名称",
    "Select client": "选择客户",
    "Select due date": "选择截止日期",
    "Enter project cost": "输入项目成本",
    "Select label": "选择标签",
    "Sales Owner": "销售负责人",
    "Select option": "选择选项",
    "Sub Sales": "辅助销售",
    "Project Cost": "项目成本",
    "Total Claim Request": "总报销请求",
    "Search by User Name": "按用户姓名搜索",
    "Claim Request Details": "报销请求详细信息",
    "Bank Account No.": "银行户口号码",
    "Period Start From": '期间开始于',
    "Period End From": '期间结束时间',
    "Quantity": "数量",
    "Category": "类别",
    "Cost": "成本",
    "Total": "总计",
    "Enter remark": "输入备注",
    "Required if rejected": "如果被拒绝则必填",
    "Reject": "拒绝",
    "Approve": "批准",
    "Claim Request Log": "索赔请求日志",
    "Total User": "总用户",
    "Search by username": "按用户名搜索",
    "Search by full name": "按姓名搜索",
    "Search by employee ID": "按员工 ID 搜索",
    "Search by organization name": "按组织名称搜索",
    "Search by department name": "按部门名称搜索",
    "Search by position name": "按位置名称搜索",
    "Add User": "添加用户",
    "Full Name": "姓名",
    "Employee ID": "员工 ID",
    "Position": "职位",
    "New User": "新用户",
    "Username must at least 3 characters": "用户名至少需要3个字符",
    "Password must at least 6 characters": "密码至少需要6个字符",
    "Only allow alphanumeric": "只允许字母和数字",
    "Login Account Details": "登录帐户详细信息",
    "Enter username": "输入用户名",
    "Enter password": "输入密码",
    "Personal Information": "个人信息",
    "Full Name as per IC": "与身份证一致的全名",
    "Enter full name": "输入全名",
    "IC Number": "身份证号码",
    "Enter IC number": "输入身份证号码",
    "Phone Code": "电话区号",
    "Select Phone Code": "选择电话区号",
    "Contact Number": "联系电话",
    "Enter contact number": "输入联系电话",
    "Enter email address": "输入电子邮件地址",
    "Date Of Birth": "出生日期",
    "Select date of birth": "选择出生日期",
    "Gender": "性别",
    "Select gender": "选择性别",
    "Nationality": "国籍",
    "Address": "地址",
    "Residency": "居住地",
    "Residency Type": "居住类型",
    "Professional Details": "专业详情",
    "Employee Number": "员工编号",
    "Enter employee number": "输入员工编号",
    "Select job position": "选择职位",
    "Select ranking": "选择级别",
    "Payroll Details": "工资详情",
    "Job Types": "工作类型",
    "Select job type": "选择工作类型",
    "Salary": "工资",
    "Enter salary": "输入工资",
    "Working day": "工作日",
    "Enter working day": "输入工作日",
    "Working hour": "工作时间",
    "Enter working hour": "输入工作时间",
    "EPF Number": "公积金号码",
    "Enter EPF number": "输入公积金号码",
    "SOCSO Number": "社保号码",
    "Enter SOCSO number": "输入社保号码",
    "Enter bank name": "输入银行名称",
    "Bank Account Name": "银行账户名称",
    "Enter bank account name": "输入银行账户名称",
    "Bank Account Number": "银行账号",
    "Enter bank account number": "输入银行账号",
    "Select end date": "选择结束日期",
    "Female": "女",
    "Male": "男",
    "Full-time": "全日制",
    "Part-time": "兼职",
    "Contract": "合同",
    "Internship": "实习",
    "Temporary": "临时",
    "Edit User": "编辑用户",
    "Please wait for data finish loading.": "请等待数据加载完成。",
    "Select report type": "选择报告类型",
    "Sales Person": "销售人员",
    "Estimate Delivery Date": "预计交货日期",
    "Stage": "阶段",
    "Assignee": "受托人",
    "Duration": "持续时间",
    "Cost Per Hour": "每小时成本",
    "Total Cost": "总成本",
    "Estimate Cost": "估计成本",
    "Task Status": "任务状态",
    "Price": "价格",
    "Payment Status": "支付状态",
    "Select project": "选择项目",
    "Edit Project": "编辑项目",
    "Are you sure you want to start this timer?": "您确定要启动这个计时器吗？",
    "Please fill in remark": "请填写备注",
    "Total Task": "总任务",
    "On Going Task": "正在进行的任务",
    "Onqueue Task": "排队任务",
    "Completed Task": "已完成的任务",
    "Cancelled Task": "取消的任务",
    "Pending Feedback Task": "待反馈任务",
    "Search by task name": "按任务名称搜索",
    "Search by assignee name": "按受托人姓名搜索",
    "Select stage": "选择阶段",
    "Select task type": "选择任务类型",
    "Add Task": "添加任务",
    "Time Log": "时间日志",
    "Assigned to Me": "分配给我",
    "New Sub Task": "新子任务",
    "Add Time Log": "添加时间日志",
    "Sub Task": "子任务",
    "New Task": "新任务",
    "Enter task name": "输入任务名称",
    "Enter quantity": "输入数量",
    "Actual Delivery Date": "实际交货日期",
    "Select actual delivery date": "选择实际交货日期",
    "Select estimate delivery date": "选择预计交货日期",
    "Outcome Link": "结果链接",
    "Enter outcome link": "输入结果链接",
    "or": "或",
    "Overtime Approval Setting": "加班审批设置",
    "Total Overtime Approval Setting": "总加班审批设置",
    "Add Overtime Approval Setting": "添加加班审批设置",
    "New Overtime Approval Setting": "新加班审批设置",
    "Edit Overtime Approval Setting": "编辑加班审批设置",
    "Task Name": "任务名称",
    "Assignation": "分配",
    "Timeline": "时间线",
    "Sent to Client": "已发送给客户",
    "Amendment": "修改",
    "Edit Task": "编辑任务",
    "Total Customer": "总客户",
    "Search by email": "按电子邮件搜索",
    "Search by contact no.": "按联系电话搜索",
    "Search by company name": "按公司名称搜索",
    "Add Customer": "添加客户",
    "Contact No.": "联系电话",
    "Company": "公司",
    "New Customer": "新客户",
    "Customer Title": "称呼",
    "Select title": "选择称呼",
    "Customer Name": "客户名称",
    "Enter customer name": "输入客户姓名",
    "Company Profile": "公司简介",
    "Company Name": "公司名称",
    "Enter company name": "输入公司名称",
    "Company SSM Number": "公司 SSM 号码",
    "Enter company ssm number": "输入公司 ssm 号码",
    "Customer Profile": "客户简介",
    "Edit Customer": "编辑客户",
    "Overview": "概述",
    "Task Overview": "任务概述",
    "On Going Projects": "正在进行的项目",
    "Completed Projects": "已完成的项目",
    "On Going Tasks": "正在进行的任务",
    "Completed Tasks": "已完成的任务",
    "Projects Status Report": "项目状态报告",
    "Days": "天",
    "Notifications": "通知",
    "Overdue": "已逾期",
    "Due in": "即将到期",
    "Upcoming in": "即将到来",
    "Overdue Task": "已逾期任务",
    "Due In A Day": "一天到期",
    "Total Time Attendance": "总考勤时间",
    "Employee": "员工",
    "Workflow Overview": "工作流程概述",
    "View Project Details": "查看项目详情",
    "Feedback List": "反馈列表",
    "Total Feedback": "总反馈",
    "Back": "返回",
    "Feedback Details": "反馈详情",
    "Total Quotation": "总报价",
    "Quotation No.": "报价单号.",
    "Subject": "主题",
    "Confirm convert this quotation to invoice?": "确认将此报价单转换为发票吗？",
    "Quotation has been convert to invoice successfully.": "报价单已成功转换为发票。",
    "Created Date": "创建日期",
    "Add Quotation": "添加报价单",
    "Project Task": "项目任务",
    "Project Timesheet": "项目时间表",
    "Project Overview": "项目概况",
    "Task Status Report": "任务状态报告",
    "Started": "开始",
    "Deadline": "截止日期",
    "Progress": "进展",
    "Budget": "预算",
    "Company Involved": "参与公司",
    "Workflow": "任务流程",
    "by": "由",
    "update by": '更新由',
    "creation": "创建",
    "Task Cost": "任务成本",
    "Financial Status": "财务状况",
    "No attachment": "无附件",
    "Main Approver": "主要审批人",
    "Each layer must have at least 1 main approver.": "每层必须至少有1个主要审批者。",
    "Hours Limit": "小时限制",
    "Enter hours limit": "输入时间限制",
    "Select Layer": "选择层级",
    "Add Invoice": "添加发票",
    "Total Invoice": "总发票",
    "Invoice No.": "发票号",
    "Search by invoice no.": "按发票号搜索",
    "New Quotation": "新报价",
    "Task code already exist.": "任务代码已存在。",
    "Quotation Details": "报价详情",
    "Enter subject": "输入主题",
    "Quotation Date": "报价日期",
    "Select quotation date": "选择报价日期",
    "Quotation Due Date": "报价到期日",
    "Select quotation due date": "选择报价截止日期",
    "Quotation Delivery Date": "报价交割日期",
    "Select quotation delivery date": "选择报价交割日期",
    "Select payment term": "选择付款期限",
    "Company Details": "公司详情",
    "Select company": "选择公司",
    "Billing Details": "账单详情",
    "Customer's Company": "客户公司",
    "Select address": "选择地址",
    "Quotation Items": "报价项目",
    "Item": "项目",
    "Tax": "税",
    "Discount": "折扣",
    "Total Amount": "总金额",
    "Subtotal": "小计",
    "Edit Quotation Item": "编辑报价项目",
    "Add Quotation Item": "添加报价项目",
    "Select task": "选择任务",
    "Task Code": "任务代码",
    "Enter task code": "输入任务代码",
    "Create custom task": "创建自定义任务",
    "Choose existing task": "选择现有任务",
    "Enter price": "输入价格",
    "Select tax": "选择税费",
    "Enter discount": "输入折扣",
    "Select discount type": "选择折扣类型",
    "percent": "百分比",
    "Convert to Invoice": "转换为发票",
    "Edit Quotation": "编辑报价",
    "Overtime Request": "加班请求",
    "Total Overtime Request": "总加班请求",
    "Overtime Request Details": "加班请求详情",
    "Approval Log": "审批日志",
    "Error: Please add approver at layer {layer}.": "错误：请在层级{layer}添加审批者。",
    "Invalid hour": "小时无效",
    "Eligible to Claim OT": "有资格申请加班",
    "Yes": "是",
    "Enter residency": "输入居住地",
    "Enter residency type": "输入居住类型",
    "active": "活跃",
    "inactive": "不活跃",
    "New Invoice": "新发票",
    "Invoice Details": "发票详情",
    "Invoice Date": "发票日期",
    "Select invoice date": "选择发票日期",
    "Invoice Due Date": "发票到期日期",
    "Select invoice due date": "选择发票到期日期",
    "Invoice Delivery Date": "发票交付日期",
    "Select invoice delivery date": "选择发票交付日期",
    "Invoice Items": "发票项目",
    "Edit Invoice Item": "编辑发票项目",
    "Add Invoice Item": "添加发票项目",
    "amount": "金额",
    "Edit Invoice": "编辑发票",
    "Total Payment": "总付款",
    "Search by payment no.": "按付款号码搜索",
    "Add Payment": "添加付款",
    "Payment No.": "付款号",
    "Back to login": "返回登录",
    "Email Verification Code": "邮箱验证码",
    "Enter email verification code": "输入邮箱验证码",
    "Enter new login password": "输入新的登录密码",
    "New Login Password": "新登录密码",
    "Confirm New Login Password": "确认新登录密码",
    "Confirm password does not match with new login password": "确认密码与新登录密码不匹配",
    'Forgot Password': "忘记密码",
    "Verification code has sent to $email.": "验证码已发送至$email。",
    "Send": "发送",
    "Password has reset successfully": "已重置新登录密码",
    "Revolutionizing & Simplifying Your Business With Us.": "与我们一起革新并简化您的业务。",
    "Start": "开始",
    "End": "结束",
    "New Timesheet": "新时间表",
    "Edit Timesheet": "编辑时间表",
    "Stop Timer": "停止计时器",
    "Select hour": "选择时间",
    "Select minute": "选择分钟",
    "Start Time": "开始时间",
    "End Time": "结束时间",
    "Reason Why You Backdate": "回溯日期的原因",
    "Enter reason": "输入原因",
    "End date cannot smaller than start date": "结束日期不能小于开始日期",
    "End time cannot smaller than start time": "结束时间不能小于开始时间",
    "Cannot enter future date": "无法输入未来日期",
    "View Timesheet": "查看时间表",
    "Overtime Details": "加班詳情",
    "Start Datetime": "开始日期时间",
    "End Datetime": "结束日期时间",
    "Overtime Approval Log": "加班审核日志",
    "by Layer": "按层",
    "Claim Details": "报销詳情",
    "Reason": "原因",
    "hour": "小时",
    "minutes": "分钟",
    "seconds": "秒",
    'tasks': "任务",
    "See tasks": "查看任务",
    "days": "天",
    "Search by quotation no.": "按报价号搜索",
    "Anonymous": "匿名",
    "Report Abuse": "举报滥用",
    "Confirm this action? Action cannot be revert.": "确认此动作？操作无法恢复。",
    "unpaid": "未付款",
    "partialpaid": "部分付费",
    "fullypaid": "全额付款",
    'partialrefund': "部分退款",
    "fullyrefund": "全额退款",
    "Refund": "退款",
    "New Refund": "新退款",
    "payment": "付款",
    "refund": "退款",
    "Payment Date": "付款日期",
    "Payment Remark": "付款备注",
    "Cancel Remark": "取消备注",
    "Void": "无效",
    "Void Payment": "无效付款",
    "voided": "无效的",
    "Total Invoice Amount": "发票总金额",
    "Total Paid Invoice": "已付发票总额",
    "Total Partially Paid Invoice": "部分支付发票总额",
    "Total Unpaid Amount": "未付总额",
    "Total Unpaid Invoice": "未付发票总额",
    "PDF View": "PDF视图",
    "Payment Logs": "付款日志",
    "Refund Date": "退款日期",
    "Select refund date": "选择退款日期",
    "Enter amount": "输入金额",
    "Total Quotation Amount": "报价总额",
    "Download PDF": "下载 PDF",
    "Print": "打印",
    "Refund No.": "退款号",
    "Refund Remark": "退款备注",
    "Total Refund": "退款总额",
    "Void Refund": "无效退款",
    "New": "最新",
}


export default ZH_CN;