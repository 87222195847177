import DateFnsUtils from "@date-io/date-fns";
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import moment from "moment";
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { dateButtonTextMap } from "utils/config";
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const localeMap = {
    en: enLocale,
    zh: zhCNLocale,
};

const NewPaymentRefund = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        amount: '',
        payment_date: '',
        attachment: '',
        remark: '',
    }
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [formData, setFormData] = useState({
        amount: '',
        payment_date: '',
        attachment: null,
        remark: '',
        type: props.type
    })
    let breadcrumbData = [
        { label: props.type === 'payment' ? translate(props.lang, 'Payment') : translate(props.lang, 'Refund'), url: '#' },
        { label: props.type === 'payment' ? translate(props.lang, 'New Payment') : translate(props.lang, 'New Refund'), active: true }
    ]

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(acceptedFiles);
            // Call your backend API endpoint to upload files
        },
        accept: {
            'image/*': []
        },
        multiple: false
    });

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        const form = new FormData();

        if (!formData.payment_date) {
            errorMsg.payment_date = "This field is required.";
            check = false;
        } else {
            form.append('payment_date', formData.payment_date);
        }
        if (!formData.amount) {
            errorMsg.amount = "This field is required.";
            check = false;
        } else {
            form.append('amount', Number(formData.amount));
        }
        if (uploadedFiles.length === 0) {
            errorMsg.attachment = "This field is required.";
            check = false;
        } else {
            form.append('attachment',uploadedFiles[0], encodeURIComponent(uploadedFiles[0].name));
        }
        if(formData.remark){
            form.append('remark', formData.remark);
        }
        if(props.type === 'refund'){
        } else {
            form.append('type', formData.type);
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            let url = ""
            if(props.type === 'refund'){
                url = api.refund_project_financial_payment + `/${props.id}`
            } else {
                url = api.add_project_financial_payment + `/${props.id}`
            }
            fetch(url, {
                method: "PUT",
                headers: new Headers({
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: form
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been submit successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        resetForm();
                        props.closePanel();
                        props.getInfo();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            newFormData[type] = moment(e).format('YYYY-MM-DD');
        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const resetForm = () => {
        setFormData({
            amount: '',
            payment_date: '',
            attachment: null,
            remark: '',
            type: props.type
        });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{props.type === 'payment' ? translate(props.lang, "New Payment") : translate(props.lang, "New Refund")}</h2>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.payment_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{props.type === 'payment' ? translate(props.lang, "Payment Date") : translate(props.lang, "Refund Date")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    format="yyyy-MM-dd"
                                    className="form-control input-transparent px-3"
                                    placeholder={props.type === 'payment' ? translate(props.lang, "Select payment date") : translate(props.lang, "Select refund date")}
                                    value={formData.payment_date ? formData.payment_date : null}
                                    onChange={(date) => onChangeCalendar(date, 'payment_date')}
                                    clearLabel={dateButtonTextMap[locale].clear}
                                    cancelLabel={dateButtonTextMap[locale].cancel}
                                    okLabel={dateButtonTextMap[locale].ok}
                                    views={['year', 'month', 'date']}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.payment_date)}</span>
                    </div>
                </Col><Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.amount ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Amount")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'number'}
                                name="amount"
                                placeholder={translate(props.lang, "Enter amount")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.amount}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                                onWheel={event => event.currentTarget.blur()}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.amount)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.remark ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Remark")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="remark"
                                placeholder={translate(props.lang, "Enter remark")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.remark}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                                onWheel={event => event.currentTarget.blur()}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.remark)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.attachment ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attachment")} <span className="text-danger">*</span></span>
                        <div className="px-3 pb-3">
                            <div {...getRootProps()} className={`dropzone`}>
                                <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                                    {translate(props.lang, "Drag your files here")}{' '}
                                    <span className="text-body-secondary">{translate(props.lang, "or")} </span>
                                    <Button variant="link" className="p-0">
                                        {translate(props.lang, "Browse from device")}
                                    </Button>
                                    <br />
                                </div>
                            </div>
                            {uploadedFiles.map((file, index) => (
                                <div key={index} className={`${index !== uploadedFiles.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between mt-3`}>
                                    <a href={URL.createObjectURL(file)} target="_blank">
                                        <span className="fs-9 font-weight-400">{index + 1}. {file.path}</span>
                                    </a>
                                    <button className="btn p-0" onClick={() => setUploadedFiles([])}>
                                        <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.attachment)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="my-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => props.closePanel()}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewPaymentRefund);
