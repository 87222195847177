import TasksPieChart from 'components/charts/e-charts/TasksPieChart';
import DataLoading from 'page/Components/DataLoading';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { deepCopyArray } from 'utils/function';
import { translate } from 'utils/translate';

const WorkLoads = (props) => {
    const [chartData, setChartData] = useState([
        { value: 0, name: translate(props.lang, 'Draft'), code: 'draft' },
        { value: 0, name: translate(props.lang, 'On Going'), code: 'ongoing' },
        { value: 0, name: translate(props.lang, 'Completed'), code: 'completed' },
        { value: 0, name: translate(props.lang, 'On Queue'), code: 'onqueue' },
        { value: 0, name: translate(props.lang, 'Maintenance'), code: 'maintenance' },
        { value: 0, name: translate(props.lang, 'QC'), code: 'qc' },
        { value: 0, name: translate(props.lang, 'Cancelled'), code: 'cancelled' },
        { value: 0, name: translate(props.lang, 'Pending Feedback'), code: 'pendingfeedback' },
    ])
    const [color, setColor] = useState([ '#8d8d8d', '#02CFFC', '#6FD41F', '#FFB800', '#108C76', '#B972FF', '#FF2D20', '#0C3F99' ])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        if (props.projectInfo) {
            let newArr = deepCopyArray(chartData)
            newArr.forEach(item => {
                let filtered = props.projectInfo.task.filter(pt => pt.progress_status === item.code);
                item.value = filtered.length;
            })
            setChartData(newArr);
        }
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [props.projectInfo, props.lang])

    return (
        <div>
            <div className="mb-5">
                <h4 className="text-body-emphasis">{translate(props.lang, "Task Status Report")}</h4>
            </div>
            {loading ? (
                <DataLoading theme='light' />
            ) : (
                <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={4}>
                        {chartData.map((item, i) => (
                            <div className="d-flex align-items-center mb-1" key={i}>
                                <span className={`d-inline-block bullet-item me-2`} style={{ background: color[i] }} />
                                <p className="mb-0 fw-bold text-body lh-sm flex-1 fs-9 text-uppercase">
                                    {item.name} ({item.value})
                                </p>
                            </div>
                        ))}
                    </Col>
                    <Col xs={12} md={8}>
                        <div className="position-relative mb-sm-4 mb-xl-0">
                            <TasksPieChart data={chartData} title={translate(props.lang, 'Task')}/>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(WorkLoads);