import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { titleList } from 'utils/config';
import { faPlus, faSpinner, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import countries from 'utils/countries';
import phoneCodeList from 'utils/phoneCodeList';
import { useAppContext } from 'providers/AppProvider';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import NoResultFound from 'page/Components/NoResultFound';
import { logout } from 'store/actions/AuthActions';
import Error404 from 'pages/error/Error404';

const EditCustomer = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { id } = useParams();
    let errorObj = {
        name: null,
        phone: null,
        email: null,
        currency: '',
        companyName: '',
        title: '',
        ssm: '',
    }
    const { config } = useAppContext();
    const [errors, setErrors] = useState(errorObj);
    const addressMandatoryField = { name: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '' }
    const [errorAddress, setErrorAddress] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('general');
    const [removeAddressList, setRemoveAddressList] = useState([]);
    const [errorBankAccount, setErrorBankAccount] = useState([{ bank_name: '', account_number: '', account_name: '' }]);
    const [bankAccountList, setBankAccountList] = useState([]);
    const [removeBankList, setRemoveBankList] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        currency: 'MYR',
        companyName: '',
        title: 'Mr',
        ssm: '',
        address: [],
        country_code: '',
    })
    const [customerInfo, setCustomerInfo] = useState(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Customer'), url: '/customer/overview' },
        { label: translate(props.lang, 'Edit Customer'), active: true }
    ]
    useEffect(() => {
        if (props?.auth?.permission?.allCustomer) {
            getInfo();
        }
    }, [id, props.auth.permission]);

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_customer_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setCustomerInfo(responseJson.data)
                    let newFormData = { ...formData };
                    newFormData.name = responseJson.data.name;
                    newFormData.phone = responseJson.data.phone;
                    newFormData.email = responseJson.data.email;
                    newFormData.title = responseJson.data.title;
                    newFormData.currency = responseJson.data.currency;
                    newFormData.ssm = responseJson.data.ssm;
                    newFormData.country_code = responseJson.data.country_code;
                    newFormData.companyName = responseJson.data.customer_company_name;
                    let newAddress = [];
                    let newErrorAddress = []
                    if (responseJson.data.address.length > 0) {
                        for (var i = 0; i < responseJson.data.address.length; i++) {
                            let format = { name: '', attention: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '', phone: '', email: '', fax: '' };
                            format.name = responseJson.data.address[i].name;
                            format.address1 = responseJson.data.address[i].address1;
                            format.address2 = responseJson.data.address[i].address2;
                            format.town = responseJson.data.address[i].town;
                            format.postcode = responseJson.data.address[i].postcode;
                            format.state = responseJson.data.address[i].state;
                            format.country = responseJson.data.address[i].country;
                            format.customer_address_id = responseJson.data.address[i].customer_address_id;
                            format.attention = responseJson.data.address[i].attention;
                            format.email = responseJson.data.address[i].email;
                            format.fax = responseJson.data.address[i].fax;
                            format.phone = responseJson.data.address[i].phone;
                            format.country_code = responseJson.data.address[i].country_code;
                            newAddress.push(format);
                            newErrorAddress.push({ name: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '' });
                        }
                        setErrorAddress(newErrorAddress);
                        newFormData.address = newAddress;
                    }
                    setFormData(newFormData);

                    let newBank = [];
                    let newErrorBank = []
                    if (responseJson.data.bank_account.length > 0) {
                        for (var i = 0; i < responseJson.data.bank_account.length; i++) {
                            let format = { bank_name: '', account_name: '', account_number: '', customer_bank_id: '' };

                            format.bank_name = responseJson.data.bank_account[i].bank_name;
                            format.account_name = responseJson.data.bank_account[i].account_name;
                            format.account_number = responseJson.data.bank_account[i].account_number;
                            format.customer_bank_id = responseJson.data.bank_account[i].customer_bank_id;
                            newBank.push(format);
                            newErrorBank.push({ bank_name: '', account_name: '', account_number: '' });
                        }
                        setErrorBankAccount(newErrorBank);
                    }
                    setBankAccountList(newBank);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        if (fieldName === 'name' || fieldName === 'companyName') {
            newFormData[fieldName] = fieldValue.toUpperCase();
        } else {
            newFormData[fieldName] = fieldValue;
        }
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        let regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!formData.title) {
            errorMsg.title = "This field is required.";
            check = false;
        }
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.phone) {
            errorMsg.phone = "This field is required.";
            check = false;
        }
        if (!formData.country_code) {
            errorMsg.country_code = "This field is required.";
            check = false;
        }
        if (formData.email) {
            if (!regEmail.test(formData.email)) {
                errorMsg.email = "Invalid email address";
                check = false;
            }

        }
        // for (const key in newFormData) {
        //     if (newFormData[key] === '') {
        //         delete newFormData[key];
        //     }
        // }
        let addressError = [...errorAddress];
        for (var i = 0; i < newFormData.address.length; i++) {
            Object.keys(addressMandatoryField).map((item, j) => {
                if (!newFormData.address[i][item]) {
                    check = false;
                    addressError[i][item] = "This field is required.";
                } else {
                    addressError[i][item] = "";
                }
            })
        }

        setErrorAddress(addressError)
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_customer + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {

                        if (newFormData.address.length > 0) {
                            fetch(api.edit_customer_address + '/' + customerInfo.customer_id, {
                                method: "PUT",
                                headers: new Headers({
                                    "Content-Type": "application/json",
                                    "Accept-Language": props.lang.toLowerCase(),
                                    "Authorization": "Bearer " + props.auth.auth.token
                                }),
                                body: JSON.stringify(newFormData.address)
                            })
                                .then(response => response.json())
                                .then(responseJson => {
                                    setLoading(false)
                                    if (responseJson.status === 'success') {
                                        if (removeAddressList.length > 0) {
                                            proceedRemoveAddress();
                                        } else {
                                            setLoading(false)
                                            Swal.fire({
                                                icon: "success",
                                                title: translate(props.lang, 'Success'),
                                                text: translate(props.lang, "Data has been update successful."),
                                                background: getSweelAlertBg(), // Dark background color
                                                color: getSweelAlertTextColor(), // Text color
                                            });
                                            setEditable(false);
                                            getInfo();
                                        }
                                    } else {
                                        if (responseJson.message === 'token expired') {
                                            dispatch(logout());
                                            navigate('/')
                                        } else if (responseJson.tokenExpired) {
                                            dispatch(logout());
                                            navigate('/')
                                        } else {
                                            Swal.fire({
                                                icon: "error",
                                                title: translate(props.lang, 'Error'),
                                                text: responseJson.message,
                                                background: getSweelAlertBg(), // Dark background color
                                                color: getSweelAlertTextColor(), // Text color
                                            });
                                        }
                                    }
                                }).catch(error => {
                                    console.error("error", error);
                                });
                        } else {
                            if (removeAddressList.length > 0) {
                                proceedRemoveAddress();
                            } else {
                                setLoading(false)
                                Swal.fire({
                                    icon: "success",
                                    title: translate(props.lang, 'Success'),
                                    text: translate(props.lang, "Data has been update successful."),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                                setEditable(false)
                            }
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const proceedRemoveAddress = () => {
        fetch(api.remove_customer_address + '/' + customerInfo.customer_id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(removeAddressList)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getInfo();
                    setEditable(false);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const removeAddress = (i, item) => {
        let newFormData = { ...formData };
        newFormData.address.splice(i, 1);
        setFormData(newFormData);

        let newList = [...removeAddressList];
        newList.push(item.customer_address_id);
        setRemoveAddressList(prevState => ([...prevState, newList]))

        let newErrorAddress = [...errorAddress];
        newErrorAddress.splice(i, 1);
        setErrorAddress(prevState => ([...prevState, newErrorAddress]))
    }



    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const resetForm = () => {
        setFormData({
            name: '',
            phone: '',
            email: '',
            currency: '',
            companyName: '',
            title: '',
            address: [],
        });
        setErrors(errorObj);
    }

    const handleOptionSelectedAddress = async (e, j) => {
        const { value } = e;
        const newFormData = { ...formData };
        let newAddress = newFormData.address;
        newAddress[j]['country'] = value;
        if (value) {
            let phoneCode = phoneCodeList.filter(item => item.name.toUpperCase() === value.toUpperCase())[0].dial_code;
            newAddress[j]['country_code'] = phoneCode;
        }


        newFormData.address = newAddress;
        setFormData(newFormData);
    }

    const handleAddFormChangeAddress = (e, i) => {
        let capitalLetterField = ['name', 'state', 'town', 'attention', 'address1', 'address2']
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        const newFormData2 = { ...formData };
        if (capitalLetterField.includes(fieldName)) {
            newFormData2.address[i][fieldName] = fieldValue.toUpperCase();
        } else {
            newFormData2.address[i][fieldName] = fieldValue;
        }
        setFormData(newFormData2);
    }

    const addMoreAddress = () => {
        let format = { name: '', attention: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '', phone: '', email: '', fax: '', isBilling: true, country_code: '' };
        let newFormData = { ...formData };
        newFormData.address.push(format);
        setFormData(newFormData);

        let newErrorAddress = [...errorAddress];
        newErrorAddress.push(format);
        setErrorAddress(prevState => ([...prevState, newErrorAddress]))
    }

    const handleAddFormChangeBank = (e, index) => {
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        // const newBankAccountList = [...bankAccountList];
        // newBankAccountList[index][fieldName] = fieldValue;
        // setBankAccountList(newBankAccountList);
        setBankAccountList(
            bankAccountList.map((singleBank, i) =>
                i === index ?
                    fieldName === 'bank_name' ? { ...singleBank, bank_name: fieldValue } :
                        fieldName === 'account_name' ? { ...singleBank, account_name: fieldValue } :
                            fieldName === 'account_number' ? { ...singleBank, account_number: fieldValue } : singleBank : singleBank
            )
        );
    }

    const addMoreBank = () => {
        let format = { bank_name: '', account_name: '', account_number: '' };
        let newBankAccountList = [...bankAccountList];
        setBankAccountList(newBankAccountList.concat(format));

        setErrorBankAccount(prevState => [...prevState, format])
    }

    const removeBank = (i, item) => {
        let newBankAccountList = [...bankAccountList];
        newBankAccountList.splice(i, 1);
        setBankAccountList(newBankAccountList);

        if (item.customer_bank_id) {
            let newList = [...removeBankList];
            newList.push(item.customer_bank_id);
            setRemoveBankList(newList)
        }

        let newErrorBank = [...errorBankAccount];
        newErrorBank.splice(i, 1);
        setErrorBankAccount(newErrorBank)
    }

    const submitBankForm = () => {
        let check = true;
        let newBankError = [];
        let newBankAccountList = [...bankAccountList];
        for (var i = 0; i < newBankAccountList.length; i++) {
            let formatBank = { bank_name: '', account_name: '', account_number: '' };
            Object.keys(newBankAccountList[i]).map((item, j) => {
                if (!newBankAccountList[i][item]) {
                    check = false;
                    formatBank[item] = "This field is required.";
                } else {
                    formatBank[item] = "";
                }
            })
            newBankError.push(formatBank)
        }
        setErrorBankAccount(newBankError)
        if (check) {
            if (newBankAccountList.length === 0 && removeBankList.length > 0) {
                proceedRemoveBank();
            } else {
                setLoading(true);
                fetch(api.edit_customer_bank_account + '/' + customerInfo.customer_id, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(newBankAccountList)
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === 'success') {
                            if (removeBankList.length > 0) {
                                proceedRemoveBank();
                            } else {
                                setLoading(false)
                                Swal.fire({
                                    icon: "success",
                                    title: translate(props.lang, 'Success'),
                                    text: translate(props.lang, "Data has been update successful."),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                                getInfo();
                                setEditable(false);
                            }
                        } else {
                            setLoading(false)
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        }
    }

    const proceedRemoveBank = () => {
        fetch(api.remove_customer_bank_account + '/' + customerInfo.customer_id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(removeBankList)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getInfo();
                    setEditable(false);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        props?.auth?.permission?.editCustomer ? (
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <h2 className="mb-4">{translate(props.lang, "Edit Customer")}</h2>
                <div className='border-bottom mb-3'>
                    <Row className='align-items-center'>
                        <Col lg={6} md={6} sm={6} xs={6} className='text-center py-2'>
                            <span className={`cursor-pointer fw-semibold px-3 fs-9 ${tab === 'general' ? 'text-primary' : 'text-grey-1'}`} onClick={() => setTab('general')}>{translate(props.lang, "General")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='text-center py-2'>
                            <span className={`cursor-pointer fw-semibold px-3 fs-9 ${tab === 'payment' ? 'text-primary' : 'text-grey-1'}`} onClick={() => setTab('payment')}>{translate(props.lang, "Payment Information")}</span>
                        </Col>
                    </Row>
                </div>
                {
                    tab === 'general' ?
                        <>
                            <Row className='align-items-start justify-content-center'>
                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                    <span className="fs-7 fw-semibold">{translate(props.lang, "Customer Profile")}</span>
                                    <hr />
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.title ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer Title")} <span className="text-danger">*</span></span>
                                        <div className="input-group-select px-3">
                                            <Select
                                                options={
                                                    titleList.map((item, i) => ({ key: i, value: item.value, label: item.label }))
                                                }
                                                placeholder={translate(props.lang, "Select title")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                isSearchable={false}
                                                isClearable={false}
                                                value={
                                                    formData.title ? (
                                                        titleList.filter(item => item.value == formData.title).length > 0 ?
                                                            titleList.filter(item => item.value == formData.title).map((item, i) => ({ value: item.value, label: item.label, key: i }))
                                                            : null
                                                    ) : null
                                                }
                                                name="title"
                                                onChange={(e) => handleOptionSelected(e, 'title')}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.title)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer Name")} <span className="text-danger">*</span></span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'text'}
                                                name="name"
                                                placeholder={translate(props.lang, "Enter customer name")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={formData.name}
                                                autoComplete="off"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.phone ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Contact No.")} <span className="text-danger">*</span></span>
                                        <div className='d-flex w-100 justify-content-between align-items-center'>
                                            <div className="input-group-select px-3 w-50">
                                                <Select
                                                    options={
                                                        phoneCodeList.length > 0 ? phoneCodeList.map((item, i) => ({ key: i, value: item.dial_code, label: item.name + " (" + item.dial_code + ")" })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Phone Code")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                    value={
                                                        formData.country_code ? (
                                                            phoneCodeList.filter(singleItem => singleItem.dial_code == formData.country_code).length > 0 ?
                                                                (phoneCodeList.filter(singleItem => singleItem.dial_code == formData.country_code).map((item, i) => ({ value: item.dial_code, label: item.name + " (" + item.dial_code + ")", key: i })))
                                                                : null
                                                        ) : null
                                                    }
                                                    name="country_code"
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    onChange={(e) => handleOptionSelected(e, 'country_code')}
                                                />
                                            </div>
                                            <div className={`input-group w-50`}>
                                                <input
                                                    type={'text'}
                                                    name="phone"
                                                    placeholder={translate(props.lang, "Enter contact number")}
                                                    className="form-control input-transparent py-1 px-3"
                                                    value={formData.phone}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.phone)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.email ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'text'}
                                                name="email"
                                                placeholder={translate(props.lang, "Enter email address")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={formData.email}
                                                autoComplete="off"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.email)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                    <span className="fs-7 fw-semibold">{translate(props.lang, "Company Profile")}</span>
                                    <hr />
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.companyName ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Company Name")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'text'}
                                                name="companyName"
                                                placeholder={translate(props.lang, "Enter company name")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={formData.companyName}
                                                autoComplete="off"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.companyName)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className={`groupLabel-input py-1 ${errors.ssm ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Company SSM Number")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type={'text'}
                                                name="ssm"
                                                placeholder={translate(props.lang, "Enter company ssm number")}
                                                className="form-control input-transparent py-1 px-3"
                                                value={formData.ssm}
                                                autoComplete="off"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.ssm)}</span>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                    <span className="fs-7 fw-semibold">{translate(props.lang, "Billing Address Information")}</span>
                                    <hr />
                                </Col>
                                {formData.address.map((item, j) => (
                                    <Col lg={12} md={12} sm={12} xs={12} key={j} className={`mb-2 ${j !== formData.address.length - 1 ? 'mb-2' : ''}`}>
                                        <div className='border rounded-2 px-2 py-3'>
                                            <Row className="px-0 mx-0 align-items-start">
                                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                    <Row className="px-0 mx-0 align-items-center">
                                                        <Col lg={11} md={11} sm={10} xs={10} className="px-0">
                                                            <div className="text-start">
                                                                <div className={`groupLabel-input py-1`}>
                                                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Label")}</span>
                                                                    <div className={`input-group ${errorAddress[j].name ? 'errorBorder' : ''}`}>
                                                                        <input
                                                                            type='text'
                                                                            name="name"
                                                                            placeholder={translate(props.lang, "Label address as (Eg: HQ / Penang Branch)")}
                                                                            className={`form-control input-transparent py-1 px-3`}
                                                                            value={item.name}
                                                                            autoComplete="off"
                                                                            onChange={e => handleAddFormChangeAddress(e, j)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].name)}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1} md={1} sm={2} xs={2} className="text-end pe-0">
                                                            <div className='cursor-pointer' onClick={() => removeAddress(j, item)}>
                                                                <FontAwesomeIcon icon={faTrashAlt} size={18} color="red" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                    <hr className="my-1" />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].address1 ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Street Address Line 1")} <span className='text-danger'>*</span></span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="address1"
                                                                    placeholder={translate(props.lang, "Enter Street Address Line 1")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.address1}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].address1)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].address2 ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Street Address Line 2")} <span className='text-danger'>*</span></span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="address2"
                                                                    placeholder={translate(props.lang, "Enter Street Address Line 2")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.address2}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].address2)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].postcode ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Postcode")} <span className="text-danger">*</span></span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="postcode"
                                                                    placeholder={translate(props.lang, "Enter postcode")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.postcode}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].postcode)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].town ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "City")} <span className='text-danger'>*</span></span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="town"
                                                                    placeholder={translate(props.lang, "Enter city")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.town}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].town)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].state ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "State")} <span className='text-danger'>*</span></span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="state"
                                                                    placeholder={translate(props.lang, "Enter state")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.state}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].state)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].country ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country")} <span className='text-danger'>*</span></span>
                                                            <div className="input-group-select px-3">
                                                                <Select
                                                                    options={
                                                                        countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: item.name.toLowerCase() })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                                                    }
                                                                    placeholder={translate(props.lang, "Select country")}
                                                                    className="input-transparent w-100 text-capitalize"
                                                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                                    value={
                                                                        item.country ? (
                                                                            countries.filter(singleItem => singleItem.name == item.country.toUpperCase()).length > 0 ?
                                                                                (countries.filter(singleItem => singleItem.name == item.country.toUpperCase()).map((item, i) => ({ value: item.name, label: item.name.toLowerCase(), key: i })))
                                                                                : null
                                                                        ) : null
                                                                    }
                                                                    name="country"
                                                                    onChange={(e) => handleOptionSelectedAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].country)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].attention ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attention To")}</span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="attention"
                                                                    placeholder={translate(props.lang, "Enter attention to")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.attention}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].attention)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].email ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}</span>
                                                            <div className={`input-group`}>
                                                                <input
                                                                    type='text'
                                                                    name="email"
                                                                    placeholder={translate(props.lang, "Enter email address")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.email}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].email)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].phone ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Phone Number")}</span>
                                                            <div className={`input-group`}>
                                                                <div className={`d-flex align-items-center h-100 ${item.country_code ? 'ps-3' : ''}`}>
                                                                    <span className="form-control input-transparent d-flex align-items-center">{item.country_code}</span>
                                                                </div>
                                                                <input
                                                                    type='text'
                                                                    name="phone"
                                                                    placeholder={translate(props.lang, "Enter phone number")}
                                                                    className={`form-control input-transparent py-1 px-3 `}
                                                                    value={item.phone}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].phone)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                    <div className="text-start">
                                                        <div className={`groupLabel-input py-1 ${errorAddress[j].fax ? 'errorBorder' : ''}`}>
                                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Fax Number")}</span>
                                                            <div className={`input-group`}>
                                                                <div className={`d-flex align-items-center h-100 ${item.country_code ? 'ps-3' : ''}`}>
                                                                    <span className="form-control input-transparent d-flex align-items-center">{item.country_code}</span>
                                                                </div>
                                                                <input
                                                                    type='text'
                                                                    name="fax"
                                                                    placeholder={translate(props.lang, "Enter fax number")}
                                                                    className={`form-control input-transparent py-1 px-3`}
                                                                    value={item.fax}
                                                                    autoComplete="off"
                                                                    onChange={e => handleAddFormChangeAddress(e, j)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].fax)}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ))}
                                {formData.address.length === 0 ? (
                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
                                        <div className='border rounded-2 px-2 py-3'>
                                            <NoResultFound />
                                        </div>
                                    </Col>
                                ) : null}
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <Button variant="secondary" className='w-100 text-center h-100' onClick={() => addMoreAddress()}>
                                        <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                        {translate(props.lang, "Add new address")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="my-5">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex justify-content-start gap-3">
                                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                                        </Button>
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/customer/overview')}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row className='justify-content-center align-items-center'>
                                {bankAccountList && bankAccountList.length > 0 ?
                                    bankAccountList.map((item, j) => (
                                        <>
                                            <Col lg={12} md={12} sm={12} xs={12} key={j} className={`mb-2 ${j !== bankAccountList.length - 1 ? 'mb-2' : ''}`}>
                                                <div className='border rounded-2 px-2 py-3'>
                                                    <Row className="px-0 mx-0 align-items-start">
                                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                            <Row className="px-0 mx-0 align-items-center">
                                                                <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                                    <div className="text-start">
                                                                        <span className={`input-label d-block`}>{translate(props.lang, "Bank Account")} {j + 1}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                                    <div className='cursor-pointer' onClick={() => removeBank(j, item)}>
                                                                        <FontAwesomeIcon icon={faTrashAlt} size={18} color="red" />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                            <hr className="my-1" />
                                                        </Col>
                                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                            <div className="text-start">
                                                                <div className={`groupLabel-input py-1 ${errorBankAccount[j].bank_name ? 'errorBorder' : ''}`}>
                                                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")} <span className='text-danger'>*</span></span>
                                                                    <div className={`input-group`}>
                                                                        <input
                                                                            type='text'
                                                                            name="bank_name"
                                                                            placeholder={translate(props.lang, "Enter bank name")}
                                                                            className={`form-control input-transparent py-1 px-3 `}
                                                                            value={item.bank_name}
                                                                            autoComplete="off"
                                                                            onChange={e => handleAddFormChangeBank(e, j)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errorBankAccount[j].bank_name)}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                                            <div className="text-start">
                                                                <div className={`groupLabel-input py-1 ${errorBankAccount[j].account_number ? 'errorBorder' : ''}`}>
                                                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Number")} <span className='text-danger'>*</span></span>
                                                                    <div className={`input-group`}>
                                                                        <input
                                                                            type='text'
                                                                            name="account_number"
                                                                            placeholder={translate(props.lang, "Enter bank account number")}
                                                                            className={`form-control input-transparent py-1 px-3 `}
                                                                            value={item.account_number}
                                                                            autoComplete="off"
                                                                            onChange={e => handleAddFormChangeBank(e, j)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errorBankAccount[j].account_number)}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                            <div className="text-start">
                                                                <div className={`groupLabel-input py-1 ${errorBankAccount[j].account_name ? 'errorBorder' : ''}`}>
                                                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Name")} <span className="text-danger">*</span></span>
                                                                    <div className={`input-group`}>
                                                                        <input
                                                                            type='text'
                                                                            name="account_name"
                                                                            placeholder={translate(props.lang, "Enter bank account name")}
                                                                            className={`form-control input-transparent py-1 px-3 `}
                                                                            value={item.account_name}
                                                                            autoComplete="off"
                                                                            onChange={e => handleAddFormChangeBank(e, j)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errorBankAccount[j].account_name)}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </>
                                    )) : (
                                        <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
                                            <div className='border rounded-2 px-2 py-3'>
                                                <NoResultFound />
                                            </div>
                                        </Col>
                                    )}
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <Button variant='secondary' className='w-100' onClick={() => addMoreBank()}>{translate(props.lang, "Add New Data")}</Button>
                                </Col>
                            </Row>
                            <Row className="my-5">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex justify-content-start gap-3">
                                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitBankForm()} disabled={loading}>
                                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                                        </Button>
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/customer/overview')}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
            </div >
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditCustomer);
