const EN = {
    "Sign In": "Sign In",
    "Get access to your account": "Get access to your account",
    "Username": "Username",
    "Password": "Password",
    "Remember me": "Remember me",
    "Forgot Password?": "Forgot Password?",
    "This field is required.": "This field is required.",
    "Invalid email or password": "Invalid email or password",
    "Dashboard": "Dashboard",
    "Announcement": "Announcement",
    "Forum": "Forum",
    "Project": "Project",
    "Task": "Task",
    "Timesheet": "Timesheet",
    "Daily Task": "Daily Task",
    "Overtime": "Overtime",
    "Claim": "Claim",
    "Customer": "Customer",
    "Time Attendance": "Time Attendance",
    "Activity Log": "Activity Log",
    "Finance": "Finance",
    "Quotation": "Quotation",
    "Invoice": 'Invoice',
    "Payment": "Payment",
    "Claim Request": 'Claim Request',
    "Report": 'Report',
    "Setting": 'Setting',
    "Organization": 'Organization',
    "Department": "Department",
    "Job Position": 'Job Position',
    "Ranking": 'Ranking',
    "User": "User",
    "Task Type": 'Task Type',
    "User Group": 'User Group',
    "Financial Setting": 'Financial Setting',
    "Profile": "Profile",
    "Change Password": "Change Password",
    "Clock In": "Clock In",
    "Clock Out": "Clock Out",
    "Total Organization": "Total Organization",
    "Error": "Error",
    "No data to export": "No data to export",
    "Success": "Success",
    "Are you sure you want to deactivate this data?": "Are you sure you want to deactivate this data?",
    "Are you sure you want to activate this data?": "Are you sure you want to activate this data?",
    "Confirmation": "Confirmation",
    "Confirm": 'Confirm',
    "No": "No",
    "No.": "No.",
    "Data has been update successful.": "Data has been update successful.",
    "Search by name": "Search by name",
    "Search by code": "Search by code",
    "Export": 'Export',
    "Organization Name": "Organization Name",
    "Organization Code": "Organization Code",
    "Results per page": "Results per page",
    "Add Organization": 'Add Organization',
    "New Organization": "New Organization",
    "Loading Data": "Loading Data",
    "Invalid URL, URL must start with https://": 'Invalid URL, URL must start with https://',
    "Invalid email address": "Invalid email address",
    "Data has been created successfully.": "Data has been created successfully.",
    "This organization code has been used.": 'This organization code has been used.',
    "Enter organization name": "Enter organization name",
    "Organization Code": "Organization Code",
    "Enter organization code": "Enter organization code",
    "SSM Number": "SSM Number",
    "Enter SSM number": "Enter SSM number",
    "Tax ID": "Tax ID",
    "Enter tax ID": "Enter tax ID",
    "Industry": "Industry",
    "Enter industry": "Enter industry",
    "Business Type": "Business Type",
    "Enter business type": "Enter business type",
    "Logo": "Logo",
    'Drag your files here': "Drag your files here",
    "Browse from device": "Browse from device",
    "Billing Address Information": "Billing Address Information",
    "Label": "Label",
    "Label address as (Eg: HQ / Penang Branch)": "Label address as (Eg: HQ / Penang Branch)",
    "Street Address Line 1": "Street Address Line 1",
    "Enter Street Address Line 1": "Enter Street Address Line 1",
    "Street Address Line 2": "Street Address Line 2",
    "Enter Street Address Line 2": "Enter Street Address Line 2",
    "Postcode": "Postcode",
    "Enter postcode": "Enter postcode",
    "City": "City",
    "Enter city": "Enter city",
    "State": "State",
    "Enter state": "Enter state",
    "Country": "Country",
    "Select country": "Select country",
    "Attention To": "Attention To",
    "Enter attention to": "Enter attention to",
    "Email Address": "Email Address",
    "Enter email address": 'Enter email address',
    "Phone Number": "Phone Number",
    "Enter phone number": "Enter phone number",
    "Fax Number": "Fax Number",
    "Enter fax number": "Enter fax number",
    "Add new address": "Add new address",
    "Website URL": "Website URL",
    "Enter website URL": "Enter website URL",
    "Currency": "Currency",
    "Select currency": "Select currency",
    "Fiscal Year": "Fiscal Year",
    "Select month": "Select month",
    "Select start date": "Select start date",
    "No option available": "No option available",
    "Attachment": "Attachment",
    "Submit": "Submit",
    "Cancel": "Cancel",
    "Sign out": "Sign out",
    "Oops! No result found...": 'Oops! No result found...',
    "Edit Organization": 'Edit Organization',
    "Are you sure you want to remove this data?": "Are you sure you want to remove this data?",
    "Data has been deleted successfully.": "Data has been deleted successfully.",
    "Edit": "Edit",
    "Disable": "Disable",
    "Enable": "Enable",
    "General": 'General',
    "Payment Information": "Payment Information",
    "Description": 'Description',
    "Name": "Name",
    "Add New Data": "Add New Data",
    "Edit Payment Method": "Edit Payment Method",
    "Add Payment Method": "Add Payment Method",
    "Payment Type": "Payment Type",
    "Select payment type": "Select payment type",
    "Enter description": "Enter description",
    "Close": "Close",
    "Collapsed View": "Collapsed View",
    "Total Department": "Total Department",
    "Search by Organization": "Search by Organization",
    "Add Department": "Add Department",
    "Department Name": "Department Name",
    "Department Code": "Department Code",
    "New Department": "New Department",
    "Select organization": "Select organization",
    "Enter department name": "Enter department name",
    "Enter department code": "Enter department code",
    "Edit Department": "Edit Department",
    'Job Position': 'Job Position',
    "Total Job Position": "Total Job Position",
    "Add Job Position": "Add Job Position",
    "New Job Position": "New Job Position",
    "Job Position Name": "Job Position Name",
    "Enter job position name": "Enter job position name",
    "Job Scope": 'Job Scope',
    "Enter job scope": "Enter job scope",
    "Edit Job Position": "Edit Job Position",
    "Total Ranking": "Total Ranking",
    "Add Ranking": "Add Ranking",
    "Code": "Code",
    "Level": "Level",
    'New Ranking': 'New Ranking',
    "Ranking Name": "Ranking Name",
    "Enter ranking name": "Enter ranking name",
    "Ranking Code": "Ranking Code",
    "Enter ranking code": "Enter ranking code",
    "Ranking Level": "Ranking Level",
    "Enter ranking level": "Enter ranking level",
    "Permission": "Permission",
    "Select All": 'Select All',
    "Edit Ranking": 'Edit Ranking',
    "Total Task Type": "Total Task Type",
    "Add Task Type": "Add Task Type",
    'New Task Type': 'New Task Type',
    "Task Type Name": "Task Type Name",
    "Enter task type name": "Enter task type name",
    "Requirement": "Requirement",
    "Edit Task Type": "Edit Task Type",
    "List": "List",
    "Total Time Attendance": "Total Time Attendance",
    "Clock In Date": "Clock In Date",
    "Clock In Time": 'Clock In Time',
    "Clock Out Date": "Clock Out Date",
    "Clock Out Time": 'Clock Out Time',
    "Total Hours Worked": 'Total Hours Worked',
    "Total User Group": 'Total User Group',
    "Select Type": "Select Type",
    "Add User Group": "Add User Group",
    "Type": 'Type',
    "New User Group": "New User Group",
    "User Group Name": 'User Group Name',
    "Enter user group name": "Enter user group name",
    "Target Audience Type": "Target Audience Type",
    "Select type": 'Select type',
    "Select department": "Select department",
    "Select user": 'Select user',
    "User": "User",
    "Edit User Group": "Edit User Group",
    "Target Audience": "Target Audience",
    "Total Announcement": "Total Announcement",
    "Search by Title": "Search by Title",
    "Add Announcement": "Add Announcement",
    "Title": "Title",
    "Release Date": "Release Date",
    "Target Audience Group": "Target Audience Group",
    "New Announcement": "New Announcement",
    "All": "All",
    "Enter title": "Enter title",
    "Select audience type": "Select audience type",
    "Audience Group": 'Audience Group',
    "Publish Date": 'Publish Date',
    "Enter publish date": "Enter publish date",
    "Image": "Image",
    "File": "File",
    "Edit Announcement": 'Edit Announcement',
    "Customer Note": "Customer Note",
    "Enter customer note": "Enter customer note",
    "Payment Terms and Condition": "Payment Terms and Condition",
    "Enter payment terms and condition": "Enter payment terms and condition",
    "Payment Term": "Payment Term",
    "Payment Method": "Payment Method",
    "Payment Name": "Payment Name",
    "Enter payment name": "Enter payment name",
    "Edit Payment Term": "Edit Payment Term",
    "Add Payment Term": "Add Payment Term",
    "Payment Term Name": "Payment Term Name",
    "Enter payment term name": "Enter payment term name",
    "Add Claim Approval Setting": "Add Claim Approval Setting",
    "Total Claim Approval Setting": "Total Claim Approval Setting",
    "Claim Approval Setting": "Claim Approval Setting",
    "New Claim Approval Setting": "New Claim Approval Setting",
    "Layer": "Layer",
    "Approver": "Approver",
    "Edit Approver": "Edit Approver",
    "Add Approver": "Add Approver",
    "Enter layer": "Enter layer",
    "Either one of these approver in this layer can approve/reject request.": "Either one of these approver in this layer can approve/reject request.",
    "Select approver": "Select approver",
    "Edit Claim Approval Setting": "Edit Claim Approval Setting",
    "users view this announcement": "users view this announcement",
    "Post by": "Post by",
    "Details": "Details",
    "Posted at": "Posted at",
    "Please write down your opinion": "Please write down your opinion",
    "Confirm delete data? Action cannot be revert.": "Confirm delete data? Action cannot be revert.",
    "Write down what you think about your company/job/colleagues": "Write down what you think about your company/job/colleagues",
    "Delete": "Delete",
    "Comments": "Comments",
    "Like": "Like",
    "Anonymous": "Anonymous",
    "Edit Forum": 'Edit Forum',
    "Total Project": "Total Project",
    "Active Project": "Active Project",
    "Onqueue Project": "On Queue Project",
    "Completed Project": "Completed Project",
    "Cancelled Project": "Cancelled Project",
    "Overdue Project": "Overdue Project",
    "Search by project name": "Search by project name",
    "Search by customer name": "Search by customer name",
    "Search by person in charge": "Search by person in charge",
    "Search by label": "Search by label",
    "Select status": "Select status",
    "Select priority": "Select priority",
    "Add Project": "Add Project",
    "Project Name": 'Project Name',
    "Status": "Status",
    "Completion": "Completion",
    "Start Date": "Start Date",
    "Due Date": "Due Date",
    "Creator": "Creator",
    "View": "View",
    "Client": "Client",
    "Person In Charge": "Person In Charge",
    "Priority": "Priority",
    "Submit Feedback Form": "Submit Feedback Form",
    "Form": "Form",
    "Data has been submit successful.": "Data has been submit successful.",
    'Draft': "Draft",
    "On Going": "On Going",
    "Completed": "Completed",
    "On Queue": "On Queue",
    "Maintenance": 'Maintenance',
    "QC": "QC",
    "Execution": "Execution",
    "Cancelled": 'Cancelled',
    "Pending Feedback": "Pending Feedback",
    "Feedback": "Feedback",
    "Select assignee": 'Select assignee',
    "End Date": "End Date",
    "Worker": "Worker",
    "Daily Log": "Daily Log",
    "Time Period": "Time Period",
    "Submitted Datetime": "Submitted Datetime",
    "From": "From",
    "To": "To",
    "Total Overtime": "Total Overtime",
    "Date": "Date",
    "Remark": 'Remark',
    "pending": "pending",
    "approved": "approved",
    "rejected": "rejected",
    "Total Claim": 'Total Claim',
    "Add Claim": "Add Claim",
    "Claim Period": "Claim Period",
    "Amount": "Amount",
    "Bank Name": 'Bank Name',
    "Bank Account": 'Bank Account',
    "None": "None",
    'Low': "Low",
    "Medium": 'Medium',
    "High": "High",
    "New Project": 'New Project',
    "Start date cannot bigger than due date": "Start date cannot bigger than due date",
    "Enter project name": "Enter project name",
    "Select client": "Select client",
    "Select due date": "Select due date",
    "Enter project cost": "Enter project cost",
    "Select label": "Select label",
    "Sales Owner": "Sales Owner",
    "Select option": "Select option",
    "Sub Sales": "Sub Sales",
    "Project Cost": 'Project Cost',
    "Total Claim Request": 'Total Claim Request',
    "Search by User": "Search by User",
    "Claim Request Details": "Claim Request Details",
    "Bank Account No.": "Bank Account No.",
    "Period Start From": 'Period Start From',
    "Period End From": 'Period End From',
    "Quantity": "Quantity",
    "Category": "Category",
    "Cost": "Cost",
    "Total": "Total",
    "Enter remark": "Enter remark",
    "Required if rejected": "Required if rejected",
    "Reject": "Reject",
    "Approve": "Approve",
    "Claim Request Log": "Claim Request Log",
    "Total User": "Total User",
    "Search by username": "Search by username",
    "Search by name": "Search by name",
    "Search by employee ID": "Search by employee ID",
    "Search by organization name": "Search by organization name",
    "Search by department name": "Search by department name",
    "Search by position name": "Search by position name",
    "Add User": "Add User",
    "Full Name": "Full Name",
    "Employee ID": "Employee ID",
    "Position": "Position",
    "New User": "New User",
    "Username must at least 3 characters": "Username must at least 3 characters",
    "Password must at least 6 characters": "Password must at least 6 characters",
    "Only allow alphanumeric": "Only allow alphanumeric",
    "Login Account Details": "Login Account Details",
    "Enter username": 'Enter username',
    "Enter password": "Enter password",
    "Personal Information": "Personal Information",
    "Full Name as per IC": "Full Name as per IC",
    "Enter full name": "Enter full name",
    "IC Number": "IC Number",
    "Enter IC number": "Enter IC number",
    "Phone Code": "Phone Code",
    "Select Phone Code": "Select Phone Code",
    "Contact Number": "Contact Number",
    "Enter contact number": "Enter contact number",
    "Enter email address": "Enter email address",
    "Date Of Birth": "Date Of Birth",
    "Select date of birth": "Select date of birth",
    "Gender": "Gender",
    "Select gender": "Select gender",
    "Nationality": "Nationality",
    "Address": "Address",
    "Residency": "Residency",
    "Residency Type": "Residency Type",
    "Professional Details": "Professional Details",
    "Employee Number": "Employee Number",
    "Enter employee number": "Enter employee number",
    "Select job position": "Select job position",
    "Select ranking": "Select ranking",
    "Payroll Details": "Payroll Details",
    "Job Types": "Job Types",
    "Select job type": "Select job type",
    "Salary": "Salary",
    "Enter salary": "Enter salary",
    "Working day": "Working day",
    "Enter working day": "Enter working day",
    "Working hour": "Working hour",
    "Enter working hour": "Enter working hour",
    "EPF Number": "EPF Number",
    "Enter EPF number": "Enter EPF number",
    "SOCSO Number": "SOCSO Number",
    "Enter SOCSO number": "Enter SOCSO number",
    "Enter bank name": "Enter bank name",
    "Bank Account Name": "Bank Account Name",
    "Enter bank account name": "Enter bank account name",
    "Bank Account Number": "Bank Account Number",
    "Enter bank account number": "Enter bank account number",
    "Select end date": "Select end date",
    "Female": "Female",
    "Male": "Male",
    "Full-time": "Full-time",
    "Part-time": "Part-time",
    "Contract": "Contract",
    "Internship": "Internship",
    "Temporary": "Temporary",
    "Edit User": "Edit User",
    "Please wait for data finish loading.": "Please wait for data finish loading.",
    "Select report type": "Select report type",
    "Sales Person": "Sales Person",
    "Estimate Delivery Date": "Estimate Delivery Date",
    "Stage": "Stage",
    "Assignee": "Assignee",
    "Duration": "Duration",
    "Cost Per Hour": "Cost Per Hour",
    "Total Cost": "Total Cost",
    "Estimate Cost": "Estimate Cost",
    "Task Status": "Task Status",
    "Price": "Price",
    "Payment Status": "Payment Status",
    "Select project": "Select project",
    "Edit Project": "Edit Project",
    "Are you sure you want to start this timer?": "Are you sure you want to start this timer?",
    "Please fill in remark": "Please fill in remark",
    "Total Task": "Total Task",
    "On Going Task": "On Going Task",
    "Onqueue Task": "Onqueue Task",
    "Completed Task": "Completed Task",
    "Cancelled Task": "Cancelled Task",
    "Pending Feedback Task": "Pending Feedback Task",
    "Search by task name": "Search by task name",
    "Search by assignee name": "Search by assignee name",
    "Select stage": "Select stage",
    "Select task type": "Select task type",
    "Add Task": "Add Task",
    "Time Log": "Time Log",
    "Assigned to Me": "Assigned to Me",
    "New Sub Task": "New Sub Task",
    "Add Time Log": "Add Time Log",
    "Sub Task": "Sub Task",
    "New Task": "New Task",
    "Enter task name": "Enter task name",
    "Enter quantity": "Enter quantity",
    "Actual Delivery Date": "Actual Delivery Date",
    "Select actual delivery date": "Select actual delivery date",
    "Select estimate delivery date": "Select estimate delivery date",
    "Outcome Link": "Outcome Link",
    "Enter outcome link": "Enter outcome link",
    "or": "or",
    "Overtime Approval Setting": "Overtime Approval Setting",
    "Total Overtime Approval Setting": "Total Overtime Approval Setting",
    "Add Overtime Approval Setting": "Add Overtime Approval Setting",
    "New Overtime Approval Setting": "New Overtime Approval Setting",
    "Edit Overtime Approval Setting": "Edit Overtime Approval Setting",
    "Task Name": "Task Name",
    "Assignation": "Assignation",
    "Timeline": "Timeline",
    "Sent to Client": "Sent to Client",
    "Amendment": "Amendment",
    "Edit Task": "Edit Task",
    "Total Customer": "Total Customer",
    "Search by email": "Search by email",
    "Search by contact no.": "Search by contact no.",
    "Search by company name": "Search by company name",
    "Add Customer": "Add Customer",
    "Contact No.": "Contact No.",
    "Company": "Company",
    "New Customer": "New Customer",
    "Customer Title": "Title",
    "Select title": "Select title",
    "Customer Name": "Customer Name",
    "Enter customer name": "Enter customer name",
    "Company Profile": "Company Profile",
    "Company Name": "Company Name",
    "Enter company name": "Enter company name",
    "Company SSM Number": "Company SSM Number",
    "Enter company ssm number": "Enter company ssm number",
    "Customer Profile": "Customer Profile",
    "Edit Customer": "Edit Customer",
    "Overview": "Overview",
    "Task Overview": "Task Overview",
    "On Going Projects": "On Going Projects",
    "Completed Projects": "Completed Projects",
    "On Going Tasks": "On Going Tasks",
    "Completed Tasks": "Completed Tasks",
    "Projects Status Report": "Projects Status Report",
    "Days": "Days",
    "Notifications": "Notifications",
    "Overdue": "Overdue",
    "Due in": "Due in",
    "Upcoming in": "Upcoming in",
    "Overdue Task": "Overdue Task",
    "Due In A Day": "Due In A Day",
    "Total Time Attendance": "Total Time Attendance",
    "Employee": "Employee",
    "Workflow Overview": "Workflow Overview",
    "View Project Details": "View Project Details",
    "Feedback List": "Feedback List",
    "Total Feedback": "Total Feedback",
    "Back": "Back",
    "Feedback Details": "Feedback Details",
    "Total Quotation": "Total Quotation",
    "Quotation No.": "Quotation No.",
    "Subject": "Subject",
    "Confirm convert this quotation to invoice?": "Confirm convert this quotation to invoice?",
    "Quotation has been convert to invoice successfully.": "Quotation has been convert to invoice successfully.",
    "Created Date": "Created Date",
    "Add Quotation": "Add Quotation",
    "Project Task": 'Project Task',
    "Project Timesheet": "Project Timesheet",
    "Project Overview": "Project Overview",
    "Task Status Report": "Task Status Report",
    "Started": "Started",
    "Deadline": "Deadline",
    "Progress": "Progress",
    "Budget": "Budget",
    "Company Involved": "Company Involved",
    "Workflow": "Workflow",
    "by": "by",
    "update by": 'update by',
    "creation": "creation",
    "Task Cost": "Task Cost",
    "Financial Status": "Financial Status",
    "No attachment": "No attachment",
    "Main Approver": "Main Approver",
    "Each layer must have at least 1 main approver.": "Each layer must have at least 1 main approver.",
    "Hours Limit": "Hours Limit",
    "Enter hours limit": "Enter hours limit",
    "Select Layer": "Select Layer",
    "Add Invoice": "Add Invoice",
    "Total Invoice": "Total Invoice",
    "Invoice No.": "Invoice No.",
    "Search by invoice no.": "Search by invoice no.",
    "New Quotation": "New Quotation",
    "Task code already exist.": "Task code already exist.",
    "Quotation Details": "Quotation Details",
    "Enter subject": "Enter subject",
    "Quotation Date": "Quotation Date",
    "Select quotation date": "Select quotation date",
    "Quotation Due Date": "Quotation Due Date",
    "Select quotation due date": "Select quotation due date",
    "Quotation Delivery Date": "Quotation Delivery Date",
    "Select quotation delivery date": "Select quotation delivery date",
    "Select payment term": "Select payment term",
    "Company Details": "Company Details",
    "Select company": "Select company",
    "Billing Details": "Billing Details",
    "Customer's Company": "Customer's Company",
    "Select address": "Select address",
    "Quotation Items": "Quotation Items",
    "Item": "Item",
    "Tax": 'Tax',
    "Discount": "Discount",
    "Total Amount": 'Total Amount',
    "Subtotal": "Subtotal",
    "Edit Quotation Item": 'Edit Quotation Item',
    "Add Quotation Item": "Add Quotation Item",
    "Select task": "Select task",
    "Task Code": "Task Code",
    "Enter task code": "Enter task code",
    "Create custom task": "Create custom task",
    "Choose existing task": "Choose existing task",
    "Enter price": "Enter price",
    "Select tax": "Select tax",
    "Enter discount": "Enter discount",
    "Select discount type": "Select discount type",
    "percent": "percent",
    "Convert to Invoice": "Convert to Invoice",
    "Edit Quotation": "Edit Quotation",
    "Overtime Request": "Overtime Request",
    "Total Overtime Request": "Total Overtime Request",
    "Overtime Request Details": "Overtime Request Details",
    "Approval Log": "Approval Log",
    "Error: Please add approver at layer {layer}.": "Error: Please add approver at layer {layer}.",
    "Invalid hour": "Invalid hour",
    "Eligible to Claim OT": "Eligible to Claim OT",
    "Yes": "Yes",
    "Enter residency": "Enter residency",
    "Enter residency type": "Enter residency type",
    "active": "active",
    "inactive": "inactive",
    "New Invoice": "New Invoice",
    "Invoice Details": "Invoice Details",
    "Invoice Date": "Invoice Date",
    "Select invoice date": "Select invoice date",
    "Invoice Due Date": "Invoice Due Date",
    "Select invoice due date": "Select invoice due date",
    "Invoice Delivery Date": "Invoice Delivery Date",
    "Select invoice delivery date": "Select invoice delivery date",
    "Invoice Items": "Invoice Items",
    "Edit Invoice Item": "Edit Invoice Item",
    "Add Invoice Item": "Add Invoice Item",
    "amount": "amount",
    "Edit Invoice": "Edit Invoice",
    "Total Payment": "Total Payment",
    "Search by payment no.": "Search by payment no.",
    "Add Payment": "Add Payment",
    "Payment No.": "Payment No.",
    "Back to login": "Back to login",
    "Email Verification Code": "Email Verification Code",
    "Enter email verification code": "Enter email verification code",
    "Enter new login password": "Enter new login password",
    "New Login Password": "New Login Password",
    "Confirm New Login Password": "Confirm New Login Password",
    "Confirm password does not match with new login password": "Confirm password does not match with new login password",
    'Forgot Password': "Forgot Password",
    "Verification code has sent to $email.": "Verification code has sent to $email.",
    "Send": "Send",
    "Password has reset successfully": "Password has reset successfully",
    "Revolutionizing & Simplifying Your Business With Us.": "Revolutionizing & Simplifying Your Business With Us.",
    "Start": "Start",
    "End": "End",
    "New Timesheet": "New Timesheet",
    "Edit Timesheet": "Edit Timesheet",
    "Stop Timer": "Stop Timer",
    "Select hour": "Select hour",
    "Select minute": "Select minute",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "Reason Why You Backdate": "Reason Why You Backdate",
    "Enter reason": "Enter reason",
    "End date cannot smaller than start date": "End date cannot smaller than start date",
    "End time cannot smaller than start time": "End time cannot smaller than start time",
    "Cannot enter future date": "Cannot enter future date",
    "View Timesheet": "View Timesheet",
    "Overtime Details": "Overtime Details",
    "Start Datetime": "Start Datetime",
    "End Datetime": "End Datetime",
    "Overtime Approval Log": "Overtime Approval Log",
    "by Layer": "by Layer",
    "Claim Details": "Claim Details",
    "Reason": "Reason",
    "hour": "hour",
    "minutes": "minutes",
    "seconds": "seconds",
    'tasks': "tasks",
    "See tasks": "See tasks",
    "days": "days",
    "Search by quotation no.": "Search by quotation no.",
    "Anonymous": "Anonymous",
    "Report Abuse": "Report Abuse",
    "Confirm this action? Action cannot be revert.": "Confirm this action? Action cannot be revert.",
    "unpaid": "Unpaid",
    "partialpaid": "Partial Paid",
    "fullypaid": "Fully Paid",
    'partialrefund': "Partial Refund",
    "fullyrefund": "Fully Refund",
    "Refund": "Refund",
    "New Refund": "New Refund",
    "payment": "payment",
    "refund": "refund",
    "Payment Date": "Payment Date",
    "Payment Remark": "Payment Remark",
    "Cancel Remark": "Cancel Remark",
    "Void": "Void",
    "Void Payment": "Void Payment",
    "Total Invoice Amount": "Total Invoice Amount",
    "Total Paid Invoice": "Total Paid Invoice",
    "Total Partially Paid Invoice": "Total Partially Paid Invoice",
    "Total Unpaid Amount": "Total Unpaid Amount",
    "Total Unpaid Invoice": "Total Unpaid Invoice",
    "PDF View": "PDF View",
    "Payment Logs": "Payment Logs",
    "Refund Date": "Refund Date",
    "Select refund date": "Select refund date",
    "Enter amount": "Enter amount",
    "Total Quotation Amount": "Total Quotation Amount",
    "Download PDF": "Download PDF",
    "Print": "Print",
    "Refund No.": "Refund No.",
    "Refund Remark": "Refund Remark",
    "Total Refund": "Total Refund",
    "Void Refund": "Void Refund",
    "New": "New",
}

export default EN;