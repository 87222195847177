import { faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from "antd";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import dayjs from "dayjs";
import moment from 'moment';
import Swal from 'sweetalert2'
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { api } from 'utils/api';
import * as XLSX from 'xlsx';
import { priorityList, reportTypeList, shownItem, stageList, statusList } from 'utils/config';
import customSelectStyles from 'utils/customSelectStyles';
import { translate } from 'utils/translate';
import { useAppContext } from 'providers/AppProvider';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import { getColor, getSweelAlertBg, getSweelAlertTextColor, minutesToHour } from 'utils/function';
import { logout } from 'store/actions/AuthActions';
import Error404 from 'pages/error/Error404';
import { minutesToHours } from 'date-fns';
const { RangePicker } = DatePicker;

const Report = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { config } = useAppContext();
    const [productPerpage, setProductPerpage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [assigneeList, setAssigneeList] = useState(null);
    const [loadingAssignee, setLoadingAssignee] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [reportType, setReportType] = useState('timesheet');
    const [allResult, setAllResult] = useState([]);
    const [projectName, setProjectName] = useState('');
    const timerRef = useRef(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Report'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]

    useEffect(() => {
        if (allResult.length > 0) {
            const startIndex = (pageNumber - 1) * productPerpage;
            const endIndex = startIndex + productPerpage;
            const pageItems = allResult.slice(startIndex, endIndex);
            setResult(pageItems);
        }
    }, [pageNumber])

    useEffect(() => {
        setPageNumber(1)
    }, [productPerpage])

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    const handleTimeChange = (e) => {
        const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
        const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
        setStartDate(formattedFromDate);
        setEndDate(formattedToDate);
    }

    useEffect(() => {
        if (firstLoad) {
            if (props?.auth?.permission?.allReport) {
                getList();
                setFirstLoad(false)
            }
        }
    }, [props.auth.permission])

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [props.auth.auth.token, props.lang, reportType, startDate, endDate, assigneeList, projectName]);

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.report + '?type=' + reportType;
            if (projectName) {
                url = url + `&projectName=${projectName.value}`;
            }
            if (startDate && endDate) {
                url = url + '&startDate=' + startDate + ' 00:00:00' + '&endDate=' + endDate + ' 23:59:59';
            }

            if (assigneeList) {
                url = url + `&assignee=${assigneeList.value}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setAllResult(responseJson.data)
                        setPageCount(Math.ceil(responseJson.data.length / productPerpage));
                        const startIndex = (pageNumber - 1) * productPerpage;
                        const endIndex = startIndex + productPerpage;
                        const pageItems = responseJson.data.slice(startIndex, endIndex);
                        setResult(pageItems);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const exportExcel = () => {
        if (allResult.length > 0) {
            setLoadingExcel(true)
            const wb = XLSX.utils.book_new();
            // const headers = ["Project", "Sales Person", "Sub Sales", "PIC", "Task", "Status", "Stage", "Priority", "Start Date", "End Date", "Estimated Delivery Date", "Assignee", "Duration", "Total Costing", "Estimate Costing", "Cost Per Hour", "Invoice", "Quotation", "Price", "Payment Status"];
            let excelData = [];
            let excelMerge = [];
            let rowIndex = 1;
            let showCPH = false;
            allResult.map((item, i) => {
                excelMerge.push({ s: { r: rowIndex, c: 0 }, e: { r: rowIndex + item.project_task.length - 1, c: 0 } })
                excelMerge.push({ s: { r: rowIndex, c: 1 }, e: { r: rowIndex + item.project_task.length - 1, c: 1 } })
                excelMerge.push({ s: { r: rowIndex, c: 2 }, e: { r: rowIndex + item.project_task.length - 1, c: 2 } })
                excelMerge.push({ s: { r: rowIndex, c: 3 }, e: { r: rowIndex + item.project_task.length - 1, c: 3 } })
                if (item.withCPH) {
                    showCPH = true;
                }
                if (item.project_task.length > 0) {
                    item.project_task.map((child, index) => {
                        excelData.push({
                            project: item.project_name,
                            salesPerson: getPersonName(item.project_owner.owner_sales_owner),
                            subSales: getPersonName(item.project_owner.owner_sales_subs),
                            pic: getPersonName(item.project_owner.owner_pic),
                            task: child.task_name,
                            startDate: child.task_start_date,
                            endDate: child.task_end_date,
                            estimateDeliveryDate: child.task_estimated_delivery,
                            status: child.task_progress_status,
                            stage: getLabel(child.task_stage, "stage"),
                            priority: getLabel(child.task_priority, 'priority'),
                            assignee: child.task_assignee,
                            duration: minutesToHours(child.task_duration, props.lang),
                            costing: child.task_costing ? child.task_costing : 0,
                            estimateCost: child.task_estimated_costing ? child.task_estimated_costing : 0,
                            cph: child.task_cph ? child.task_cph : 0,
                            quotation: child.task_quotation,
                            invoice: child.task_invoice,
                            price: child.task_invoice_price,
                            paymentStatus: child.task_payment_status
                        })
                    })
                } else {
                    excelData.push({
                        project: item.project_name,
                        salesPerson: getPersonName(item.project_owner.owner_sales_owner),
                        subSales: getPersonName(item.project_owner.owner_sales_subs),
                        pic: getPersonName(item.project_owner.owner_pic),
                        task: '',
                        startDate: '',
                        endDate: '',
                        estimateDeliveryDate: '',
                        status: '',
                        stage: '',
                        priority: '',
                        assignee: '',
                        duration: '',
                        costing: '',
                        estimateCost: '',
                        cph: '',
                        quotation: '',
                        invoice: '',
                        price: '',
                        paymentStatus: ''
                    })
                }
                rowIndex = rowIndex + item.project_task.length;
            })
            const ws = XLSX.utils.json_to_sheet(excelData, {
                header:
                    showCPH ?
                        ["project", "salesPerson", "subSales", "pic", "task", "status", "stage", "priority", "startDate", "endDate", "estimateDeliveryDate", "assignee", "duration", "costing", "estimateCost", "cph", "quotation", "invoice", "price", "paymentStatus"] :
                        ["project", "salesPerson", "subSales", "pic", "task", "status", "stage", "priority", "startDate", "endDate", "estimateDeliveryDate", "assignee", "duration", "quotation", "invoice", "price", "paymentStatus"], // Map to the keys of the data objects
                // skipHeader: true // We'll add our custom headers separately
            });

            // Writing the headers manually
            let headers = []
            if (showCPH) {
                headers = ["Project", "Sales Person", "Sub Sales", "PIC", "Task", "Status", "Stage", "Priority", "Start Date", "End Date", "Estimated Delivery Date", "Assignee", "Duration", "Costing", 'Estimate Costing', "Cost Per Hour", "Invoice", "Quotation", "Price", "Payment Status"];
            } else {
                headers = ["Project", "Sales Person", "Sub Sales", "PIC", "Task", "Status", "Stage", "Priority", "Start Date", "End Date", "Estimated Delivery Date", "Assignee", "Duration", "Invoice", "Quotation", "Price", "Payment Status"];
            }
            XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

            // Apply the merges to the worksheet
            ws['!merges'] = excelMerge;

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // Generate XLSX file and send to client
            let fileName = "report_" + moment(new Date()).format('YYYYMMDD') + '.xlsx';
            XLSX.writeFile(wb, fileName);
            setLoadingExcel(false);
        } else {
            if (loading) {
                Swal.fire({
                    icon: "info",
                    title: "",
                    text: translate(props.lang, "Please wait for data finish loading."),
                    background: getSweelAlertBg(), // Dark background color
                    color: getSweelAlertTextColor, // Text color
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: translate(props.lang, 'Error'),
                    text: translate(props.lang, "No data to export"),
                    background: getSweelAlertBg(), // Dark background color
                    color: getSweelAlertTextColor(), // Text color
                });
            }
        }
    }

    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingAssignee) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);
                const hasMore = page < totalPage;
                setLoadingAssignee(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                setLoadingAssignee(false)
                console.error('Error fetching data:', error);
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    // Function to decode HTML entities
    const decodeHtmlEntity = (str) => {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = str;
        return textarea.value;
    };

    // Function to remove HTML tags
    const removeHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const loadProjectOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        try {
            const response = await fetch(`${api.get_project + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=1&isOptions=1&name=' + searchQuery}`, {
                method: 'GET',
                headers: {
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }
            });

            const responseJson = await response.json();

            if (responseJson.status !== "success") {
                throw new Error('Failed to fetch: ' + responseJson);
            }
            const options = responseJson.data.data.map(item => ({
                value: item.name,
                label: item.name
            }));
            let totalPage = Math.ceil(responseJson.data.subs.totalProjects / productPerpage);
            const hasMore = page < totalPage;
            return {
                options,
                hasMore,
                additional: {
                    page: page + 1,
                },
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                options: [],
                hasMore: false,
                additional: {
                    page,
                },
            };
        }
    }

    const getPersonName = (arr) => {
        let string = "";
        arr.map((item, index) => {
            string = index === 0 ? item.oWorker.name : string + ', ' + item.oWorker.name;
        })
        return string;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    return (
        props?.auth?.permission?.allReport ? (
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{translate(props.lang, "Report")}</h2>
                    <div className="mb-4">
                        <Row className="g-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={reportTypeList.length > 0 ? reportTypeList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select report type")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={false}
                                        value={reportTypeList.filter(option => option.value === reportType).length > 0 ? reportTypeList.filter(option => option.value === reportType).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                                        name="reportType"
                                        onChange={(e) => setReportType(e.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                    <div className="input-group-select px-3 w-100">
                                        <AsyncPaginate
                                            placeholder={translate(props.lang, "Select assignee")}
                                            loadingMessage={() => translate(props.lang, 'Loading Data')}
                                            debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                            loadOptions={loadOptions} // Function to load options asynchronously
                                            additional={{
                                                page: 1, // Initial page
                                            }}
                                            isClearable={true}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            value={assigneeList}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => setAssigneeList(e)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                    <RangePicker
                                        className="w-100 input-transparent px-2 py-2 h-100"
                                        placeholder={[
                                            translate(props.lang, "From"),
                                            translate(props.lang, "To"),
                                        ]}
                                        onChange={(e) => handleTimeChange(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                    <div className="input-group-select px-3 w-100">
                                        <AsyncPaginate
                                            placeholder={translate(props.lang, "Select project")}
                                            loadingMessage={() => translate(props.lang, 'Loading Data')}
                                            debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                            loadOptions={loadProjectOptions} // Function to load options asynchronously
                                            additional={{
                                                page: 1, // Initial page
                                            }}
                                            isClearable={true}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => setProjectName(e)}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} md={4} xs={12} sm={12}>
                                <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                                    {loadingExcel ? (
                                        <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                            {translate(props.lang, "Export")}
                                        </>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                        <div className='table-responsive'>
                            <Table responsive="sm" bordered hover>
                                <thead>
                                    <tr>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Project")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Sales Person")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Sub Sales")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Person In Charge")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Task")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '100px' }}>{translate(props.lang, "Start Date")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '100px' }}>{translate(props.lang, "End Date")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '200px' }}>{translate(props.lang, "Estimate Delivery Date")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '100px' }}>{translate(props.lang, "Status")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '100px' }}>{translate(props.lang, "Stage")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '100px' }}>{translate(props.lang, "Priority")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '100px' }}>{translate(props.lang, "Assignee")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Duration")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Cost Per Hour")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Total Cost")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Estimate Cost")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Quotation")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Invoice")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Price")}</th>
                                        <th className={`th-text py-2 fs-8 fw-bold px-2 align-middle`} style={{ minWidth: '150px' }}>{translate(props.lang, "Payment Status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ? (
                                            <tr role="row">
                                                <td colSpan={21}>
                                                    <DataLoading theme="light" />
                                                </td>
                                            </tr>
                                        ) : (
                                            result.length > 0 ? (
                                                result.map((item, idx) => {
                                                    const el = [];
                                                    el.push(
                                                        item.project_task.length > 0 ? item.project_task.map((child, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {!index && (
                                                                        <>
                                                                            <td className='td-text fs-9 fw-normal px-2 align-top' rowSpan={item.project_task.length}>{item.project_name}</td>
                                                                            <td className='td-text fs-9 fw-normal px-2 align-top' rowSpan={item.project_task.length} style={{ minWidth: '200px' }}>{getPersonName(item.project_owner.owner_sales_owner)}</td>
                                                                            <td className='td-text fs-9 fw-normal px-2 align-top' rowSpan={item.project_task.length} style={{ minWidth: '200px' }}>{getPersonName(item.project_owner.owner_sales_subs)}</td>
                                                                            <td className='td-text fs-9 fw-normal px-2 align-top' rowSpan={item.project_task.length} style={{ minWidth: '200px' }}>{getPersonName(item.project_owner.owner_pic)}</td>
                                                                        </>
                                                                    )}
                                                                    <Fragment>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle' style={{ minWidth: '200px' }}>{child.task_name}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_start_date}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_end_date}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_estimated_delivery}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>
                                                                            <div className="px-2 text-capitalize text-center status-dropdown d-block" style={{ background: getColor(child.task_progress_status, 'status'), color: '#fff' }}>
                                                                                <span className='fw-bold fs-9'>{translate(props.lang, getLabel(child.task_progress_status, "status"))}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>
                                                                            <div className="px-2 text-capitalize text-center status-dropdown d-block" style={{ background: getColor(child.task_stage, 'stage'), color: '#fff' }}>
                                                                                <span className='fw-bold fs-9'>{translate(props.lang, getLabel(child.task_stage, "stage"))}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>
                                                                            <div className="px-2 text-capitalize text-center status-dropdown d-block" style={{ background: getColor(child.task_priority, 'priority'), color: '#fff' }}>
                                                                                <span className='fw-bold fs-9'>{translate(props.lang, getLabel(child.task_priority, "priority"))}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle' style={{ minWidth: '200px' }}>{child.task_assignee}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{minutesToHour(child.task_duration, props.lang)}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_cph}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_costing}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_estimated_costing}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_quotation}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_invoice}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle'>{child.task_invoice_price}</td>
                                                                        <td className='td-text fs-9 fw-normal px-2 align-middle text-capitalize'><span className={`statusBox ${child.task_payment_status} px-2`}>{translate(props.lang, child.task_payment_status)}</span></td>
                                                                    </Fragment>
                                                                </tr>
                                                            );
                                                        }) : ((
                                                            <tr key={idx}>
                                                                <td>{item.project_name}</td>
                                                                <td>{getPersonName(item.project_owner.owner_sales_owner)}</td>
                                                                <td>{getPersonName(item.project_owner.owner_sales_subs)}</td>
                                                                <td>{getPersonName(item.project_owner.owner_pic)}</td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                                <td className='td-text fs-9 fw-normal px-2 align-middle'></td>
                                                            </tr>
                                                        )
                                                        )
                                                    );

                                                    return el;
                                                })
                                            ) : (
                                                <tr role="row">
                                                    <td colSpan={21}>
                                                        <NoResultFound />
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </Table>
                        </div>
                        <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                                <div className='d-flex w-100 align-items-center justify-content-end'>
                                    <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                    <div className="input-group-select-paginate ms-2 px-2">
                                        <Select
                                            options={
                                                shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                            }
                                            className="input-transparent w-100 text-capitalize px-0"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                            name="productPerpage"
                                            isSearchable={false}
                                            onChange={(e) => setProductPerpage(e ? e.value : '')}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-center my-3'>
                            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                {!loading && result.length > 0 ? (
                                    <div className="my-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            forcePage={pageNumber - 1}
                                            onPageChange={changePage}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-white"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        ) : <Error404 />
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Report);
