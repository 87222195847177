import moment from "moment";
import * as XLSX from 'xlsx';
import { priorityList, quotationStatus, stageList, statusList } from "./config";
import { translate } from "./translate";

function isValidUrl(string) {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}

const downloadExcel = (data, filename) => {
    let date = moment(new Date()).format('YYYYMMDDHHmmss');
    let excelFileName = `${filename}_${date.toString()}.xlsx`;
    const wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(data && data.length > 0 ? data : []);
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, excelFileName)
}

function deepCopyArray(arr) {
    return arr.map(item => ({ ...item }));
}

const getSweelAlertBg = () => {
    let darkTheme = JSON.parse(localStorage.getItem('isDark'));
    if(darkTheme){
        return "#1e1e1e";
    } else {
        return "#ffffff";
    }
}

const getSweelAlertTextColor = () => {
    let darkTheme = JSON.parse(localStorage.getItem('isDark'));
    if(darkTheme){
        return "#ffffff";
    } else {
        return "#000000";
    }
}

const replaceHtmlTagFromString = (val) => {
    const regex = /(<([^>]+)>)/gi;
    const result = val.replace(regex, "");
    return result;
}

const getLabel = (val, type) => {
    let text = val;
    if (type === 'priority') {
        let filterList = priorityList.filter(item => item.value.toString() === val.toString());
        if (filterList.length > 0) {
            text = filterList[0].label;
        }
    } else if (type === 'status') {
        let filterList = statusList.filter(item => item.value.toString() === val.toString());
        if (filterList.length > 0) {
            text = filterList[0].label;
        }
    } else if (type === 'stage') {
        let filterList = stageList.filter(item => item.value.toString() === val.toString());
        if (filterList.length > 0) {
            text = filterList[0].label;
        }
    }
    return text;
}

const getColor = (val, type) => {
    let color = '#fff';
    if (type === 'status') {
        let filterList = statusList.filter(item => item.value === val);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#000';
        }
    } else if (type === 'priority') {
        let filterList = priorityList.filter(item => item.value === val);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#000';
        }
    } else if (type === 'stage') {
        let filterList = stageList.filter(item => item.value === val);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#000';
        }
    }

    return color;
}

const getFinanceLabel = (val, type) => {
    let text = val;
    if (type === 'status') {
        let filterList = quotationStatus.filter(item => item.value=== val);
        if (filterList.length > 0) {
            text = filterList[0].label;
        }
    } 
    return text;
}

const getFinanceColor = (val, type) => {
    let color = '#fff';
    if (type === 'status') {
        let filterList = quotationStatus.filter(item => item.value === val);
        if (filterList.length > 0) {
            color = filterList[0].color;
        } else {
            color = '#000';
        }
    } 

    return color;
}

const minutesToHour = (seconds, lang) => {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600 * 24));
    // var h = Math.floor(seconds % (3600 * 24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    var h = Math.floor(seconds / 60);
    let m = Math.round(seconds % 60);

    if (h === 0 && m === 0) {
        return "-";
    } else {
        let currentText = "";
        // if (d > 0) {
        //     if (d.toString().length === 1) {
        //         d = "0" + d;
        //     }
        //     currentText = currentText + d + ` days `
        // }
        if (h > 0) {
            if (h.toString().length === 1) {
                h = "0" + h;
            }
            currentText = currentText + h + ` ${translate(lang, "hour")} `
        }else {
            currentText = currentText + ` 0 ${translate(lang, "hour")} `
        }
        if (m > 0) {
            if (m.toString().length === 1) {
                m = "0" + m;
            }
            currentText = currentText + m + ` ${translate(lang, "minutes")} `
        } else {
            currentText = currentText + ` 0 ${translate(lang, "minutes")} `
        }
        // if (s > 0) {
        //     if (s.toString().length === 1) {
        //         s = "0" + s;
        //     }
        //     currentText = currentText + s + ` seconds`
        // }else {
        //     currentText = currentText + '0 second'
        // }

        return currentText;
    }
}

const checkPermission = (array, permission, name) => {
    if(array.length > 0){
        if(permission){
            if(Object.keys(permission).length > 0){
                let isAllExist = array.some(item => permission[item] && permission[item] === 1);
                return isAllExist;
            } else {
                return false;
            }
        }else {
            return false;
        }
        
    } else {
        return true;
    }
}

const truncateToTwoDecimals = (num) => {
    const str = num.toString();
    const decimalIndex = str.indexOf('.');
  
    if (decimalIndex === -1) return str; // No decimal point, return the number as is
  
    return str.substring(0, decimalIndex + 3); // Get the part up to two decimal places
};

export { truncateToTwoDecimals, checkPermission, getFinanceLabel, getFinanceColor, minutesToHour, getColor, getLabel, isValidUrl, downloadExcel, deepCopyArray, getSweelAlertTextColor, getSweelAlertBg, replaceHtmlTagFromString }