import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import { api } from 'utils/api';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'User', url: '#' },
    { label: 'Profile', active: true },
]

const Profile = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profileInfo, setProfileInfo] = useState(null);

    useEffect(() => {
        getProfile();
    }, [props.auth.auth.token])

    const getProfile = () => {
        fetch(api.get_my_details, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setProfileInfo(responseJson.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Profile")}</h2>
            <Row className='align-items-start justify-content-center'>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Employee ID")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Employee ID")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.employee_number ? profileInfo.employee_number : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Full Name as per IC")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Full Name as per IC")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.name ? profileInfo.name : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "IC Number")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter IC Number")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.identity_card ? profileInfo.identity_card : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Contact Number")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Contact Number")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.phone ? profileInfo.phone : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Email Address")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.email ? profileInfo.email : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Date of Birth")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Date of Birth")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.date_of_birth ? profileInfo.date_of_birth : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Gender")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Gender")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.gender ? profileInfo.gender : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Nationality")}</span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                placeholder={translate(props.lang, "Enter Gender")}
                                className="form-control input-transparent py-1 px-3"
                                value={profileInfo && profileInfo.nationality ? profileInfo.nationality : ''}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Profile);
