import { AuthRouter } from 'AuthRoutes';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import { checkAutoLogin } from 'services/AuthService';
import { isAuthenticated } from 'store/selectors/AuthSelectors';
import { api } from 'utils/api';
import { languageList } from 'utils/config';

const Index = (props) => {
    const { config } = useAppContext();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('pms-lang')) {
            if (languageList.filter(item => item.code === localStorage.getItem('pms-lang')).length > 0) {
                dispatch({
                    type: "UPDATE_LANGUAGE",
                    lang: localStorage.getItem('pms-lang'),
                });
            } else {
                dispatch({
                    type: "UPDATE_LANGUAGE",
                    lang: 'EN',
                });
            }
        } else {
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: 'EN',
            });
        }
        checkAutoLogin(dispatch, props.history, location.pathname);
    }, [dispatch, props.history, location.pathname]);

    const scriptAppended = useRef(false);

    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('pms-crm');
        let tokenDetails = JSON.parse(tokenDetailsString);
        if (props.auth.auth.token) {
            if (tokenDetailsString) {
                if (!scriptAppended.current && config.theme && props.lang) {
                    const script = document.createElement('script')
                    script.src = 'https://ai-chat.dothouz.com/inject.js';
                    script.setAttribute('id', 'AIChatBot');
                    script.setAttribute('data-apikey', process.env.REACT_APP_AI_APIKEY);
                    script.setAttribute('theme', config.theme);
                    script.setAttribute('lang', props.lang);
                    script.async = true
                    document.body.appendChild(script);

                    const iframe = document.getElementById('main_iframe');
                    if (iframe) {
                        iframe.setAttribute('lang', props.lang);
                        iframe.setAttribute('theme', config.theme);
                        const newSrc = `https://ai-chat.dothouz.com/Chatbot?apikey=${process.env.REACT_APP_AI_APIKEY}&lang=${props.lang}&theme=${config.theme}`;
                        iframe.setAttribute('src', newSrc);
                    }

                    scriptAppended.current = true;

                    return () => {
                        document.body.removeChild(script);
                        scriptAppended.current = false;
                    }
                }
            } else {
                var frame = document.getElementById("main_iframe");
                if(frame){
                    frame.parentNode.removeChild(frame);
                }
            }
        } else {
            var frame = document.getElementById("main_iframe");
            if(frame){
                frame.parentNode.removeChild(frame);
            }
        }
    }, [config.theme, props.lang, props.auth.auth.token])

    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('pms-crm');
        let tokenDetails = '';
        tokenDetails = JSON.parse(tokenDetailsString);
        if (tokenDetailsString) {
            getPermission(tokenDetails);
            getProfile(tokenDetails);
            getUnreadNewsCount(tokenDetails);
        }
    }, [props.auth.auth.token])

    const getPermission = (tokenDetails) => {
        fetch(api.get_my_permission, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + tokenDetails.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    dispatch({
                        type: 'UPDATE_PERMISSION_ACCESS',
                        permission: responseJson.data,
                    });
                } else {
                    console.log('error')
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getProfile = (tokenDetails) => {
        fetch(api.get_my_details, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + tokenDetails.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    dispatch({
                        type: 'UPDATE_PROFILE',
                        profile: responseJson.data
                    });
                } else {
                    console.log('error', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getUnreadNewsCount = (tokenDetails) => {
        fetch(api.get_announcements + '?counterOnly=1&details=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + tokenDetails.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    dispatch({
                        type: 'UPDATE_UNREAD_NEWS_COUNT',
                        unreadNewsCount: responseJson.data.totalUnread
                    });
                } else if (responseJson.status === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    console.log('error announcement', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            {props.isAuthenticated ? (
                <RouterProvider router={router} />
            ) : (
                <RouterProvider router={AuthRouter} />
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        isAuthenticated: isAuthenticated(state),
        lang: i18n.lang,
        auth: auth,
        showLoading: auth.showLoading,
    }
}

export default connect(mapStateToProps)(Index);
