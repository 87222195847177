import { faAdd, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from 'components/base/Timeline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { translate } from 'utils/translate';

const OTApprovalLog = (props) => {
    const [projectLog, setProjectLog] = useState([]);

    useEffect(() => {
        if (props.overtimeLogs) {
            setProjectLog(props.overtimeLogs);
        }
    }, [props.lang, props.overtimeLogs])

    return (
        <div>
            <Timeline variant="vertical">
                {
                    projectLog && projectLog.map((item, index) => (
                        <Timeline.Item className="position-relative" key={item.id}>
                            <Row className="g-md-3">
                                <Col xs={12} md="auto" className="d-flex">
                                    <Timeline.OppositeContent>
                                        <p className="fs-10 fw-semibold text-body-tertiary text-opacity-85 text-end">
                                            {moment(item.created_at).format('DD MMM, YYYY')}
                                            <br className="d-none d-md-block" />{' '}
                                            {moment(item.created_at).format('HH:mm a')}
                                        </p>
                                    </Timeline.OppositeContent>
                                    <Timeline.Separator className="position-md-relative">
                                        <Timeline.Dot className={`${item.approval_status === 'approved' ? 'bg-success-subtle' : item.approval_status === 'rejected' ? 'bg-danger-subtle' : 'bg-primary-subtle'}`}>
                                            <FontAwesomeIcon
                                                icon={item.approval_status === 'approved' ? faCheck : item.approval_status === 'rejected' ? faTimes : faAdd}
                                                className={`${item.approval_status === 'approved' ? 'text-black' : item.approval_status === 'rejected' ? 'text-black' : 'text-primary-dark'} fs-10`}
                                            />
                                        </Timeline.Dot>
                                        {index !== projectLog.length - 1 && (
                                            <Timeline.Bar className="h-100 border-dashed" />
                                        )}
                                    </Timeline.Separator>
                                </Col>
                                <Col>
                                    <Timeline.Content>
                                        <h5 className="fs-9 lh-sm text-capitalize" style={{ color: item.approval_status === 'approved' ? 'green' : 'red' }}>{translate(props.lang, item.approval_status)}  {item.approval_status === 'rejected' ? <span className="fw-bold fs-9 text-capitalize">({translate(props.lang, "Reason")}: {item.reason})</span> : null}</h5>
                                       
                                        <p className="fs-9">{translate(props.lang, "by Layer")} {item?.layer}: {item?.approver?.name} ({item?.approver?.employee_number})</p>
                                    </Timeline.Content>
                                </Col>
                            </Row>
                        </Timeline.Item>
                    ))
                }
            </Timeline>
        </div>
    );
};

export default OTApprovalLog;
