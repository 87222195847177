const shownItem = [
    { label: "10", value: 10 }, 
    { label: "30", value: 30 }, 
    { label: "50", value: 50 }, 
    { label: "100", value: 100 }
]

const statusList = [
    { label: 'Draft', value: 'draft', color: '#8d8d8d' },
    { label: 'On Going', value: 'ongoing', color: '#02CFFC' },
    { label: 'Completed', value: 'completed', color: '#6FD41F' },
    { label: 'On Queue', value: 'onqueue', color: '#FFB800' },
    { label: 'Maintenance', value: 'maintenance', color: '#108C76' },
    { label: 'QC', value: 'qc', color: '#B972FF' },
    { label: 'Cancelled', value: 'cancelled', color: '#FF2D20' },
    { label: 'Pending Feedback', value: 'pendingfeedback', color: '#0C3F99' },
]

const priorityList = [
    { label: 'None', value: 0, color: '#8d8d8d' },
    { label: 'Low', value: 1, color: '#5eb864' },
    { label: 'Medium', value: 2, color: '#ffb100' },
    { label: 'High', value: 3, color: '#f36969' },
]

const stageList = [
    { label: 'Requirement', value: 'requirement', color: '#8d8d8d' },
    { label: 'Assignation', value: 'assignation', color: '#02CFFC' },
    { label: 'Timeline', value: 'timeline', color: '#6FD41F' },
    { label: 'Execution', value: 'execution', color: '#108C76' },
    { label: 'QC', value: 'qc', color: '#B972FF' },
    { label: 'Sent to Client', value: 'sendclient', color: '#FF2D20' },
    { label: 'Amendment', value: 'amendment', color: '#0C3F99' },
]

const titleList = [
    { label: 'Mr.', value: 'Mr' },
    { label: 'Mrs.', value: 'Mrs' },
    { label: 'Ms.', value: 'Ms' },
]

const fiscalMonth = [
    { startMonth: 'January', endMonth: 'December', value: 'January - December', label: 'January - December' },
    { startMonth: 'February', endMonth: 'January', value: 'February - January', label: 'February - January' },
    { startMonth: 'March', endMonth: 'February', value: 'March - February', label: 'March - February' },
    { startMonth: 'April', endMonth: 'March', value: 'April - March', label: 'April - March' },
    { startMonth: 'May', endMonth: 'April', value: 'May - April', label: 'May - April' },
    { startMonth: 'June', endMonth: 'May', value: 'June - May', label: 'June - May' },
    { startMonth: 'July', endMonth: 'June', value: 'July - June', label: 'July - June' },
    { startMonth: 'August', endMonth: 'July', value: 'August - July', label: 'August - July' },
    { startMonth: 'September', endMonth: 'August', value: 'September - August', label: 'September - August' },
    { startMonth: 'October', endMonth: 'September', value: 'October - September', label: 'October - September' },
    { startMonth: 'November', endMonth: 'October', value: 'November - October', label: 'November - October' },
    { startMonth: 'December', endMonth: 'November', value: 'December - March', label: 'December - November' },
]

const languageList = [
    { name: 'English', code: 'EN' },
	{ name: '中文 (简体)', code: 'ZH' },
]

const reportTypeList = [
    { label: 'Project', value: 'project' },
    { label: 'Task', value: 'task' },
    { label: 'Timesheet', value: 'timesheet' },
]

const approvalStatus = [
    { label: 'approved', value: 'approved' },
    { label: 'rejected', value: 'rejected' },
    { label: 'pending', value: 'pending' },
]

const approvalSettingType = [
    { label: 'Company', value: 'company' },
    { label: 'Organization', value: 'organization' },
    { label: 'Department', value: 'department' },
]

const dateButtonTextMap = {
    en: { clear: 'Clear', cancel: 'Cancel', ok: 'OK' },
    zh: { clear: '清除', cancel: '取消', ok: '确定' },
};

const taxOption = [
    { label: 'No', value: '0' },
    { label: '6%', value: '6' },
    { label: '8%', value: '8' },
]

const discountTypeOption = [
    { label: 'percent', value: 'percent' },
    { label: 'amount', value: 'amount' },
]

const quotationStatus = [
    { label: 'Draft', value: 'draft', color: '#8d8d8d' },
    { label: 'Sent to Client', value: 'sendclient', color: '#02CFFC' },
    { label: 'Convert to Invoice', value: 'converted', color: '#108C76' },
    { label: 'Void', value: 'voided', color: '#FF2D20' },
]

const exportPageSize = 500;

const regexDecimalPoint = /^[0-9]*(\.[0-9]{0,2})?$/;

export { quotationStatus, discountTypeOption, taxOption, regexDecimalPoint, dateButtonTextMap, approvalSettingType, approvalStatus, shownItem, statusList, priorityList, stageList, titleList, fiscalMonth, languageList, exportPageSize, reportTypeList }