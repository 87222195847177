import { faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import Error404 from 'pages/error/Error404';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api, attachmentUrl } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getFinanceColor, getFinanceLabel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const PaymentList = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { config } = useAppContext();
  const [productPerpage, setProductPerpage] = useState(10)
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [result, setResult] = useState([]);
  const [financialNumber, setFinancialNumber] = useState('');
  const [customer, setCustomer] = useState('');
  const [statistic, setStatistic] = useState(0);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [remark, setRemark] = useState('');
  const [paymentPanel, setPaymentPanel] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  const timerRef = useRef(null);
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortOrder: '',
    subSort: '',
    subOrder: '',
  });

  let breadcrumbData = [
    { label: translate(props.lang, 'Payment'), url: '#' },
    { label: translate(props.lang, 'List'), active: true }
  ]

  const changePage = (event) => {
    setPageNumber(event.selected + 1);
    getList(event.selected + 1)
  }

  useEffect(() => {
    if (firstLoad) {
      if(props?.auth?.permission?.allProjectFinancial){
        getList();
        setFirstLoad(false)
      }
    }
  }, [props.auth.auth.token, props.auth.permission]);

  useEffect(() => {
    if (!firstLoad) {
      setPageNumber(1)
      getList(1);
    }
  }, [sortBy.sortField, sortBy.sortOrder, props.lang, status, customer, financialNumber, productPerpage]);

  const getList = (page = pageNumber) => {
    setLoading(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      let url = api.get_project_financial_payments + "?page=" + page + "&limit=" + productPerpage + '&details=1&type=payment';
      if (financialNumber) {
        url = url + `&payment_number=${financialNumber}`
      }
      if (status) {
        url = url + `&payment_status=${status}`
      }
      if (sortBy.sortField && sortBy.sortOrder) {
        url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
      }
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          if (responseJson.status === "success") {
            setResult(responseJson.data.data);
            setTotalResultCount(responseJson.data.subs.total)
            let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
            setPageCount(totalPage);
            setStatistic(responseJson.data.subs.total)
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else if (responseJson.tokenExpired) {
              dispatch(logout());
              navigate('/')
            } else {
              console.log('error', responseJson.message)
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }, 1000);
  }

  const formatData = (data) => {
    let newData = [];
    data.forEach(entry => {
      let formattedData = {
        "date": entry.payment_date ? entry.payment_date : '-',
        "payment no.": entry.project_financial_payment_number ? entry.project_financial_payment_number : '-',
        "amount": entry.amount ? entry.amount : '-',
        "attachment": entry.attachment && entry.attachment.length > 0 ? entry.attachmentUrl + entry.attachment[0].path : '-',
        "payment remark": entry.industry ? entry.industry : '-',
        "invoice no.": entry.project_financial.financial_number ? entry.project_financial.financial_number : '-',
        "status": entry.payment_status ? entry.payment_status : '-',
        "cancel remark": entry.cancel_remark ? entry.cancel_remark : '-',

      };
      newData.push(formattedData)
    })
    return newData;
  }

  const exportExcel = () => {
    const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
    let processData = [];
    let totaldata = 0;
    if (totalPagesCount > 0) {
      setLoadingExcel(true);
      for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
        let url = api.get_project_financial_payments + "?page=" + page + "&limit=" + productPerpage + '&details=1&type=payment';
        if (financialNumber) {
          url = url + `&payment_number=${financialNumber}`
        }
        if (status) {
          url = url + `&payment_status=${status}`
        }
        if (sortBy.sortField && sortBy.sortOrder) {
          url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
        }

        fetch(url, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accept-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
          }),
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.status === "success") {
              totaldata = responseJson.data.subs.total;
              processData = processData.concat(formatData(responseJson.data.data));
            } else {
              if (responseJson.message === 'token expired') {
                dispatch(logout());
                navigate('/')
              } else if (responseJson.tokenExpired) {
                dispatch(logout());
                navigate('/')
              } else {
                console.log('error', responseJson.message)
              }
            }
            if (processData.length === totaldata) {
              downloadExcel(processData, 'payment')
              setLoadingExcel(false)
            }
          }).catch(error => {
            console.error("error", error);
          });

        setTimeout(() => { }, 500) // add some delay here to let server take rest.
      }
    } else {
      Swal.fire({
        icon: "error",
        title: translate(props.lang, 'Error'),
        text: translate(props.lang, "No data to export"),
        background: getSweelAlertBg(), // Dark background color
        color: getSweelAlertTextColor(), // Text color
      });
    }
  }

  const submitForm = () => {
    Swal.fire({
      title: translate(props.lang, "Confirmation"),
      text: translate(props.lang, "Confirm this action? Action cannot be revert."),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate(props.lang, "Confirm"),
      cancelButtonText: translate(props.lang, "No"),
      background: getSweelAlertBg(), // Dark background color
      color: getSweelAlertTextColor(), // Text color
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingAction(true)
        let newFormData = {
          remark: remark,
        }
        fetch(api.remove_project_financial_payment + `/${selectedId}`, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accept-Language": props.lang.toLowerCase(),
            "Authorization": "Bearer " + props.auth.auth.token
          }),
          body: JSON.stringify(newFormData)
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.status === 'success') {
              Swal.fire({
                icon: "success",
                title: translate(props.lang, 'Success'),
                text: translate(props.lang, "Data has been update successful."),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
              closePaymentPanel();
              getList();
            } else {
              if (responseJson.message === 'token expired') {
                dispatch(logout());
                navigate('/')
              } else if (responseJson.tokenExpired) {
                dispatch(logout());
                navigate('/')
              } else {
                Swal.fire({
                  icon: "error",
                  title: translate(props.lang, 'Error'),
                  text: responseJson.message,
                  background: getSweelAlertBg(), // Dark background color
                  color: getSweelAlertTextColor(), // Text color
                });
              }
            }
          }).catch(error => {
            console.error("error", error);
          });
      }
    });
  }

  const openPaymentPanel = (val) => {
    setPaymentPanel(true);
    setSelectedId(val)
    setRemark('')
  }

  const closePaymentPanel = () => {
    setPaymentPanel(false);
    setSelectedId(null)
    setRemark('')
  }

  return (
    props?.auth?.permission?.allProjectFinancial ? (
      <div>
        <PageBreadcrumb items={breadcrumbData} />
        <div className="mb-9">
          <h2 className="mb-5">{translate(props.lang, "Payment")}</h2>
          <div className="mb-4">
            <Row className="g-3">
              <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                <div className="tableBgContainer py-4 px-4 total">
                  <span className="fs-8 fw-bold">{translate(props.lang, "Total Payment")}</span>
                  <div className="dataBg px-2 py-1 mt-2">
                    <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-3">
              <Col lg={4} md={4} xs={12} sm={12}>
                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                  <Form.Control
                    type="text"
                    placeholder={translate(props.lang, "Search by payment no.")}
                    onChange={e => setFinancialNumber(e.target.value)}
                    value={financialNumber}
                    className='input-transparent py-1 px-3'
                  />
                </div>
              </Col>
              <Col lg={4} md={4} xs={12} sm={12}>
                {props?.auth?.permission?.allProjectFinancial ? (
                  <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                    {loadingExcel ? (
                      <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                        {translate(props.lang, "Export")}
                      </>
                    )}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <div className='table-responsive'>
              <Table responsive="sm" hover>
                <thead>
                  <tr>
                    <th className='py-2 px-1'>{translate(props.lang, "Payment Date")}</th>
                    <th className='py-2'>{translate(props.lang, "Payment No.")}</th>
                    <th className='py-2'>{translate(props.lang, "Amount")}</th>
                    <th className='py-2'>{translate(props.lang, "Attachment")}</th>
                    <th className='py-2'>{translate(props.lang, "Payment Remark")}</th>
                    <th className='py-2'>{translate(props.lang, "Invoice No.")}</th>
                    <th className='py-2'>{translate(props.lang, "Status")}</th>
                    <th className='py-2'>{translate(props.lang, "Cancel Remark")}</th>
                    <th className='py-2'></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={9}>
                        <DataLoading theme='light' />
                      </td>
                    </tr>
                  ) : (
                    result.length > 0 ? (
                      result.map((item, i) => (
                        <tr role="row" key={i}>
                          <td className='td-text py-2 fs-9 px-1' style={{ minWidth: '100px' }}>{item.payment_date}</td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item.project_financial_payment_number}</td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item?.amount}</td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '50px' }}>
                            {
                              item?.attachment.length > 0 ? (
                                <a href={attachmentUrl + item.attachment[0].path} className='cursor-pointer' target='_blank'>{translate(props.lang, "View")}</a>) : ''
                            }
                          </td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item.remark ? item.remark : '-'}</td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}><span className='text-primary cursor-pointer' onClick={() => navigate(`/invoice/details/${item?.project_financial?.project_financial_id}`)}>{item?.project_financial?.financial_number}</span></td>
                          <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '100px' }}>
                            <div className={`statusBox ${item.payment_status} text-center px-2`}>{translate(props.lang, item.payment_status)}</div>
                          </td>
                          <td className='td-text py-2 fs-9' style={{ minWidth: '100px' }}>{item.cancel_remark ? item.cancel_remark : '-'}</td>
                          <td className='td-text py-2 fs-9'>
                            {item.payment_status === 'approved' ? (
                              <Button variant="danger" className='py-1 px-2' onClick={() => openPaymentPanel(`${item.project_financial_payment_id}`)}>
                                <span className="fw-semibold fs-9 cursor-pointer">{translate(props.lang, "Void")}</span>
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr role="row">
                        <td colSpan={9}>
                          <NoResultFound />
                        </td>
                      </tr>
                    )
                  )}

                </tbody>
              </Table>
            </div>
            <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
              <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                <div className='d-flex w-100 align-items-center justify-content-end'>
                  <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                  <div className="input-group-select-paginate ms-2 px-2">
                    <Select
                      options={
                        shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                      }
                      className="input-transparent w-100 text-capitalize px-0"
                      styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                      value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                      name="productPerpage"
                      isSearchable={false}
                      onChange={(e) => setProductPerpage(e ? e.value : '')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='justify-content-center align-items-center my-3'>
              <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                {!loading && result.length > 0 ? (
                  <div className="my-3">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={pageCount}
                      forcePage={pageNumber - 1}
                      onPageChange={changePage}
                      containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                      previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                      nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                      disabledClassName={"pagination__link--disabled text-muted"}
                      activeClassName={"pagination__link--active text-white"}
                      marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
        <Offcanvas className={`w-50`} show={paymentPanel} onHide={() => closePaymentPanel()} placement='end' scroll={false}>
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <h2 className="mb-4">{translate(props.lang, "Void Payment")}</h2>
            <Row className="justify-content-start">
              <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                <div className={`groupLabel-input py-1`}>
                  <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Remark")}</span>
                  <div className={`input-group`}>
                    <Form.Control
                      as="textarea"
                      name="remark"
                      className="form-control input-transparent py-1 px-3"
                      rows={3}
                      value={remark}
                      placeholder={translate(props.lang, "Enter remark")}
                      onChange={e => setRemark(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loadingAction}>
                  <span className="fw-bold fs-9 text-white">{loadingAction ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                </Button>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    ) : <Error404 />
  );
};
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(PaymentList);
