import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from 'components/base/Timeline';
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import { api } from 'utils/api';
import { getColor, getLabel } from 'utils/function';
import { translate } from 'utils/translate';

const ActivityTimeline = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [result, setResult] = useState([]);
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const timerRef = useRef(null)

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        setLoading(true);
        if (props.taskInfo && props.taskInfo.project_id) {
            getList(1)
        }
    }, [props.taskInfo, productPerpage])

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_project_task_workflow + "/" + props.taskInfo.project_id + "/" + props.taskInfo.task[0]?.project_task_id + "?page=" + page + '&limit=' + productPerpage, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
                        setPageCount(totalPage);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }

                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    return (
        <div>
            {loading ? (
                <div>
                    <DataLoading />
                </div>
            ) : (
                <Timeline variant="vertical">
                    {
                        result && result.map((item, index) => (
                            <Timeline.Item className="position-relative" key={item.id}>
                                <Row className="g-md-3">
                                    <Col xs={12} md="auto" className="d-flex">
                                        <Timeline.OppositeContent>
                                            <p className="fs-10 fw-semibold text-body-tertiary text-opacity-85 text-end">
                                                {moment(item.created_at).format('DD MMM, YYYY')}
                                                <br className="d-none d-md-block" />{' '}
                                                {moment(item.created_at).format('HH:mm a')}
                                            </p>
                                        </Timeline.OppositeContent>
                                        <Timeline.Separator className="position-md-relative">
                                            <Timeline.Dot className="bg-primary-subtle">
                                                <FontAwesomeIcon
                                                    icon={item.type === 'add' ? faPlus : item.type === 'update' ? faEdit : item.type === 'delete' ? faTrash : faEdit}
                                                    className="text-primary-dark fs-10"
                                                />
                                            </Timeline.Dot>
                                            {index !== result.length - 1 && (
                                                <Timeline.Bar className="h-100 border-dashed" />
                                            )}
                                        </Timeline.Separator>
                                    </Col>
                                    <Col>
                                        <Timeline.Content>
                                            <h5 className="fs-9 lh-sm text-capitalize" style={{ color: getColor(item.stage, 'stage') }}>{translate(props.lang, getLabel(item?.stage, "stage"))}</h5>
                                            <p className="fs-9 fw-bold">
                                                {translate(props.lang, "update by")} {item?.worker}
                                                <Link className="fw-semibold" to="#!">
                                                    {item.created_by}
                                                </Link>
                                            </p>
                                        </Timeline.Content>
                                    </Col>
                                </Row>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            )}

            {!loading && result.length > 0 ? (
                <div className="my-3">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        forcePage={pageNumber - 1}
                        onPageChange={changePage}
                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                        disabledClassName={"pagination__link--disabled text-muted"}
                        activeClassName={"pagination__link--active text-white"}
                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ActivityTimeline;
