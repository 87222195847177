import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from 'components/base/Timeline';
import moment from 'moment';
import NoResultFound from 'page/Components/NoResultFound';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { translate } from 'utils/translate';

const PaymentLog = (props) => {
    const [logList, setLogList] = useState([]);

    useEffect(() => {
        if (props.project_financial_log) {
            setLogList(props.project_financial_log);
        }
    }, [props.lang, props.project_financial_log])

    return (
        <div>
            <div className="py-3 px-4 px-lg-6 bg-light dark__bg-gray-1100 position-relative top-1">
                <span className="d-block my-3 fs-7 fw-bold">{translate(props.lang, "Payment Logs")}</span>
                {logList.length > 0 ? (
                <Timeline variant="vertical">
                    {
                        logList && logList.map((item, index) => (
                            <Timeline.Item className="position-relative" key={item.id}>
                                <Row className="g-md-3">
                                    <Col xs={12} md="auto" className="d-flex">
                                        <Timeline.OppositeContent>
                                            <p className="fs-10 fw-semibold text-body-tertiary text-opacity-85 text-end">
                                                {moment(item.created_at).format('DD MMM, YYYY')}
                                                <br className="d-none d-md-block" />{' '}
                                                {moment(item.created_at).format('HH:mm a')}
                                            </p>
                                        </Timeline.OppositeContent>
                                        <Timeline.Separator className="position-md-relative">
                                            <Timeline.Dot className="bg-primary-subtle">
                                                <FontAwesomeIcon
                                                    icon={item.action.toLowerCase().includes('created') ? faPlus : item?.action.toLowerCase().includes('voided') ? faEdit : item.action?.toLowerCase().includes('updated') ? faEdit : faEdit}
                                                    className="text-primary-dark fs-10"
                                                />
                                            </Timeline.Dot>
                                            {index !== logList.length - 1 && (
                                                <Timeline.Bar className="h-100 border-dashed" />
                                            )}
                                        </Timeline.Separator>
                                    </Col>
                                    <Col>
                                        <Timeline.Content>
                                            <p className="fs-9 mb-0">{item?.description}</p>
                                            <p className="fs-9 fw-bold">{translate(props.lang, "update by")} {item?.creator?.name}</p>
                                        </Timeline.Content>
                                    </Col>
                                </Row>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
                ) : (
                    <NoResultFound />
                )}
            </div>
        </div>
    );
};

export default PaymentLog;
