import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import { Editor } from '@tinymce/tinymce-react';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import Swal from 'sweetalert2';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'store/actions/AuthActions';
import Error404 from 'pages/error/Error404';

const NewJobPosition = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        name: null,
        scope: null,
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState('US');
    const [formData, setFormData] = useState({
        name: '',
        scope: ''
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Setting'), url: '#' },
        { label: translate(props.lang, 'Job Position'), url: '/job-position/overview' },
        { label: translate(props.lang, 'New Job Position'), active: true }
    ]

    useEffect(() => {
        if (props.lang === 'ZH') {
            setLanguage('zh_CN')
        } else {
            setLanguage('US')
        }
    }, [props.lang])

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.scope) {
            errorMsg.scope = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.add_worker_position, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been created successfully"),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        navigate('/job-position/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    return (
        props?.auth?.permission?.createWorkerPosition ? (
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <h2 className="mb-4">{translate(props.lang, "New Job Position")}</h2>
                <Row className='align-items-center justify-content-center'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Position Name")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="name"
                                    placeholder={translate(props.lang, "Enter job position name")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className={`groupLabel-input py-1 ${errors.scope ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Scope")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="scope"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.scope}
                                    placeholder={translate(props.lang, "Enter job scope")}
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.scope)}</span>
                        </div>
                    </Col>

                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/job-position/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        ) : <Error404 />
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewJobPosition);
