import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import { translate } from 'utils/translate';

const TeamMembers = (props) => {
    return (
        <div>
            {props.projectInfo && props.projectInfo.owner.filter(pic => pic.type === 'sales owner').length > 0 ? (
                <>
                    <h4 className="text-body-emphasis mb-4">{translate(props.lang, "Sales Owner")}</h4>
                    <div className="d-flex mb-4 gap-1">
                        {props.projectInfo && props.projectInfo.owner ?
                            props.projectInfo.owner.filter(pic => pic.type === 'sales owner').map((person, j) => (
                                <div className="position-relative text-center hoverMe d-flex align-items-center" key={j}>
                                    <Avatar size="l" variant="name">
                                        <span>{person.oWorker && person.oWorker.name ? person.oWorker.name.substring(0, 1) : ''}</span>
                                    </Avatar>
                                    <div className="tooltip-name fs-10 fw-bold">{person.oWorker && person.oWorker.name ? person.oWorker.name : ''}</div>
                                </div>
                            )) : null}
                    </div>
                </>) : null}
            {props.projectInfo && props.projectInfo.owner.filter(pic => pic.type === 'person in charge').length > 0 ? (
                <>
                    <h4 className="text-body-emphasis mb-4">{translate(props.lang, "Person In Charge")}</h4>
                    <div className="d-flex mb-4 gap-1">
                        {props.projectInfo && props.projectInfo.owner ?
                            props.projectInfo.owner.filter(pic => pic.type === 'person in charge').map((person, j) => (
                                <div className="position-relative text-center hoverMe d-flex align-items-center" key={j}>
                                    <Avatar size="l" variant="name">
                                        <span>{person.oWorker && person.oWorker.name ? person.oWorker.name.substring(0, 1) : ''}</span>
                                    </Avatar>
                                    <div className="tooltip-name fs-10 fw-bold">{person.oWorker && person.oWorker.name ? person.oWorker.name : ''}</div>
                                </div>
                            )) : null}
                    </div>
                </>
            ) : null}
            {props.projectInfo && props.projectInfo.label ? (
                <>
                    <h4 className="text-body-emphasis mb-4">{translate(props.lang, "Label")}</h4>
                    <div className="d-flex flex-wrap gap-2">
                        {props.projectInfo && props.projectInfo.label ? props.projectInfo.label.split(',').map(tag => (
                            <Badge variant="tag" key={tag}>
                                {tag}
                            </Badge>
                        )) : null}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default TeamMembers;
