import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'store/actions/AuthActions';

const EditRanking = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    let errorObj = {
        name: null,
        term: null,
        ranking: null,
        permission: null
    }
    const [permissionList, setPermissionList] = useState([]);
    const [allPermission, setAllPermission] = useState(false);
    const [errors, setErrors] = useState(errorObj);
    const [rankingInfo, setRankingInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        term: '',
        ranking: '',
        permission: null
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Setting'), url: '#' },
        { label: translate(props.lang, 'Ranking'), url: '/ranking/overview' },
        { label: translate(props.lang, 'Edit Ranking'), active: true }
    ]

    const getPermission = () => {
        fetch(api.get_permissions, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setPermissionList(responseJson.data);
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_worker_role_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setRankingInfo(responseJson.data)
                    let newFormData = { ...formData };
                    newFormData.name = responseJson.data.name;
                    newFormData.term = responseJson.data.term;
                    newFormData.ranking = responseJson.data.rank;
                    newFormData.permission = responseJson.data.default_permission;
                    setFormData(newFormData);
                    let check = true;
                    Object.keys(responseJson.data.default_permission).map((item) => {
                        if (!responseJson.data.default_permission[item]) {
                            check = false;
                        }
                    })
                    setAllPermission(check)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    useState(() => {
        if (props?.auth?.permission?.editWorkerRanking) {
            getInfo();
            getPermission();
        }
    }, [id, props.auth.permission]);

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        if (fieldName === 'ranking') {
            if (Number(fieldValue) > 0) {
                newFormData[fieldName] = Number(fieldValue);
            } else {
                newFormData[fieldName] = '';
            }
        } else {
            newFormData[fieldName] = fieldValue;
        }

        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.term) {
            errorMsg.term = "This field is required.";
            check = false;
        }
        if (!formData.ranking) {
            errorMsg.ranking = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_worker_role + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        getInfo();
                        setEditable(false)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        getInfo();
        setErrors(errorObj);
        setEditable(false)
    }

    const toggleStatus = (status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.edit_worker_role_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been update successful."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    const togglePermission = (item) => {
        if (editable) {
            let newPermission = { ...formData };
            newPermission.permission[item] = newPermission.permission[item] ? 0 : 1;
            setFormData(newPermission);
        }
    }

    const selectAllPermission = () => {
        let currentPermission = allPermission;
        if (editable) {
            let newPermission = 0;
            if (currentPermission === 1) {
                newPermission = 0;
            } else {
                newPermission = 1;
            }

            if (formData.permission && Object.keys(formData.permission).length > 0) {
                let newFormData = { ...formData };
                Object.keys(newFormData.permission).map((item) => {
                    newFormData.permission[item] = newPermission;
                })
                setFormData(newFormData)
            }
            setAllPermission(newPermission);
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Ranking")}</h2>
            {props?.auth?.permission?.editWorkerRanking ? (
                <Row className='align-items-center justify-content-end'>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                        {!editable ? (
                            <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                            </Button>
                        ) : null}
                        {rankingInfo ? (
                            rankingInfo.status === 'active' ? (
                                <Button variant="outline-danger" onClick={() => toggleStatus('active')}>
                                    <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Disable")}</span>
                                </Button>
                            ) : (
                                <Button variant="outline-success" onClick={() => toggleStatus('inactive')}>
                                    <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Enable")}</span>
                                </Button>
                            )
                        ) : null}

                    </Col>
                </Row>
            ) : null}
            <Row className='align-items-center justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Ranking Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter ranking name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                readOnly={!editable}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.term ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Ranking Code")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="term"
                                placeholder={translate(props.lang, "Enter ranking code")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.term}
                                autoComplete="off"
                                readOnly={true}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.term)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.ranking ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Ranking Level")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type='number'
                                name="ranking"
                                placeholder={translate(props.lang, "Enter ranking level")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.ranking}
                                onWheel={event => event.currentTarget.blur()}
                                autoComplete="off"
                                readOnly={!editable}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.ranking)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className="text-start">
                        <div className={`py-1`}>
                            <span className={`fw-semibold fs-9 text-grey-1 d-block mb-1`}>{translate(props.lang, "Permission")}</span>

                            <table className="table table-bordered table-list">
                                <tbody>
                                    {formData.permission && Object.keys(formData.permission).length > 0 ? (
                                        <>
                                            <tr onClick={() => selectAllPermission()}>
                                                <td className="fs-9 lh-base px-2">{translate(props.lang, "Select All")}</td>
                                                <td>
                                                    {allPermission ? (<img src={require('../../assets/img/checkedBox.svg').default} alt="checked" />) : (<img src={require('../../assets/img/uncheckedBox.svg').default} alt="checked" />)}
                                                </td>
                                            </tr>
                                            {permissionList.map((item, i) => (
                                                <>
                                                    <tr key={i}>
                                                        <td colSpan={2} className="text-uppercase fs-9 fw-bold px-2">{item.module}</td>
                                                    </tr>
                                                    {item.permission.map((singleItem, x) => (
                                                        <tr onClick={() => togglePermission(singleItem.value)} key={x}>
                                                            <td className="fs-9 lh-base px-2">{singleItem.name}</td>
                                                            <td>{formData.permission[singleItem.value] ? (<img src={require('../../assets/img/checkedBox.svg').default} alt="checked" />) : (<img src={require('../../assets/img/uncheckedBox.svg').default} alt="checked" />)}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                        </>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
                {editable ? (
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/ranking/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditRanking);
