import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const TeamMembers = (props) => {
    return (
        <div>
            {props.taskInfo && props.taskInfo.task[0]?.project_worker.filter(pic => pic.worker.status === 'active').length > 0 ? (
                <>
                    <h4 className="text-body-emphasis mb-4">{translate(props.lang, "Person In Charge")}</h4>
                    <div className="d-flex mb-4 gap-1">
                        {props.taskInfo && props.taskInfo.task[0]?.project_worker ?
                            props.taskInfo.task[0]?.project_worker.map((person, j) => (
                                <div className="position-relative text-center hoverMe d-flex align-items-center" key={j}>
                                    <Avatar size="l" variant="name">
                                        <span>{person.worker && person.worker.name ? person.worker.name.substring(0, 1) : ''}</span>
                                    </Avatar>
                                    <div className="tooltip-name fs-10 fw-bold">{person.worker && person.worker.name ? person.worker.name : ''}</div>
                                </div>
                            )) : null}
                    </div>
                </>
            ) : null}
            {props.taskInfo && props.taskInfo.label ? (
                <>
                    <h4 className="text-body-emphasis mb-4">{translate(props.lang, "Label")}</h4>
                    <div className="d-flex flex-wrap gap-2">
                        {props.taskInfo && props.taskInfo.label ? props.taskInfo.label.split(',').map(tag => (
                            <Badge variant="tag" key={tag}>
                                {tag}
                            </Badge>
                        )) : null}
                    </div>
                </>
            ) : null}
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TeamMembers);
