import { faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor, minutesToHour } from 'utils/function';
import { translate } from 'utils/translate';
const { RangePicker } = DatePicker;
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { logout } from 'store/actions/AuthActions';
import { useDispatch } from 'react-redux';

const TimeAttendanceList = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [productPerpage, setProductPerpage] = useState(10)
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const timerRef = useRef(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Time Attendance'), url: '#' },
        { label: translate(props.lang, 'List'), active: true }
    ]

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getList();
            setFirstLoad(false)
        }
    }, [props.auth.auth.token]);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [props.lang, productPerpage, startDate, endDate]);

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_my_clock + "?page=" + page + "&limit=" + productPerpage;

            if (startDate && endDate) {
                url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        setTotalResultCount(responseJson.data.subs.total)
                        let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
                        setPageCount(totalPage);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const formatData = (data) => {
        let newData = [];

        data.forEach(item => {
            let formattedData = {
                "Clock In Date": item.clock_in ? moment(item?.clock_in).format("YYYY-MM-DD") : '-',
                "Clock In Time": item.clock_in ? moment(item?.clock_in).format("HH:mm:ss") : '-',
                "Clock Out Date": item.clock_out ? moment(item?.clock_out).format("YYYY-MM-DD") : '-',
                "Clock Out Time": item.clock_out ? moment(item?.clock_out).format("HH:mm:ss") : '-',
                "Duration": item.clock_in && item.clock_out ? calculateWorkLength(item) : "-"
            };
            newData.push(formattedData)
        })
        return newData;
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_my_clock + "?page=" + pageCount + "&limit=" + exportPageSize;
                if (startDate && endDate) {
                    url = url + `&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.total;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, 'time_attendance')
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    const handleTimeChange = (e) => {
        const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
        const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
        setStartDate(formattedFromDate);
        setEndDate(formattedToDate);
    }

    const calculateWorkLength = (data) => {
        let startTime = new Date(data.clock_in);  
        let endTime = new Date(data.clock_out);

        // Get the difference in milliseconds
        const diffInMilliseconds = endTime - startTime;

        // Convert the difference to seconds, minutes, hours, and days
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const seconds = diffInSeconds % 60;

        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const minutes = diffInMinutes % 60;

        const diffInHours = Math.floor(diffInMinutes / 60);
        const hours = diffInHours % 24;

        const days = Math.floor(diffInHours / 24);

        let formattedDuration = "";
        if(days){
            formattedDuration += `${days} ${translate(props.lang, "days")}`;
        } 
        formattedDuration += ` ${hours}  ${translate(props.lang, "hour")} ${minutes} ${translate(props.lang, "minutes")} ${seconds} ${translate(props.lang, "seconds")}`

        return formattedDuration;
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, "Time Attendance")}</h2>
                <div className="mb-4">
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                <RangePicker
                                    className="w-100 input-transparent px-2 py-2 h-100"
                                    placeholder={[
                                        translate(props.lang, "From"),
                                        translate(props.lang, "To"),
                                    ]}
                                    onChange={(e) => handleTimeChange(e)}
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                                {loadingExcel ? (
                                    <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                        {translate(props.lang, "Export")}
                                    </>
                                )}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <div className='table-responsive'>
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    <th className='py-2 text-center'>{translate(props.lang, "Clock In Date")}</th>
                                    <th className='py-2 text-center'>{translate(props.lang, "Clock In Time")}</th>
                                    <th className='py-2 text-center'>{translate(props.lang, "Clock Out Date")}</th>
                                    <th className='py-2 text-center'>{translate(props.lang, "Clock Out Time")}</th>
                                    <th className='py-2 text-center'>{translate(props.lang, "Total Hours Worked")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={5}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result.length > 0 ? (
                                        result.map((item, i) => (
                                            <tr role="row" key={i}>
                                                <td className='fs-9 py-2 text-center'>{item.clock_in ? moment(item?.clock_in).format("YYYY-MM-DD") : "-"}</td>
                                                <td className='fs-9 py-2 text-center'>{item.clock_in ? moment(item?.clock_in).format("HH:mm:ss") : "-"}</td>
                                                <td className='fs-9 py-2 text-center'>{item.clock_out ? moment(item?.clock_out).format("YYYY-MM-DD") : "-"}</td>
                                                <td className='fs-9 py-2 text-center'>{item.clock_out ? moment(item?.clock_out).format("HH:mm:ss") : "-"}</td>
                                                <td className='td-text text-center py-2 fs-9 text-capitalize'>{item.clock_in && item.clock_out ? calculateWorkLength(item) : "-"}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={5}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </Table>
                    </div>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TimeAttendanceList);