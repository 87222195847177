import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const Feedback = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        title: '',
        description: ''
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Feedback'), url: '#' },
        { label: translate(props.lang, 'Form'), active: true }
    ]

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };

        if (!formData.title) {
            errorMsg.title = "This field is required.";
            check = false;
        }
        if (!formData.description) {
            errorMsg.description = "This field is required.";
            check = false;
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.add_feedback, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been submit successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        resetForm()
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setFormData({
            title: '',
            description: '',
        });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Submit Feedback Form")}</h2>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.title ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Title")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="title"
                                placeholder={translate(props.lang, "Enter title")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.title}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.title)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <Form.Control
                                as="textarea"
                                name="description"
                                className="form-control input-transparent py-1 px-3"
                                rows={3}
                                value={formData.description}
                                placeholder={translate(props.lang, "Enter description")}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => resetForm()}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Feedback);
