import { faEllipsis, faSpinner, faStopCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import NewTimesheet from 'page/Timesheet/NewTimesheet';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Dropdown, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { priorityList, shownItem, stageList, statusList } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const MyTask = (props) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { config } = useAppContext();
    const errorObj = { remark: '' };
    const [errors, setErrors] = useState(errorObj);
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [assignedToMe, setAssignedToMe] = useState(true);
    const [workerName, setWorkerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [status, setStatus] = useState(null);
    const [taskType, setTaskType] = useState('');
    const [stage, setStage] = useState('');
    const [priority, setPriority] = useState('');
    const [expandedRowId, setExpandedRowId] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [timesheetPanel, setTimesheetPanel] = useState(false);
    const [timerPanel, setTimerPanel] = useState(false);
    const [selectedTimer, setSelectedTimer] = useState(null);
    const [remark, setRemark] = useState('')
    const [currentRunningTask, setCurrentRunningTask] = useState(null);
    const [loadingAction, setLoadingAction] = useState('')
    const [currentRunningTaskTimer, setCurrentRunningTaskTimer] = useState({
        hours: '00',
        minutes: '00',
        seconds: '00'
    });

    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });

    const timerRef = useRef(null);
    const changePage = (event) => {
        setLoading(true);
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (status) {
            if (status.length > 0) {
                status.map((s, i) => {
                    let tempData = statusList.filter(item => item.value === s.value);
                    if (tempData.length > 0) {
                        s.label = translate(props.lang, tempData[0].label)
                    }
                })
            }
        }
    }, [props.lang])

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (firstLoad) {
                getList()
                setFirstLoad(false)
            }
        }
        return () => {
            isMounted = false;  // Clean up when component is unmounted
        };
    }, [])

    useEffect(() => {
        if (!firstLoad) {
            setLoading(true);
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, projectName, status, priority, stage, name]);

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getList = (page = pageNumber) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_project_tasks + "?page=" + page + "&limit=" + productPerpage + '&details=1';
            if (projectName) {
                url = url + `&projectName=${projectName}`
            }
            if (name) {
                url = url + `&name=${name}`
            }
            if (status) {
                let statusString = '';
                status.map((item, i) => {
                    statusString += item.value
                    if (i !== status.length - 1) {
                        statusString += ','
                    }
                })
                url = url + `&taskStatus=${statusString}`
            } else {
                let filtered = statusList.filter(item => item.value === 'ongoing');
                if (filtered.length > 0) {
                    setStatus([{ label: translate(props.lang, filtered[0].label), value: filtered[0].value }]);
                    url = url + `&taskStatus=${filtered[0].value}`
                }
            }
            if (priority) {
                url = url + `&priority=${priority}`
            }
            if (stage) {
                let stageString = '';
                stage.map((item, i) => {
                    stageString += item.value
                    if (i !== stage.length - 1) {
                        stageString += ','
                    }
                })
                url = url + `&stage=${stageString}`
            }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            if (sortBy.subSort && sortBy.subOrder) {
                url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
            }
            if (assignedToMe) {
                url = url + `&me=1`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalTasks / productPerpage);
                        setPageCount(totalPage);
                        setTotalResultCount(responseJson.data.subs.totalTasks);
                        responseJson.data.data.forEach(item => {
                            if (item.task_timesheet.length > 0) {
                                setCurrentRunningTask({ project_task_id: item.project_task_id, start_time: item.task_timesheet[0].start })
                            }
                            item.subs.forEach(subtask => {
                                if (subtask.task_timesheet.length > 0) {
                                    setCurrentRunningTask({ project_task_id: subtask.project_task_id, start_time: subtask.task_timesheet[0].start })
                                }
                            })
                        })
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const secondsToDhms = (seconds) => {
        seconds = Number(seconds);

        var h = Math.floor(seconds / 60);
        let m = Math.round(seconds % 60);

        if (h === 0 && m === 0) {
            return "-";
        } else {
            let currentText = "";
            if (h > 0) {
                if (h.toString().length === 1) {
                    h = "0" + h;
                }
                currentText = currentText + h + ` hours `
            } else {
                currentText = currentText + '0 hour '
            }
            if (m > 0) {
                if (m.toString().length === 1) {
                    m = "0" + m;
                }
                currentText = currentText + m + ` minutes `
            } else {
                currentText = currentText + '0 minutes '
            }
            return currentText;
        }
    }

    const getColor = (val, type) => {
        let color = '#fff';
        if (type === 'status') {
            let filterList = statusList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        } else if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        }

        return color;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    const toggleRow = (id) => {
        let newArr = [...expandedRowId];
        let index = newArr.indexOf(id);
        if (index > -1) {
            newArr.splice(index, 1);
        } else {
            newArr.push(id)
        }
        setExpandedRowId(newArr);
    };

    const closeTimesheetPanel = () => {
        setSelectedTask(null);
        setTimesheetPanel(false)
    }

    const addTimeLog = (item, project) => {
        let data = { task: item, project }
        setSelectedTask(data);
        setTimesheetPanel(true)
    }

    const toggleTimer = (item) => {
        let url = "";
        let message = "";
        let formData = {}
        if (item.task_timesheet.length > 0) {
            setSelectedTimer(item)
            setTimerPanel(true)
        } else {
            url = api.project_task_timer + '/' + item.project.project_id + '/' + item.project_task_id + '/start';
            message = translate(props.lang, "Are you sure you want to start this timer?")
            Swal.fire({
                title: translate(props.lang, "Confirmation"),
                text: message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate(props.lang, "Confirm"),
                cancelButtonText: translate(props.lang, "No"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(url, {
                        method: "PUT",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            "Accept-Language": props.lang.toLowerCase(),
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(formData)
                    })
                        .then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.status === 'success') {
                                getList();
                                // swal(translate(props.lang, "Success"), translate(props.lang, "Project created successful"), "success");
                            } else {
                                if (responseJson.message === 'token expired') {
                                    dispatch(logout());
                                    navigate('/')
                                } else if (responseJson.tokenExpired) {
                                    dispatch(logout());
                                    navigate('/')
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }).catch(error => {
                            console.error("error", error);
                        });
                }
            })
        }

    }

    const toggleSubtaskTimer = (main, item) => {
        let url = "";
        let message = "";
        let formData = {}
        if (item.task_timesheet.length > 0) {
            let obj = { ...item, project: { project_id: main.project.project_id } }
            setSelectedTimer(obj)
            setTimerPanel(true)

        } else {
            url = api.project_task_timer + '/' + main.project.project_id + '/' + item.project_task_id + '/start';
            message = translate(props.lang, "Are you sure you want to start this timer?")
            Swal.fire({
                title: translate(props.lang, "Confirmation"),
                text: message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate(props.lang, "Confirm"),
                cancelButtonText: translate(props.lang, "No"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(url, {
                        method: "PUT",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            "Accept-Language": props.lang.toLowerCase(),
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(formData)
                    })
                        .then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.status === 'success') {
                                getList();
                            } else {
                                if (responseJson.message === 'token expired') {
                                    dispatch(logout());
                                    navigate('/')
                                } else if (responseJson.tokenExpired) {
                                    dispatch(logout());
                                    navigate('/')
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }).catch(error => {
                            console.error("error", error);
                        });
                }
            })
        }
    }

    useEffect(() => {
        if (currentRunningTask) {
            setTimeout(() => {
                setCurrentRunningTaskTimer(calculateTimer())
            }, 1000);
        }
    });

    const calculateTimer = () => {
        let date1 = new Date();
        let date2 = new Date(currentRunningTask.start_time);
        date1 = new Date(date1);
        date2 = new Date(date2);

        let seconds = Math.floor((date1 - (date2)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
        const difference = +date1 - +date2;
        let timeLeft = {};

        if (difference > 0) {
            if (hours.toString().length === 1) {
                hours = "0" + hours;
            }
            if (minutes.toString().length === 1) {
                minutes = "0" + minutes;
            }
            if (seconds.toString().length === 1) {
                seconds = "0" + seconds;
            }
            timeLeft = {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        return timeLeft;
    }

    const closeTimerPanel = () => {
        setTimerPanel(false);
        setRemark('');
        setSelectedTimer(null);
        setErrors(errorObj);
    }

    const submitForm = () => {
        let errorMsg = { ...errorObj };
        if (!remark) {
            errorMsg.remark = "This field is required.";
            setErrors(errorMsg);
            // Swal.fire({
            //     icon: "error",
            //     title: translate(props.lang, 'Error'),
            //     text: translate(props.lang, "Please fill in remark"),
            //     background: getSweelAlertBg(), // Dark background color
            //     color: getSweelAlertTextColor(), // Text color
            // });
        } else {
            setErrors(errorObj);
            setLoadingAction(true);
            let formData = {}
            formData.project_task_timer_id = selectedTimer.task_timesheet[0].project_task_timer_id;
            formData.remark = remark;
            fetch(api.project_task_timer + '/' + selectedTimer.project.project_id + '/' + selectedTimer.project_task_id + '/stop', {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoadingAction(false);
                    if (responseJson.status === 'success') {
                        getList();
                        setSelectedTimer(null);
                        setTimerPanel(false);
                        setRemark('');
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    return (
        <>
            <div>
                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <h3 className="text-body-emphasis my-3">{translate(props.lang, "Task Overview")}</h3>
                    <div className="mb-4">
                        <Row className="g-3">
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="name"
                                        placeholder={translate(props.lang, "Search by task name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={name}
                                        autoComplete="off"
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 py-1`}>
                                    <input
                                        type={'text'}
                                        name="projectName"
                                        placeholder={translate(props.lang, "Search by project name")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={projectName}
                                        autoComplete="off"
                                        onChange={e => setProjectName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={statusList.length > 0 ? statusList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select status")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={status}
                                        name="status"
                                        onChange={(e) => setStatus(e)}
                                        isMulti
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={stageList.length > 0 ? stageList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select stage")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={stage}
                                        name="stage"
                                        isMulti
                                        onChange={(e) => setStage(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12}>
                                <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                    <Select
                                        options={priorityList.length > 0 ? priorityList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                        placeholder={translate(props.lang, "Select priority")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={priorityList.filter(option => option.value === priority).length > 0 ? priorityList.filter(option => option.value === priority).map((item, i) => ({ key: i, value: item.value, label: item.label })) : null}
                                        name="priority"
                                        onChange={(e) => setPriority(e ? e.value : '')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='table-responsive'>
                        <Table responsive="sm" className='table-custom'>
                            <thead>
                                <tr>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('name', false)}>
                                            <span className="pe-2">{translate(props.lang, "Task")}</span>
                                            {sortBy.sortField === 'name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                            <span className="pe-2">{translate(props.lang, "Project")}</span>
                                            {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                            <span className="pe-2">{translate(props.lang, "Time Log")}</span>
                                            {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                            <span className="pe-2">{translate(props.lang, "Status")}</span>
                                            {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                            <span className="pe-2">{translate(props.lang, "Stage")}</span>
                                            {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th className='py-2'>
                                        <div className="d-flex cursor-pointer" onClick={() => setSorting('email', false)}>
                                            <span className="pe-2">{translate(props.lang, "Priority")}</span>
                                            {sortBy.sortField === 'email' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                        </div>
                                    </th>
                                    <th>{translate(props.lang, "Start Date")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={8}>
                                            <DataLoading theme='light' />
                                        </td>
                                    </tr>
                                ) : (
                                    result.length > 0 ? (
                                        <>
                                            {result.map((item, i) => (
                                                <>
                                                    <tr role="row" key={i}>
                                                        <td className='th-text fs-9 fw-normal px-2' style={{ minWidth: '200px' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <span className='fw-semibold fs-9 cursor-pointer'>{item?.name}</span>
                                                                </div>
                                                                {item.subs.length > 0 ? (
                                                                    <div className="ms-2 cursor-pointer">
                                                                        <Badge bg="info" variant="phoenix" onClick={() => toggleRow(i)}>
                                                                            {item.subs.length}
                                                                        </Badge>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                        <td className='th-text fs-9 fw-semibold text-capitalize'>{item?.project?.name}</td>
                                                        <td className='th-text fs-9 fw-semibold'>
                                                            {item.project_worker.length > 0 ? (
                                                                item.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                    <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => toggleTimer(item)}>
                                                                        {item.task_timesheet.length > 0 ? (
                                                                            <>
                                                                                <FontAwesomeIcon icon={faStopCircle} color="red" className="fs-9 timer-icon me-1" />
                                                                                <span className='fs-9 fw-semibold'>{currentRunningTaskTimer.hours}:</span>
                                                                                <span className='fs-9 fw-semibold'>{currentRunningTaskTimer.minutes}:</span>
                                                                                <span className='fs-9 fw-semibold'>{currentRunningTaskTimer.seconds} </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <img src={require('../../assets/img/timer.svg').default} className='timer-icon me-2' alt="timer" />
                                                                                <span className='fs-9 fw-semibold'>{translate(props.lang, "Start")}</span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : null
                                                            ) : null}
                                                        </td>

                                                        <td className="th-text fs-9 fw-normal">
                                                            <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.progress_status, 'status'), color: '#fff' }}>
                                                                <span className='fs-9 fw-bold'>{translate(props.lang, getLabel(item.progress_status, 'status'))}</span>
                                                            </div>
                                                        </td>
                                                        <td className="th-text fs-9 fw-normal">
                                                            <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.stage, 'stage'), color: '#fff' }}>
                                                                <span className='fs-9 fw-bold'>{translate(props.lang, getLabel(item.stage, 'stage'))}</span>
                                                            </div>
                                                        </td>
                                                        <td className='th-text fs-9 fw-normal'>
                                                            <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(item.priority_level, 'priority'), color: '#fff' }}>
                                                                <span className='fs-9 fw-bold'>{translate(props.lang, getLabel(item.priority_level, 'priority'))}</span>
                                                            </div>
                                                        </td>
                                                        <td className='th-text fs-9 fw-semibold text-capitalize'>{item.start_date}</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="phoenix-secondary"
                                                                    className="dropdown-caret-none btn-icon"
                                                                >
                                                                    <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="py-0">
                                                                    {item.project_worker.length > 0 ? (
                                                                        item.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                            <Dropdown.Item onClick={() => addTimeLog(item, item.project)}>{translate(props.lang, "Add Time Log")}</Dropdown.Item>
                                                                        ) : null
                                                                    ) : null}
                                                                    <Dropdown.Item onClick={() => navigate(`/task/details/${item.project_task_id}`, { state: item.project.project_id })}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>
                                                    </tr>
                                                    {item.subs.length > 0 ? (
                                                        <>
                                                            <tr className={`px-0`}>
                                                                <td colSpan={9} className={`p-3 subTask-section ${expandedRowId.includes(i) ? '' : 'd-none'}`}>
                                                                    <Table responsive="sm" className='table-custom mb-0'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className={`th-text px-0`}>{translate(props.lang, "Subtask")}</th>
                                                                                <th className={`th-text`}>{translate(props.lang, "Time Log")}</th>
                                                                                <th className={`th-text`}>{translate(props.lang, "Status")}</th>
                                                                                <th className={`th-text`}>{translate(props.lang, "Stage")}</th>
                                                                                <th className={`th-text`}>{translate(props.lang, "Priority")}</th>
                                                                                <th className={`th-text`}>{translate(props.lang, "Start Date")}</th>
                                                                                <th className={`th-text`}></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {item.subs.map((subtask, j) => (
                                                                                <tr key={j}>
                                                                                    <td className='fw-semibold fs-9 cursor-pointer'><span className='cursor-pointer'>{subtask?.name}</span></td>
                                                                                    <td className='th-text fs-9 fw-semibold'>
                                                                                        {subtask.project_worker.length > 0 ? (
                                                                                            subtask.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                                                <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => toggleSubtaskTimer(item, subtask)}>
                                                                                                    {subtask.task_timesheet.length > 0 ? (
                                                                                                        <>
                                                                                                            <FontAwesomeIcon icon={faStopCircle} color="red" className="fs-9 timer-icon me-1" />
                                                                                                            <span className='fs-9 fw-semibold'>{currentRunningTaskTimer.hours}:</span>
                                                                                                            <span className='fs-9 fw-semibold'>{currentRunningTaskTimer.minutes}:</span>
                                                                                                            <span className='fs-9 fw-semibold'>{currentRunningTaskTimer.seconds} </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img src={require('../../assets/img/timer.svg').default} className='timer-icon me-2' alt="timer" />
                                                                                                            <span className='fs-9 fw-semibold'>{translate(props.lang, "Start")}</span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : null
                                                                                        ) : null}
                                                                                    </td>
                                                                                    <td className="th-text fs-9 fw-normal">
                                                                                        <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(subtask.progress_status, 'status'), color: '#fff' }}>
                                                                                            <span>{translate(props.lang, getLabel(subtask.progress_status, 'status'))}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="th-text fs-9 fw-normal">
                                                                                        <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(subtask.stage, 'stage'), color: '#fff' }}>
                                                                                            <span>{translate(props.lang, getLabel(subtask.stage, 'stage'))}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='th-text fs-9 fw-normal'>
                                                                                        <div className="btn light px-2 text-capitalize text-center status-dropdown w-100 d-flex align-items-center justify-content-center" style={{ background: getColor(subtask.priority_level, 'priority'), color: '#fff' }}>
                                                                                            <span>{translate(props.lang, getLabel(subtask.priority_level, 'priority'))}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="th-text fs-9 fw-normal">{subtask.start_date}</td>
                                                                                    <td>
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle
                                                                                                variant="phoenix-secondary"
                                                                                                className="dropdown-caret-none btn-icon"
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faEllipsis} color="#839AB4" className="fs-9" />
                                                                                            </Dropdown.Toggle>

                                                                                            <Dropdown.Menu className="py-0">
                                                                                                {subtask.project_worker.length > 0 ? (
                                                                                                    subtask.project_worker.filter(singleItem => singleItem.worker.worker_id === props.auth.auth.worker_id).length > 0 ? (
                                                                                                        <Dropdown.Item onClick={() => addTimeLog(subtask, item.project)}>{translate(props.lang, "Add Time Log")}</Dropdown.Item>

                                                                                                    ) : null
                                                                                                ) : null}
                                                                                                <Dropdown.Item onClick={() => navigate(`/task/details/${subtask.project_task_id}`, { state: item.project.project_id })}>{translate(props.lang, "View")}</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </td>

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ) : null}
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <tr role="row">
                                            <td colSpan={8}>
                                                <NoResultFound />
                                            </td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </Table>
                    </div>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination fw-normal fs-9 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink text-white"}
                                        nextLinkClassName={"pagination__nextlink text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
            <Offcanvas className={`w-50`} show={timesheetPanel} onHide={() => closeTimesheetPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <NewTimesheet closePanel={() => closeTimesheetPanel()} selectedTask={selectedTask} />
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas className={`w-50`} show={timerPanel} onHide={() => closeTimerPanel()} placement='end' scroll={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h2 className="mb-4">{translate(props.lang, "Stop Timer")}</h2>
                    <Row className="justify-content-start">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.remark ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <Form.Control
                                        as="textarea"
                                        name="remark"
                                        className="form-control input-transparent py-1 px-3"
                                        rows={3}
                                        value={remark}
                                        placeholder={translate(props.lang, "Enter description")}
                                        onChange={e => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.remark)}</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loadingAction}>
                                <span className="fw-bold fs-9 text-white">{loadingAction ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(MyTask);
