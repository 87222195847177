import { faEdit, faFileExport, faPlus, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const OvertimeSettingList = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { config } = useAppContext();
  const [productPerpage, setProductPerpage] = useState(10)
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [result, setResult] = useState([]);
  const [statistic, setStatistic] = useState(0);
  const timerRef = useRef(null);

  let breadcrumbData = [
    { label: translate(props.lang, 'Setting'), url: '#' },
    { label: translate(props.lang, 'Overtime Approval Setting'), active: true }
  ]

  const changePage = (event) => {
    setPageNumber(event.selected + 1);
    getList(event.selected + 1)
  }

  useEffect(() => {
    if (firstLoad) {
      if (props?.auth?.permission?.allOvertimeSetting) {
        getList();
        setFirstLoad(false)
      }
    }
  }, [props.auth.auth.token]);

  useEffect(() => {
    if (!firstLoad) {
      setPageNumber(1)
      getList(1);
    }
  }, [props.lang, productPerpage]);

  const getList = (page = pageNumber) => {
    setLoading(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      let url = api.get_overtime_settings + "?page=" + page + "&limit=" + productPerpage;

      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          if (responseJson.status === "success") {
            setResult(responseJson.data.data);
            setTotalResultCount(responseJson.data.subs.total)
            let totalPage = Math.ceil(responseJson.data.subs.total / productPerpage);
            setPageCount(totalPage);
            setStatistic(responseJson.data.subs.total)
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else if (responseJson.tokenExpired) {
              dispatch(logout());
              navigate('/')
            } else {
              console.log('error', responseJson.message)
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }, 1000);
  }

  const formatData = (data) => {
    let newData = [];
    data.forEach(entry => {
      let formattedData = {
        "Type": entry.type ? entry.type : '-',
        "Organization": entry.organization ? entry.organization.name : '-',
        "Department": entry.department ? entry.department.name : '-',
      };
      newData.push(formattedData)
    })
    return newData;
  }

  const exportExcel = () => {
    const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
    let processData = [];
    let totaldata = 0;
    if (totalPagesCount > 0) {
      setLoadingExcel(true);
      for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
        let url = api.get_overtime_settings + "?page=" + pageCount + "&limit=" + exportPageSize;

        fetch(url, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accept-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
          }),
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.status === "success") {
              totaldata = responseJson.data.subs.total;
              processData = processData.concat(formatData(responseJson.data.data));
            } else {
              if (responseJson.message === 'token expired') {
                dispatch(logout());
                navigate('/')
              } else if (responseJson.tokenExpired) {
                dispatch(logout());
                navigate('/')
              } else {
                console.log('error', responseJson.message)
              }
            }
            if (processData.length === totaldata) {
              downloadExcel(processData, 'overtime_setting')
              setLoadingExcel(false)
            }
          }).catch(error => {
            console.error("error", error);
          });

        setTimeout(() => { }, 500) // add some delay here to let server take rest.
      }
    } else {
      Swal.fire({
        icon: "error",
        title: translate(props.lang, 'Error'),
        text: translate(props.lang, "No data to export"),
        background: getSweelAlertBg(), // Dark background color
        color: getSweelAlertTextColor(), // Text color
      });
    }
  }

  const removeOTSettingsConfirmation = (id) => {
    Swal.fire({
      title: translate(props.lang, "Confirmation"),
      text: translate(props.lang, "Are you sure you want to remove this data?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0080C7",
      confirmButtonText: translate(props.lang, "Confirm"),
      cancelButtonText: translate(props.lang, "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        removeItem(id)
      }
    });
  }

  const removeItem = (id) => {
    setLoading(true);
    let data = {};
    fetch(api.remove_overtime_setting + "/" + id, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.token
      }),
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        setLoading(false)
        if (responseJson.status === 'success') {
          Swal.fire({
            icon: "success",
            title: translate(props.lang, 'Success'),
            text: translate(props.lang, "Data has been removed successfully"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
          });
          getList();
        } else {
          if (responseJson.message === 'token expired') {
            dispatch(logout());
            navigate('/')
          } else if (responseJson.tokenExpired) {
            dispatch(logout());
            navigate('/')
          } else {
            if (Array.isArray(responseJson.message)) {
              Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: responseJson.message.join('\n'),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
            } else {
              Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: responseJson.message,
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
            }
          }
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  return (
    <div>
      <PageBreadcrumb items={breadcrumbData} />
      <div className="mb-9">
        <h2 className="mb-5">{translate(props.lang, "Overtime Approval Setting")}</h2>
        <div className="mb-4">
          <Row className="g-3">
            <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
              <div className="tableBgContainer py-4 px-4 total">
                <span className="fs-8 fw-bold">{translate(props.lang, "Total Overtime Approval Setting")}</span>
                <div className="dataBg px-2 py-1 mt-2">
                  <span className="fw-bold fs-7 d-block mt-1">{statistic ? statistic : 0}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="g-3">
            {props?.auth?.permission?.allOvertimeSetting ? (
              <Col lg={4} md={4} xs={12} sm={12}>
                <Button variant="secondary" className="text-center w-100" onClick={() => exportExcel()}>
                  {loadingExcel ? (
                    <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                      {translate(props.lang, "Export")}
                    </>
                  )}
                </Button>
              </Col>
            ) : null}
            {props?.auth?.permission?.createOvertimeSetting ? (
              <Col lg={4} md={4} xs={12} sm={12}>
                <Button variant="primary" className='w-100 text-center h-100' onClick={() => { navigate('/overtime-setting/new') }}>
                  <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                  {translate(props.lang, "Add Overtime Approval Setting")}
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
          <div className='table-responsive'>
            <Table responsive="sm" hover>
              <thead>
                <tr>
                  {/* <th className='py-2 text-start px-0'>{translate(props.lang, "No.")}</th> */}
                  <th className='py-2 text-start'>{translate(props.lang, "Type")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Organization")}</th>
                  <th className='py-2 text-start'>{translate(props.lang, "Department")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4}>
                      <DataLoading theme='light' />
                    </td>
                  </tr>
                ) : (
                  result.length > 0 ? (
                    result.map((item, i) => (
                      <tr role="row" key={i}>
                        {/* <td className='fs-9 py-2'>
                          {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                          {i + 1 === productPerpage ? '0' : i + 1}.
                        </td> */}
                        <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '100px' }}>{item.type ? translate(props.lang, item.type[0].toUpperCase() + item.type.slice(1)) : '-'}</td>
                        <td className='td-text py-2 fs-9'>{item.organization ? item.organization.name ? item.organization.name : '-' : '-'}</td>
                        <td className='td-text py-2 fs-9'>{item.department ? item.department.name : '-'}</td>
                        <td className='py-2' style={{ minWidth: '100px' }}>
                          <div className='d-flex align-items-center justify-content-center'>
                            <Link to={`/overtime-setting/edit/${item.overtime_setting_id}`} className='me-3'>
                              <FontAwesomeIcon icon={faEdit} color="#839AB4" fontSize={20} />
                            </Link>
                            {/* <div className='cursor-pointer' onClick={() => removeOTSettingsConfirmation(item.overtime_setting_id)}>
                              <FontAwesomeIcon icon={faTrashAlt} color="red" fontSize={18} />
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr role="row">
                      <td colSpan={4}>
                        <NoResultFound />
                      </td>
                    </tr>
                  )
                )}

              </tbody>
            </Table>
          </div>
          <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
              <div className='d-flex w-100 align-items-center justify-content-end'>
                <span className='fs-9'>{translate(props.lang, "Results per page")}</span>
                <div className="input-group-select-paginate ms-2 px-2">
                  <Select
                    options={
                      shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                    }
                    className="input-transparent w-100 text-capitalize px-0"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                    name="productPerpage"
                    isSearchable={false}
                    onChange={(e) => setProductPerpage(e ? e.value : '')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center my-3'>
            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
              {!loading && result.length > 0 ? (
                <div className="my-3">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    forcePage={pageNumber - 1}
                    onPageChange={changePage}
                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                    disabledClassName={"pagination__link--disabled text-muted"}
                    activeClassName={"pagination__link--active text-white"}
                    marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(OvertimeSettingList);
