import DateFnsUtils from "@date-io/date-fns";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import countries from 'utils/countries';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';
import phoneCodeList from "../../utils/phoneCodeList";
import { logout } from "store/actions/AuthActions";
import { useDispatch } from "react-redux";

const genderList = [
    { value: "Female", label: 'Female' },
    { value: 'Male', label: 'Male' },
]

const jobTypesList = [
    { value: 'Full-time', label: 'Full-time' },
    { value: 'Part-time', label: 'Part-time' },
    { value: 'Contract', label: 'Contract' },
    { value: 'Internship', label: 'Internship' },
    { value: 'Temporary', label: 'Temporary' },
]

const ableOT = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
]

const EditUser = (props) => {
    const dispatch = useDispatch();
    let errorObj = {
        "name": null,
        "role": null,
        "position": null,
        "organizationId": null,
        "departmentId": null,
        "username": null,
        "employeeNumber": null,
        "email": null,
        "identity_card": null,
        "country_code": null,
        "phone": null,
        "epf_number": null,
        "socso_number": null,
        "payment_frequency": null,
        "address": {
            "address1": null,
            "address2": null,
            "town": null,
            "postcode": null,
            "state": null,
            "country": null
        },
        "residency": null,
        "residency_type": null,
        "bank": {
            "bank_name": null,
            "account_name": null,
            "account_number": null
        },
        "job_type": null,
        "contract_start": null,
        "contract_period": 0,
        "contract_end": null,
        "date_of_birth": null,
        "salary": null,
        "working_day": null,
        "working_hour": null,
        "gender": null,
        "nationality": null,
        "able_claim_ot": null
    }
    const mandatoryField = {
        name: '',
        role: '',
        organizationId: '',
        departmentId: '',
        username: '',
        position: '',
        job_type: '',
        identity_card: '',
        employeeNumber: '',
        able_claim_ot: ''
    }
    const navigate = useNavigate();
    const { config } = useAppContext();
    const infoRef = useRef(null);
    const { id } = useParams();
    const [userInfo, setUserInfo] = useState(null);
    const [editable, setEditable] = useState(false);
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [workerRoleList, setWorkerRoleList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [filteredDepartmentList, setFilteredDepartmentList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [tab, setTab] = useState(1);
    const [userPermissions, setUserPermissions] = useState(null);
    const [permissionList, setPermissionList] = useState([]);
    const [allPermission, setAllPermission] = useState(false);
    const [formData, setFormData] = useState({
        "name": "",
        "role": "",
        "position": "",
        "organizationId": "",
        "departmentId": "",
        "username": "",
        "employeeNumber": "",
        "email": "",
        "identity_card": "",
        "country_code": "",
        "phone": "",
        "epf_number": "",
        "socso_number": "",
        "payment_frequency": 0,
        "address": {
            "address1": "",
            "address2": "",
            "town": "",
            "postcode": "",
            "state": "",
            "country": ""
        },
        "residency": "",
        "residency_type": "",
        "bank": {
            "bank_name": "",
            "account_name": "",
            "account_number": ""
        },
        "job_type": "",
        "contract_start": "",
        "contract_period": 0,
        "contract_end": "",
        "date_of_birth": "",
        "salary": 0,
        "working_day": 22,
        "working_hour": 8,
        "gender": "",
        "nationality": "",
        "able_claim_ot": "false"
    })
    let breadcrumbData = [
        { label: translate(props.lang, 'Setting'), url: '#' },
        { label: translate(props.lang, 'User'), url: '/user/overview' },
        { label: translate(props.lang, 'Edit User'), active: true }
    ]

    useEffect(() => {
        if (props?.auth?.permission?.allWorker) {
            let isMounted = true;
            if (isMounted) {
                getInfo();
                getPermission();
                getOrganization();
                getUserRole();
                getDepartment();
                getJobPosition();
            }
            return () => {
                isMounted = false;  // Clean up when component is unmounted
            };
        }
    }, [props.auth.permission])

    const getPermission = () => {
        fetch(api.get_permissions, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setPermissionList(responseJson.data);
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getInfo = () => {
        clearTimeout(infoRef.current);
        infoRef.current = setTimeout(() => {
            if (id) {
                fetch(api.get_worker_details + '/' + id, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            setUserInfo(responseJson.data)
                            // setUserPermissions(responseJson.data.permission)
                            setUserPermissions(Object.assign({}, responseJson.data.permission, responseJson.data.special_permission))
                            let newFormData = { ...formData };
                            newFormData.name = responseJson.data.name;
                            newFormData.role = responseJson.data.worker_assigned_role.role.role_id;
                            newFormData.position = responseJson.data.worker_position.position_id;
                            newFormData.organizationId = responseJson.data.department.organization.organization_id;
                            newFormData.departmentId = responseJson.data.department.department_id;
                            newFormData.username = responseJson.data.username;
                            newFormData.country_code = responseJson.data.country_code;
                            newFormData.phone = responseJson.data.phone;
                            newFormData.email = responseJson.data.email;
                            newFormData.epf_number = responseJson.data.epf_number;
                            newFormData.socso_number = responseJson.data.socso_number;
                            newFormData.payment_frequency = responseJson.data.payment_frequency;
                            newFormData.residency = responseJson.data.residency;
                            newFormData.residency_type = responseJson.data.residency_type;
                            newFormData.job_type = responseJson.data.job_type;
                            newFormData.contract_start = responseJson.data.contract_start;
                            newFormData.contract_period = responseJson.data.contract_period;
                            newFormData.contract_end = responseJson.data.contract_end;
                            newFormData.date_of_birth = responseJson.data.date_of_birth;
                            newFormData.salary = responseJson.data.salary;
                            newFormData.working_day = responseJson.data.working_day;
                            newFormData.working_hour = responseJson.data.working_hour;
                            newFormData.gender = responseJson.data.gender;
                            newFormData.nationality = responseJson.data.nationality;
                            newFormData.identity_card = responseJson.data.identity_card;
                            newFormData.employeeNumber = responseJson.data.employee_number;
                            newFormData.able_claim_ot = (responseJson.data.ableClaimOT).toString();
                            if (responseJson.data.address.length > 0) {
                                newFormData.address.address1 = responseJson.data.address[0].address1;
                                newFormData.address.address2 = responseJson.data.address[0].address2;
                                newFormData.address.town = responseJson.data.address[0].town;
                                newFormData.address.postcode = responseJson.data.address[0].postcode;
                                newFormData.address.state = responseJson.data.address[0].state;
                                newFormData.address.country = responseJson.data.address[0].country;
                            }
                            if (responseJson.data.bank.length > 0) {
                                newFormData.bank.bank_name = responseJson.data.bank[0].bank_name;
                                newFormData.bank.account_name = responseJson.data.bank[0].account_name;
                                newFormData.bank.account_number = responseJson.data.bank[0].account_number;
                            }
                            setFormData(newFormData);
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }

                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        }, 1000);
    }

    useEffect(() => {
        if (formData.organizationId && departmentList.length > 0) {
            let filterList = departmentList.filter(item => item.organization.organization_id === formData.organizationId);
            setFilteredDepartmentList(filterList);
        }

    }, [formData.organizationId, departmentList])

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        if (type === 'organizationId') {
            if (!value) {
                newFormData.departmentId = ''
            } else {
                let filterList = departmentList.filter(item => item.organization.organization_id === value);
                setFilteredDepartmentList(filterList);
            }
        }
        setFormData(newFormData);
    }

    const handleAddFormChangeAddress = (e) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        let newFormData = { ...formData };
        newFormData.address[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            newFormData[type] = moment(e[0]).format('YYYY-MM-DD');
        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const handleOptionSelectedAddress = async (e) => {
        const { value } = e;
        let newFormData = { ...formData };
        newFormData.address['country'] = value;
        setFormData(newFormData);
    }

    const handleAddFormChangeBank = (e, type) => {
        let newFormData = { ...formData };
        newFormData.bank[type] = e.target.value
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        Object.keys(mandatoryField).map((item, i) => {
            if (!formData[item]) {
                errorMsg[item] = "This field is required.";
                check = false;
            }
        })
        for (const key in newFormData) {
            if (newFormData[key] === '') {
                delete newFormData[key];
            }
        }
        if (newFormData.salary) {
            newFormData.salary = Number(newFormData.salary);
        }

        setErrors(errorMsg);
        if (check) {

            newFormData['working_day'] = parseInt(formData.working_day);
            newFormData['working_hour'] = parseInt(formData.working_hour);
            newFormData['able_claim_ot'] = formData.able_claim_ot === "false" ? false : true;
            setLoading(true);
            fetch(api.edit_worker + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Data has been update successful."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        getInfo();
                        setEditable(false);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const getOrganization = () => {
        fetch(api.get_organizations + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getUserRole = () => {
        fetch(api.get_worker_roles + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setWorkerRoleList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getDepartment = () => {
        fetch(api.get_departments + '?details=1&isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setDepartmentList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getJobPosition = () => {
        fetch(api.get_worker_positions + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setPositionList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }


    const updatePermission = () => {
        fetch(api.edit_worker_permission + '/' + id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(userPermissions)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Data has been update successful."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getInfo();
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: translate(props.lang, 'Error'),
                            text: responseJson.message,
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const resetPermission = () => {
        setUserPermissions(userInfo.permission);
    }

    const togglePermission = (item) => {
        if (editable) {
            let newPermission = { ...userPermissions };
            newPermission[item] = newPermission[item] ? 0 : 1;
            setUserPermissions(newPermission);
        }
    }

    const selectAllPermission = () => {
        let currentPermission = allPermission;
        if (editable) {
            let newPermission = 0;
            if (currentPermission === 1) {
                newPermission = 0;
            } else {
                newPermission = 1;
            }

            if (userPermissions && Object.keys(userPermissions).length > 0) {
                let newFormData = { ...userPermissions };
                Object.keys(newFormData).map((item) => {
                    newFormData[item] = newPermission;
                })
                setUserPermissions(newFormData)
            }
            setAllPermission(newPermission);
        }
    }

    const toggleStatus = (status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.edit_worker_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been update successful."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="">{translate(props.lang, "Edit User")}</h2>
            {props?.auth?.permission?.editWorker ? (
                <Row className='align-items-center justify-content-end'>
                    <Col lg={4} md={4} sm={12} xs={12} className="justify-content-end d-flex align-items-center">
                        {!editable ? (
                            <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                            </Button>
                        ) : null}
                        {userInfo ? (
                            userInfo.status === 'active' ? (
                                <Button variant="outline-danger" onClick={() => toggleStatus('active')}>
                                    <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Disable")}</span>
                                </Button>
                            ) : (
                                <Button variant="outline-success" onClick={() => toggleStatus('inactive')}>
                                    <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Enable")}</span>
                                </Button>
                            )
                        ) : null}

                    </Col>
                </Row>
            ) : null}
            <div className='border-bottom mb-3'>
                <Row className='align-items-center'>
                    <Col lg={6} md={6} sm={6} xs={6} className='text-center py-2'>
                        <span className={`cursor-pointer fw-semibold px-3 fs-9 ${tab === 1 ? 'text-primary' : 'text-grey-1'}`} onClick={() => setTab(1)}>{translate(props.lang, "General")}</span>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className='text-center py-2'>
                        <span className={`cursor-pointer fw-semibold px-3 fs-9 ${tab === 2 ? 'text-primary' : 'text-grey-1'}`} onClick={() => setTab(2)}>{translate(props.lang, "Permission")}</span>
                    </Col>
                </Row>
            </div>

            {tab === 1 ?
                <Row className='align-items-start justify-content-center'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-5 px-0">
                        <Row className="mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                <span className="fs-7 fw-semibold">{translate(props.lang, "Login Account Details")}</span>
                                <hr />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.username ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Username")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="username"
                                            placeholder={translate(props.lang, "Enter username")}
                                            className={`form-control input-transparent py-1 px-3`}
                                            value={formData.username}
                                            autoComplete="off"
                                            readOnly
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.username)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.username ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Status")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="status"
                                            placeholder={translate(props.lang, "Status")}
                                            className={`form-control input-transparent py-1 px-3 text-capitalize`}
                                            value={userInfo ? userInfo.status ? translate(props.lang, userInfo.status) : '' : ''}
                                            autoComplete="off"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                <span className="fs-7 fw-semibold">{translate(props.lang, "Personal Information")}</span>
                                <hr />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Full Name as per IC")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="name"
                                            placeholder={translate(props.lang, "Enter full name")}
                                            className={`form-control input-transparent py-1 px-3`}
                                            value={formData.name}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "IC Number")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="identity_card"
                                            placeholder={translate(props.lang, "Enter IC number")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.identity_card}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.identity_card)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <Row className="px-0 mx-0">
                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                        <div className={`groupLabel-input py-1 ${errors.country_code ? 'errorBorder' : ''}`}>
                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Phone Code")}</span>
                                            <div className={`input-group-select px-3`}>
                                                <Select
                                                    options={
                                                        phoneCodeList.length > 0 ? phoneCodeList.map((item, i) => ({ key: i, value: item.dial_code, label: item.name + " (" + item.dial_code + ")" })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Phone Code")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                    isDisabled={!editable}
                                                    value={
                                                        formData.country_code ? (
                                                            phoneCodeList.filter(singleItem => singleItem.dial_code == formData.country_code).length > 0 ?
                                                                (phoneCodeList.filter(singleItem => singleItem.dial_code == formData.country_code).map((item, i) => ({ value: item.dial_code, label: item.name + " (" + item.dial_code + ")", key: i })))
                                                                : null
                                                        ) : null
                                                    }
                                                    name="country_code"
                                                    onChange={(e) => handleOptionSelected(e, 'country_code')}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                        <div className={`groupLabel-input py-1 ${errors.phone ? 'errorBorder' : ''}`}>
                                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Contact Number")}</span>
                                            <div className={`input-group`}>
                                                <input
                                                    type='text'
                                                    name="phone"
                                                    placeholder={translate(props.lang, "Enter contact number")}
                                                    className={`form-control input-transparent py-1 px-3`}
                                                    value={formData.phone}
                                                    autoComplete="off"
                                                    readOnly={!editable}
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.phone)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.email ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="email"
                                            placeholder={translate(props.lang, "Enter email address")}
                                            className={`form-control input-transparent py-1 px-3`}
                                            value={formData.email}
                                            readOnly={!editable}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.email)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.email ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Date Of Birth")}</span>
                                    <div className={`input-group`}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="w-100">
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                views={['year', 'month', 'date']}
                                                disabled={!editable}
                                                placeholder={translate(props.lang, "Select date of birth")}
                                                format="dd/MM/yyyy"
                                                className={`form-control date input-transparent py-1 px-3 w-100`}
                                                value={formData.date_of_birth ? new Date(formData.date_of_birth) : null}
                                                onChange={(e) => onChangeCalendar(e, 'date_of_birth')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.date_of_birth)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.gender ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Gender")}</span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                genderList.length > 0 ? genderList.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                            }
                                            placeholder={translate(props.lang, "Select gender")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isDisabled={!editable}
                                            value={
                                                formData.gender ? (
                                                    genderList.filter(singleItem => singleItem.value == formData.gender).length > 0 ?
                                                        (genderList.filter(singleItem => singleItem.value == formData.gender).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                        : null
                                                ) : null
                                            }
                                            name="gender"
                                            onChange={(e) => handleOptionSelected(e, 'gender')}
                                        />
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.gender)}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.nationality ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Nationality")}</span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: item.name.toLowerCase() })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                            }
                                            placeholder={translate(props.lang, "Select country")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isDisabled={!editable}
                                            value={
                                                formData.nationality ? (
                                                    countries.filter(singleItem => singleItem.name == formData.nationality.toUpperCase()).length > 0 ?
                                                        (countries.filter(singleItem => singleItem.name == formData.nationality.toUpperCase()).map((item, i) => ({ value: item.name, label: item.name.toLowerCase(), key: i })))
                                                        : null
                                                ) : null
                                            }
                                            name="nationality"
                                            onChange={(e) => handleOptionSelected(e, 'nationality')}
                                        />
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.nationality)}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <hr />
                            </Col>
                            {/* address */}
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
                                <div className={`groupLabel-input py-1 ${errors.address1 ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="address1"
                                            placeholder={translate(props.lang, "Enter Street Address Line 1")}
                                            className={`form-control input-transparent py-1 px-3 `}
                                            value={formData.address.address1}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeAddress(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.address1)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.address2 ? 'errorBorder' : ''}`}>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="address2"
                                            placeholder={translate(props.lang, "Enter Street Address Line 2")}
                                            className={`form-control input-transparent py-3 px-3 `}
                                            value={formData.address.address2}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeAddress(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.address2)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.postcode ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Postcode")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="postcode"
                                            placeholder={translate(props.lang, "Enter postcode")}
                                            className={`form-control input-transparent py-1 px-3 `}
                                            value={formData.address.postcode}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeAddress(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.postcode)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.town ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "City")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="town"
                                            placeholder={translate(props.lang, "Enter city")}
                                            className={`form-control input-transparent py-1 px-3 `}
                                            value={formData.address.town}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeAddress(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.town)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.state ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "State")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="state"
                                            placeholder={translate(props.lang, "Enter state")}
                                            className={`form-control input-transparent py-1 px-3 `}
                                            value={formData.address.state}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeAddress(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.state)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.country ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country")}</span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: item.name.toLowerCase() })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                            }
                                            placeholder={translate(props.lang, "Select country")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            value={
                                                formData.address.country ? (
                                                    countries.filter(singleItem => singleItem.name == formData.address.country.toUpperCase()).length > 0 ?
                                                        (countries.filter(singleItem => singleItem.name == formData.address.country.toUpperCase()).map((item, i) => ({ value: item.name, label: item.name.toLowerCase(), key: i })))
                                                        : null
                                                ) : null
                                            }
                                            name="country"
                                            isDisabled={!editable}
                                            onChange={(e) => handleOptionSelectedAddress(e)}
                                        />
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.country)}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.residency ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Residency")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="residency"
                                            placeholder={translate(props.lang, "Enter residency")}
                                            className={`form-control input-transparent py-1 px-3 `}
                                            value={formData.residency}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.residency)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.residency_type ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Residency Type")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="residency_type"
                                            placeholder={translate(props.lang, "Enter residency type")}
                                            className={`form-control input-transparent py-1 px-3 `}
                                            value={formData.residency_type}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.residency_type)}</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                <span className="fs-7 fw-semibold">{translate(props.lang, "Professional Details")}</span>
                                <hr />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.employeeNumber ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Employee Number")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="employeeNumber"
                                            placeholder={translate(props.lang, "Enter employee number")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.employeeNumber}
                                            autoComplete="off"
                                            readOnly
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.employeeNumber)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.employeeNumber ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select organization")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isSearchable={true}
                                            isDisabled={!editable}
                                            name="organizationId"
                                            value={
                                                formData.organizationId ? (
                                                    organizationList.filter(item => item.organization_id == formData.organizationId).length > 0 ?
                                                        (organizationList.filter(item => item.organization_id == formData.organizationId).map((item, i) => ({ value: item.organization_id, label: item.name, key: i })))
                                                        : null
                                                ) : null
                                            }
                                            onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.departmentId ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Department")} <span className="text-danger">*</span></span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                filteredDepartmentList.map((item, i) => ({ key: i, value: item.department_id, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select department")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isSearchable={true}
                                            isDisabled={!editable}
                                            name="departmentId"
                                            value={
                                                formData.departmentId ? (
                                                    filteredDepartmentList.filter(item => item.department_id == formData.departmentId).length > 0 ?
                                                        (filteredDepartmentList.filter(item => item.department_id == formData.departmentId).map((item, i) => ({ value: item.department_id, label: item.name, key: i })))
                                                        : null
                                                ) : null
                                            }
                                            onChange={(e) => handleOptionSelected(e, 'departmentId')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.departmentId)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.position ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Position")} <span className="text-danger">*</span></span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                positionList.map((item, i) => ({ key: i, value: item.position_id, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select job position")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isSearchable={true}
                                            isDisabled={!editable}
                                            name="position"
                                            value={
                                                formData.position ? (
                                                    positionList.filter(item => item.position_id == formData.position).length > 0 ?
                                                        (positionList.filter(item => item.position_id == formData.position).map((item, i) => ({ value: item.position_id, label: item.name, key: i })))
                                                        : null
                                                ) : null
                                            }
                                            onChange={(e) => handleOptionSelected(e, 'position')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.position)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.role ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Ranking")} <span className="text-danger">*</span></span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                workerRoleList.map((item, i) => ({ key: i, value: item.role_id, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select ranking")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isSearchable={true}
                                            isDisabled={!editable}
                                            name="role"
                                            value={
                                                formData.role ? (
                                                    workerRoleList.filter(item => item.role_id == formData.role).length > 0 ?
                                                        (workerRoleList.filter(item => item.role_id == formData.role).map((item, i) => ({ value: item.role_id, label: item.name, key: i })))
                                                        : null
                                                ) : null
                                            }
                                            onChange={(e) => handleOptionSelected(e, 'role')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.role)}</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                <span className="fs-7 fw-semibold">{translate(props.lang, "Payroll Details")}</span>
                                <hr />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.job_type ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Types")} <span className="text-danger">*</span></span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                jobTypesList.length > 0 ? jobTypesList.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                            }
                                            placeholder={translate(props.lang, "Select job type")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isDisabled={!editable}
                                            value={
                                                formData.job_type ? (
                                                    jobTypesList.filter(singleItem => singleItem.value == formData.job_type).length > 0 ?
                                                        (jobTypesList.filter(singleItem => singleItem.value == formData.job_type).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                        : null
                                                ) : null
                                            }
                                            name="nationality"
                                            onChange={(e) => handleOptionSelected(e, 'job_type')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.job_type)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.contract_start ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Date")}</span>
                                    <div className={`input-group`}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                disabled={!editable}
                                                format="dd/MM/yyyy"
                                                views={['year', 'month', 'date']}
                                                placeholder={translate(props.lang, "Select start date")}
                                                className={`form-control date input-transparent py-1 px-3`}
                                                value={formData.contract_start ? new Date(formData.contract_start) : null}
                                                onChange={(e) => onChangeCalendar(e, 'contract_start')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.contract_start)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.contract_end ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "End Date")}</span>
                                    <div className={`input-group`}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                disabled={!editable}
                                                format="dd/MM/yyyy"
                                                views={['year', 'month', 'date']}
                                                placeholder={translate(props.lang, "Select end date")}
                                                className={`form-control date input-transparent py-1 px-3`}
                                                value={formData.contract_end ? new Date(formData.contract_end) : null}
                                                onChange={(e) => onChangeCalendar(e, 'contract_end')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.contract_end)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.salary ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Salary")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="salary"
                                            placeholder={translate(props.lang, "Enter salary")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.salary}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.salary)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.working_day ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Working day")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="working_day"
                                            placeholder={translate(props.lang, "Enter working day")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.working_day}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.working_day)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.working_hour ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Working hour")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="working_hour"
                                            placeholder={translate(props.lang, "Enter working hour")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.working_hour}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.working_hour)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.epf_number ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "EPF Number")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="epf_number"
                                            placeholder={translate(props.lang, "Enter EPF number")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.epf_number}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.epf_number)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.socso_number ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "SOCSO Number")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="socso_number"
                                            placeholder={translate(props.lang, "Enter SOCSO number")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.socso_number}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.socso_number)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.bank_name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="bank_name"
                                            placeholder={translate(props.lang, "Enter bank name")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.bank.bank_name}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeBank(e, 'bank_name')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.bank_name)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.account_name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Name")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="account_name"
                                            placeholder={translate(props.lang, "Enter bank account name")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.bank.account_name}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeBank(e, 'account_name')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.account_name)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.account_number ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Number")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type='text'
                                            name="account_number"
                                            placeholder={translate(props.lang, "Enter bank account number")}
                                            className={`form-control input-transparent py-1  px-3`}
                                            value={formData.bank.account_number}
                                            autoComplete="off"
                                            readOnly={!editable}
                                            onChange={e => handleAddFormChangeBank(e, 'account_number')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.account_number)}</span>
                                </div>
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                <div className={`groupLabel-input py-1 ${errors.able_claim_ot ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Eligible to Claim OT")} <span className="text-danger">*</span></span>
                                    <div className={`input-group-select px-3`}>
                                        <Select
                                            options={
                                                ableOT.length > 0 ? ableOT.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                            }
                                            placeholder={translate(props.lang, "Select option")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isDisabled={!editable}
                                            value={
                                                formData.able_claim_ot ? (
                                                    ableOT.filter(singleItem => singleItem.value == formData.able_claim_ot).length > 0 ?
                                                        (ableOT.filter(singleItem => singleItem.value == formData.able_claim_ot).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                                                        : null
                                                ) : null
                                            }
                                            name="able_claim_ot"
                                            onChange={(e) => handleOptionSelected(e, 'able_claim_ot')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.able_claim_ot)}</span>
                                </div>
                            </Col>
                        </Row>

                        {editable ?
                            <Row className="mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex justify-content-start gap-3">
                                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                                        </Button>
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/user/overview')}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            : null
                        }
                    </Col>
                </Row>
                :
                <Row className='align-items-start justify-content-center'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-5 mt-3">
                        <div className="text-start">
                            <div className={`py-1`}>
                                <span className={`fw-semibold fs-9 d-block mb-1`}>{translate(props.lang, "Permission")} <span className='text-danger'>*</span></span>

                                <table className="table table-bordered table-list">
                                    <tbody>
                                        {userPermissions && Object.keys(userPermissions).length > 0 ? (
                                            <>
                                                <tr onClick={() => selectAllPermission()}>
                                                    <td className="fs-9 lh-base px-2">{translate(props.lang, "Select All")}</td>
                                                    <td>
                                                        {allPermission ? (<img src={require('../../assets/img/checkedBox.svg').default} alt="checked" />) : (<img src={require('../../assets/img/uncheckedBox.svg').default} alt="checked" />)}
                                                    </td>
                                                </tr>
                                                {permissionList.map((item, i) => (
                                                    <>
                                                        <tr key={i}>
                                                            <td colSpan={2} className="text-uppercase fs-9 fw-bold px-2">{item.module}</td>
                                                        </tr>
                                                        {item.permission.map((singleItem, x) => (
                                                            <tr onClick={() => togglePermission(singleItem.value)} key={x}>
                                                                <td className="fs-9 lh-base px-2">{singleItem.name}</td>
                                                                <td>{userPermissions[singleItem.value] ? (<img src={require('../../assets/img/checkedBox.svg').default} alt="checked" />) : (<img src={require('../../assets/img/uncheckedBox.svg').default} alt="checked" />)}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ))}
                                            </>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {editable ? (
                            <Row className="mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                    <div className="d-flex justify-content-start gap-3">
                                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => updatePermission()} disabled={loading}>
                                            <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
                                        </Button>
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/user/overview')}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        ) : null}
                    </Col>
                </Row>
            }
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditUser);
