import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import {
    faChevronRight,
    faEarthAmericas,
    faListCheck,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'react-bootstrap';
import { getColor, getLabel } from 'utils/function';
import { translate } from 'utils/translate';

const ProjectDetailsSummary = (props) => {
    const scrollToSection = () => {
        window.scrollTo({
            top: document.querySelector("#taskSection").offsetTop,
            behavior: "smooth",
         })
    }

    return (
        <>
            <div className="mb-4 mb-xl-7">
                <table className="lh-sm mb-4 mb-sm-0 mb-xl-2">
                    <tbody>
                        <tr>
                            <td className="py-1" colSpan={2}>
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                        icon={faEarthAmericas}
                                        className="me-2 text-body-tertiary fs-9"
                                    />
                                    <h5 className="text-body">{props.projectInfo ? props.projectInfo.project_number : ''}</h5>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="align-top py-1">
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="me-2 text-body-tertiary fs-9"
                                    />
                                    <h5 className="text-body mb-0 text-nowrap">{translate(props.lang, "Client")}</h5>
                                </div>
                            </td>
                            <td className="py-1">
                                <div className='d-flex'>
                                    <span className="fw-bold text-body-highlight px-1 d-block">{" "}:{" "}</span>
                                    <span className="fw-bold text-body-highlight d-block">{props.projectInfo ? props.projectInfo.customer ? props.projectInfo.customer.name : '-' : '-'}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="align-top py-1">
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                        icon={faCreditCard}
                                        className="me-2 text-body-tertiary fs-9"
                                    />
                                    <h5 className="text-body mb-0 text-nowrap">{translate(props.lang, "Budget")}</h5>
                                </div>
                            </td>
                            <td className="fw-bold text-body-highlight px-1">{" "}: {props.projectInfo ? props.projectInfo.budget : '-'}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="lh-sm">
                    <tbody>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Started")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                    {props.projectInfo && props.projectInfo.start_date ? props.projectInfo.start_date : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Deadline")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 fs-8'>
                                    {props.projectInfo && props.projectInfo.due_date ? props.projectInfo.due_date : '-'}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Progress")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='fw-semibold text-capitalize status-dropdown text-start px-1 text-warning fs-8'>
                                    {props.projectInfo ? props.projectInfo.progress_percentage : 0}%
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Status")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight">
                                {props.projectInfo && props.projectInfo.progress_status ?
                                    <div className='btn light px-2 text-capitalize text-center status-dropdown fs-8 fw-semibold' style={{ background: getColor(props.projectInfo.progress_status, 'status'), color: '#fff' }}>
                                        {translate(props.lang, getLabel(props.projectInfo.progress_status, 'status'))}
                                    </div> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">{translate(props.lang, "Priority")}</td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight">
                                {props.projectInfo && props.projectInfo.priority_level.toString() ?
                                    <div className='btn light px-2 text-capitalize text-center status-dropdown fs-8 fw-semibold' style={{ background: getColor(props.projectInfo.priority_level, 'priority'), color: '#fff' }}>
                                        {translate(props.lang, getLabel(props.projectInfo.priority_level.toString(), 'priority'))}
                                    </div>
                                    : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-body text-nowrap fw-bold">
                                {translate(props.lang, "Company Involved")}
                            </td>
                            <td className='fw-semibold fs-8'>:</td>
                            <td className="">
                                <div className='text-capitalize text-start px-1 fs-8'>
                                    {props.projectInfo && props.projectInfo.project_organization ?
                                        props.projectInfo.project_organization.map((item, i) => (
                                            <Badge variant="tag" key={i} className='mb-1 me-1 fw-semibold'>
                                                {item?.organization?.name}
                                            </Badge>
                                        ))
                                        : " -"
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                        icon={faListCheck}
                        className="me-2 text-body-tertiary fs-9"
                    />
                    <h5 className="text-body-emphasis mb-0 me-2">
                        {props.projectInfo ? props.projectInfo.task.length : 0}<span className="text-body fw-normal ms-2">{translate(props.lang, "tasks")}</span>
                    </h5>
                    <div className="fw-bold fs-9 cursor-pointer" onClick={()=>scrollToSection()}>
                        {translate(props.lang, "See tasks")}{' '}
                        <FontAwesomeIcon icon={faChevronRight} className="me-2 fs-10" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectDetailsSummary;
