import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'utils/api';
import { translate } from 'utils/translate';
import ActivityTimeline from './ActivityTimelime';
import ProjectDetailsSummary from './ProjectDetailsSummary';
import ProjectTask from './ProjectTask';
import ProjectTimesheet from './ProjectTimesheet';
import TaskCompleted from './TaskCompleted';
import TeamMembers from './TeamMembers';
import WorkLoads from './WorkLoads';
import { logout } from 'store/actions/AuthActions';
import { useDispatch } from 'react-redux';

const ProjectDetails = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [projectInfo, setProjectInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const timerRef = useRef(null);

    let breadcrumbData = [
        { label: translate(props.lang, 'Project'), url: '/project/overview' },
        { label: translate(props.lang, 'Details'), active: true }
    ]

    useEffect(() => {
        if (props?.auth?.permission?.allProject) {
            let isMounted = true;
            if (id && isMounted) {
                getInfo();
            } else {
                setProjectInfo(null)
            }
            return () => {
                isMounted = false;  // Clean up when component is unmounted
            };
        }
    }, [id, props.lang, props.auth.permission])

    const getInfo = () => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_project_details + `/${id}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setProjectInfo(responseJson.data)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, projectInfo?.name)}</h2>
                {loading ? (
                    <div>
                        <DataLoading />
                    </div>
                ) : (
                    <div className="mb-4">
                        <Row className="g-0">
                            <Col xs={12} xxl={8} className="px-0 bg-body">
                                <div className="pb-9">
                                    {/* <div className="mb-5">
                                        <div className="d-flex justify-content-end">
                                            <RevealDropdownTrigger>
                                                <RevealDropdown>
                                                    <Dropdown.Item>Edit</Dropdown.Item>
                                                    <Dropdown.Item className="text-danger">Delete</Dropdown.Item>
                                                    <Dropdown.Item>Downlaod</Dropdown.Item>
                                                    <Dropdown.Item>Report abuse</Dropdown.Item>
                                                </RevealDropdown>
                                            </RevealDropdownTrigger>
                                        </div>
                                    </div> */}
                                    <Row className="gx-0 gx-sm-5 gy-8 mb-5">
                                        {/* <Col xs={12} xl={3} xxl={4} className="pe-xl-0">
                                            <ProjectDetailsSummary projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                        </Col>
                                        <Col xs={12} xl={9} xxl={8}>
                                            <TaskCompleted projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                        </Col> */}
                                        <Col xs={12} xl={12} xxl={12} className="pe-xl-0">
                                            <ProjectDetailsSummary projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                        </Col>
                                    </Row>
                                    <Row className="gx-0 gx-sm-5 gy-8 mb-5 align-items-center">
                                        <Col xs={12} sm={5} lg={5} xl={5} xxl={6}>
                                            <WorkLoads projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                        </Col>
                                        <Col xs={12} sm={7} lg={7} xl={7} xxl={6}>
                                            <TeamMembers projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                        </Col>
                                    </Row>
                                    <div className=''>
                                        <h3 className="text-body-emphasis mb-4">{translate(props.lang, "Description")}</h3>
                                        {projectInfo && projectInfo.description ? (
                                            <p className="text-body-secondary mb-4" dangerouslySetInnerHTML={{ __html: projectInfo && projectInfo.description }}></p>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} xxl={4} className="px-0 border-start-xxl">
                                <div className="py-3 px-4 px-lg-6 bg-light dark__bg-gray-1100 position-relative top-1">
                                    <h3 className="text-body-highlight mb-4 fw-bold">
                                        {translate(props.lang, "Activity Log")}
                                    </h3>
                                    <ActivityTimeline projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <ProjectTask projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                            </Col>
                            <Col lg={12}>
                                <ProjectTimesheet projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ProjectDetails);
