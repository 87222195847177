import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import Error404 from 'pages/error/Error404';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { logout } from 'store/actions/AuthActions';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

const typeList = [
  { label: 'Organization', value: 'organization' },
  { label: 'Department', value: 'department' },
  { label: 'User', value: 'user' },
]

const NewUserGroup = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let errorObj = {
    name: null,
    type: null,
    audience: null,
    description: null,
  }
  const { config } = useAppContext();
  const productPerpage = 10;
  const [errors, setErrors] = useState(errorObj);
  const [loading, setLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    description: '',
    audience: [],
  })
  let breadcrumbData = [
    { label: translate(props.lang, 'Setting'), url: '#' },
    { label: translate(props.lang, 'User Group'), url: '/user-group/overview' },
    { label: translate(props.lang, 'Edit User Group'), active: true }
  ]

  const loadOptionsOrganization = async (searchQuery, loadedOptions, { page }) => {
    try {
      const response = await fetch(`${api.get_organizations + "?page=" + page + "&limit=" + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
        method: 'GET',
        headers: {
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }
      });

      const responseJson = await response.json();
      if (responseJson.status !== "success") {
        throw new Error('Failed to fetch: ' + responseJson);
      }

      const options = responseJson.data.data.map(item => ({
        value: item.organization_id,
        label: item.name
      }));
      let totalPage = Math.ceil(responseJson.data.subs.totalOrganization / productPerpage);

      const hasMore = page < totalPage;

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      };
    }
  }

  const loadOptionsDepartment = async (searchQuery, loadedOptions, { page }) => {
    try {
      const response = await fetch(`${api.get_departments + "?page=" + page + "&limit=" + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
        method: 'GET',
        headers: {
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }
      });

      const responseJson = await response.json();
      if (responseJson.status !== "success") {
        throw new Error('Failed to fetch: ' + responseJson);
      }

      const options = responseJson.data.data.map(item => ({
        value: item.department_id,
        label: item.name + ' (' + item.organization.name + ')'
      }));
      let totalPage = Math.ceil(responseJson.data.subs.totalDepartment / productPerpage);

      const hasMore = page < totalPage;

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      };
    }
  }

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const response = await fetch(`${api.get_workers + "?page=" + page + "&limit=" + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
        method: 'GET',
        headers: {
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }
      });

      const responseJson = await response.json();
      if (responseJson.status !== "success") {
        throw new Error('Failed to fetch: ' + responseJson);
      }

      const options = responseJson.data.data.map(item => ({
        value: item.worker_id,
        label: item.name + ' (' + item.employee_number + ')'
      }));
      let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);

      const hasMore = page < totalPage;

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      };
    }
  }

  function handleChange(value, type) {
    if (type === 'organization') {
      setSelectedOrganization([value])
    } else if (type === 'department') {
      setSelectedDepartment([value])
    } else {
      setSelectedUser([value])
    }
  }

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...formData };
    newFormData[fieldName] = fieldValue;
    setFormData(newFormData);
  }

  const handleOptionSelected = async (e, type) => {
    const { value } = e;
    const newFormData = { ...formData };
    newFormData[type] = value;
    setFormData(newFormData);
  }

  const submitForm = () => {
    let check = true;
    let errorMsg = { ...errorObj };
    let newFormData = { ...formData };
    if (!formData.name) {
      errorMsg.name = "This field is required.";
      check = false;
    }
    // if (!formData.description) {
    //   errorMsg.description = "This field is required.";
    //   check = false;
    // }
    if (!formData.type) {
      errorMsg.type = "This field is required.";
      check = false;
    } else {
      let arr = [];
      if (formData.type === 'organization') {
        if (selectedOrganization.length === 0) {
          errorMsg.organization = "This field is required.";
          check = false;
        } else {
          selectedOrganization.map((item, i) => {
            arr.push(item.value)
          })
        }
      } else if (formData.type === 'department') {
        if (selectedDepartment.length === 0) {
          errorMsg.department = "This field is required.";
          check = false;
        } else {
          selectedDepartment.map((item, i) => {
            arr.push(item.value)
          })
        }
      } else if (formData.type === 'user') {
        console.log(selectedUser)
        if (selectedUser.length === 0) {
          errorMsg.user = "This field is required.";
          check = false;
        } else {
          selectedUser.map((item, i) => {
            arr.push(item.value)
          })
        }
      }
      newFormData.audience = arr;
    }

    setErrors(errorMsg);
    if (check) {
      setLoading(true);
      fetch(api.add_announcement_group, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
        body: JSON.stringify(newFormData)
      })
        .then(response => response.json())
        .then(responseJson => {
          setLoading(false)
          if (responseJson.status === 'success') {
            resetForm();
            Swal.fire({
              icon: "success",
              title: translate(props.lang, 'Success'),
              text: translate(props.lang, "Data has been created successfully."),
              background: getSweelAlertBg(), // Dark background color
              color: getSweelAlertTextColor(), // Text color
            });
            navigate('/user-group/overview')
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else if (responseJson.tokenExpired) {
              dispatch(logout());
              navigate('/')
            } else {
              if (Array.isArray(responseJson.message)) {
                Swal.fire({
                  icon: "error",
                  title: translate(props.lang, 'Error'),
                  text: responseJson.message.join('\n'),
                  background: getSweelAlertBg(), // Dark background color
                  color: getSweelAlertTextColor(), // Text color
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: translate(props.lang, 'Error'),
                  text: responseJson.message,
                  background: getSweelAlertBg(), // Dark background color
                  color: getSweelAlertTextColor(), // Text color
                });
              }
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }
  }

  useEffect(() => {
    setSelectedDepartment([]);
    setSelectedOrganization([]);
    setSelectedUser([]);
  }, [formData.type])

  const resetForm = () => {
    setSelectedDepartment([]);
    setSelectedOrganization([]);
    setSelectedUser([]);
    setErrors(errorObj);
    setFormData({
      name: '',
      type: '',
      description: '',
      audience: [],
    })
  }

  return (
    props?.auth?.permission?.createAnnouncementGroup ? (
      <div>
        <PageBreadcrumb items={breadcrumbData} />
        <h2 className="mb-4">{translate(props.lang, "New User Group")}</h2>
        <Row className='align-items-center justify-content-center'>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
            <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
              <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "User Group Name")} <span className="text-danger">*</span></span>
              <div className={`input-group`}>
                <input
                  type={'text'}
                  name="name"
                  placeholder={translate(props.lang, "Enter user group name")}
                  className="form-control input-transparent py-1 px-3"
                  value={formData.name}
                  autoComplete="off"
                  onChange={e => handleAddFormChange(e)}
                />
              </div>
            </div>
            <div>
              <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
            <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
              <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
              <div className={`input-group`}>
                <Form.Control
                  as="textarea"
                  name="description"
                  className="form-control input-transparent py-1 px-3"
                  rows={3}
                  value={formData.description}
                  placeholder={translate(props.lang, "Enter description")}
                  onChange={e => handleAddFormChange(e)}
                />
              </div>
            </div>
            <div>
              <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
            <div className={`groupLabel-input py-1 ${errors.type ? 'errorBorder' : ''}`}>
              <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Target Audience Type")} <span className="text-danger">*</span></span>
              <div className="input-group-select px-3">
                <Select
                  options={
                    typeList.map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) }))
                  }
                  placeholder={translate(props.lang, "Select type")}
                  className="input-transparent w-100 text-capitalize"
                  styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                  isSearchable={true}
                  value={typeList.filter(option => option.value === formData.type).length > 0 ? typeList.filter(option => option.value === formData.type).map((item, i) => ({ key: i, value: item.value, label: translate(props.lang, item.label) })) : null}
                  name="type"
                  onChange={(e) => handleOptionSelected(e, 'type')}
                />
              </div>
            </div>
            <div>
              <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.type)}</span>
            </div>
          </Col>
          {formData.type === 'organization' ? (
            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
              <div className={`groupLabel-input py-1 ${errors.organization ? 'errorBorder' : ''}`}>
                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                <div className="input-group-select px-3">
                  <AsyncPaginate
                    placeholder={translate(props.lang, "Select organization")}
                    loadingMessage={() => translate(props.lang, 'Loading Data')}
                    debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                    loadOptions={loadOptionsOrganization} // Function to load options asynchronously
                    additional={{
                      page: 1, // Initial page
                    }}
                    className="input-transparent w-100 text-capitalize"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    value={selectedOrganization}
                    closeMenuOnSelect={true}
                    onChange={(e) => handleChange(e, 'organization')}
                  />
                </div>
              </div>
              <div>
                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization)}</span>
              </div>
            </Col>
          ) : null}
          {formData.type === 'department' ? (
            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
              <div className={`groupLabel-input py-1 ${errors.department ? 'errorBorder' : ''}`}>
                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Department")} <span className="text-danger">*</span></span>
                <div className="input-group-select px-3">
                  <AsyncPaginate
                    placeholder={translate(props.lang, "Select department")}
                    loadingMessage={() => translate(props.lang, 'Loading Data')}
                    debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                    loadOptions={loadOptionsDepartment} // Function to load options asynchronously
                    additional={{
                      page: 1, // Initial page
                    }}
                    className="input-transparent w-100 text-capitalize"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    value={selectedDepartment}
                    closeMenuOnSelect={true}
                    onChange={(e) => handleChange(e, 'department')}
                  />
                </div>
              </div>
              <div>
                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.department)}</span>
              </div>
            </Col>
          ) : null}
          {formData.type === 'user' ? (
            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
              <div className={`groupLabel-input py-1 ${errors.user ? 'errorBorder' : ''}`}>
                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "User")} <span className="text-danger">*</span></span>
                <div className="input-group-select px-3">
                  <AsyncPaginate
                    placeholder={translate(props.lang, "Select user")}
                    loadingMessage={() => translate(props.lang, 'Loading Data')}
                    debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                    loadOptions={loadOptions} // Function to load options asynchronously
                    additional={{
                      page: 1, // Initial page
                    }}
                    className="input-transparent w-100 text-capitalize"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    value={selectedUser}
                    closeMenuOnSelect={true}
                    onChange={(e) => handleChange(e, 'user')}
                  />
                </div>
              </div>
              <div>
                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.user)}</span>
              </div>
            </Col>
          ) : null}
          <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
            <div className="d-flex justify-content-start gap-3">
              <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                <span className="fw-bold fs-9 text-white">{loading ? <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" /> : translate(props.lang, "Submit")}</span>
              </Button>
              <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/user-group/overview')}>
                {translate(props.lang, "Cancel")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    ) : <Error404 />
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(NewUserGroup);
