import PageBreadcrumb from "components/common/PageBreadcrumb";
import DataLoading from "page/Components/DataLoading";
import { useEffect, useState, useRef } from "react";
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from "store/actions/AuthActions";
import { api, attachmentUrl } from "utils/api";
import { translate } from "utils/translate";

const UserAnnouncementDetails = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(null);
    const timerRef = useRef(null);
    let breadcrumbData = [
        { label: translate(props.lang, 'Announcement'), url: '/user-announcement' },
        { label: translate(props.lang, 'Details'), active: true }
    ]
    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token]);

    const getInfo = () => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_announcement_details + `/${id}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setInfo(responseJson.data);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }

                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    return (
        <>
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{info && info.title ? info.title : ''}</h2>
                    <Row className="align-items-center px-0 mx-0">
                        {loading ? (
                            <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                <DataLoading theme={'light'} />
                            </Col>
                        ) : (
                            info ? (
                                <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                    <div className="news-container px-4 py-4">
                                        <Row className="px-0 mx-0 align-items-center">
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div className="d-flex align-items-center">
                                                    <span className="fs-7 fw-bold">{info.title} </span>
                                                </div>
                                            </Col>

                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <hr />
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="fs-8" dangerouslySetInnerHTML={{ __html: info.description }}></div>
                                            </Col>
                                            {info && info.image.length > 0 ? (
                                                <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                                    <div className="mb-2">
                                                        <span className="fs-8 fw-bold">{translate(props.lang, "Image")}</span>
                                                    </div>
                                                    {info.image.map((item, i) => (
                                                        <div className="">
                                                            <a className="fs-9 fw-normal" href={attachmentUrl + item.path} target="_blank">{i + 1}. {item.filename ? item.filename : item.name}</a>
                                                        </div>
                                                    ))}
                                                </Col>
                                            ) : null}
                                            {info && info.attachment.length > 0 ? (
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <div className="mb-2">
                                                        <span className="fs-8 fw-bold">{translate(props.lang, "Attachment")}</span>
                                                    </div>
                                                    {info.attachment.map((item, i) => (
                                                        <div>
                                                            <a className="fs-9 fw-normal" href={attachmentUrl + item.path} target="_blank">{i + 1}. {item.filename ? item.filename : item.name}</a>
                                                        </div>
                                                    ))}
                                                </Col>
                                            ) : null}
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <hr />
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="text-end">
                                                <span className="text-grey-9 fs-9 fw-normal d-block">{translate(props.lang, "Posted at")} {info.release_date}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ) : null
                        )}
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(UserAnnouncementDetails);