import { faAngleRight, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AsyncPaginate } from "react-select-async-paginate";
import { api } from 'utils/api';
import { priorityList, stageList, statusList } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { translate } from 'utils/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'store/actions/AuthActions';

const WorkflowOverview = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useAppContext();
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState([])
    const [projectInfo, setProjectInfo] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const timerRef = useRef(null);

    useEffect(() => {
        if (projectId) {
            getList();
        } else {
            setResult([])
        }
    }, [projectId])

    const getList = () => {
        setLoading(true)
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_project_dashboard + `/activity_log`;
            if (projectId) {
                url += `?project_id=${projectId.value}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        if(responseJson.data) {
                            setProjectInfo(responseJson.data);
                            setResult(responseJson.data.task);
                        }
                        else {
                            setProjectInfo(null);
                            setResult([]);
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const loadProjectOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        try {
            const response = await fetch(`${api.get_project + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=1&isOptions=1&name=' + searchQuery}`, {
                method: 'GET',
                headers: {
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }
            });

            const responseJson = await response.json();

            if (responseJson.status !== "success") {
                throw new Error('Failed to fetch: ' + responseJson);
            }
            const options = responseJson.data ? responseJson.data.data.map(item => ({
                value: item.project_id,
                label: item.name
            })) : [];
            let totalPage = responseJson.data ? Math.ceil(responseJson.data.subs.totalProjects / productPerpage) : 0;
            const hasMore = page < totalPage;
            return {
                options,
                hasMore,
                additional: {
                    page: page + 1,
                },
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                options: [],
                hasMore: false,
                additional: {
                    page,
                },
            };
        }
    }

    const getColor = (val, type) => {
        let color = '#fff';
        if (type === 'status') {
            let filterList = statusList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        } else if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#000';
            }
        }

        return color;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    return (
        <Card className="h-100 todo-list">
            <Card.Header className="border-bottom-0 py-3">
                <Row className="justify-content-between align-items-center">
                    <Col lg={8} md={8} sm={12} xs={12} className='mb-md-0 mb-3'>
                        <h3 className="text-body-emphasis">{translate(props.lang, "Workflow Overview")}</h3>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className='text-center'>
                        <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                            <div className="input-group-select px-3 w-100 py-1">
                                <AsyncPaginate
                                    placeholder={translate(props.lang, "Select project")}
                                    loadingMessage={() => translate(props.lang, 'Loading Data')}
                                    debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                    loadOptions={loadProjectOptions} // Function to load options asynchronously
                                    additional={{
                                        page: 1, // Initial page
                                    }}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                    closeMenuOnSelect={true}
                                    value={projectId}
                                    onChange={(e) => setProjectId(e)}
                                    isClearable
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="py-0 scrollbar to-do-list-body" style={{ overflowX: 'hidden' }}>
                <Row className="justify-content-center align-items-center position-relative pb-4 h-100">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        {loading ? (
                            <DataLoading theme='light' />
                        ) : (
                            <>
                                {projectInfo ? (
                                    <div className='d-flex w-100 align-items-center justify-content-start mb-3'>
                                        <span className='fw-bold fs-9 text-decoration-underline' onClick={() => navigate(`/project/details/${projectInfo?.project_id}`)}>{translate(props.lang, "View Project Details")}</span>
                                    </div>
                                ) : null}
                                {result.length > 0 ? (
                                    result.map((item, i) => (
                                        <div key={i} className={`workflowRow d-flex align-items-center py-1 ${i !== result.length - 1 ? 'border-bottom' : ''} todolist-item w-100`} onClick={() => navigate(`/task/details/${item.project_task_id}`, { state: projectInfo?.project_id })}>
                                            <Row className='align-items-center px-0 mx-0 w-100'>
                                                <Col lg={6} md={6} sm={6} xs={6} className='mb-2 mb-md-0 ps-0'>
                                                    <span className='fw-bold fs-9 cursor-pointer' onClick={() => navigate(`/task/details/${item.project_task_id}`, { state: projectInfo?.project_id })}>{item.name ? item.name : ''}</span>
                                                    <div className="px-2 text-capitalize text-center status-dropdown d-inline-block ms-2" style={{ background: getColor(item.progress_status, 'status'), color: '#fff' }}>
                                                        <span className='fw-bold fs-9'>{translate(props.lang, getLabel(item.progress_status, 'status'))}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6} className='text-end pe-0'>
                                                    <div className='d-flex justify-content-end'>
                                                        <div className="px-2 text-capitalize text-center status-dropdown d-inline-block" style={{ background: getColor(item?.workflow[0]?.workflow[0]?.stage, 'stage'), color: '#fff' }}>
                                                            <span className='fw-bold fs-9'>{translate(props.lang, getLabel(item?.workflow[0]?.workflow[0]?.stage, 'stage'))}</span>
                                                        </div>
                                                        <div className='mx-2'><FontAwesomeIcon icon={faAngleRight} /></div>
                                                        <div className="px-2 text-capitalize text-center status-dropdown d-inline-block" style={{ background: getColor(item?.workflow[0]?.workflow[1]?.stage, 'stage'), color: '#fff' }}>
                                                            <span className='fw-bold fs-9'>{translate(props.lang, getLabel(item?.workflow[0]?.workflow[1]?.stage, 'stage'))}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))
                                ) : (
                                    <div>
                                        <NoResultFound />
                                    </div>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(WorkflowOverview);

